import { Cascader } from 'antd'

import { FormikWithCustomHandlersPropTypes } from '../propTypes'
import withCustomHandlers from '../withCustomHandlers'

const FormikCascader = ({
    field: { value },
    form,
    handleChange,
    handleBlur,
    handleClick,
    ...props
}) => (
    <Cascader
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
    />
)

FormikCascader.propTypes = FormikWithCustomHandlersPropTypes

export default withCustomHandlers(FormikCascader)
