import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import TrashIconSvg from './TrashIconSvg'

const TrashIcon = (props: IconProps): ReactElement => (
    <Icon component={TrashIconSvg} aria-label="delete" {...props} />
)

export default TrashIcon
