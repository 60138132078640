/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable no-undef */

import { composeWithDevTools } from '@redux-devtools/extension'
import { captureException } from '@sentry/browser'
import {
    applyMiddleware,
    legacy_createStore as createStore,
    Reducer,
} from 'redux'
import { Persistor, persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'

import { asyncReducerHydrated } from 'actions/ui/app'
import { DynamicReducerKey, DynamicReducerOptions } from 'reducers/ui/ui'

import { sentryMiddleware } from './middlewares'
import { buildRootReducer, injectReducer as injectUiReducer } from './reducers'
import SagaManager from './sagas/SagaManager'
import storeRegistry from './utilities/storeRegistry'

// create saga middleware
const sagaMiddleware = createSagaMiddleware({
    onError: (error: Error, errorInfo: any) => {
        captureException(error, errorInfo)
    },
})

// create redux store with redux tools in development and beta stages
const store = createStore(
    buildRootReducer(),
    {},
    composeWithDevTools({ trace: true })(
        ...[applyMiddleware(sagaMiddleware, sentryMiddleware)]
    )
)

// redux persist
let persistor = persistStore(store)

const injectReducerToStore = (
    key: DynamicReducerKey,
    reducer: Reducer<any, any>,
    options: DynamicReducerOptions = {}
): void => {
    injectUiReducer(key, reducer, options)
    store.replaceReducer(buildRootReducer())
    persistor.persist()
    store.dispatch(
        asyncReducerHydrated({
            key,
            initialState: reducer(undefined, { type: '@@INIT' }),
        })
    )
}

// run sagas
SagaManager.startSagas(sagaMiddleware)

// register store for access outside of components while avoid circular references
storeRegistry.registerStore(store)

if (module.hot) {
    module.hot.accept('./reducers', () => {
        const newReducers = require('./reducers')
        store.replaceReducer(newReducers.buildRootReducer())
        persistor.persist()
    })

    module.hot.accept('./sagas/SagaManager', () => {
        SagaManager.cancelSagas(store)

        const newSagaManager = require('./sagas/SagaManager')
        newSagaManager.default.startSagas(sagaMiddleware)
    })
}

const getPersistor = (): Persistor => persistor

export { store, getPersistor, injectReducerToStore }
