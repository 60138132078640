import {
    makeCurrencyMetricFieldFunc,
    makePercentageMetricFieldFunc,
} from './localUtils'
import translations from './translations/productAds'

export const raw_cogs_weighted = makeCurrencyMetricFieldFunc({
    name: translations.raw_cogs_weighted.name,
    shortName: translations.raw_cogs_weighted.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'raw_cogs_weighted',
})

export const supplier_cogs_weighted = makeCurrencyMetricFieldFunc({
    name: translations.supplier_cogs_weighted.name,
    shortName: translations.supplier_cogs_weighted.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'supplier_cogs_weighted',
})

export const misc_cogs_weighted = makeCurrencyMetricFieldFunc({
    name: translations.misc_cogs_weighted.name,
    shortName: translations.misc_cogs_weighted.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'misc_cogs_weighted',
})

export const total_cogs_weighted = makeCurrencyMetricFieldFunc({
    name: translations.total_cogs_weighted.name,
    shortName: translations.total_cogs_weighted.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'total_cogs_weighted',
})

export const gross_profit_1_day_same_sku = makeCurrencyMetricFieldFunc({
    name: translations.gross_profit_1_day_same_sku.name,
    shortName: translations.gross_profit_1_day_same_sku.shortName,
    localDefinition: translations.gross_profit_1_day_same_sku.helpText,
    deltas: ['change', 'percentage_change'],
    id: 'gross_profit_1_day_same_sku',
})

export const gross_profit_7_day_same_sku = makeCurrencyMetricFieldFunc({
    name: translations.gross_profit_7_day_same_sku.name,
    shortName: translations.gross_profit_7_day_same_sku.shortName,
    localDefinition: translations.gross_profit_7_day_same_sku.helpText,
    deltas: ['change', 'percentage_change'],
    id: 'gross_profit_7_day_same_sku',
})

export const gross_profit_14_day_same_sku = makeCurrencyMetricFieldFunc({
    name: translations.gross_profit_14_day_same_sku.name,
    shortName: translations.gross_profit_14_day_same_sku.shortName,
    localDefinition: translations.gross_profit_14_day_same_sku.helpText,
    deltas: ['change', 'percentage_change'],
    id: 'gross_profit_14_day_same_sku',
})

export const gross_profit_30_day_same_sku = makeCurrencyMetricFieldFunc({
    name: translations.gross_profit_30_day_same_sku.name,
    shortName: translations.gross_profit_30_day_same_sku.shortName,
    localDefinition: translations.gross_profit_30_day_same_sku.helpText,
    deltas: ['change', 'percentage_change'],
    id: 'gross_profit_30_day_same_sku',
})

export const gross_profit_margin_1_day_same_sku = makePercentageMetricFieldFunc(
    {
        name: translations.gross_profit_margin_1_day_same_sku.name,
        shortName: translations.gross_profit_margin_1_day_same_sku.shortName,
        localDefinition:
            translations.gross_profit_margin_1_day_same_sku.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'gross_profit_margin_1_day_same_sku',
    }
)

export const gross_profit_margin_7_day_same_sku = makePercentageMetricFieldFunc(
    {
        name: translations.gross_profit_margin_7_day_same_sku.name,
        shortName: translations.gross_profit_margin_7_day_same_sku.shortName,
        localDefinition:
            translations.gross_profit_margin_7_day_same_sku.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'gross_profit_margin_7_day_same_sku',
    }
)

export const gross_profit_margin_14_day_same_sku =
    makePercentageMetricFieldFunc({
        name: translations.gross_profit_margin_14_day_same_sku.name,
        shortName: translations.gross_profit_margin_14_day_same_sku.shortName,
        localDefinition:
            translations.gross_profit_margin_14_day_same_sku.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'gross_profit_margin_14_day_same_sku',
    })

export const gross_profit_margin_30_day_same_sku =
    makePercentageMetricFieldFunc({
        name: translations.gross_profit_margin_30_day_same_sku.name,
        shortName: translations.gross_profit_margin_30_day_same_sku.shortName,
        localDefinition:
            translations.gross_profit_margin_30_day_same_sku.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'gross_profit_margin_30_day_same_sku',
    })
