import { ReactElement } from 'react'

import { UserAddOutlined } from '@ant-design/icons'

import { USER_GROUP_TYPE_NAMES } from 'const/organizations'
import { UserGroupType } from 'types'

import * as styles from './styles.scss'

type Props = {
    emails: string[]
    groupType: UserGroupType
}

export default function InviteMembersList({
    emails,
    groupType,
}: Props): ReactElement {
    return (
        <ul className={styles['recipients-list']}>
            {emails.map((email) => (
                <li key={email} className={styles['recipients-list--item']}>
                    <span className={styles['recipient-email--wrapper']}>
                        <UserAddOutlined
                            style={{ marginRight: 8, marginLeft: 4 }}
                        />
                        <span className={styles['recipient-email']}>
                            {email}
                        </span>
                        <span style={{ marginLeft: 8 }}>
                            (Member Type: {USER_GROUP_TYPE_NAMES[groupType]})
                        </span>
                    </span>
                </li>
            ))}
        </ul>
    )
}
