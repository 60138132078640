import { handleActions } from 'redux-actions'

import { COUNTRIES, LABELS, AD_ACCOUNTS } from 'const/filters'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { defaultTab } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [FILTERS]: {
        [COUNTRIES]: [],
        [LABELS]: [],
        [AD_ACCOUNTS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [COUNTRIES, LABELS, AD_ACCOUNTS],
        order: [COUNTRIES, LABELS, AD_ACCOUNTS],
        displayState: {
            [COUNTRIES]: true,
            [LABELS]: true,
            [AD_ACCOUNTS]: true,
        },
    },
}

export default handleActions({}, initialState)
