import { ReactElement } from 'react'

import { Alert } from 'antd'
import { useDispatch } from 'react-redux'

import { resetAuthErrors } from 'actions/auth'
import { useAuthDomainValue } from 'hooks'

const AuthError = (): ReactElement | null => {
    const dispatch = useDispatch()
    const error: string | null = useAuthDomainValue('error')

    if (!error) {
        return null
    }

    return (
        <div style={{ marginTop: 12 }}>
            <Alert
                message={error}
                type="error"
                closable
                afterClose={() => dispatch(resetAuthErrors())}
            />
        </div>
    )
}

export default AuthError
