import { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { CurrencyCode } from 'types'

import * as styles from './styles.scss'

const ProductCogsTableHeaderRow = (props: {
    currencyCode: CurrencyCode
}): ReactElement => {
    const { currencyCode } = props
    const { t } = useTranslation()

    return (
        <tr>
            <th className={styles.dateCol}>
                {t(
                    'cogs:CogsManagement.edit.table.headers.effectiveDate',
                    'Effective Date'
                )}
            </th>
            <th />
            <th>
                {t(
                    'cogs:CogsManagement.edit.table.headers.cogsPerUnit',
                    'Cost of Goods Per Unit'
                )}
            </th>
            <th>
                {t(
                    'cogs:CogsManagement.edit.table.headers.supplierCostPerUnit',
                    'Supplier Shipping Cost Per Unit'
                )}
            </th>
            <th>
                {t(
                    'cogs:CogsManagement.edit.table.headers.miscCostPerUnit',
                    'Miscellaneous Cost Per Unit'
                )}
            </th>
            <th className={styles.amountCell}>
                {t(
                    'cogs:CogsManagement.edit.table.headers.totalCost',
                    'Total Cost ({{ currencyCode }})',
                    { currencyCode }
                )}
            </th>
        </tr>
    )
}

export default ProductCogsTableHeaderRow
