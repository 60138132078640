import isEmpty from 'lodash/isEmpty'

import {
    AD_ITEM_STATES,
    CAMPAIGNS,
    CAMPAIGN_BUDGET_TYPES,
    CAMPAIGN_NAME,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    DATES,
    FACT_TYPES,
    AD_GROUPS,
    AD_GROUP_STATES,
    METRIC_FILTERS,
    KEYWORD_TEXT,
    KEYWORD_MATCH_TYPES,
    KEYWORD_STATES,
    AD_ACCOUNTS,
} from 'const/filters'
import { isNonEmptyArray, isNonEmptyString } from 'helpers/typeGuard'
import { formatDateFromFilter } from 'helpers/utils'
import { FiltersState, MetricFilter, WmAdvertisingFactsParams } from 'types'

import { formatMetricFiltersForParams } from './metrics'

export const formatFilters = (
    filters: FiltersState
): WmAdvertisingFactsParams => {
    let params: WmAdvertisingFactsParams = {}

    const campaignName = filters[CAMPAIGN_NAME]
    if (isNonEmptyString(campaignName)) {
        params.campaign__name__icontains = campaignName
    }

    const campaignStates = filters[CAMPAIGN_STATES]
    if (isNonEmptyArray(campaignStates)) {
        params.campaign__state__in = campaignStates
            .map((item) => item.value)
            .join()
    }

    const factTypes = filters[FACT_TYPES]
    if (isNonEmptyArray(factTypes)) {
        params.campaign__campaign_type__in = factTypes
            .map((item) => item.value)
            .join()
    }

    const campaignTargetingTypes = filters[CAMPAIGN_TARGETING_TYPES]
    if (isNonEmptyArray(campaignTargetingTypes)) {
        params.campaign__targeting_type__in = campaignTargetingTypes
            .map((item) => item.value)
            .join()
    }

    const campaignBudgetTypes = filters[CAMPAIGN_BUDGET_TYPES]
    if (isNonEmptyArray(campaignBudgetTypes)) {
        params.campaign__budget_type__in = campaignBudgetTypes
            .map((item) => item.value)
            .join()
    }

    const campaigns = filters[CAMPAIGNS]
    if (isNonEmptyArray(campaigns)) {
        params.campaign_id__in = campaigns.map((item) => item.value).join()
    }

    const dates = filters[DATES]
    const { start, end } = formatDateFromFilter(dates)
    params.report_date_min = start
    params.report_date_max = end

    const adGroupStates = filters[AD_GROUP_STATES]
    if (isNonEmptyArray(adGroupStates)) {
        params.ad_group__state__in = adGroupStates
            .map((item) => item.value)
            .join()
    }

    const adGroups = filters[AD_GROUPS]
    if (isNonEmptyArray(adGroups)) {
        params.ad_group_id__in = adGroups.map((item) => item.value).join()
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (!isEmpty(metricFilters)) {
        params = {
            ...params,
            ...formatMetricFiltersForParams(metricFilters as MetricFilter[]),
        }
    }

    const adItemStates = filters[AD_ITEM_STATES]
    if (isNonEmptyArray(adItemStates)) {
        params.ad_item__state__in = adItemStates
            .map((item) => item.value)
            .join()
    }

    const keywordText = filters[KEYWORD_TEXT]
    if (isNonEmptyString(keywordText)) {
        params.keyword__text__icontains = keywordText
    }

    const keywordMatchTypes = filters[KEYWORD_MATCH_TYPES]
    if (isNonEmptyArray(keywordMatchTypes)) {
        params.keyword__match_type__in = keywordMatchTypes
            .map((item) => item.value)
            .join()
    }

    const keywordStates = filters[KEYWORD_STATES]
    if (isNonEmptyArray(keywordStates)) {
        params.keyword__state__in = keywordStates
            .map((item) => item.value)
            .join()
    }

    const adAccounts = filters[AD_ACCOUNTS]
    if (isNonEmptyArray(adAccounts)) {
        params.advertiser_id__in = adAccounts.map((item) => item.value).join()
    }

    return params
}
