import { ReactElement } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { useAppConfig } from 'appConfig'
import { ContactUsLink } from 'components/Links'
import { PageHeader } from 'components/PageHeader'
import { formatDay } from 'helpers/formatting'
import { ReactComponent as IconSVG } from 'images/logo/icon-logo-color.svg'
import { Organization } from 'types'

import * as styles from './styles.scss'

interface Props {
    organization: Organization
}

function OrganizationLockedPage({ organization }: Props): ReactElement {
    const { t } = useTranslation('account')
    const { productTitle } = useAppConfig()

    const formattedDate = formatDay(organization.trial_expiration_date)

    return (
        <>
            <PageHeader />
            <div className="centered-card-wrapper">
                <div className="centered-card">
                    <IconSVG className={styles.logo} />
                    <h1 className={styles.header}>
                        {t(
                            'account:OrganizationLockedPage.header',
                            'Thanks for using {{ productTitle }}!',
                            { productTitle }
                        )}
                    </h1>

                    <div className={styles.cta}>
                        <Trans i18nKey="account:OrganizationLockedPage.message">
                            Your service ended on {{ formattedDate }}. To
                            continue using {{ productTitle }}{' '}
                            <ContactUsLink
                                link
                                contactUsPage="/group/3/create/10"
                            >
                                contact us
                            </ContactUsLink>
                            .
                        </Trans>
                    </div>

                    <p className="fg-text-extra-light">
                        {t(
                            'account:OrganizationLockedPage.footer',
                            '*Your Amazon Integration data is no longer being synced, enabled automations have been paused and any active dayparting bids have been reverted to base bids.'
                        )}
                    </p>
                </div>
            </div>
        </>
    )
}

export default OrganizationLockedPage
