/**
 * REDUCER KEYS USED IN ALL REDUCERS
 */

import { TFunction } from 'i18next'

// Normalization
export const ALL_IDS = 'allIds'

// Page Filters
export const LOCAL_FILTERS = 'localFilters'
export const FILTERS = 'filters'
export const FILTER_UPDATED_AT = 'filterUpdatedAt'
export const FILTER_SETTINGS = 'filtersSettings'
export const DATES = 'dates'

// Charts
export const SOV_CHART = 'sovChart'
export const ORGANIC_SEARCH_POSITION_CHART = 'organicSearchPositionChart'

export const AGG_UNIT = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const AGG_UNIT_LABELS = (t: TFunction) => ({
    [AGG_UNIT.DAY]: t('filters:DateRangeFilter.aggUnitLables.day', 'Daily'),
    [AGG_UNIT.WEEK]: t('filters:DateRangeFilter.aggUnitLables.week', 'Weekly'),
    [AGG_UNIT.MONTH]: t(
        'filters:DateRangeFilter.aggUnitLables.month',
        'Monthly'
    ),
})

// Reload
export const LOADED_AT = 'loadedAt'
