import { ReactElement, useEffect } from 'react'

import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import ReactGridLayout, { WidthProvider } from 'react-grid-layout'
import { RouteComponentProps, useRouteMatch } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import { useLagAdjustedDateRanges } from 'components/FilterGroup/useLagAdjustedDateRanges'
import { InfoIcon, WhitelabelLogoIcon } from 'components/Icons'
import Stack from 'components/Layout/Stack'
import { LoadingIndicator } from 'components/LoadingIndicator'
import { Widget } from 'components/Widgets'
import { DATES } from 'const/filters'
import { DASHBOARD_PRINT_PAGE } from 'const/pages'
import {
    WIDGET_GRID_ROW_HEIGHT,
    WIDGET_GRID_X_MARGIN,
    WIDGET_GRID_Y_MARGIN,
} from 'const/widgets'
import { PageContextProvider } from 'context/PageContext'
import { UserOverrideContextProvider } from 'context/UserOverrideContext'
import { getDateRange } from 'helpers/dateRange'
import { getOrganizationTheme } from 'helpers/organizationTheme'
import {
    useDenormalizedTabs,
    useFiltersState,
    useMountingState,
    useMountPage,
    useRumManualViewTracking,
    useUiDomainValue,
} from 'hooks'
import usePendo from 'hooks/usePendo'
import CobaltLogo from 'images/logo/CobaltLogo'
import { defaultDatesFilter } from 'reducers/ui/defaults'
import {
    Dashboard,
    DashboardTab,
    DatadogService,
    DateRangeFilter,
    Organization,
} from 'types'
import moment from 'utilities/moment'

import { useDateRangeQueryParam } from './localHooks'
import * as styles from './styles.scss'

const GridLayout = WidthProvider(ReactGridLayout)

interface DashboardPrintPageTabProps {
    tab: DashboardTab
    index: number
    dateRange?: DateRangeFilter
}

const PAGE_PATH = [DASHBOARD_PRINT_PAGE]

function DashboardPrintPageTab({
    tab,
    index,
    dateRange,
}: DashboardPrintPageTabProps): ReactElement {
    const tabDateRange =
        (tab.localFilters?.[DATES] as DateRangeFilter) ?? defaultDatesFilter

    const showFilterConflictIcon = dateRange && dateRange !== tabDateRange
    const pagePath = PAGE_PATH
    const tabPath = [...PAGE_PATH, 'tabs', tab.id]

    const { tabLocalFilters } = useFiltersState({
        pagePath,
        tabPath,
    })

    useLagAdjustedDateRanges({
        pagePath,
        tabPath,
        tab,
        tabLocalFilters,
        notifyOnChange: false,
    })

    return (
        <div key={tab.id} className={styles['tab-section']}>
            {index > 0 && <div className={styles['page-break']} />}
            <div
                className={classNames(styles['tab-header'], {
                    'first-tab-header': index === 0,
                })}
            >
                <h2>{tab.name}</h2>
                <h3 className={styles['date-range']}>
                    {getDateRange(dateRange ?? tabDateRange, 'MMMM D, YYYY')}
                    {showFilterConflictIcon && (
                        <InfoIcon className={styles['info-icon']} />
                    )}
                </h3>
            </div>
            <div className={styles['tab-content']}>
                <GridLayout
                    layout={tab.widgets.map((w) => w.layout)}
                    rowHeight={WIDGET_GRID_ROW_HEIGHT}
                    isDraggable={false}
                    isResizable={false}
                    containerPadding={[0, 0]}
                    margin={[WIDGET_GRID_X_MARGIN, WIDGET_GRID_Y_MARGIN]}
                >
                    {tab.widgets.map((widget) => (
                        <div key={widget.id}>
                            <Widget
                                className={classNames(styles.widget, {
                                    [styles.table]: widget.type === 'table',
                                })}
                                widget={widget}
                                widgetTab={tab}
                                isExport
                                hasPermission={false}
                                showFiltersDisplay
                                dateRange={dateRange}
                            />
                        </div>
                    ))}
                </GridLayout>
            </div>
        </div>
    )
}

type DashboardPrintPageProps = RouteComponentProps<{ dashboardId: string }>

function DashboardPrintPage({
    match: {
        params: { dashboardId },
    },
}: DashboardPrintPageProps): ReactElement {
    const { path } = useRouteMatch()
    const { pendo } = usePendo()
    useEffect(() => {
        try {
            pendo?.setGuidesDisabled(true)
            pendo?.stopGuides()
        } catch {
            // ignore - can error if pendo is not loaded
        }
    }, [pendo])

    useRumManualViewTracking(DatadogService.DASHBOARDS, path)

    const [userId] = useQueryParam('user_id', StringParam)
    const [organizationId] = useQueryParam('org_id', StringParam)
    const [organizationGroupId] = useQueryParam('org_group_id', StringParam)
    const [currencyCode] = useQueryParam('currency_code', StringParam)
    const [dateRange] = useDateRangeQueryParam()

    useMountPage(DASHBOARD_PRINT_PAGE, {
        dashboardId,
        userId,
        orgId: organizationId,
        orgGroupId: organizationGroupId,
        dateRange,
        currencyCode,
    })

    const mounting = useMountingState(DASHBOARD_PRINT_PAGE)

    const organization = useUiDomainValue<Organization>([
        DASHBOARD_PRINT_PAGE,
        'organization',
    ])

    const dashboard = useUiDomainValue<Dashboard>([
        DASHBOARD_PRINT_PAGE,
        'dashboard',
    ])

    const tabs = useDenormalizedTabs(DASHBOARD_PRINT_PAGE)

    // Remove horizontal scroll bar on table widgets
    useEffect(() => {
        if (!mounting) {
            setTimeout(() => {
                const tables = document.querySelectorAll('.ant-table-body')
                tables.forEach((table) =>
                    table.setAttribute('style', 'overflow-x: hidden')
                )
            }, 100)
        }
    }, [mounting])

    if (mounting || !dashboard || isEmpty(tabs)) {
        return <LoadingIndicator size="small" />
    }

    const theme = getOrganizationTheme(organization)
    const whiteLabelLogoUrl = theme?.logo_url_print

    return (
        <PageContextProvider pageName={DASHBOARD_PRINT_PAGE}>
            <UserOverrideContextProvider
                userOverride={{
                    userId: userId ?? undefined,
                    organizationId: organizationId ?? undefined,
                    organizationGroupId: organizationGroupId ?? undefined,
                }}
            >
                <div id="dashboard-content" className={styles.container}>
                    <header>
                        <div>
                            <h1>{dashboard.name}</h1>
                        </div>
                        {whiteLabelLogoUrl ? (
                            <WhitelabelLogoIcon
                                src={whiteLabelLogoUrl}
                                alt={organization.name}
                                className={styles['org-logo']}
                            />
                        ) : (
                            <div className={styles['org-name']}>
                                {organization.name}
                            </div>
                        )}
                        <div
                            className="fg-text-light"
                            style={{ marginBottom: 12 }}
                        >
                            {dashboard.description}
                        </div>
                        <div className={styles.metadata}>
                            <span>Created by:</span>
                            {dashboard.created_by}
                        </div>
                        <div className={styles.metadata}>
                            <span>Exported:</span>
                            {moment().format('MMM D, YYYY')}
                        </div>
                    </header>
                    {tabs.map((t, i) => (
                        <DashboardPrintPageTab key={t.id} tab={t} index={i} />
                    ))}
                    <Stack
                        direction="row"
                        justifyContent="between"
                        component="footer"
                    >
                        <div>
                            <div>{dashboard.name}</div>
                            <div>{getDateRange(dateRange, 'MMMM D, YYYY')}</div>
                        </div>

                        <div className={styles['ds-icon']}>
                            <div className={styles.powered}>Powered By</div>
                            <CobaltLogo style={{ width: 130 }} />
                        </div>
                    </Stack>
                </div>
            </UserOverrideContextProvider>
        </PageContextProvider>
    )
}

export default DashboardPrintPage
