import { ReactElement } from 'react'

import { Translation } from 'react-i18next'

import { TargetingIcon, CampaignIcon } from 'components/Icons'
import Stack from 'components/Layout/Stack'
import { CampaignLink } from 'components/Links'
import { TextDividerVertical } from 'components/TextDividerVertical'
import { COST_TYPE_LABELS } from 'const/campaigns'
import { FACT_TYPE_LABELS } from 'const/factTypes'
import { UNDEFINED_VALUE } from 'const/formatting'
import { titleCase, formatCurrency } from 'helpers/formatting'
import { Campaign } from 'types'

import { DetailTag, DetailWrapper, DetailText, DetailIcon } from './shared'

export function campaignRenderer(campaign: Campaign): ReactElement {
    const tooltip = (
        <>
            <Translation>{(t) => t('common:campaign', 'Campaign')}</Translation>
            <TextDividerVertical />
            <Translation>{(t) => t('common:type', 'Type')}</Translation>
        </>
    )

    const text = (
        <Stack component="span" direction="row" alignItems="center">
            <CampaignLink campaign={campaign} />
            <TextDividerVertical />
            <span>{FACT_TYPE_LABELS[campaign.campaign_type]}</span>
        </Stack>
    )

    return (
        <DetailWrapper
            leftContent={
                <DetailIcon icon={CampaignIcon} tooltipTitle={tooltip} />
            }
            rightContent={<DetailText text={text} />}
        />
    )
}

export function campaignActiveBudgetRenderer(campaign: Campaign): ReactElement {
    const text = formatCurrency(campaign.active_budget, {
        decimal: true,
        currencyCode: campaign.profile.currency_code,
    })

    return (
        <DetailWrapper
            leftContent={<DetailTag type="active" text="Active Budget" />}
            rightContent={<DetailText text={text} />}
        />
    )
}

export function campaignBudgetRenderer(campaign: Campaign): ReactElement {
    const budget = formatCurrency(campaign.base_budget, {
        decimal: true,
        currencyCode: campaign.profile.currency_code,
    })
    const budgetType = titleCase(campaign.budget_type)
    const costType =
        COST_TYPE_LABELS?.[campaign.cost_type] ?? campaign.cost_type

    const text = (
        <Stack component="span" direction="row" alignItems="center">
            {budget}
            {campaign.budget_type !== 'daily' && ` (${budgetType})`}
            <TextDividerVertical />
            {costType}
        </Stack>
    )

    return (
        <DetailWrapper
            leftContent={<DetailTag text="Budget" />}
            rightContent={<DetailText text={text} />}
        />
    )
}

export function campaignTargetingTypeRenderer(
    campaign: Campaign
): ReactElement {
    const text = campaign.targeting_type ?? UNDEFINED_VALUE
    return (
        <DetailWrapper
            leftContent={
                <DetailIcon
                    icon={TargetingIcon}
                    tooltipTitle="Targeting Type"
                />
            }
            rightContent={<DetailText text={titleCase(text)} />}
        />
    )
}

export function campaignTypeRenderer(campaign: Campaign): ReactElement {
    const text = FACT_TYPE_LABELS[campaign.campaign_type] ?? UNDEFINED_VALUE
    return (
        <DetailWrapper
            leftContent={
                <DetailIcon icon={CampaignIcon} tooltipTitle="Campaign Type" />
            }
            rightContent={<DetailText text={titleCase(text)} />}
        />
    )
}

export function campaignAutomatedBiddingRenderer(
    campaign: Campaign
): ReactElement {
    return (
        <DetailWrapper
            leftContent={<DetailTag text="Automated Bidding" />}
            rightContent={
                <DetailText text={campaign.bid_optimization ? 'On' : 'Off'} />
            }
        />
    )
}
