import createCachedSelector from 're-reselect'

import { deserializeColumns, isMetricField } from 'helpers/tables'
import {
    RootReduxState,
    FieldSerialized,
    Field,
    Path,
    Table,
    FieldPropertiesSerializable,
} from 'types'

import {
    selectDomainValue,
    createPathCacheKey as createTablePathCacheKey,
} from './ui'

const selectSerializedColumns = (
    state: RootReduxState,
    tablePath: Path
): FieldSerialized[] =>
    selectDomainValue(state, [...tablePath, 'tableSettings', 'columns'])

export const cachedTableSettingsSelector = createCachedSelector<
    RootReduxState,
    Path,
    Table<unknown>,
    Table<any>['tableSettings']
>(
    (state, tablePath) => selectDomainValue(state, tablePath),
    (table) => table.tableSettings
)((_state, tablePath) => createTablePathCacheKey(tablePath))

export function selectTableSettings<T = unknown>(
    rootState: RootReduxState,
    path: Path
): Table<T>['tableSettings'] {
    return cachedTableSettingsSelector(rootState, path)
}

const cachedTableColumnStateSelector = createCachedSelector<
    RootReduxState,
    Path,
    FieldSerialized[],
    Field<unknown>[],
    Field<any>[]
>(
    (state, tablePath) => selectSerializedColumns(state, tablePath),
    (_state, _tablePath, columnsConfiguration: Field<unknown>[]) =>
        columnsConfiguration,
    (serializedColumns, columnsConfiguration) => {
        return deserializeColumns(serializedColumns, columnsConfiguration)
    }
)((_state, tablePath) => createTablePathCacheKey(tablePath))

export function selectTableColumnState<T = unknown>(
    rootState: RootReduxState,
    path: Path,
    config: Field<T>[] | FieldPropertiesSerializable[]
): Field<T>[] {
    return cachedTableColumnStateSelector(rootState, path, config)
}

const cachedVisibleMetricsSelector = createCachedSelector<
    RootReduxState,
    Path,
    FieldSerialized[],
    Field<unknown>[],
    Field<any>[]
>(
    (state, tablePath) => selectSerializedColumns(state, tablePath),
    (_state, _tablePath, columnsConfiguration) => columnsConfiguration,
    (serializedColumns, columnsConfiguration) =>
        deserializeColumns(serializedColumns, columnsConfiguration).filter(
            (col) => isMetricField(col) && col.isVisible
        )
)((_state, tablePath) => createTablePathCacheKey(tablePath))

export function selectVisibleMetricsOfTable<T = unknown>(
    rootState: RootReduxState,
    path: Path,
    config: Field<T>[] | FieldPropertiesSerializable[]
): Field<T>[] {
    return cachedVisibleMetricsSelector(rootState, path, config)
}

const cachedVisibleColumnsSelector = createCachedSelector<
    RootReduxState,
    Path,
    FieldSerialized[],
    Field<unknown>[],
    Field<any>[]
>(
    (state, tablePath) => selectSerializedColumns(state, tablePath),
    (_state, _tablePath, columnsConfiguration) => columnsConfiguration,
    (serializedColumns, columnsConfiguration) =>
        deserializeColumns(serializedColumns, columnsConfiguration).filter(
            (col) => col.isVisible
        )
)((_state, tablePath) => createTablePathCacheKey(tablePath))

export function selectVisibleColumnsOfTable<T = unknown>(
    rootState: RootReduxState,
    path: Path,
    config: Field<T>[] | FieldPropertiesSerializable[]
): Field<T>[] {
    return cachedVisibleColumnsSelector(rootState, path, config)
}
