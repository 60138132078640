/**
 * Helper for widgets that define several axes at both sides of the graph.
 * After DOW-10016. New widgets always use this helper.
 */

import { YAxisOptions } from 'highcharts'

import { Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS } from 'configuration/charts'
import { COLUMN } from 'const/widgets'
import { DataSourceMetricMetadataFormat } from 'features/MetricsGlossary/types'
import {
    formatCurrency,
    formatNumber,
    formatPercentage,
} from 'helpers/formatting'
import { elementToString } from 'helpers/utilities'
import { transformToString } from 'helpers/widgets'
import {
    AutomationCapability,
    AxisConfiguration,
    FieldMapping,
    PriorPeriodConfig,
    Widget,
} from 'types'
import { DEFAULT_REFERENCE_LINE_COLOR } from 'views/DashboardPage/Forms/TimeSeriesReferenceLineConfig'

import {
    generateGroupedSeries,
    generateMetricSeries,
    getAxisByMetric,
    getFinalChartType,
} from './utils'

function formatReferenceLineValue(
    widget: Widget,
    value: number,
    metricFormat?: DataSourceMetricMetadataFormat
): string {
    const isStackedPercentArea =
        widget.stacked && widget.stacked_type === 'percent'

    const resolvedMetricFormat = isStackedPercentArea
        ? 'percentage_as_is'
        : metricFormat

    switch (resolvedMetricFormat) {
        case 'percentage':
            return formatPercentage(value, '0.[00]%')
        case 'percentage_as_is':
            return formatPercentage(value / 100, '0.[00]%')
        case 'currency':
            return formatCurrency(value, {
                decimal: true,
                abbreviate: true,
                currencyCode: '',
            })
        case 'number':
            return formatNumber(value)
    }
    return ''
}
interface AxisDefinition {
    isRightSide: boolean
    sideIdx: number
    conf: AxisConfiguration
}

export class AxisDrivenSeries {
    private readonly widget: Widget

    private readonly widgetData: any[]

    private readonly widgetPriorPeriodData: any[]

    private readonly widgetGroupBy: string[]

    private readonly automationCapabilitiesMap: Record<
        string,
        AutomationCapability
    >

    private readonly brandGroupsMap: Record<string, any>

    private readonly priorPeriodConfig: PriorPeriodConfig

    private readonly metricsConfig: FieldMapping

    private numberOfMetricsOnTheRight = 0

    private numberOfMetricsOnTheLeft = 0

    private allAxesDefined: AxisDefinition[]

    private colorMapper: (metric: string) => string
    private yAxes: YAxisOptions[] = []
    private showYAxesTitles: boolean

    constructor(
        widget: Widget,
        widgetData: any[],
        widgetPriorPeriodData: any[],
        priorPeriodConfig: PriorPeriodConfig,
        automationCapabilitiesMap: any,
        brandGroupsMap: any,
        metricsConfig: FieldMapping,
        colorMapper: (metric: string) => string,
        showYAxesTitles: boolean
    ) {
        this.widget = widget
        this.widgetData = widgetData
        this.widgetPriorPeriodData = widgetPriorPeriodData
        this.widgetGroupBy = transformToString(widget.group_by) ?? []
        this.automationCapabilitiesMap = automationCapabilitiesMap
        this.brandGroupsMap = brandGroupsMap
        this.priorPeriodConfig = priorPeriodConfig
        this.metricsConfig = metricsConfig
        this.colorMapper = colorMapper
        this.showYAxesTitles = showYAxesTitles

        const mapSideToAxisDefinitions = (
            isRightSide: boolean,
            axes: AxisConfiguration[]
        ): AxisDefinition[] => {
            let numberOfMetricsOnThisSide = 0
            const definitionsOnThisSide = axes
                .filter((axis) => axis?.metrics?.length)
                .map((axis, idx) => {
                    numberOfMetricsOnThisSide += axis.metrics.length
                    return { isRightSide, conf: axis, sideIdx: idx }
                })
            if (isRightSide) {
                this.numberOfMetricsOnTheRight = numberOfMetricsOnThisSide
            } else {
                this.numberOfMetricsOnTheLeft = numberOfMetricsOnThisSide
            }
            return definitionsOnThisSide
        }

        let axes: AxisDefinition[] = []
        if (this.widget.metrics_by_axis?.left) {
            axes = mapSideToAxisDefinitions(
                false,
                this.widget.metrics_by_axis.left
            )
        }
        if (this.widget.metrics_by_axis?.right) {
            axes = axes.concat(
                mapSideToAxisDefinitions(
                    true,
                    this.widget.metrics_by_axis.right
                )
            )
        }

        if (priorPeriodConfig.hasData) {
            this.numberOfMetricsOnTheLeft *= 2
            this.numberOfMetricsOnTheRight *= 2
        }

        this.allAxesDefined = [...axes]
    }

    _createYAxes = (showTitle: boolean = true): void => {
        this.yAxes = this.allAxesDefined.map((axis) => {
            // Let's assume that all the metrics in the axis can be formatted similarly
            const sampleMetric = axis.conf.metrics[0].metric
            const useTitle = showTitle && axis.conf.metrics.length === 1

            return getAxisByMetric({
                widget: this.widget,
                metric: sampleMetric,
                useTitle,
                color:
                    this.widgetGroupBy.length === 1
                        ? axis.conf.axisColor
                        : '#aaa',
                isRightAxis: axis.isRightSide,
                metricsConfig: this.metricsConfig,
                verticalTitle: axis.sideIdx > 0,
                title: useTitle
                    ? elementToString(
                          this.metricsConfig[sampleMetric]?.name ?? '-'
                      )
                    : undefined,
            })
        })
    }

    getYAxes = (): YAxisOptions[] => this.yAxes

    getSeries = (): any[] => {
        let series = []
        if (this.widgetGroupBy.length === 1) {
            let seriesIdx = -1
            series = this.allAxesDefined
                .flatMap((axis, axisIdx) =>
                    axis.conf.metrics.map((metric) => ({
                        metricConf: metric,
                        axisConf: axis,
                        axisIdx,
                    }))
                )
                .sort((a, b) => {
                    if (a.metricConf.chartType === b.metricConf.chartType) {
                        return 0
                    }
                    return a.metricConf.chartType === COLUMN ? -1 : 1
                })
                .flatMap((metricDefinition) => {
                    seriesIdx += 1
                    const metric = metricDefinition.metricConf
                    const axis = metricDefinition.axisConf
                    const { axisIdx } = metricDefinition
                    const lineColor = metric.color
                    const yAxisColor = axis.conf.axisColor
                    return generateMetricSeries({
                        widget: this.widget,
                        widgetData: this.widgetData,
                        widgetPriorPeriodData: this.widgetPriorPeriodData,
                        groupedBy: this.widgetGroupBy[0],
                        metric: metric.metric,
                        chartType: getFinalChartType(
                            metric.chartType,
                            this.widget.stacked
                        ),
                        color: lineColor,
                        yAxisIdx: axisIdx,
                        seriesIdx,
                        priorPeriodConfig: this.priorPeriodConfig,
                        priorPeriodColor:
                            metric.chartType === COLUMN
                                ? Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS[
                                      axisIdx %
                                          Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS.length
                                  ]
                                : lineColor,
                        priorPeriodBorderColor: lineColor,
                        metricsConfig: this.metricsConfig,
                        side: axis.isRightSide ? 'right' : 'left',
                        legendItemBorderColor: this.widget.metrics_by_axis
                            ?.addBorderToLegendItems
                            ? yAxisColor
                            : undefined,
                        markerSymbol: this.widget.metrics_by_axis
                            ?.showSymbolsInLegend
                            ? metric.symbol
                            : undefined,
                    })
                })
        } else if (this.widgetGroupBy.length === 2) {
            // We can assume that, if there is an additional grouping, there is only a single metric
            series = generateGroupedSeries(
                this.widgetGroupBy,
                this.allAxesDefined[0].conf.metrics[0].metric,
                this.widget.data_source,
                this.widgetData,
                this.automationCapabilitiesMap,
                this.brandGroupsMap,
                getFinalChartType(
                    this.allAxesDefined[0].conf.metrics[0].chartType,
                    this.widget.stacked
                ),
                this.metricsConfig,
                this.widget.color_palette,
                this.colorMapper
            )
        }
        this._createYAxes(this.showYAxesTitles)
        this.addReferenceLinesToSeries(series)
        return series
    }

    addReferenceLinesToSeries = (allSeries: any[]): void => {
        const referenceLine = this.widget.reference_line
        const yAxis = this.yAxes
        if (referenceLine) {
            const axesAndSeries = allSeries
                .map((series) => ({
                    axis: yAxis[series.yAxis ?? 0],
                    series,
                }))
                .filter((x) => !!x.axis)

            const axesAndSeriesLeft = axesAndSeries.filter(
                (x) => !x.axis.opposite
            )
            const axesAndSeriesRight = axesAndSeries.filter(
                (x) => x.axis.opposite
            )

            const axesAndSeriesSameSide =
                referenceLine.axis_side === 'right'
                    ? axesAndSeriesRight
                    : axesAndSeriesLeft

            const referenceLineAxisAndSeries = axesAndSeriesSameSide.find(
                (x) => x.series.metric === referenceLine.metric_key
            )

            if (referenceLineAxisAndSeries) {
                const metric = this.metricsConfig[referenceLine.metric_key]
                const metricFormat = metric?.metric?.metadata_format

                const labelParts = referenceLine.label
                    ? [referenceLine.label]
                    : []
                if (
                    referenceLine.show_value_in_label &&
                    referenceLine.value != null
                ) {
                    labelParts.push(
                        formatReferenceLineValue(
                            this.widget,
                            referenceLine.value,
                            metricFormat
                        )
                    )
                }

                referenceLineAxisAndSeries.axis.plotLines = [
                    {
                        color:
                            referenceLine.color ?? DEFAULT_REFERENCE_LINE_COLOR,
                        value: referenceLine.value,
                        width: 1.5,
                        zIndex: 1000,
                        dashStyle: 'Dash',
                        label: {
                            text: labelParts.join(' ꞏ '),
                            style: {
                                color:
                                    referenceLine.color ??
                                    DEFAULT_REFERENCE_LINE_COLOR,
                                fontWeight: '600',
                                textShadow:
                                    '-1px -1px 0 white,1px -1px 0 white,-1px  1px 0 white,1px  1px 0 white',
                            },
                            align: referenceLine.axis_side,
                            y: -8,
                        },
                    },
                ]

                const axesAndSeriesOfSameMetricType = axesAndSeries.filter(
                    (x) =>
                        this.metricsConfig[x.series.metric]?.metric
                            ?.metadata_format === metricFormat
                )

                const dataPoints = axesAndSeriesOfSameMetricType
                    .map((x) => x.series.data.map((d: number[]) => d[1] ?? 0))
                    .reduce((a, b) => a.concat(b), [])

                const minValue = Math.min(...dataPoints, referenceLine.value)
                const maxValue = Math.max(...dataPoints, referenceLine.value)

                const isStackedArea =
                    !!this.widget.stacked &&
                    this.widget.stacked_type === 'percent'
                // We don't need to adjust bounds of stacked percent area charts
                // They're always 0-100
                if (!isStackedArea) {
                    // When we have only one metric, we can use soft min/max
                    // to let Highcharts adjust the scale beyond our min/max values.
                    // When we have multiple metrics, we need to set the min/max
                    // explicitly to ensure that all axes are aligned.
                    const useSoftMinMax =
                        axesAndSeriesOfSameMetricType.length === 1 ||
                        !!this.widget.stacked

                    axesAndSeriesOfSameMetricType.forEach((x) => {
                        if (useSoftMinMax) {
                            x.axis.softMin = minValue
                            x.axis.softMax = maxValue
                        } else {
                            x.axis.min = minValue
                            x.axis.max = maxValue
                        }
                    })
                }
            }
        }
    }

    getLegendOptions = (legend: any): any => {
        const estimatedLines = Math.floor(
            Math.max(
                this.numberOfMetricsOnTheLeft,
                this.numberOfMetricsOnTheRight
            ) / 4
        )
        return {
            ...legend,
            doSplit: this.widget.metrics_by_axis?.splitLegendItemsPerYAxis,
            considerSymbols: this.widget.metrics_by_axis?.showSymbolsInLegend,
            width: this.widget.metrics_by_axis?.splitLegendItemsPerYAxis
                ? '100%'
                : legend.width,
            padding: this.widget.metrics_by_axis?.splitLegendItemsPerYAxis
                ? 8 + estimatedLines * 10
                : legend.padding,
            symbolHeight: this.widget.metrics_by_axis?.showSymbolsInLegend
                ? 10
                : legend.symbolHeight,
            symbolWidth: this.widget.metrics_by_axis?.showSymbolsInLegend
                ? 10
                : legend.symbolWidth,
            estimatedLines,
        }
    }
}
