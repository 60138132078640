import { ReactElement } from 'react'

import { Detail } from 'types'

import * as styles from './styles.scss'

interface Props {
    details: Detail[]
}

function Details({ details }: Props): ReactElement {
    return (
        <div className={styles['resource-metadata']}>
            {details
                .filter((detail) => detail.show !== false)
                .map((detail, idx) => (
                    <div key={idx} className={styles['resource-metadata-item']}>
                        {detail.render()}
                    </div>
                ))}
        </div>
    )
}

export default Details
