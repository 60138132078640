import { ReactElement } from 'react'

import { RemoveProductFromLabelField } from 'components/EditableFields/ProductFields'
import { ProductTitleCell } from 'components/FieldRenderers/ProductTitleCell'
import { AsinLink } from 'components/Links'
import { UNDEFINED_VALUE } from 'const/formatting'
import { formatCurrency } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import {
    ProductAdAsinGroupBy,
    CellRenderProps,
    Brand,
    ProductAdFact,
    CountryCode,
} from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex?: string[]
}

export function productTitleRenderer<
    RecordType extends ProductAdAsinGroupBy & {
        profile__country_code: CountryCode
    },
>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    const product_ad_asin = record.product_ad__asin
    const product_ad_id = record.metadata_id
    const product_img = record.metadata?.small_image_url
    const profile_country_code = record.profile__country_code
    return (
        <ProductTitleCell
            value={value}
            productImg={product_img}
            productAdId={product_ad_id}
            productAsin={product_ad_asin}
            profileCountryCode={profile_country_code}
        />
    )
}

export function campaignProductTitleRenderer<
    RecordType extends ProductAdAsinGroupBy,
>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    return (
        <ProductTitleCell
            value={value}
            productImg={record.metadata?.small_image_url}
            productAdId={record.metadata_id}
            productAsin={record.metadata?.asin}
            profileCountryCode={record.metadata?.marketplace}
        />
    )
}

export function productPriceRenderer<RecordType extends ProductAdAsinGroupBy>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }
    const currencyCode = record.metadata?.currency_code ?? ''
    return formatCurrency(value, {
        decimal: true,
        currencyCode,
    })
}

export function productAsinRenderer<
    RecordType extends ProductAdAsinGroupBy & {
        profile__country_code: Brand['country_code']
    },
>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }
    const countryCode = record.profile__country_code
    return <AsinLink asin={value} countryCode={countryCode} />
}

export function removeProductFromLabelRenderer<
    RecordType extends ProductAdFact<
        ProductAdAsinGroupBy & {
            profile__country_code: Brand['country_code']
        }
    >,
>({ record, readonly, reloadData }: CellRenderProps<RecordType>): ReactElement {
    return (
        <RemoveProductFromLabelField
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}
