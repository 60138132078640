import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import BookIconSvg from './BookIconSvg'

const BookIcon = (props: IconProps): ReactElement => (
    <Icon component={BookIconSvg} {...props} />
)

export default BookIcon
