import { ReactElement } from 'react'

import { Select } from 'antd'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import { useTranslation, Trans } from 'react-i18next'
import { object, string } from 'yup'

import { createAuthPageOrganizationRequest } from 'actions/auth'
import { useAppConfig } from 'appConfig'
import { FormikInput, FormikSelect } from 'components/formik'
import { ContactUsLink } from 'components/Links'
import { useAction } from 'hooks'

import { AuthError, SubmitButton } from '../Shared'
import * as styles from '../styles.scss'

const CreateOrganizationForm = (): ReactElement => {
    const createOrganization = useAction(createAuthPageOrganizationRequest)
    const { t } = useTranslation('account')
    const { productTitle } = useAppConfig()

    const adSpendOptions = [
        {
            id: 'lt_20',
            value: 'Less than $20,000 USD a month',
            text: t(
                'account:CreateOrganizationForm.adSpendOptions.lt_20',
                'Less than $20,000 USD a month'
            ),
        },
        {
            id: '20_to_100',
            value: '$20,001 - $100,000 USD a month',
            text: t(
                'account:CreateOrganizationForm.adSpendOptions.20_to_100',
                '$20,001 - $100,000 USD a month'
            ),
        },
        {
            id: '100_to_250',
            value: '$100,001 - $250,000 USD a month',
            text: t(
                'account:CreateOrganizationForm.adSpendOptions.100_to_250',
                '$100,001 - $250,000 USD a month'
            ),
        },
        {
            id: '250_to_500',
            value: '$250,001 - $500,000 USD a month',
            text: t(
                'account:CreateOrganizationForm.adSpendOptions.250_to_500',
                '$250,001 - $500,000 USD a month'
            ),
        },
        {
            id: '500_to_1m',
            value: '$500,001 - $1,000,000 USD a month',
            text: t(
                'account:CreateOrganizationForm.adSpendOptions.500_to_1m',
                '$500,001 - $1,000,000 USD a month'
            ),
        },
        {
            id: 'no_spend',
            value: 'Not currently spending but looking to spend',
            text: t(
                'account:CreateOrganizationForm.adSpendOptions.no_spend',
                'Not currently spending but looking to spend'
            ),
        },
    ]

    const corporateRevenueOptions = [
        {
            id: 'under_2',
            value: 'Under $2 Million',
            text: t(
                'account:CreateOrganizationForm.corporateRevenueOptions.under_2',
                'Under $2 Million'
            ),
        },
        {
            id: '2_to_5',
            value: '$2-5 Million',
            text: t(
                'account:CreateOrganizationForm.corporateRevenueOptions.2_to_5',
                '$2-5 Million'
            ),
        },
        {
            id: '5_to_10',
            value: '$5-10 Million',
            text: t(
                'account:CreateOrganizationForm.corporateRevenueOptions.5_to_10',
                '$5-10 Million'
            ),
        },
        {
            id: '10_to_20',
            value: '$10-20 Million',
            text: t(
                'account:CreateOrganizationForm.corporateRevenueOptions.10_to_20',
                '$10-20 Million'
            ),
        },
        {
            id: '20_to_50',
            value: '$20-50 Million',
            text: t(
                'account:CreateOrganizationForm.corporateRevenueOptions.20_to_50',
                '$20-50 Million'
            ),
        },
        {
            id: '50_to_100',
            value: '$50-100 Million',
            text: t(
                'account:CreateOrganizationForm.corporateRevenueOptions.50_to_100',
                '$50-100 Million'
            ),
        },
        {
            id: '100_to_500',
            value: '$100-500 Million',
            text: t(
                'account:CreateOrganizationForm.corporateRevenueOptions.100_to_500',
                '$100-500 Million'
            ),
        },
        {
            id: 'over_500',
            value: 'Over $500 Million',
            text: t(
                'account:CreateOrganizationForm.corporateRevenueOptions.over_500',
                'Over $500 Million'
            ),
        },
    ]

    return (
        <>
            <div className={styles.title}>
                <h1>
                    {t(
                        'account:CreateOrganizationForm.title',
                        'Create an Organization'
                    )}
                </h1>
                <div className={styles.subtitle}>
                    <Trans i18nKey="account:CreateOrganizationForm.subTitle">
                        To get started with {{ productTitle }} you&apos;ll need
                        to
                        <br />
                        create an organization.
                    </Trans>
                </div>
            </div>

            <Formik
                initialValues={{ name: '' }}
                onSubmit={(values) => createOrganization(values)}
                validationSchema={object().shape({
                    name: string()
                        .required()
                        .label(
                            t(
                                'account:CreateOrganizationForm.orgName',
                                'Organization Name'
                            )
                        ),
                    user_reported_ad_spend: string()
                        .required()
                        .label(
                            t(
                                'account:CreateOrganizationForm.orgUserReportedAdSpend',
                                'Monthly Ad Spend'
                            )
                        ),
                    user_reported_corporate_revenue: string()
                        .required()
                        .label(
                            t(
                                'account:CreateOrganizationForm.orgUserReportedCorporateRevenue',
                                'Corporate Revenue'
                            )
                        ),
                })}
            >
                {() => (
                    <Form>
                        <div style={{ marginBottom: 8 }}>
                            <Field
                                component={FormikInput}
                                placeholder={t(
                                    'account:CreateOrganizationForm.orgName',
                                    'Organization Name'
                                )}
                                name="name"
                            />
                            <ErrorMessage
                                name="name"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <div style={{ marginBottom: 8 }}>
                            <Field
                                component={FormikSelect}
                                placeholder={t(
                                    'account:CreateOrganizationForm.orgUserReportedAdSpend',
                                    'Monthly Ad Spend'
                                )}
                                name="user_reported_ad_spend"
                                className="text-left"
                            >
                                {adSpendOptions.map(({ id, text, value }) => (
                                    <Select.Option key={id} value={value}>
                                        {text}
                                    </Select.Option>
                                ))}
                            </Field>
                            <ErrorMessage
                                name="user_reported_ad_spend"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <div style={{ marginBottom: 8 }}>
                            <Field
                                component={FormikSelect}
                                placeholder={t(
                                    'account:CreateOrganizationForm.orgUserReportedCorporateRevenue',
                                    'Corporate Revenue'
                                )}
                                name="user_reported_corporate_revenue"
                                className="text-left"
                            >
                                {corporateRevenueOptions.map(
                                    ({ id, value, text }) => (
                                        <Select.Option key={id} value={value}>
                                            {text}
                                        </Select.Option>
                                    )
                                )}
                            </Field>
                            <ErrorMessage
                                name="user_reported_corporate_revenue"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <SubmitButton>
                            {t(
                                'account:CreateOrganizationForm.submitBtnText',
                                'Get Started'
                            )}
                        </SubmitButton>
                    </Form>
                )}
            </Formik>

            <AuthError />

            <div className={styles.subtext}>
                <Trans i18nKey="account:CreateOrganizationForm.footer">
                    Looking to join an organization? Reach out to your
                    <br />
                    organization administrator for an invite.
                </Trans>
            </div>

            <div className={styles.subtext}>
                <Trans i18nKey="account:CreateOrganizationForm.contactUsLink">
                    Questions?{' '}
                    <ContactUsLink link contactUsPage="/group/3/create/14">
                        Contact Us
                    </ContactUsLink>
                </Trans>
            </div>
        </>
    )
}

export default CreateOrganizationForm
