import { ReactElement } from 'react'

import {
    CapabilityElement,
    CapabilityGroup,
} from 'components/AutomationType/Automation/AutomationCapability'
import { UNDEFINED_VALUE } from 'const/formatting'
import { AutomationCapability, AutomationCapabilityId } from 'types'

import * as variables from '../../../styles/_variables.scss'

interface Props {
    capabilities?: AutomationCapability[]
    capabilityId?: AutomationCapabilityId | null
    empty?: ReactElement | string
}

const AutomationType = ({
    capabilities = [],
    capabilityId = null,
    empty = UNDEFINED_VALUE,
}: Props): ReactElement => {
    // CapabilityID Helper
    const getCapabilityId = (
        capability?: AutomationCapability
    ): AutomationCapabilityId | null => capability?.id ?? capabilityId

    // Empty Element
    const renderEmpty = (): ReactElement => (
        <div style={{ color: variables.colorTextMuted }}>{empty}</div>
    )

    // Render Single Capability Element or Empty Element
    const renderSingleCapability = (
        capId: AutomationCapabilityId | null
    ): ReactElement =>
        !capId ? renderEmpty() : <CapabilityElement capabilityId={capId} />

    // Render Switch
    switch (capabilities.length) {
        case 0: {
            const capId = getCapabilityId()
            return renderSingleCapability(capId)
        }
        case 1: {
            const capId = getCapabilityId(capabilities[0])
            return renderSingleCapability(capId)
        }
        default:
            return <CapabilityGroup capabilities={capabilities} />
    }
}

export default AutomationType
