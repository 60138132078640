import { ReactElement } from 'react'

import Stack from 'components/Layout/Stack'

import { ColumnConfig } from './localTypes'
import * as styles from './styles.scss'

interface Props {
    columnsConfig: ColumnConfig[]
}

export default function CreateKeywordFormHeader({
    columnsConfig,
}: Props): ReactElement {
    return (
        <Stack direction="row" alignItems="start">
            <div
                style={{
                    flex: 1,
                    marginTop: 8,
                    marginRight: 4,
                    paddingLeft: 8,
                }}
                className="fg-control-label"
            >
                Keyword
            </div>

            {columnsConfig.map(({ width, name, dataKey }) => {
                return (
                    <div
                        className="fg-control-label"
                        style={{
                            width,
                            marginTop: 8,
                            marginRight: 4,
                        }}
                        key={dataKey}
                    >
                        {name}
                    </div>
                )
            })}

            <div
                className={styles['remove-column']}
                style={{
                    marginTop: 8,
                    marginRight: 4,
                }}
            />
        </Stack>
    )
}
