import { Input } from 'antd'

import { FormikWithCustomHandlersPropTypes } from '../propTypes'
import withCustomHandlers from '../withCustomHandlers'

const { TextArea } = Input

const FormikTextArea = ({
    field: { value },
    form,
    handleChange,
    handleBlur,
    handleClick,
    ...props
}) => (
    <TextArea
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        {...props}
    />
)

FormikTextArea.propTypes = FormikWithCustomHandlersPropTypes

export default withCustomHandlers(FormikTextArea)
