import isArray from 'lodash/isArray'
import { Moment } from 'moment-timezone'

import { DATA_SOURCES } from 'configuration/widgets'
import { WidgetDataSourceKey } from 'const/dataSources'
import { FilterValue } from 'types'

export class DateRangeWithLagUtil {
    static includePreset = [
        'last_7_days',
        'last_14_days',
        'last_28_days',
        'last_30_days',
        'last_90_days',
        'last_180_days',
        'last_365_days',
    ]

    static getDatasourceWithBiggestLag(
        dataSources: WidgetDataSourceKey[] | undefined
    ): { name: WidgetDataSourceKey; lag: number } | null {
        if (!dataSources) {
            return null
        }

        const dsWithLag = dataSources.filter((ds) => DATA_SOURCES[ds]?.lag)

        if (dsWithLag.length === 0) {
            return { name: '', lag: 0 }
        }

        return dsWithLag
            .map((ds) => {
                return {
                    name: ds,
                    lag: DATA_SOURCES[ds]?.lag || 0,
                }
            })
            .reduce((prev, curr) => (prev.lag > curr.lag ? prev : curr))
    }

    static shouldOverrideRange(
        selectedRange: FilterValue | Moment[] | string[],
        dataSource: WidgetDataSourceKey | null | undefined
    ): boolean {
        if (isArray(selectedRange) || !dataSource) {
            return false
        }

        const isDsWithLag = !!DATA_SOURCES[dataSource]?.lag

        return isDsWithLag && this.includePreset.includes(selectedRange)
    }
}
