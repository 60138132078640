import { ReactElement } from 'react'

import { LockOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import compact from 'lodash/compact'
import find from 'lodash/find'
import remove from 'lodash/remove'
import uniq from 'lodash/uniq'
import pluralize from 'pluralize'

import { User, Dashboard } from 'types'

interface Props {
    onClick: () => void
    dashboard: Dashboard
    orgMembers: User[]
    isDirty: boolean
}

const ShareButton = ({
    dashboard,
    onClick,
    orgMembers,
    isDirty,
}: Props): ReactElement | null => {
    const createUser = find(orgMembers, ['email', dashboard.created_by])
    const createUserId = createUser && Number(createUser.id)

    const allUsersWithPermissions = compact(
        remove(
            uniq([...dashboard.read_users, ...dashboard.write_users]),
            (id) => id !== createUserId
        )
    )

    const onlyThisUser: boolean = allUsersWithPermissions.length === 0

    const tooltipTitle = (): string => {
        let title = 'This dashboard is private to only you'

        if (!onlyThisUser) {
            title = `This dashboard is shared with ${pluralize(
                'people',
                allUsersWithPermissions.length,
                true
            )}`
        }

        if (dashboard.org_wide_permission) {
            const permission =
                dashboard.org_wide_permission === 'read' ? 'view' : 'edit'
            title = `Your entire organization can ${permission} this dashboard`
        }

        if (isDirty) {
            title =
                'Please save or dismiss changes before updating share settings.'
        }
        return title
    }

    const buttonIcon = onlyThisUser ? <LockOutlined /> : <TeamOutlined />

    return dashboard.user_can_edit ? (
        <Tooltip title={tooltipTitle()} placement="bottom">
            <Button
                icon={buttonIcon}
                type="primary"
                onClick={() => onClick()}
                disabled={isDirty}
                style={{ marginLeft: 8 }}
            >
                Share
            </Button>
        </Tooltip>
    ) : null
}

export default ShareButton
