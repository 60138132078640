import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import createCachedSelector from 're-reselect'

import {
    AGGREGATION,
    isFilterValueTruthy,
    SEGMENT_ASIN_EXCLUDES,
    SEGMENT_ASIN_INCLUDES,
    SEGMENT_CATEGORY,
    SEGMENT_PRICE_MIN_MAX,
    SEGMENT_REVENUE_MIN_MAX,
    SEGMENT_REVIEWS_MIN_MAX,
    SEGMENT_SALES_MIN_MAX,
} from 'const/filters'
import {
    APP,
    DASHBOARD_PAGE,
    DASHBOARD_PRINT_PAGE,
    HOME_PAGE,
} from 'const/pages'
import { FILTER_SETTINGS, FILTERS, LOCAL_FILTERS } from 'const/reducerKeys'
import { mergeAndDedupeObjects } from 'helpers/utils'
import { FilterSettings, FiltersState, RootReduxState } from 'types'

import { selectDomainValue } from './ui'

export const selectLocalFilters = (
    state: RootReduxState,
    path: string[]
): FiltersState => selectDomainValue(state, [...path, LOCAL_FILTERS]) ?? {}

export const selectFilters = <T extends FiltersState = FiltersState>(
    state: RootReduxState,
    path: string[]
): T => selectDomainValue(state, [...path, FILTERS]) ?? {}

export const selectFilterSettings = (
    state: RootReduxState,
    path: string[]
): FilterSettings => selectDomainValue(state, [...path, FILTER_SETTINGS]) ?? {}

export const selectVisibleFilters = createCachedSelector(
    selectFilters,
    selectFilterSettings,
    (
        filters,
        {
            anchored = [],
            displayState = {},
            userAdded = [],
            order = [],
        }: FilterSettings
    ) => {
        return pickBy(
            filters,
            (value, key) =>
                anchored.includes(key) ||
                displayState[key] ||
                userAdded.includes(key) ||
                (isFilterValueTruthy(value) &&
                    (anchored.includes(key) || order.includes(key)))
        )
    }
)((_state, path) => JSON.stringify(path))

export const selectVisibleCombinedFilters = (
    state: RootReduxState,
    path: string[]
): FiltersState => {
    const pagePath = path.slice(0, 1)
    const page = pagePath[0]
    let appFilters = selectFilters(state, [APP])

    // DATES filter is stored at the dashboard level on DASHBOARD_PAGE
    // AGGREGATION filter is stored at the widget level on DASHBOARD_PAGE
    if (page === DASHBOARD_PAGE || page === DASHBOARD_PRINT_PAGE) {
        appFilters = {}
    }

    // AGGREGATION filter is stored at the widget level on HOME_PAGE
    if (page === HOME_PAGE) {
        appFilters = {
            ...appFilters,
            [AGGREGATION]: undefined,
        }
    }

    const pageFilters = selectVisibleFilters(state, pagePath)
    const pageLocalFilters = selectLocalFilters(state, pagePath)

    const objectsToMerge = [appFilters, pageFilters, pageLocalFilters]

    if (!isEqual(path, pagePath)) {
        // We also need to fetch the current tab filters
        const tabFilters = selectVisibleFilters(state, path)
        const tabLocalFilters = selectLocalFilters(state, path)

        objectsToMerge.push(tabFilters, tabLocalFilters)
    }

    return mergeAndDedupeObjects(objectsToMerge, [
        'dates',
        SEGMENT_ASIN_INCLUDES,
        SEGMENT_ASIN_EXCLUDES,
        SEGMENT_PRICE_MIN_MAX,
        SEGMENT_REVENUE_MIN_MAX,
        SEGMENT_SALES_MIN_MAX,
        SEGMENT_REVIEWS_MIN_MAX,
        SEGMENT_CATEGORY,
    ])
}
