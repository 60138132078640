import { useCallback, useState } from 'react'

import { type Key } from 'antd/es/table/interface'
import isUndefined from 'lodash/isUndefined'
import { shallowEqual, useSelector } from 'react-redux'

import {
    makeDownloadTableRequest,
    makeFetchTableRequest,
    makeUpdateTableColumnsConfig,
    makeUpdateTablePagination,
    makeUpdateTablePeriodDeltaDateRange,
    makeUpdateTablePeriodDeltaType,
    makeUpdateTablePeriodDeltaVisibility,
    makeUpdateTableRecord,
    makeUpdateTableSorter,
    makeUpdateTableTotalRowVisibility,
} from 'actions/ui/shared'
import {
    selectDomainValue,
    selectTableColumnState,
    selectTableSettings,
} from 'selectors/ui'
import {
    Field,
    PageFilters,
    Pagination,
    Path,
    PeriodDeltaType,
    RootReduxState,
    Sorter,
    Table,
} from 'types'

import useDeepCompareEffect from '../useDeepCompareEffect'

import { useAction } from './useAction'

interface TableDispatch {
    updateColumns: (columns: Field<any>[]) => void
    updateSorter: (sorter: Sorter) => void
    updatePagination: (pagination: Pagination) => void
    reloadData: (options?: { noCount?: boolean }) => void
    downloadData: () => void
    updateRecord: () => void
    updatePeriodDeltaVisibility: (isVisible: boolean) => void
    updatePeriodDeltaType: (periodDeltaType: PeriodDeltaType) => void
    updatePeriodDeltaDateRange: (range: string[]) => void
    updateTotalRowVisibility: (isVisible: boolean) => void
}

export const useTableDispatch = (tablePath: Path): TableDispatch => ({
    updateColumns: useCallback(
        useAction<Field<any>[]>(makeUpdateTableColumnsConfig(tablePath)),
        [tablePath]
    ),
    updateSorter: useCallback(
        useAction<Sorter>(makeUpdateTableSorter(tablePath)),
        [tablePath]
    ),
    updatePagination: useCallback(
        useAction<Pagination>(makeUpdateTablePagination(tablePath)),
        [tablePath]
    ),
    reloadData: useCallback(
        useAction<{ noCount?: boolean }>(makeFetchTableRequest(tablePath)),
        [tablePath]
    ),
    downloadData: useCallback(
        useAction<{ noCount?: boolean }>(makeDownloadTableRequest(tablePath)),
        [tablePath]
    ),
    updateRecord: useCallback(
        useAction<{
            rowIndex: number
            record: object
        }>(makeUpdateTableRecord(tablePath)),
        [tablePath]
    ),
    updatePeriodDeltaVisibility: useCallback(
        useAction<boolean>(makeUpdateTablePeriodDeltaVisibility(tablePath)),
        [tablePath]
    ),
    updatePeriodDeltaType: useCallback(
        useAction<PeriodDeltaType>(makeUpdateTablePeriodDeltaType(tablePath)),
        [tablePath]
    ),
    updatePeriodDeltaDateRange: useCallback(
        useAction<string[]>(makeUpdateTablePeriodDeltaDateRange(tablePath)),
        [tablePath]
    ),
    updateTotalRowVisibility: useCallback(
        useAction<boolean>(makeUpdateTableTotalRowVisibility(tablePath)),
        [tablePath]
    ),
})

interface TableSettings {
    isSettingsModalVisible: boolean
    toggleModalVisibility: () => void
    handleModalCancel: () => void
    handleModalOk: (
        columns: Field<any>[],
        periodDeltaType: PeriodDeltaType,
        periodDeltaDateRange: string[],
        showPeriodDeltas?: boolean,
        showTotalRow?: boolean
    ) => void
}

export const useTableSettingsProps = (tablePath: Path): TableSettings => {
    const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false)

    const {
        reloadData,
        updateColumns,
        updatePeriodDeltaVisibility,
        updatePeriodDeltaType,
        updatePeriodDeltaDateRange,
        updateTotalRowVisibility,
    } = useTableDispatch(tablePath)

    return {
        isSettingsModalVisible,
        toggleModalVisibility: useCallback(
            () => setIsSettingsModalVisible(!isSettingsModalVisible),
            [isSettingsModalVisible]
        ),
        handleModalCancel: useCallback(
            () => setIsSettingsModalVisible(false),
            []
        ),
        handleModalOk: useCallback(
            (
                columns: Field<any>[],
                periodDeltaType: PeriodDeltaType,
                periodDeltaDateRange: string[],
                showPeriodDeltas?: boolean,
                showTotalRow?: boolean
            ) => {
                if (!isUndefined(showPeriodDeltas)) {
                    updatePeriodDeltaVisibility(showPeriodDeltas)
                    updatePeriodDeltaType(periodDeltaType)
                    updatePeriodDeltaDateRange(periodDeltaDateRange)
                }

                if (!isUndefined(showTotalRow)) {
                    updateTotalRowVisibility(showTotalRow)
                }
                updateColumns(columns)
                reloadData({ noCount: true })
                setIsSettingsModalVisible(false)
            },
            [
                reloadData,
                updateColumns,
                updatePeriodDeltaVisibility,
                updatePeriodDeltaType,
                updatePeriodDeltaDateRange,
                updateTotalRowVisibility,
            ]
        ),
    }
}

export const useTableState = <T>(
    tablePath: Path,
    columnsConfiguration: Field<T>[]
): Omit<Table<T>, 'columns' | 'tableSettings'> & {
    columns: Field<T>[]
} & Table<T>['tableSettings'] => ({
    data: useSelector(
        (state: RootReduxState) =>
            selectDomainValue(state, [...tablePath, 'data']),
        shallowEqual
    ),
    loading: useSelector(
        (state: RootReduxState) =>
            selectDomainValue(state, [...tablePath, 'loading']),
        shallowEqual
    ),
    error: useSelector(
        (state: RootReduxState) =>
            selectDomainValue(state, [...tablePath, 'error']),
        shallowEqual
    ),
    downloading: useSelector(
        (state: RootReduxState) =>
            selectDomainValue(state, [...tablePath, 'downloading']),
        shallowEqual
    ),
    pagination: useSelector(
        (state: RootReduxState) =>
            selectTableSettings(state, tablePath).pagination,
        shallowEqual
    ),
    sorter: useSelector(
        (state: RootReduxState) => selectTableSettings(state, tablePath).sorter,
        shallowEqual
    ),
    showPeriodDeltas: useSelector(
        (state: RootReduxState) =>
            selectTableSettings(state, tablePath).showPeriodDeltas,
        shallowEqual
    ),
    periodDeltaType: useSelector(
        (state: RootReduxState) =>
            selectTableSettings(state, tablePath).periodDeltaType
    ),
    periodDeltaDateRange: useSelector(
        (state: RootReduxState) =>
            selectTableSettings(state, tablePath).periodDeltaDateRange
    ),
    showTotalRow: useSelector(
        (state: RootReduxState) =>
            selectTableSettings(state, tablePath).showTotalRow,
        shallowEqual
    ),
    columns: useSelector(
        (state: RootReduxState) =>
            selectTableColumnState(state, tablePath, columnsConfiguration),
        shallowEqual
    ),
})

interface TableRowSelection<T> {
    selectedRowKeys: Key[]
    selectedRows: T[]
    shouldUpdatePastPage: boolean
    isAllRowsSelected: boolean
    handleRowSelectionChange: (
        selectedRowKeys: Key[],
        selectedRows: T[]
    ) => void
    handleUpdatePastPageChange: (selected: boolean) => void
    unselectAllRows: () => void
}

export const useTableRowSelection = <T>(
    tablePath: Path,
    filters: PageFilters
): TableRowSelection<T> => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

    const [selectedRows, setSelectedRows] = useState<T[]>([])

    const [shouldUpdatePastPage, setShouldUpdatePastPage] = useState(false)

    const pagination = useSelector(
        (state: RootReduxState) =>
            selectTableSettings(state, tablePath).pagination
    )

    const isAllRowsSelected =
        selectedRowKeys.length === pagination.pageSize &&
        selectedRowKeys.length !== pagination.total

    const handleRowSelectionChange = useCallback(
        (keys: Key[], rows: T[]): void => {
            setSelectedRowKeys(keys)
            setSelectedRows(rows)
        },
        []
    )

    const unselectAllRows = useCallback((): void => {
        setSelectedRowKeys([])
        setSelectedRows([])
        setShouldUpdatePastPage(false)
    }, [])

    const handleUpdatePastPageChange = useCallback(
        (selected: boolean): void => setShouldUpdatePastPage(selected),
        []
    )

    // reset state when filters or pagination changes
    useDeepCompareEffect(() => {
        setSelectedRowKeys([])
        setSelectedRows([])
        setShouldUpdatePastPage(false)
    }, [filters, pagination])

    return {
        selectedRowKeys,
        selectedRows,
        shouldUpdatePastPage,
        isAllRowsSelected,
        handleUpdatePastPageChange,
        handleRowSelectionChange,
        unselectAllRows,
    }
}
