import { ReactElement, useCallback, CSSProperties } from 'react'

import { Select, Spin } from 'antd'
import isEqual from 'lodash/isEqual'
import uniqBy from 'lodash/uniqBy'

import {
    PREFETCH_RESULTS_PER_QUERY,
    SEARCH_RESULTS_PER_QUERY,
} from 'configuration/typeahead'
import { useTypeahead } from 'hooks'
import { getUserOrganizationGroups } from 'services/cerebroApi/orgScope/resourceApi'
import { OrganizationGroup } from 'types'

import {
    buildSerializableOrgGroupLabel,
    SerializableOrgGroup,
    serializeOrgGroup,
} from './serializableOrgGroup'
import SerializableOrgGroupDisplay from './SerializableOrgGroupDisplay'

interface SelectOption {
    value: string
    label: ReactElement
    searchable: string
    sortable: [string, string]
}

const sortOptions = (optionA: SelectOption, optionB: SelectOption): number => {
    const [idA, labelA] = optionA.sortable
    const [idB, labelB] = optionB.sortable

    // Order by id first, then title
    if (Number(idA) < Number(idB)) {
        return -1
    }
    if (Number(idA) > Number(idB)) {
        return 1
    }
    if (labelA < labelB) {
        return -1
    }
    if (labelA > labelB) {
        return 1
    }

    return 0
}

interface Props {
    value: SerializableOrgGroup
    onChange: (value: SerializableOrgGroup) => void
    defaultOptions: OrganizationGroup[]
    pinnedOrganizations: SerializableOrgGroup[]
    onPinOrganizationGroup: (serializable: SerializableOrgGroup) => void
    className?: string
    style?: CSSProperties
    prefetchRecordLimit?: number
}

const OrganizationGroupSearchSelect = ({
    value,
    onChange,
    defaultOptions = [],
    pinnedOrganizations,
    onPinOrganizationGroup,
    className,
    style,
    prefetchRecordLimit = PREFETCH_RESULTS_PER_QUERY,
}: Props): ReactElement => {
    const [options, loading, onSearch, resetLoadingState] =
        useTypeahead<OrganizationGroup>({
            apiSearchFunc: (query) => {
                return getUserOrganizationGroups({
                    limit: SEARCH_RESULTS_PER_QUERY,
                    name_or_org_id__matches: query,
                })
            },
            optionFormatter: ({
                id: organizationGroupId,
                name: organizationGroupName,
                organization: { id: organizationId, name: organizationName },
                agency,
            }) => {
                const serializable: SerializableOrgGroup = {
                    orgId: organizationId,
                    orgGroupId: organizationGroupId,
                    label: buildSerializableOrgGroupLabel(
                        organizationName,
                        organizationGroupName,
                        agency?.name
                    ),
                }
                // Return a minimal object that complies with Typeahead types and has everything we need
                return {
                    value: serializeOrgGroup(serializable),
                    label: `${organizationId} ${organizationName}`,
                    serializable,
                }
            },
            defaultOptions,
            prefetchApiFunc: useCallback(
                () =>
                    getUserOrganizationGroups({
                        limit: prefetchRecordLimit,
                        ordering: '-created_date',
                    }),
                [prefetchRecordLimit]
            ),
        })

    // Construct the proper objects to be used in the select
    const searchOptions: SelectOption[] = options.map((option) => {
        const isPinned = pinnedOrganizations.some((serializable) =>
            isEqual(serializable, option.serializable)
        )
        const serializedOption = serializeOrgGroup(option.serializable)
        return {
            value: serializedOption,
            label: (
                <SerializableOrgGroupDisplay
                    serializable={option.serializable}
                    showAction={!isPinned && !isEqual(value, serializedOption)}
                    isPinned={isPinned}
                    onPin={onPinOrganizationGroup}
                />
            ),
            searchable: option.label,
            sortable: [option.serializable.orgId, option.label],
        }
    })

    // Add the pinned organizations as options
    const pinnedOptions: SelectOption[] = pinnedOrganizations.map((pin) => {
        return {
            value: serializeOrgGroup(pin),
            label: (
                <SerializableOrgGroupDisplay
                    serializable={pin}
                    showAction={false}
                    isPinned
                />
            ),
            searchable: pin.label ?? '',
            sortable: [pin.orgId, pin.label ?? ''],
        }
    })

    // Combine the search and pinned options
    const selectOptions = uniqBy([...pinnedOptions, ...searchOptions], 'value')

    return (
        <Select<SerializableOrgGroup, SelectOption>
            className={className}
            style={{ ...style, width: '100%' }}
            optionFilterProp="searchable"
            optionLabelProp="label"
            placeholder="Select an Organization Group"
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={resetLoadingState}
            filterSort={sortOptions}
            showSearch
            value={value}
            options={selectOptions}
        />
    )
}

export default OrganizationGroupSearchSelect
