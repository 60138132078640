import produce from 'immer'
import set from 'lodash/set'

import { Field, FieldSerialized, FieldMapping } from 'types'

export function serializeColumns<RecordType>(
    columns: Field<RecordType>[]
): FieldSerialized[] {
    return columns.map((col) => ({
        id: col.id,
        isVisible: col.isVisible,
        antTableColumnOptions: {
            width: col.antTableColumnOptions.width,
        },
        ...(col.childrenFields
            ? {
                  childrenFields: col.childrenFields.map((child) => ({
                      isVisible: child.isVisible,
                      antTableColumnOptions: {
                          width: child.antTableColumnOptions.width,
                      },
                  })),
              }
            : {}),
    }))
}

export function deserializeColumns<RecordType>(
    columnsSerialized: (FieldSerialized | string)[],
    columns: Field<RecordType>[],
    mergeInNewCodedColumns = true
): Field<RecordType>[] {
    const codedColumns: FieldMapping<RecordType> = {}
    columns.forEach((each) => {
        codedColumns[each.id] = each
    })

    // serialized columns may have fields that no longer exist in the code
    const validSerializedColumns = columnsSerialized.filter((each) => {
        return (typeof each === 'string' ? each : each?.id) in codedColumns
    })
    const presentColumnMap: FieldMapping<RecordType> = {}
    validSerializedColumns.forEach((each) => {
        const key = typeof each === 'string' ? each : each.id
        presentColumnMap[key] = codedColumns[key]
    })

    // columns that are in the codebase but not in the serialized columns list
    const missingCodedColumns = mergeInNewCodedColumns
        ? columns
              .map((each, idx) => ({
                  id: each.id,
                  index: idx,
                  column: each,
              }))
              .filter((each) => {
                  return !presentColumnMap[each.id]
              })
        : []

    // map serialized columns to the columns in the codebase, preserving serialized order
    const mappedColumns = validSerializedColumns
        .filter((col) => {
            const key = typeof col === 'string' ? col : col.id
            return !!codedColumns[key]
        })
        .map((col) => {
            const key = typeof col === 'string' ? col : col.id
            const columnsDeserialized = codedColumns[key]!
            return produce(columnsDeserialized, (draft) => {
                const visible =
                    typeof col === 'string' ? undefined : col.isVisible
                if (draft) {
                    draft.isVisible = visible ?? draft.isVisible

                    // if the column is configured to not be resizeable,
                    // always use the official width over the serialized width
                    if (draft.isResizeable) {
                        const width =
                            typeof col === 'string'
                                ? undefined
                                : col?.antTableColumnOptions?.width

                        draft.antTableColumnOptions.width =
                            width ?? draft.antTableColumnOptions.width
                    }

                    if (
                        typeof col !== 'string' &&
                        draft.childrenFields &&
                        col.childrenFields
                    ) {
                        draft.childrenFields.forEach(
                            (_child, idx, children) => {
                                const childSerialized =
                                    col.childrenFields?.[idx]
                                set(
                                    children,
                                    [idx, 'antTableColumnOptions', 'width'],
                                    childSerialized?.antTableColumnOptions
                                        ?.width
                                )
                                set(
                                    children,
                                    [idx, 'isVisible'],
                                    childSerialized?.isVisible
                                )
                            }
                        )
                    }
                }
            })
        })

    // add missing columns to the end of the list,
    // try to preserve the order of the missing columns
    missingCodedColumns.forEach((each) => {
        mappedColumns.splice(each.index, 0, each.column)
    })

    return mappedColumns
}
