import { ReactElement } from 'react'

import classNames from 'classnames'

import Stack from 'components/Layout/Stack'

import * as styles from './styles.scss'

export default function CreateAdGroupNegativeKeywordFormheader(): ReactElement {
    return (
        <Stack direction="row" alignItems="start">
            <div
                className={styles['header-label']}
                style={{
                    flex: 1,
                    marginTop: 8,
                    marginRight: 4,
                    textAlign: 'left',
                }}
            >
                Keyword
            </div>
            <div
                className={classNames(
                    styles['header-label'],
                    styles['match-type-column']
                )}
                style={{
                    marginTop: 8,
                    marginRight: 4,
                    textAlign: 'left',
                }}
            >
                Match Type
            </div>

            <div
                className={classNames(
                    styles['header-label'],
                    styles['remove-column']
                )}
                style={{
                    textAlign: 'center',
                    marginTop: 8,
                    marginRight: 4,
                }}
            >
                Remove
            </div>
        </Stack>
    )
}
