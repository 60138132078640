import { PropsWithChildren, ReactElement, ReactNode } from 'react'

import { Tag } from 'antd'
import noop from 'lodash/noop'

import { BRAND_COLOR } from 'const/colors'

interface ColoredTagProps {
    color?: string
    onClose: () => void
    onClick: () => void
    className: string
    closable: boolean
    children: ReactNode
}

const ColoredTag = ({
    children,
    color = BRAND_COLOR.PRIMARY,
    onClose = noop,
    onClick = noop,
    closable = false,
    ...rest
}: PropsWithChildren<ColoredTagProps>): ReactElement => {
    const forwardedProps = {
        color,
        onClose,
        onClick,
        closable,
        ...(rest.className ? { className: rest.className } : {}),
    }

    return <Tag {...forwardedProps}>{children}</Tag>
}

export default ColoredTag
