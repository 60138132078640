import { ReactNode, ReactElement, Fragment } from 'react'

import { Typography } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import {
    DataSourceMetric,
    GlossaryItem,
    GlossaryMetricItem,
    GlossaryMetricItemFormulaExpressionNode,
} from 'features/MetricsGlossary/types'

import * as styles from './styles.scss'

interface MetricRefExpression {
    hashcode: string
    value: ReactNode[]
}

function parseMetricExpression(
    tree: GlossaryMetricItemFormulaExpressionNode,
    metricRefs: { [key: string]: string }
): MetricRefExpression {
    if (!tree) {
        return {
            hashcode: '',
            value: [],
        }
    }

    let hashcode = ''
    const expression = []

    if (tree.left) {
        const leftSubExpression = parseMetricExpression(tree.left, metricRefs)
        if (tree.left.data.type === 'operator') {
            expression.push(
                <span key={uuid()} className={styles.operator}>
                    (
                </span>
            )
            expression.push(leftSubExpression.value)
            expression.push(
                <span key={uuid()} className={styles.operator}>
                    )
                </span>
            )
            hashcode += leftSubExpression.hashcode
        } else {
            expression.push(leftSubExpression.value)
            hashcode += leftSubExpression.hashcode
        }
    }

    if (tree.data.type === 'operator') {
        expression.push(
            <span key={uuid()} className={styles.operator}>
                &nbsp;{tree.data.value}&nbsp;
            </span>
        )
        hashcode += tree.data.value
    } else {
        const value =
            tree.data.type === 'metric_ref'
                ? metricRefs[tree.data.value]
                : tree.data.value
        expression.push(
            <span key={uuid()} className={styles.operand}>
                {value}
            </span>
        )
        hashcode += `${value}`
    }

    if (tree.right) {
        const rightSubExpression = parseMetricExpression(tree.right, metricRefs)
        if (tree.right.data.type === 'operator') {
            expression.push(
                <span key={uuid()} className={styles.operator}>
                    (
                </span>
            )
            expression.push(rightSubExpression.value)
            expression.push(
                <span key={uuid()} className={styles.operator}>
                    )
                </span>
            )
            hashcode += `(${rightSubExpression.hashcode})`
        } else {
            expression.push(rightSubExpression.value)
            hashcode += rightSubExpression.hashcode
        }
    }

    return { hashcode, value: expression }
}

interface MetricFormulaExpressionProps {
    metricShortName: string
    expression: MetricRefExpression
}

function MetricFormulaExpression({
    metricShortName,
    expression,
}: MetricFormulaExpressionProps): ReactElement | null {
    return (
        <div className={styles['metric-formula-container']}>
            <div className={styles['metric-formula']}>
                <span className={styles['primary-metric']}>
                    {metricShortName}
                </span>
                &nbsp;
                <span className={styles['equals-sign']}>=</span>
                &nbsp;
                {expression.value}
            </div>
        </div>
    )
}

interface MetricFormulaProps {
    metric: DataSourceMetric
}

export function MetricFormula({
    metric,
}: MetricFormulaProps): ReactElement | null {
    if (!metric.metadata_formula) {
        return null
    }

    const parsedFormulaExpression = parseMetricExpression(
        metric.metadata_formula.expression,
        metric.metadata_formula.metric_refs
    )

    return (
        <Typography.Paragraph>
            <MetricFormulaExpression
                metricShortName={metric.short_name || metric.display_name}
                expression={parsedFormulaExpression}
            />
        </Typography.Paragraph>
    )
}

interface MetricFormulaListProps {
    metric: GlossaryItem
    dataSourceId?: string
    allVersionsLink?: string
}

export default function MetricFormulaList({
    metric,
    dataSourceId,
    allVersionsLink,
}: MetricFormulaListProps): ReactElement | null {
    const { t } = useTranslation()
    const parsedFormulaExpressions = (metric?.metrics ?? []).map((m) => {
        if (!m.metadata_formula) {
            return null
        }
        const expression = parseMetricExpression(
            m.metadata_formula.expression,
            m.metadata_formula.metric_refs
        )
        return {
            metric: m,
            expression,
        }
    })

    const formulasByHash: Record<
        string,
        {
            metric: GlossaryMetricItem
            expression: MetricRefExpression
        }[]
    > = {}
    parsedFormulaExpressions.forEach((formula) => {
        if (!formula) {
            return
        }
        if (!formulasByHash[formula.expression.hashcode]) {
            formulasByHash[formula.expression.hashcode] = []
        }
        formulasByHash[formula.expression.hashcode].push(formula)
    })

    const orderedKeys = Object.keys(formulasByHash).sort((a, b) => {
        return formulasByHash[a].length > formulasByHash[b].length ? -1 : 1
    })

    const selectedFormula = parsedFormulaExpressions.find(
        (e) => e?.metric.data_source.id === dataSourceId
    )

    const hasMultipleFormulas = orderedKeys.length > 1

    return selectedFormula ? (
        <>
            {hasMultipleFormulas && (
                <Typography.Paragraph style={{ marginBottom: 0 }}>
                    {selectedFormula.metric.data_source.display_name}:
                </Typography.Paragraph>
            )}
            <Typography.Paragraph>
                <MetricFormulaExpression
                    metricShortName={
                        selectedFormula.metric.short_name ||
                        selectedFormula.metric.display_name
                    }
                    expression={selectedFormula.expression}
                />
            </Typography.Paragraph>
            {hasMultipleFormulas && allVersionsLink && (
                <Typography.Paragraph>
                    <Trans i18nKey="metrics:glossary.formulaList.formulasDiffer.subtitle">
                        This metric has a different formula for some different
                        data sources,{' '}
                        <Link to={allVersionsLink}>see all versions</Link>
                    </Trans>
                </Typography.Paragraph>
            )}
        </>
    ) : (
        <>
            {orderedKeys.map((m) => {
                const formulas = formulasByHash[m]
                const firstFormula = formulas[0]
                const dataSources = formulas
                    .map((f) => f.metric.data_source.display_name)
                    .join(', ')
                return (
                    <Fragment key={m}>
                        {orderedKeys.length > 1 && (
                            <Typography.Paragraph style={{ marginBottom: 0 }}>
                                {t(
                                    'metrics:glossary.formulaList.formulasDiffer.count',
                                    'Formula for {{ count }} data sources',
                                    {
                                        count: formulas.length,
                                    }
                                )}{' '}
                                ({dataSources}):
                            </Typography.Paragraph>
                        )}
                        <Typography.Paragraph>
                            <MetricFormulaExpression
                                metricShortName={
                                    firstFormula.metric.short_name ||
                                    firstFormula.metric.display_name
                                }
                                expression={firstFormula.expression}
                            />
                        </Typography.Paragraph>
                    </Fragment>
                )
            })}
        </>
    )
}
