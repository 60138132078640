import { ReactElement } from 'react'

import { useAppConfig } from 'appConfig'
import { ContactUsLink } from 'components/Links'
import LogoSvg from 'images/logo/ds-js-color-logo'

import * as styles from './styles.scss'

function DowntimePage(): ReactElement {
    const { productTitle } = useAppConfig()
    return (
        <div className={styles.background}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <LogoSvg />
                </div>
                <p>
                    We are experiencing an issue resulting in unexpected
                    downtime. We are aware of this and are working to remedy the
                    situation.
                </p>
                <p>
                    <ContactUsLink link>Let us know</ContactUsLink> if you have
                    questions or concerns.
                </p>
                <p style={{ marginBottom: 0 }}>Thanks for your patience,</p>
                <p>{productTitle} Engineering Team</p>
            </div>
        </div>
    )
}

export default DowntimePage
