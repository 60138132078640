import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import InfoIconSvg from './InfoIconSvg'

const InfoIcon = (props: IconProps): ReactElement => (
    <Icon component={InfoIconSvg} {...props} />
)

export default InfoIcon
