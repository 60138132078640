import './wdyr'
import { ReactElement, useCallback, useEffect, useState } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { BrowserOptions, dedupeIntegration, init } from '@sentry/browser'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider } from 'antd'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { LDContext, LDProvider } from 'launchdarkly-react-client-sdk'
import { createRoot } from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { AppConfigProvider } from 'appConfig'
import ImpersonationChangeAlert from 'components/ImpersonationChangeAlert/ImpersonationChangeAlert'
import { excludedActivityUrls, ignoreErrors } from 'configuration/monitoring'
import { OrgContextProvider } from 'context/OrgContext'
import { UserContextProvider } from 'context/UserContext'
import { UserOverrideContextProvider } from 'context/UserOverrideContext'
import { isSuppressableError, shouldSuppressError } from 'helpers/monitoring'
import enResource from 'locales/en'
import zhResource from 'locales/zh'
import { getPersistor, store } from 'store'
import { antdTheme } from 'theme/antdTheme'
import { DatadogService } from 'types'
import { App } from 'views/App'

import 'configuration/highcharts'
import 'antd/dist/reset.css'
import 'react-grid-layout/css/styles.css'

import 'react-international-phone/style.css'
import 'react-resizable/css/styles.css'
import 'rc-calendar/assets/index.css'
import 'quill/dist/quill.snow.css'
import './styles/quill/quill-overrides.scss'
import './styles/global.scss'

// configure sentry
const sentryConfig: BrowserOptions = {
    dsn: SENTRY_CONFIG_URL,
    environment: ASSET_PATH === '/' ? STAGE : 'PR',
    ...(ASSET_PATH === '/' ? { release: VERSION } : {}),
    // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
    ignoreErrors,
    integrations: [dedupeIntegration()],
}

if (process.env.NODE_ENV === 'development') {
    // do not report to sentry during local development
    sentryConfig.beforeSend = (_event, hint) => {
        console.error(hint.originalException || hint.syntheticException)
        return null
    }
}

init(sentryConfig)

// configure Datadog Real User Monitoring (RUM)
datadogRum.init({
    applicationId: RUM_APP_ID,
    clientToken: RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: DatadogService.SYSTEM,
    env: STAGE,
    version: VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [CEREBRO_API_ENDPOINT],
    excludedActivityUrls,
    beforeSend: (event, context) => !shouldSuppressError({ event, context }),
})

if (process.env.NODE_ENV !== 'development') {
    datadogRum.startSessionReplayRecording()
}

// configure i18n
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: process.env.NODE_ENV === 'development',
        resources: {
            en: enResource,
            zh: zhResource,
        }, // add resources for new languages here after translations are added by Lokalise
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        returnEmptyString: false,
        react: {
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span', 'p'],
            useSuspense: false,
        },
    })

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 1,
            staleTime: Infinity,
            gcTime: 1000 * 60 * 30,
        },
    },
})

const RootComponent = (): ReactElement => {
    const [ldContext, setLdContext] = useState<LDContext>()
    const persistor = getPersistor()

    // Set the initial LaunchDarkly context when the user context is available.
    // This allows us to defer loading flags the first time until we have an actual user set.
    // Otherwise, the flags would be loaded with the default anonymous user context and anonymous users count
    // towards the monthly active user limit.
    const setInitialContext = useCallback(
        (context: LDContext) => {
            if (!ldContext && !!context) {
                setLdContext(context)
            }
        },
        [ldContext]
    )

    useEffect(() => {
        const handler = (event: ErrorEvent): void => {
            if (isSuppressableError(event.message)) {
                event.stopImmediatePropagation()
                event.preventDefault()
                event.stopPropagation()
                console.error(
                    `ignored error on handler: ${event.message}`,
                    event
                )
            }
        }
        window.addEventListener('error', handler)
        return () => {
            window.removeEventListener('error', handler)
        }
    })

    return (
        <LDProvider
            clientSideID={LAUNCH_DARKLY_CLIENT_ID}
            deferInitialization
            context={ldContext}
            options={{ streaming: false }}
        >
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <AppConfigProvider>
                        <QueryClientProvider client={queryClient}>
                            <ReactQueryDevtools initialIsOpen={false} />
                            <ConfigProvider theme={antdTheme}>
                                <ImpersonationChangeAlert />
                                <OrgContextProvider>
                                    <UserContextProvider
                                        onContextChanged={setInitialContext}
                                    >
                                        {/*
                                        UserOverrideContextProvider can be used further down the tree
                                        when there is a need to override the context of the current user.
                                        For example, the dashboard print page uses this provider to
                                        provide overrides when fetching widget data.
                                        */}
                                        <UserOverrideContextProvider>
                                            <App />
                                        </UserOverrideContextProvider>
                                    </UserContextProvider>
                                </OrgContextProvider>
                            </ConfigProvider>
                        </QueryClientProvider>
                    </AppConfigProvider>
                </PersistGate>
            </Provider>
        </LDProvider>
    )
}

const render = (): void => {
    const container = document.getElementById('root')
    const root = createRoot(container!)
    root.render(<RootComponent />)
}
render()
