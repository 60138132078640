import { ReactNode } from 'react'

import isEmpty from 'lodash/isEmpty'

import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { labelUrl, productUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import {
    ProductMetadataGroupBy,
    CellRenderProps,
    Label,
    CountryCode,
    ProductMetadata,
} from 'types'

import ProductTitleCell from '../ProductCatalogTitleCell/ProductCatalogTitleCell'

export function metadataIdRenderer<RecordType extends ProductMetadataGroupBy>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) || isEmpty(record.metadata) ? (
        undefinedValue
    ) : (
        <ResourceLink
            to={productUrl(record.metadata.asin, record.metadata.marketplace)}
        >
            {record.metadata.title}
        </ResourceLink>
    )
}

export function productLabelIdRenderer<
    RecordType extends ProductMetadataGroupBy & {
        product__label: Label
    },
>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }
    return (
        <ResourceLink
            to={{
                pathname: labelUrl(value),
                search: '?tab=products',
            }}
        >
            {record.product__label?.name}
        </ResourceLink>
    )
}

interface ProductInfo {
    metadata_id?: string
    asin: string
    name: string | null
    small_image_url?: string
    title: string | null
    hero_asin_image_url?: string
    hero_asin_title?: string
    marketplace?: CountryCode
    metadata?: ProductMetadata
}

const extractMarketplaceFromRecord = (
    record: ProductInfo
): CountryCode | undefined => {
    let { marketplace } = record
    if (!marketplace && record.metadata_id) {
        marketplace = record.metadata_id.split('_')[0] as CountryCode
    }
    return marketplace
}

export function TitleRenderer<RecordType extends ProductInfo>({
    record,
    showImage,
}: CellRenderProps<RecordType>): ReactNode {
    const { metadata } = record
    return (
        <ProductTitleCell
            value={
                metadata?.hero_asin_title ??
                record.hero_asin_title ??
                metadata?.title ??
                record.title ??
                record.name
            }
            productImg={
                metadata?.hero_asin_image_url ??
                record.hero_asin_image_url ??
                metadata?.small_image_url ??
                record.small_image_url
            }
            productAsin={metadata?.asin}
            productMarketplace={extractMarketplaceFromRecord(record)}
            showImage={showImage}
        />
    )
}
