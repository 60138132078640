import type {
    CerebroApiOptions,
    BannerRequestBody,
    CerebroResourceResponse,
    Report,
    AsyncUpdate,
    CerebroPaginatedResponse,
} from 'types'

import { axiosInstanceOrganizations } from './client'
import { getCerebroHeaders } from './headers'

export const getCampaignFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/campaign_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )

export const asyncUpdateCampaignFacts = (
    data: any,
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<AsyncUpdate>> =>
    axiosInstanceOrganizations.patch(
        '/api/campaign_facts/aggregate/banner/mutate/',
        data,
        {
            params: { ...params, metrics: null },
            headers: getCerebroHeaders(options.headers),
        }
    )

export const getCampaignFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        `/api/campaign_facts/aggregate/banner/export/`,
        params
    )
