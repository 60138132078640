import { ReactElement } from 'react'

import { Button } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { signOutRequest } from 'actions/auth'
import { useAppConfig } from 'appConfig'
import { TextButton } from 'components/TextButton'
import {
    AUTH_FORGOT_PASSWORD_PAGE,
    AUTH_INVITATION_SIGN_UP_PAGE,
    AUTH_ORGANIZATION_PAGE,
    AUTH_PAGE,
    AUTH_RESET_PASSWORD_PAGE,
    AUTH_SIGN_IN_MFA_PAGE,
    AUTH_SIGNUP_PAGE,
    AUTH_VERIFY_EMAIL_PAGE,
    HOME_PAGE,
} from 'const/pages'
import { userHasAssumeAnyUserPermissions } from 'helpers/featurePermissions'
import { getPath } from 'helpers/pages'
import { useAuthDomainValue, useRumManualViewTracking } from 'hooks'
import CobaltLogo from 'images/logo/CobaltLogo'
import { DatadogService, UserPermission } from 'types'

import AuthOrganizationPage from './AuthOrganizationPage'
import {
    CreateAccountForm,
    ForgotPasswordForm,
    LoginForm,
    ResetPasswordForm,
    VerifyEmailForm,
    SmsCodeForm,
} from './Forms'
import InvitationSignUpPage from './InvitationSignUpPage'
import * as styles from './styles.scss'

interface LocationState {
    from: Partial<Location> | Location
}

export const sanitizeLocationState = (
    locationState?: LocationState
): LocationState => {
    if (
        locationState?.from?.pathname === getPath(AUTH_PAGE) ||
        !locationState?.from?.pathname ||
        !locationState?.from?.pathname?.startsWith('/')
    ) {
        return {
            from: {
                pathname: getPath(HOME_PAGE),
            },
        }
    }

    return locationState
}

const Auth = ({ location }: RouteComponentProps): ReactElement => {
    useRumManualViewTracking(DatadogService.AUTH)

    const dispatch = useDispatch()
    const signedIn = useAuthDomainValue<boolean>('signedIn')
    const organizationId = useAuthDomainValue('organizationId')
    const userFeaturePermissions: UserPermission[] = useAuthDomainValue(
        'userFeaturePermissions'
    )
    const { t, i18n } = useTranslation('common')

    const { isChinaBuild } = useAppConfig()

    const locationState = location.state as LocationState | undefined
    const { from } = sanitizeLocationState(locationState)

    const isChinese = i18n.language === 'zh'

    const changeLanguage = (): void => {
        i18n.changeLanguage(isChinese ? 'en' : 'zh')
    }

    const changeLanguageText = isChinese
        ? 'Switch Language to English'
        : t('common:languageSelect.zh', 'Switch Language to Chinese')

    if (
        signedIn &&
        (organizationId ||
            userHasAssumeAnyUserPermissions(userFeaturePermissions))
    ) {
        return <Redirect to={from} />
    }

    return (
        <div className={styles.container}>
            {signedIn && (
                <Button
                    type="link"
                    className={styles.logout}
                    onClick={() => dispatch(signOutRequest())}
                >
                    {t('common:logOut', 'Log Out')}
                </Button>
            )}
            <div className={styles.logo}>
                <CobaltLogo style={{ height: 48 }} />
            </div>
            <div className={classNames(styles['form-container'], styles.form)}>
                <Switch>
                    <Route
                        exact
                        path={getPath(AUTH_PAGE)}
                        component={LoginForm}
                    />
                    <Route
                        exact
                        path={getPath(AUTH_SIGNUP_PAGE)}
                        component={CreateAccountForm}
                    />
                    <Route
                        path={getPath(AUTH_VERIFY_EMAIL_PAGE)}
                        component={VerifyEmailForm}
                    />
                    <Route
                        path={getPath(AUTH_ORGANIZATION_PAGE)}
                        component={AuthOrganizationPage}
                    />
                    <Route
                        path={getPath(AUTH_INVITATION_SIGN_UP_PAGE)}
                        component={InvitationSignUpPage}
                    />
                    <Route
                        path={getPath(AUTH_FORGOT_PASSWORD_PAGE)}
                        component={ForgotPasswordForm}
                    />
                    <Route
                        path={getPath(AUTH_RESET_PASSWORD_PAGE)}
                        component={ResetPasswordForm}
                    />
                    <Route
                        path={getPath(AUTH_SIGN_IN_MFA_PAGE)}
                        component={SmsCodeForm}
                    />
                    <Redirect to={getPath(AUTH_PAGE)} />
                </Switch>
            </div>
            {(isChinaBuild || isChinese) && (
                <div className={styles.languageSelector}>
                    <TextButton onClick={changeLanguage}>
                        {changeLanguageText}
                    </TextButton>
                </div>
            )}
        </div>
    )
}

export default Auth
