import { ReactElement } from 'react'

import { LoadingIndicator } from '../LoadingIndicator'

import * as styles from './styles.scss'

const RouteLoadingPlaceholder = (): ReactElement => {
    return (
        <div className={styles.background}>
            <LoadingIndicator size="small" />
        </div>
    )
}

export default RouteLoadingPlaceholder
