import { ReactElement } from 'react'

import { CheckOutlined } from '@ant-design/icons'
import { Button, Divider, Select } from 'antd'
import { Field, Formik, FormikHelpers, ErrorMessage, FieldProps } from 'formik'
import { object, string } from 'yup'

import { FormikSelect } from 'components/formik'
import Stack from 'components/Layout/Stack'
import {
    CampaignForSearchTermNegativeKeywordSearchSelect,
    AdGroupForSearchTermNegativeKeywordSelectField,
} from 'components/SearchSelect'
import {
    NEGATIVE_EXACT,
    NEGATIVE_MATCH_TYPE_OPTIONS,
    NEGATIVE_PHRASE,
} from 'const/matchTypes'
import { ENABLED } from 'const/resourceStates'
import { useCerebroApiRequest } from 'hooks'
import {
    createCampaignNegativeKeyword,
    createNegativeKeyword,
} from 'services/cerebroApi/orgScope/resourceApi'
import { Keyword, CerebroResourceResponse } from 'types'
import message from 'utilities/message'

import { SelectedSearchTerm } from '../localTypes'
import * as styles from '../styles.scss'

import KeywordTypeSelectField from './KeywordTypeSelectField'
import { KEYWORD_TYPE, FormValues } from './shared'

interface Props {
    selectedSearchTerm: SelectedSearchTerm
    handleCancel: () => void
}

const AddNegativeKeywordForm = ({
    selectedSearchTerm,
    handleCancel,
}: Props): ReactElement | null => {
    const makeCerebroApiRequest = useCerebroApiRequest()

    const {
        id: searchTerm,
        record: { campaign },
    } = selectedSearchTerm

    const { profile_id: profileId } = campaign

    return (
        <Formik<FormValues>
            initialValues={{
                // static values
                searchTerm,

                // form values
                keywordType: '',
                campaignId: '',
                adGroupId: '',
                matchType: '',
            }}
            enableReinitialize
            onSubmit={async (
                values: FormValues,
                { resetForm }: FormikHelpers<FormValues>
            ): Promise<void> => {
                if (values.keywordType === KEYWORD_TYPE.CAMPAIGN_NEGATIVE) {
                    await makeCerebroApiRequest<
                        Keyword,
                        CerebroResourceResponse<Keyword>
                    >({
                        request: createCampaignNegativeKeyword({
                            text: values.searchTerm,
                            match_type: values.matchType,
                            campaign_id: values.campaignId,
                            state: ENABLED,
                        }),
                        onRequestSuccess: ({ data: keyword }) => {
                            message.success(
                                <span>
                                    Successfully created new Campaign Negative
                                    Keyword <strong>{keyword?.text}</strong> on
                                    Campaign{' '}
                                    <strong>{keyword?.campaign.name}</strong>.
                                </span>
                            )
                            resetForm()
                            handleCancel()
                        },
                    })
                }

                if (values.keywordType === KEYWORD_TYPE.AD_GROUP_NEGATIVE) {
                    await makeCerebroApiRequest<
                        Keyword,
                        CerebroResourceResponse<Keyword>
                    >({
                        request: createNegativeKeyword({
                            text: values.searchTerm,
                            match_type: values.matchType,
                            campaign_id: values.campaignId,
                            ad_group_id: values.adGroupId,
                            state: ENABLED,
                        }),
                        onRequestSuccess: ({ data: keyword }) => {
                            message.success(
                                <span>
                                    Successfully created Ad Group Negative
                                    Keyword <strong>{keyword?.text}</strong> on
                                    Campaign{' '}
                                    <strong>{keyword?.campaign.name}</strong>.
                                </span>
                            )
                            resetForm()
                            handleCancel()
                        },
                    })
                }
            }}
            validationSchema={object().shape({
                // static values
                searchTerm: string().required(),

                // form values
                keywordType: string()
                    .matches(
                        new RegExp(
                            `${KEYWORD_TYPE.AD_GROUP_NEGATIVE}|${KEYWORD_TYPE.CAMPAIGN_NEGATIVE}`
                        )
                    )
                    .label('Keyword Type')
                    .required(),
                campaignId: string().label('Campaign').nullable().required(),
                adGroupId: string()
                    .label('Ad Group')
                    .when('keywordType', {
                        is: KEYWORD_TYPE.AD_GROUP_NEGATIVE,
                        then: (schema) => schema.required(),
                    }),
                matchType: string()
                    .matches(new RegExp(`${NEGATIVE_EXACT}|${NEGATIVE_PHRASE}`))
                    .label('Match Type')
                    .required(),
            })}
        >
            {({ handleSubmit, isSubmitting, resetForm, values }) => (
                <>
                    <Stack direction="column" style={{ marginBottom: 12 }}>
                        <div className={styles.label}>Campaign</div>
                        <div>
                            <Field name="campaignId">
                                {({
                                    field,
                                    form: { setFieldValue },
                                }: FieldProps) => (
                                    <CampaignForSearchTermNegativeKeywordSearchSelect
                                        profileId={profileId}
                                        onChange={(id) => {
                                            setFieldValue(field.name, id)
                                        }}
                                        value={field.value}
                                        defaultOptions={[campaign]}
                                    />
                                )}
                            </Field>
                        </div>
                        <ErrorMessage
                            name="campaignId"
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </Stack>

                    <Stack direction="column" style={{ marginBottom: 12 }}>
                        <div className={styles.label}>
                            Negative Keyword Type
                        </div>
                        <div>
                            <KeywordTypeSelectField />
                        </div>
                        <ErrorMessage
                            name="matchType"
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </Stack>

                    {values.keywordType === KEYWORD_TYPE.AD_GROUP_NEGATIVE && (
                        <Stack direction="column" style={{ marginBottom: 12 }}>
                            <div className={styles.label}>Ad Group</div>
                            <div>
                                <AdGroupForSearchTermNegativeKeywordSelectField
                                    name="adGroupId"
                                    campaignId={values.campaignId}
                                    disabled={!values.campaignId}
                                />
                            </div>
                            <ErrorMessage
                                name="adGroupId"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </Stack>
                    )}

                    <Stack direction="column">
                        <div className={styles.label}>Match Type</div>
                        <div>
                            <Field
                                name="matchType"
                                component={FormikSelect}
                                placeholder="Select Type"
                                style={{ width: 250 }}
                            >
                                {NEGATIVE_MATCH_TYPE_OPTIONS.map(
                                    ({ value, label }) => (
                                        <Select.Option
                                            value={value}
                                            key={value}
                                        >
                                            {label}
                                        </Select.Option>
                                    )
                                )}
                            </Field>
                        </div>
                        <ErrorMessage
                            name="matchType"
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </Stack>

                    <Divider />

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                    >
                        <Button
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                resetForm()
                                handleCancel()
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={() => handleSubmit()}
                            loading={isSubmitting}
                        >
                            Add Keyword
                        </Button>
                    </Stack>
                </>
            )}
        </Formik>
    )
}

export default AddNegativeKeywordForm
