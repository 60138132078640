import { ReactElement } from 'react'

import { Select } from 'antd'
import classNames from 'classnames'
import { Field, ErrorMessage } from 'formik'

import { IconButton } from 'components/Buttons'
import { FormikSelect } from 'components/formik'
import { TrashIcon } from 'components/Icons'
import Stack from 'components/Layout/Stack'
import { NEGATIVE_MATCH_TYPE_OPTIONS } from 'const/matchTypes'

import { KeywordField } from './localTypes'
import * as styles from './styles.scss'

interface Props {
    keyword: KeywordField
    keywordIndex: number
    remove: (index: number) => void
}

const CreateCampaignNegativeKeywordFormRow = ({
    keyword,
    keywordIndex,
    remove,
}: Props): ReactElement => {
    return (
        <Stack direction="row" alignItems="start" style={{ marginTop: 8 }}>
            <div style={{ flex: 1, marginRight: 4 }}>{keyword.searchTerm}</div>
            <Stack
                direction="column"
                alignItems="center"
                style={{ marginRight: 4 }}
                className={classNames(
                    styles.field,
                    styles['match-type-column']
                )}
            >
                <Field
                    name={`keywords.${keywordIndex}.matchType`}
                    component={FormikSelect}
                    placeholder="Select Match Type"
                >
                    {NEGATIVE_MATCH_TYPE_OPTIONS.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                            {label}
                        </Select.Option>
                    ))}
                </Field>
                <ErrorMessage
                    name={`keywords.${keywordIndex}.matchType`}
                    component="div"
                    className="fg-control-text is-error"
                />
            </Stack>

            <Stack
                direction="column"
                alignItems="center"
                className={classNames(styles.field, styles['remove-column'])}
                style={{ marginRight: 4 }}
            >
                <IconButton
                    icon={<TrashIcon />}
                    danger
                    onClick={() => {
                        remove(keywordIndex)
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default CreateCampaignNegativeKeywordFormRow
