import get from 'lodash/get'
import includes from 'lodash/includes'
import { Translation } from 'react-i18next'

import { ChangeDelta } from 'components/ChangeDelta'
import { UNDEFINED_VALUE } from 'const/formatting'
import { CHANGE, LAST_PERIOD, PERCENTAGE_CHANGE } from 'const/metrics'
import { formatCerebroDate, formatNumber } from 'helpers/formatting'
import { ChildField, Field, MetricFieldConfig, MetricType } from 'types'

import { FieldCreatorOptions } from '../localTypes'

interface MakeFormatDeltaColumnOptions {
    format?: string
    type?: MetricType
}

type FormatDeltaColumn = (value: string | number) => string

const makeFormatDeltaColumn = (
    deltaType: string = CHANGE,
    { format, type }: MakeFormatDeltaColumnOptions = {}
): FormatDeltaColumn => {
    if (deltaType === PERCENTAGE_CHANGE) {
        return (v) => `${formatNumber(v, '0,0.00')}%`
    }

    return (v) => formatNumber(v, format, false, type === 'percentage_as_is')
}

const getMetricValue = (
    record: unknown,
    dataIndex: string[] | undefined,
    isTotalCell = false,
    periodSelector = ''
): number | null => {
    if (!dataIndex) {
        return null
    }

    const targetIndex = [...dataIndex]
    const lastIndex = targetIndex.length - 1
    const totalCellSelector = isTotalCell ? '__total' : ''

    targetIndex[lastIndex] =
        `${targetIndex[lastIndex]}${totalCellSelector}${periodSelector}`

    return get(record, targetIndex)
}

export const makeMetricFieldFunc =
    <RecordType,>(metricConfig: MetricFieldConfig) =>
    (options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> => {
        const dataIndex: string[] | undefined = options.dataIndex ?? [
            options.id ?? metricConfig.id,
        ]

        const getChildFieldDataIndex = (key: string): string[] => [
            ...dataIndex.slice(0, dataIndex.length - 1),
            `${dataIndex.slice(-1)[0]}__${key}`,
        ]

        const shortName =
            options.shortName ?? options.name ?? metricConfig.shortName

        const calculatedShortName =
            typeof shortName === 'function' ? shortName(false) : shortName

        const field = {
            id: options.id ?? metricConfig.id,
            alternateId: options.alternateId ?? options.id ?? metricConfig.id,
            metric: metricConfig.metric,
            name: options.name ?? metricConfig.name,
            shortName,
            localDefinition:
                options.localDefinition ?? metricConfig.localDefinition,
            operator: metricConfig.operator,
            dataIndex,
            isResizeable: true,
            minWidth: options.minWidth ?? 50,
            isVisible: options.isVisible ?? false,
            isTotalSupported: metricConfig.isTotalSupported ?? true,
            metricOptions: {
                format: metricConfig.format,
                shortFormat: metricConfig.shortFormat,
                type: metricConfig.type,
                isInverse: metricConfig.isInverse,
                isRatio: metricConfig.isRatio ?? false,
                isLastValue: metricConfig.isLastValue ?? false,
                render:
                    options.renderOptions?.render ??
                    (({ record, isTotalCell }: any) => {
                        const value = getMetricValue(
                            record,
                            dataIndex,
                            isTotalCell
                        )
                        return metricConfig.format === 'YYYY-MM-DD'
                            ? formatCerebroDate(value)
                            : formatNumber(
                                  value,
                                  metricConfig.format,
                                  false,
                                  metricConfig.type === 'percentage_as_is'
                              )
                    }),
                releaseDate:
                    options.releaseDate ??
                    metricConfig.releaseDate ??
                    undefined,
                isDeprecated:
                    options.isDeprecated ?? metricConfig.isDeprecated ?? false,
                endOfLifeDate:
                    options.endOfLifeDate ??
                    metricConfig.endOfLifeDate ??
                    undefined,
                category: metricConfig.category ?? undefined,
            },
            childrenFields: !options.excludeLastPeriod
                ? ([
                      {
                          dataIndex: getChildFieldDataIndex(LAST_PERIOD),
                          isResizeable: true,
                          minWidth: options.minWidth ?? 50,
                          isVisible: false,
                          isTotalSupported:
                              metricConfig.isTotalSupported ?? true,
                          metricOptions: {
                              isInverse: metricConfig.isInverse,
                              isPriorPeriod: true,
                              render:
                                  options.renderOptions?.lastPeriodRender ??
                                  (({ record, isTotalCell }: any) => {
                                      const value = getMetricValue(
                                          record,
                                          dataIndex,
                                          isTotalCell,
                                          `__${LAST_PERIOD}`
                                      )
                                      return formatNumber(
                                          value,
                                          metricConfig.format,
                                          false,
                                          metricConfig.type ===
                                              'percentage_as_is'
                                      )
                                  }),
                          },
                          antTableColumnOptions: {
                              title: shortName,
                              dataIndex: getChildFieldDataIndex(LAST_PERIOD),
                              width: options.width ?? 150,
                              align: 'right' as const,
                              sorter: options.sorter ?? false,
                          },
                      },
                  ] as ChildField<any>[])
                : [],
            antTableColumnOptions: {
                title: calculatedShortName,
                dataIndex,
                width: options.width ?? 120,
                align: 'right' as const,
                sorter: options.sorter ?? true,
                fixed: options.fixed ?? false,
            },
            customToolTip: options.customToolTip,
            createConfig: metricConfig,
            createOptions: options,
        }

        const deltasConfig = metricConfig.deltas

        if (!options.excludeLastPeriod && includes(deltasConfig, CHANGE)) {
            field.childrenFields.push({
                dataIndex: getChildFieldDataIndex(CHANGE),
                isResizeable: true,
                minWidth: options.minWidth ?? 100,
                isVisible: false,
                isTotalSupported: metricConfig.isTotalSupported ?? true,
                metricOptions: {
                    isInverse: field.metricOptions.isInverse,
                    isDelta: true,
                    render: ({ record, isTotalCell }) => {
                        const value = getMetricValue(
                            record,
                            dataIndex,
                            isTotalCell,
                            `__${CHANGE}`
                        )

                        if (!value) {
                            return UNDEFINED_VALUE
                        }

                        const props = {
                            inverse: field.metricOptions.isInverse,
                            value,
                            formatter: makeFormatDeltaColumn(CHANGE, {
                                format: metricConfig.format,
                                type: metricConfig.type,
                            }),
                        }

                        return <ChangeDelta {...props} />
                    },
                },
                antTableColumnOptions: {
                    title: (
                        <Translation>
                            {(t) => t('table:fields.change.name', 'Change')}
                        </Translation>
                    ),
                    dataIndex: getChildFieldDataIndex(CHANGE),
                    width: options.width ?? 100,
                    align: 'right' as const,
                    sorter: options.sorter ?? true,
                },
            })
        }

        if (
            !options.excludeLastPeriod &&
            includes(deltasConfig, PERCENTAGE_CHANGE)
        ) {
            field.childrenFields.push({
                dataIndex: getChildFieldDataIndex(PERCENTAGE_CHANGE),
                isResizeable: true,
                minWidth: options.minWidth ?? 100,
                isVisible: false,
                isTotalSupported: metricConfig.isTotalSupported ?? true,
                metricOptions: {
                    isInverse: field.metricOptions.isInverse,
                    isDelta: true,
                    render: ({ record, isTotalCell }) => {
                        const value = getMetricValue(
                            record,
                            dataIndex,
                            isTotalCell,
                            `__${PERCENTAGE_CHANGE}`
                        )

                        if (!value) {
                            return UNDEFINED_VALUE
                        }

                        const props = {
                            inverse: field.metricOptions.isInverse,
                            value,
                            formatter: makeFormatDeltaColumn(PERCENTAGE_CHANGE),
                        }

                        return <ChangeDelta {...props} />
                    },
                },
                antTableColumnOptions: {
                    title: (
                        <Translation>
                            {(t) =>
                                t(
                                    'table:fields.percentageChange.name',
                                    '% Change'
                                )
                            }
                        </Translation>
                    ),
                    dataIndex: getChildFieldDataIndex(PERCENTAGE_CHANGE),
                    width: options.width ?? 100,
                    align: 'right' as const,
                    sorter: options.sorter ?? true,
                },
            })
        }

        return field
    }

type TypedMetricFieldConfig<T> = Omit<
    MetricFieldConfig,
    'type' | 'format' | 'shortFormat'
> & {
    format?: string
    shortFormat?: string
    type?: T
    precise?: boolean
}

export const makeCurrencyMetricFieldFunc = <RecordType,>(
    metricConfig: TypedMetricFieldConfig<'currencyAmount'>
): ((options?: FieldCreatorOptions<RecordType>) => Field<RecordType>) =>
    makeMetricFieldFunc<RecordType>({
        format: '$0,0.00',
        shortFormat: '$0.[00]a',
        type: 'currencyAmount',
        ...metricConfig,
    })

export const makeCountMetricFieldFunc = <RecordType,>(
    metricConfig: TypedMetricFieldConfig<'count'>
): ((options?: FieldCreatorOptions<RecordType>) => Field<RecordType>) =>
    makeMetricFieldFunc<RecordType>({
        format: metricConfig.precise ? '0.[00]' : '0,0',
        shortFormat: metricConfig.precise ? '0.[00]' : '0.[00]a',
        type: 'count',
        ...metricConfig,
    })

export const makePercentageMetricFieldFunc = <RecordType,>(
    metricConfig: TypedMetricFieldConfig<'percentage' | 'percentage_as_is'>
): ((options?: FieldCreatorOptions<RecordType>) => Field<RecordType>) =>
    makeMetricFieldFunc<RecordType>({
        format: '0.00%',
        shortFormat: '0.[00]%',
        type: metricConfig.type ?? 'percentage',
        ...metricConfig,
    })

export const makeRankMetricFieldFunc = <RecordType,>(
    metricConfig: TypedMetricFieldConfig<'rank'>
): ((options?: FieldCreatorOptions<RecordType>) => Field<RecordType>) =>
    makeMetricFieldFunc<RecordType>({
        format: '0.00',
        shortFormat: '0.[00]',
        type: 'rank',
        ...metricConfig,
    })
