import { ReactElement } from 'react'

import { AlertDataSourceKey } from 'const/dataSources'

import { FieldMapping } from '../components/table'
import { CurrencyCode } from '../currencies'
import { FilterOption, LabelFilterConfig } from '../filters'

import { AdGroup } from './adGroup'
import { Campaign } from './campaign'
import { Keyword } from './keyword'
import { Label } from './label'
import { User } from './organization'
import { ProductMetadata } from './product'
import { SovKeyword } from './sov'
import { NonKeywordTarget } from './target'

export interface AlertThreshold {
    mode: 'relative' | 'to_date'
    days?: number
    min_lookback_days?: number
    to_date?: 'month' | 'week'
    metric: string
    trigger_type: 'all' | 'any'
    aggregate: 'sum' | 'avg'
    operator: 'lt' | 'lte' | 'gt' | 'gte' | 'eq'
    value?: number
    currency_code: CurrencyCode
}

export interface Action {
    label: string
    value: string
}

export interface AlertDataSourceConfiguration {
    name: string
    key: string
    filters: string[]
    metricConfig: FieldMapping
    labelConfig?: LabelFilterConfig
    actionConfig: Action[]
    requiredFilters?: string[]
    defaultFilters?: { [key: string]: FilterOption[] }
    minLookbackDays: number
    metadataKey: string
    metricCategoryOrder?: string[]
    defaultMetric: string
}

export type AlertDataSources = Record<
    AlertDataSourceKey,
    AlertDataSourceConfiguration
>

export enum AlertCategory {
    AMS_ADVERTISING = 'amsAdvertising',
    AMS_VENDOR_CENTRAL = 'amsVendorCentral',
    AMS_SHELF_INTELLIGENCE = 'amsShelfIntelligence',
    WALMART = 'walmart',
}

export type AlertCategories = {
    [category in AlertCategory]: {
        name: ReactElement
        dataSources: AlertDataSourceKey[]
    }
}

export interface ThresholdConfig {
    aggregate: FilterOption[]
    operator: FilterOption[]
    rank_operator: FilterOption[]
    currency_code: FilterOption[]
}

export interface ThresholdDispatch {
    handleThresholdChange: (
        relativePath: string
    ) => (value?: string | number | null) => void
    formattedThreshold: AlertThreshold
}

export interface AlertFilters {
    [filter: string]: FilterOption[] | never[]
}

export interface AlertConfig {
    data_source: AlertDataSourceKey
    filters?: AlertFilters
    group_by?: string
    threshold: AlertThreshold
}

export interface Alert {
    id: string
    config: AlertConfig
    name: string
    created_at: string
    updated_at: string
    created_by: User['id']
}

export interface TriggeredAlertTarget {
    id: string
    triggered_alert_id: string
    target_id: string
    target:
        | Campaign
        | Keyword
        | SovKeyword
        | AdGroup
        | NonKeywordTarget
        | ProductMetadata
    target_type: 'campaign' | 'keyword' | 'sov_keyword' | 'ad_group' | 'target'
    triggering_value: number
    triggered_alert: string
}

export interface TriggeredAlert {
    id: string
    labels?: Label[]
    alert_configuration_id: string
    alert_configuration: Alert
    triggered_at: string
    targets: TriggeredAlertTarget[]
    triggering_value: number
    status: 'unread' | 'read'
}

export interface AlertSubscription {
    id: string
    style: 'in-app' | 'email'
    alert_configuration_id: string
    user_id: string
}

export interface AlertPayload {
    name: Alert['name']
    config: AlertConfig
    subscriptions: { [style in AlertSubscription['style']]: boolean }
}
