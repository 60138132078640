import { ReactElement } from 'react'

import { CheckOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'
import { ErrorMessage, Field, FieldProps, Formik, FormikHelpers } from 'formik'
import { number, object, string } from 'yup'

import Stack from 'components/Layout/Stack'
import {
    AdGroupForSearchTermKeywordSelectField,
    CampaignForSearchTermKeywordSearchSelect,
} from 'components/SearchSelect'
import { KEYWORD_BID_MAX, KEYWORD_BID_MIN } from 'const/keywords'
import { BROAD, EXACT, PHRASE } from 'const/matchTypes'
import { ENABLED } from 'const/resourceStates'
import { formatNumber } from 'helpers/formatting'
import { useCerebroApiRequest } from 'hooks'
import { createKeyword } from 'services/cerebroApi/orgScope/resourceApi'
import { Keyword } from 'types'
import message from 'utilities/message'

import { SelectedSearchTerm } from '../localTypes'
import * as styles from '../styles.scss'

import KeywordBidNumberInputField from './KeywordBidNumberInputField'
import MatchTypeSelectField from './MatchTypeSelectField'
import { FormValues } from './shared'

interface Props {
    selectedSearchTerm: SelectedSearchTerm
    handleCancel: () => void
}

const AddKeywordForm = ({
    selectedSearchTerm,
    handleCancel,
}: Props): ReactElement | null => {
    const makeCerebroApiRequest = useCerebroApiRequest()

    const {
        id: searchTerm,
        record: { campaign, cpc },
    } = selectedSearchTerm

    const {
        profile: { id: profileId },
    } = campaign

    return (
        <Formik<FormValues>
            initialValues={{
                // static values
                searchTerm,

                // form values
                campaignId: '',
                adGroupId: '',
                matchType: '',
                bid: cpc ?? null,
            }}
            enableReinitialize
            onSubmit={async (
                values: FormValues,
                { resetForm }: FormikHelpers<FormValues>
            ): Promise<void> => {
                await makeCerebroApiRequest<Keyword>({
                    request: createKeyword({
                        text: values.searchTerm,
                        bid: formatNumber(values.bid, '0.00'),
                        match_type: values.matchType,
                        campaign_id: values.campaignId,
                        ad_group_id: values.adGroupId,
                        state: ENABLED,
                    }),
                    onRequestSuccess: () => {
                        message.success(
                            `Successfully created new keyword ${values.searchTerm} on campaign ${campaign.name}.`
                        )
                        resetForm()
                        handleCancel()
                    },
                })
            }}
            validationSchema={object().shape({
                // static values
                searchTerm: string().required(),

                // form values
                campaignId: string().label('Campaign').required(),
                adGroupId: string().label('Ad Group').required(),
                matchType: string()
                    .label('Match Type')
                    .matches(new RegExp(`${EXACT}|${PHRASE}|${BROAD}`))
                    .required(),
                bid: number()
                    .label('Keyword Bid')
                    .max(KEYWORD_BID_MAX)
                    .min(KEYWORD_BID_MIN)
                    .nullable()
                    .required(),
            })}
        >
            {({ isSubmitting, resetForm, handleSubmit, values }) => (
                <>
                    <Stack direction="column" style={{ marginBottom: 12 }}>
                        <div className={styles.label}>Campaign</div>
                        <div>
                            <Field name="campaignId">
                                {({
                                    field,
                                    form: { setFieldValue },
                                }: FieldProps) => (
                                    <CampaignForSearchTermKeywordSearchSelect
                                        profileId={profileId}
                                        onChange={(id) =>
                                            setFieldValue(field.name, id)
                                        }
                                        value={field.value}
                                        defaultOptions={[campaign]}
                                    />
                                )}
                            </Field>
                        </div>
                        <ErrorMessage
                            name="campaignId"
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </Stack>

                    <Stack direction="column" style={{ marginBottom: 12 }}>
                        <div className={styles.label}>Ad Group</div>
                        <div>
                            <AdGroupForSearchTermKeywordSelectField
                                name="adGroupId"
                                campaignId={values.campaignId}
                                disabled={!values.campaignId}
                            />
                        </div>
                        <ErrorMessage
                            name="adGroupId"
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </Stack>

                    <Stack direction="column" style={{ marginBottom: 12 }}>
                        <div className={styles.label}>Match Type</div>
                        <div>
                            <MatchTypeSelectField />
                        </div>
                        <ErrorMessage
                            name="matchType"
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </Stack>

                    <Stack direction="column">
                        <div className={styles.label}>Keyword Bid</div>
                        <div>
                            <KeywordBidNumberInputField
                                selectedSearchTerm={selectedSearchTerm}
                            />
                        </div>
                        <ErrorMessage
                            name="bid"
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </Stack>

                    <Divider />

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                    >
                        <Button
                            onClick={() => {
                                resetForm()
                                handleCancel()
                            }}
                            style={{ marginRight: 8 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={() => handleSubmit()}
                            loading={isSubmitting}
                        >
                            Add Keyword
                        </Button>
                    </Stack>
                </>
            )}
        </Formik>
    )
}

export default AddKeywordForm
