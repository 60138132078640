import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useFormikContext } from 'formik'

import { useCerebroApiRequest } from 'hooks'
import { getAdGroups } from 'services/cerebroApi/orgScope/resourceApi'
import { AdGroup, Campaign, CerebroPaginatedResponse } from 'types'

import { Option } from './localTypes'

export const useFetchAdGroupsCached = (
    campaign: Campaign,
    name?: string,
    isFormikField = false
): [boolean, Option[]] => {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const { setFieldTouched } = useFormikContext()

    const [options, setOptions] = useState<Option[]>(() => {
        return campaign.ad_groups?.length > 0
            ? campaign.ad_groups.map((adGroup) => ({
                  label: adGroup.name,
                  value: adGroup.id,
              }))
            : []
    })

    const { data, isFetching } = useQuery({
        queryKey: ['adGroups', campaign.id, { 'state!': 'archived' }],
        queryFn: async () => {
            const result = await makeCerebroApiRequest<
                AdGroup,
                CerebroPaginatedResponse<AdGroup>
            >({
                request: getAdGroups({
                    campaign: campaign.id,
                    'state!': 'archived',
                }),
            })
            if (!result?.data.results) {
                return []
            }
            return result.data.results.map(
                ({ id, name: adGroupName }: { id: string; name: string }) => ({
                    value: id,
                    label: adGroupName,
                })
            )
        },
        enabled: !!campaign.id,
    })

    useEffect(() => {
        if (data) {
            setOptions(data)
        }
    }, [data])

    useEffect(() => {
        if (isFormikField && name) {
            setFieldTouched(name, false)
        }
    }, [name, setFieldTouched, isFormikField])

    return [isFetching, options]
}
