import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import KeywordIconSvg from './KeywordIconSvg'

const KeywordIcon = (props: IconProps): ReactElement => (
    <Icon component={KeywordIconSvg} {...props} />
)

export default KeywordIcon
