/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all, takeLatest } from 'redux-saga/effects'

import { mountTabRequest, fetchTabDataRequest } from 'actions/ui/shared/tab'

import { fetchTabDataWorker, mountTabWorker } from './workers'

export default function* tabWatcher() {
    yield all([
        takeLatest(mountTabRequest.toString(), mountTabWorker),
        takeLatest(fetchTabDataRequest.toString(), fetchTabDataWorker),
    ])
}
