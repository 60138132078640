import { all, call, select } from 'redux-saga/effects'

import { AD_GROUP_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceOfPage } from 'selectors/ui'
import { createKeyword } from 'services/cerebroApi/orgScope/resourceApi'
import message from 'utilities/message'

const TAB_PATH = [AD_GROUP_PAGE, 'keywords']
const TABLE_PATH = [...TAB_PATH, 'table']

function* createKeywordSaga({ keywords }) {
    const adGroup = yield select(selectResourceOfPage, AD_GROUP_PAGE)

    const keywordsToCreate = keywords.map((keyword) => ({
        ...keyword,
        campaign_id: adGroup.campaign_id,
        ad_group_id: adGroup.id,
    }))

    yield all(
        keywordsToCreate.map((keyword) =>
            call(cerebroApiSaga, null, createKeyword, keyword)
        )
    )

    message.success('Keywords successfully created!')
}

uiSagaRegistry.registerSagas(TABLE_PATH, {
    createTableRecordSaga: createKeywordSaga,
})
