import { ReactElement, useState, SetStateAction, Dispatch } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import classNames from 'classnames'

import { LoadingIndicator } from 'components/LoadingIndicator'
import { PillTag } from 'components/PillTag'

import * as styles from './styles.scss'

interface Props {
    fetchSuggestedKeywords: (
        setSuggestedKeywords: Dispatch<SetStateAction<string[]>>,
        setEmptyStateMsg: Dispatch<SetStateAction<string>>
    ) => Promise<void>
    pushKeyword: (keyword: string) => void
}

function SuggestedKeywords({
    fetchSuggestedKeywords,
    pushKeyword,
}: Props): ReactElement {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [suggestedKeywords, setSuggestedKeywords] = useState<string[]>([])
    const [emptyStateMsg, setEmptyStateMsg] = useState<string>(
        'No suggested Keywords'
    )

    const removeKeywordFromSuggested = (keyword: string): void => {
        setSuggestedKeywords(suggestedKeywords.filter((k) => k !== keyword))
    }

    const noKeywords = suggestedKeywords.length === 0

    const addKeyword = (keyword: string): void => {
        removeKeywordFromSuggested(keyword)
        pushKeyword(keyword)
    }

    const renderSuggestedKeywords = (): ReactElement | string =>
        noKeywords ? (
            emptyStateMsg
        ) : (
            <>
                {suggestedKeywords.map((keyword) => (
                    <PillTag
                        key={keyword}
                        icon={<PlusOutlined />}
                        closable
                        label={keyword}
                        iconTooltipTitle="Add Keyword"
                        onClose={() => addKeyword(keyword)}
                        onClick={() => addKeyword(keyword)}
                        className={styles['keyword-tag']}
                    />
                ))}
            </>
        )

    return (
        <Collapse
            onChange={async (panel) => {
                const keywordsPanelOpen = panel[0] === 'keywords'

                if (keywordsPanelOpen) {
                    await fetchSuggestedKeywords(
                        setSuggestedKeywords,
                        setEmptyStateMsg
                    ).then(() => setIsLoading(false))
                }
            }}
            className={styles['suggested-keywords-collapse']}
        >
            <Collapse.Panel key="keywords" header="Suggested Keywords">
                <div
                    className={classNames(
                        styles['suggested-keywords-wrapper'],
                        {
                            [styles['empty-state']]: noKeywords && !isLoading,
                            [styles['no-keywords']]: noKeywords,
                        }
                    )}
                >
                    {isLoading ? (
                        <LoadingIndicator size="small" fullContent={false} />
                    ) : (
                        renderSuggestedKeywords()
                    )}
                </div>
            </Collapse.Panel>
        </Collapse>
    )
}

export default SuggestedKeywords
