import { removeProductFromLabelRenderer } from 'components/FieldRenderers/Renderers/product'
import { productCogsRenderer } from 'components/FieldRenderers/Renderers/productCogs'
import {
    misc_cogs_weighted,
    raw_cogs_weighted,
    supplier_cogs_weighted,
    total_cogs_weighted,
} from 'configuration/fieldCreators/metrics/productAds'
import { Field } from 'types'

import {
    createActionField,
    createField,
    createNumberField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function productMetaDataTitle<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_metadata', 'title']
    return createField({
        ...options,
        id: 'product_metadata__title',
        name: 'Product Title',
        shortName: 'Product Title',
        minWidth: 80,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        width: 300,
    })
}

export function productMetaDataPrice<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_metadata', 'price']
    return createField({
        ...options,
        id: 'product__metadata_price',
        name: 'List Price',
        shortName: 'List Price',
        minWidth: 60,
        dataIndex,
        width: 100,
    })
}

export function skuTotalCogs<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['total_cogs']
    return total_cogs_weighted({
        id: 'sku__total_cogs',
        alternateId: 'total_cogs_weighted',
        dataIndex,
        isVisible: options.isVisible ?? true,
        minWidth: options.minWidth ?? 120,
        renderOptions: {
            render: (props) =>
                productCogsRenderer<any>({ cellRenderProps: props, dataIndex }),
        },
    }) as Field<RecordType>
}

export function skuRawCogs<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['raw_cogs']
    return raw_cogs_weighted({
        id: 'sku__raw_cogs',
        alternateId: 'raw_cogs_weighted',
        dataIndex,
        isVisible: options.isVisible ?? true,
        minWidth: options.minWidth ?? 120,
        renderOptions: {
            render: (props) =>
                productCogsRenderer<any>({ cellRenderProps: props, dataIndex }),
        },
    }) as Field<RecordType>
}

export function skuMiscCogs<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['misc_cogs']
    return misc_cogs_weighted({
        id: 'sku__misc_cogs',
        alternateId: 'misc_cogs_weighted',
        dataIndex,
        isVisible: options.isVisible ?? true,
        minWidth: options.minWidth ?? 120,
        renderOptions: {
            render: (props) =>
                productCogsRenderer<any>({ cellRenderProps: props, dataIndex }),
        },
    }) as Field<RecordType>
}

export function skuSupplierCogs<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['supplier_cogs']
    return supplier_cogs_weighted({
        id: 'sku__supplier_cogs',
        alternateId: 'supplier_cogs_weighted',
        dataIndex,
        isVisible: options.isVisible ?? true,
        minWidth: options.minWidth ?? 120,
        renderOptions: {
            render: (props) =>
                productCogsRenderer<any>({ cellRenderProps: props, dataIndex }),
        },
    }) as Field<RecordType>
}

export function productMetaDataNumberOfItems<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? [
        'product_metadata',
        'number_of_items',
    ]
    return createNumberField({
        ...options,
        id: 'product_metadata__number_of_items',
        name: 'Product Pack Size',
        shortName: 'Pack Size',
        minWidth: 60,
        dataIndex,
        width: 100,
    })
}

export function productAdId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_ad_id']
    return createField({
        ...options,
        id: 'product_ad_id',
        name: 'Product Ad',
        shortName: 'Product Ad',
        minWidth: 80,
        dataIndex,
        width: 300,
        sorter: options.sorter ?? false,
    })
}

export function productAsin<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['asin']
    return createField({
        ...options,
        id: 'product_ad__asin',
        name: 'ASIN',
        shortName: 'ASIN',
        minWidth: 50,
        dataIndex,
        width: 100,
    })
}

export function productSku<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['sku']
    return createField({
        ...options,
        id: 'product_ad__sku',
        name: 'SKU',
        shortName: 'SKU',
        minWidth: 80,
        dataIndex,
        width: 120,
    })
}

export function productAdState<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_ad', 'state']
    return createField({
        ...options,
        id: 'product_ad__state',
        name: 'Product Ad State',
        shortName: 'State',
        minWidth: 70,
        dataIndex,
        width: 100,
    })
}

export function archiveProductAd<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'archive_product_ad',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        align: 'center',
        sorter: false,
        fixed: 'right',
    })
}

export function removeProductFromLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'remove_product_from_label',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (props) => removeProductFromLabelRenderer<any>(props),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
