import { generatePath } from 'react-router-dom'

import {
    ASIN_DOMAINS,
    HELP_CENTER,
    WALMART_ITEM_DOMAIN,
} from 'configuration/urls'
import {
    AD_ACCOUNT_PAGE,
    AD_GROUP_PAGE,
    AUTOMATION_PAGE,
    AUTOMATION_PROFILE_PAGE,
    BULK_UPDATES_PAGE,
    CAMPAIGN_PAGE,
    CAMPAIGN_PRODUCT_AD_PAGE,
    DASHBOARD_PAGE,
    DASHBOARDS_SUMMARY_PAGE,
    DSP_ADVERTISER_PAGE,
    DSP_LINE_ITEM_PAGE,
    DSP_ORDER_PAGE,
    KEYWORD_PAGE,
    KEYWORD_RESEARCH_LIST_DETAIL_PAGE,
    KEYWORD_RESEARCH_PAGE,
    LABEL_PAGE,
    ORGANIZATION_GROUP_PAGE,
    PORTFOLIO_PAGE,
    PRODUCT_PAGE,
    SEGMENT_PAGE,
    SOV_KEYWORD_PAGE,
    SOV_KEYWORDS_SUMMARY_PAGE,
    TARGETING_SUMMARY_PAGE,
    WALMART_CAMPAIGN_PAGE,
} from 'const/pages'
import {
    AD_GROUP,
    AUTOMATION_TASK,
    CAMPAIGN,
    KEYWORD,
    NON_KEYWORD_TARGET,
    PRODUCT_AD,
    PRODUCT_METADATA,
    SOV_KEYWORD,
} from 'const/resourceTypes'
import { getPath } from 'helpers/pages'
import { Campaign, CountryCode, OrganizationGroup } from 'types'

// Internal URLs
export const keywordUrl = (keywordId: string): string =>
    generatePath(getPath(KEYWORD_PAGE), {
        keywordId,
    })

export const targetingUrl = (
    filters: any | undefined,
    tab:
        | 'keywords'
        | 'keywordSearchTerms'
        | 'productTargets'
        | 'productTargetSearchTerms'
): string => {
    if (!filters) {
        return `${getPath(TARGETING_SUMMARY_PAGE)}/?tab=${tab}`
    }
    return `/?redirect=${TARGETING_SUMMARY_PAGE}&tab=${tab}&filters=${encodeURIComponent(
        JSON.stringify(filters)
    )}`
}

export const automationProfilesUrl = (): string => {
    return `${getPath(AUTOMATION_PAGE)}/?tab=profiles`
}

export const automationProfileUrl = (profileId: string): string =>
    generatePath(getPath(AUTOMATION_PROFILE_PAGE), {
        profileId,
    })

export const sovKeywordUrl = (sovKeywordId: string): string =>
    generatePath(getPath(SOV_KEYWORD_PAGE), {
        sovKeywordId,
    })

export const sovKeywordSummaryUrl = (
    filters: any | undefined,
    tab: 'timeline' | 'keywordPerformance' | 'manageKeywords'
): string => {
    if (!filters) {
        return `${getPath(SOV_KEYWORDS_SUMMARY_PAGE)}/?tab=${tab}`
    }
    return `/?redirect=${SOV_KEYWORDS_SUMMARY_PAGE}&tab=${tab}&filters=${encodeURIComponent(
        JSON.stringify(filters)
    )}`
}

export const sovKeywordResearchUrl = (
    country: CountryCode,
    keyword: string
): string => {
    const path = generatePath(getPath(KEYWORD_RESEARCH_PAGE))
    return `${path}?country=${country}&keyword=${keyword}`
}

export const sovAsinReverseResearchUrl = (
    country: CountryCode,
    asin: string
): string => {
    const path = generatePath(getPath(KEYWORD_RESEARCH_PAGE))
    return `${path}?country=${country}&asins=${asin}`
}

export const campaignUrl = (campaignId: string, tabId?: string): string => {
    const path = generatePath(getPath(CAMPAIGN_PAGE), {
        campaignId,
    })

    return tabId ? `${path}?tab=${tabId}` : path
}

export const segmentUrl = (segmentId: string, tabId?: string): string => {
    const path = generatePath(getPath(SEGMENT_PAGE), {
        segmentId,
    })

    return tabId ? `${path}?tab=${tabId}` : path
}

export const keywordResearchListUrl = (keywordResearchListId: string): string =>
    generatePath(getPath(KEYWORD_RESEARCH_LIST_DETAIL_PAGE), {
        keywordResearchListId,
    })

export const adAccountUrl = (adAccountId: string): string =>
    generatePath(getPath(AD_ACCOUNT_PAGE), {
        adAccountId,
    })

export const productUrl = (asin: string, countryCode: string): string =>
    // Ideally the countryCode argument should be of type CountryCode
    generatePath(getPath(PRODUCT_PAGE), {
        asin,
        countryCode,
    })

export const productAdUrl = (
    campaignId: string,
    productAdId?: string
): string =>
    generatePath(getPath(CAMPAIGN_PRODUCT_AD_PAGE), {
        campaignId,
        productAdId,
    })

export const dashboardUrl = (dashboardId: string, tabId?: string): string => {
    const path = generatePath(getPath(DASHBOARD_PAGE), {
        dashboardId,
    })

    return tabId ? `${path}?tab=${tabId}` : path
}

export const dashboardsUrl = (tabId?: string): string => {
    const path = generatePath(getPath(DASHBOARDS_SUMMARY_PAGE))

    return tabId ? `${path}?tab=${tabId}` : path
}

export const labelUrl = (labelId: string): string =>
    generatePath(getPath(LABEL_PAGE), {
        labelId,
    })

export const changelogResourceUrl = (
    resourceType: string,
    resourceId: string,
    campaign: Campaign
): string => {
    if (resourceType === KEYWORD) {
        return keywordUrl(resourceId)
    }

    if (resourceType === CAMPAIGN) {
        return campaignUrl(resourceId)
    }

    if (resourceType === PRODUCT_AD) {
        return productAdUrl(campaign.id, resourceId)
    }

    if (resourceType === AUTOMATION_TASK) {
        return automationProfileUrl(resourceId)
    }

    throw new Error(
        `Attempted to build URL for unhandled resource type of ${resourceType}`
    )
}

export const adGroupUrl = (campaignId: string, adGroupId: string): string =>
    generatePath(getPath(AD_GROUP_PAGE), {
        campaignId,
        adGroupId,
    })

export const portfolioUrl = (portfolioId: string): string =>
    generatePath(getPath(PORTFOLIO_PAGE), {
        portfolioId,
    })

export const organizationGroupUrl = (group: OrganizationGroup): string =>
    generatePath(getPath(ORGANIZATION_GROUP_PAGE), {
        organizationGroupId: group.id,
    })

export const walmartCampaignUrl = (wmCampaignId: string): string =>
    generatePath(getPath(WALMART_CAMPAIGN_PAGE), {
        wmCampaignId,
    })

// External Amazon URLs
export const asinUrl = (
    asin: string,
    countryCode: CountryCode = 'US'
): string => {
    const domain = ASIN_DOMAINS[countryCode]
    return `https://${domain}/gp/product/${asin}`
}

export const sellerUrl = (
    sellerId: string,
    countryCode: CountryCode = 'US'
): string => {
    const domain = ASIN_DOMAINS[countryCode]
    return `https://${domain}/sp/?seller=${sellerId}`
}

// External Learning Hub URL
export const learningHubExternalUrl = (): string => {
    const { href } = new URL('https://fast.wistia.com/embed/channel/a2tgtglkx9')
    return href
}

// External help center URLs
export const helpCenterExternalUrl = (): string => {
    const { href } = new URL('/hc/en-us/', HELP_CENTER)
    return href
}

export const categoryExternalUrl = (
    categoryPath: string,
    anchor = ''
): string => {
    const { href } = new URL(`/hc/en-us/${categoryPath}${anchor}`, HELP_CENTER)
    return href
}

export const automationCategoryExternalUrl = (): string =>
    categoryExternalUrl('sections/14713460879383-Advertising-Automations')

export const daypartingCategoryExternalUrl = (): string =>
    categoryExternalUrl('articles/14132123684119-Dayparting-Automation')

export const roiOptimizationCategoryExternalUrl = (): string =>
    categoryExternalUrl('articles/14131958979735-ROI-Optimization')

export const budgetMaximizationCategoryExternalUrl = (): string =>
    categoryExternalUrl(
        'articles/14132163992087-Budget-Maximization-Feature-Overview'
    )

export const keywordHarvestingCategoryExternalUrl = (): string =>
    categoryExternalUrl(
        'articles/14080520365335-Keyword-Harvesting-Feature-Overview'
    )

export const asinHarvestingCategoryExternalUrl = (): string =>
    categoryExternalUrl(
        'articles/14185843527575-ASIN-Harvesting-Feature-Overview'
    )

export const advancedBudgetControlCategoryExternalUrl = (): string =>
    categoryExternalUrl(
        'articles/14132303770519-Advanced-Budget-Control-Best-Practices'
    )

export const creatingVCIntegrationExternalUrl = (): string =>
    categoryExternalUrl(
        'articles/14378447057943-How-to-Add-Amazon-Selling-Partner-Integrations'
    )

export const walmartOnBoardingGuideExternalUrl = (): string =>
    categoryExternalUrl(
        'articles/14531895531287-Walmart-Marketplace-Onboarding-Guide'
    )

export const alertTargetUrl = (
    targetType: string,
    targetId: string,
    campaign_id: string
): string => {
    // Ideally the targetType argument should be of type TriggeredAlertTarget['target_type']
    // Some of the switch cases do not apply to that type, so it probably needs to be updated
    switch (targetType) {
        case CAMPAIGN:
            return campaignUrl(targetId)
        case KEYWORD:
            return keywordUrl(targetId)
        case PRODUCT_AD:
            return productAdUrl(targetId)
        case SOV_KEYWORD:
            return sovKeywordUrl(targetId)
        case AD_GROUP:
            return adGroupUrl(campaign_id, targetId)
        case PRODUCT_METADATA: {
            const parts = targetId.split('_')
            return productUrl(parts[1], parts[0])
        }
        case NON_KEYWORD_TARGET:
            return `${campaignUrl(campaign_id)}?tab=targets`
        default:
            return campaignUrl(targetId)
    }
}

export const uploadPageUrl = generatePath(getPath(BULK_UPDATES_PAGE))

export const advertiserUrl = (advertiserId: string): string =>
    generatePath(getPath(DSP_ADVERTISER_PAGE), {
        advertiserId,
    })

export const orderUrl = (orderId: string): string =>
    generatePath(getPath(DSP_ORDER_PAGE), {
        orderId,
    })

export const lineItemUrl = (lineItemId: string): string =>
    generatePath(getPath(DSP_LINE_ITEM_PAGE), {
        lineItemId,
    })

export const walmartItemUrl = (itemId: string): string => {
    return `https://${WALMART_ITEM_DOMAIN}/ip/${itemId}`
}

export const keywordSearchUrl = (
    keyword: string,
    countryCode: CountryCode = 'US'
): string => {
    return `https://${ASIN_DOMAINS[countryCode]}/s?k=${keyword}`
}
