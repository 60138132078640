import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/fp/set'
import get from 'lodash/get'
import { Action, combineActions, handleActions } from 'redux-actions'

import {
    changeOrganizationGroupRequest,
    changeOrganizationRequest,
    signOutSuccess,
} from 'actions/auth'
import {
    AsyncReducerHydratedPayload,
    asyncReducerHydrated,
} from 'actions/ui/app'
import { APP } from 'const/pages'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { deepPick } from 'utilities/reducers'

import {
    getDefaultState,
    getPristineUiStateClone,
    updatePristineUiStateClone,
} from '../ui'

import bidReducer from './bid'
import dashboardReducer from './dashboard'
import dataManagement from './dataManagement'
import filterReducer from './filter'
import {
    makeDesiredFiltersVisible,
    updateFilters as resetFilters,
} from './filter'
import labelReducer from './label'
import pageReducer from './page'
import resourceReducer from './resource'
import tabReducer from './tab'
import tableReducer from './table'

const combinedActions = combineActions(
    changeOrganizationRequest,
    changeOrganizationGroupRequest
)

export default handleActions(
    {
        // reset all state except settings that are persisted across sessions
        [signOutSuccess.toString()](state) {
            return deepPick(
                state,
                ['tableSettings', FILTERS, FILTER_SETTINGS],
                cloneDeep(getDefaultState())
            )
        },
        // on org change, don't reset filters since we could be redirecting to the correct org
        [combinedActions.toString()](
            state,
            action: ReturnType<typeof changeOrganizationRequest>
        ) {
            const { filters, redirect, tab } = action.payload

            const copy: any = JSON.parse(
                JSON.stringify(
                    deepPick(
                        state,
                        ['tableSettings', FILTERS, FILTER_SETTINGS, 'hydrated'],
                        cloneDeep(getDefaultState())
                    )
                )
            )
            if (APP in copy) {
                resetFilters(copy[APP].filters, filters)
                makeDesiredFiltersVisible(copy[APP].filtersSettings, filters)
            }
            if (redirect && redirect in copy) {
                let hasTouchedRedirectFilters = resetFilters(
                    copy[redirect]?.localFilters,
                    filters
                )
                hasTouchedRedirectFilters =
                    resetFilters(copy[redirect]?.filters, filters) ||
                    hasTouchedRedirectFilters
                const hasTouchedRedirectFilterSettings =
                    makeDesiredFiltersVisible(
                        copy[redirect].filtersSettings,
                        filters
                    )
                if (copy[redirect].filtersSettings) {
                    copy[redirect].filtersSettings.touched =
                        hasTouchedRedirectFilters ||
                        hasTouchedRedirectFilterSettings
                }
                if (tab && tab in copy[redirect]) {
                    let hasTouchedTabFilters = resetFilters(
                        copy[redirect][tab]?.filters,
                        filters
                    )
                    hasTouchedTabFilters =
                        resetFilters(
                            copy[redirect][tab]?.localFilters,
                            filters
                        ) || hasTouchedTabFilters
                    const hasTouchedTabFilterSettings =
                        makeDesiredFiltersVisible(
                            copy[redirect][tab].filtersSettings,
                            filters
                        )
                    if (copy[redirect][tab].filtersSettings) {
                        copy[redirect][tab].filtersSettings.touched =
                            hasTouchedTabFilters || hasTouchedTabFilterSettings
                    }
                }
                if ('tabs' in copy[redirect] && tab in copy[redirect].tabs) {
                    let hasTouchedTabsFilters = resetFilters(
                        copy[redirect].tabs[tab]?.filters,
                        filters
                    )
                    hasTouchedTabsFilters =
                        resetFilters(
                            copy[redirect].tabs[tab]?.localFilters,
                            filters
                        ) || hasTouchedTabsFilters
                    const hasTouchedTabsFilterSettings =
                        makeDesiredFiltersVisible(
                            copy[redirect].tabs[tab].filtersSettings,
                            filters
                        )
                    if (copy[redirect].tabs[tab].filtersSettings) {
                        copy[redirect].tabs[tab].filtersSettings.touched =
                            hasTouchedTabsFilters ||
                            hasTouchedTabsFilterSettings
                    }
                }
            }

            return copy
        },

        // when async reducers have been persisted and added

        [asyncReducerHydrated.toString()](
            state,
            action: Action<AsyncReducerHydratedPayload>
        ) {
            const { key, initialState } = action.payload

            // update clean UI state with the new reducer state
            // so we can use for resetting
            const defaultUiStateClone = getPristineUiStateClone()
            const pageDefaultState = get(defaultUiStateClone, [key])
            if (!pageDefaultState) {
                const currentState = get(state, [key])
                updatePristineUiStateClone(initialState ?? currentState, [key])
            }

            return set([key, 'hydrated'], true, state)
        },

        ...pageReducer,
        ...filterReducer,
        ...tabReducer,
        ...dashboardReducer,
        ...resourceReducer,
        ...dataManagement,
        ...tableReducer,
        ...bidReducer,
        ...labelReducer,
    },
    {}
)
