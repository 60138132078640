import { ReactElement, ReactNode, HTMLAttributes } from 'react'

import { Card } from 'antd'

interface Props extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
}

const BasicContainer = ({ className, children }: Props): ReactElement => (
    <Card
        size="small"
        className={className}
        styles={{ body: { padding: 0 } }}
        variant="borderless"
    >
        {children}
    </Card>
)

export default BasicContainer
