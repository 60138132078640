import { ReactElement } from 'react'

import { Drawer, List } from 'antd'
import classNames from 'classnames'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
    fetchUpdatesForDrawerRequest,
    toggleBulkUpdatesAppDrawer,
} from 'actions/ui/app'
import {
    AsyncUpdateStatus,
    AsyncUpdateAction,
    AsyncUpdateDescription,
} from 'components/AsyncUpdates'
import { BULK_UPDATES_PAGE } from 'const/pages'
import { formatCerebroDateTime } from 'helpers/formatting'
import { getPath } from 'helpers/pages'
import { useAction } from 'hooks'
import { AsyncUpdate } from 'types'

import { CloseIcon } from '../../Icons'

import * as styles from './styles.scss'

interface Props {
    drawerUpdates: AsyncUpdate[]
    drawerUpdatesLoading: boolean
    visible: boolean
    onClose: () => void
}

const UpdatesDrawer = ({
    drawerUpdates,
    drawerUpdatesLoading,
    visible,
    onClose,
}: Props): ReactElement => {
    const { t } = useTranslation(['common', 'navigation'])
    const reloadData = useAction(fetchUpdatesForDrawerRequest)
    const toggleBulkUpdatesDrawer = useAction(toggleBulkUpdatesAppDrawer)
    const closeMe = (): void => {
        toggleBulkUpdatesDrawer(false)
    }
    return (
        <Drawer
            title={
                <span>
                    {t('common:bulkUpdates')} (
                    <Link
                        type="link"
                        onClick={closeMe}
                        to={getPath(BULK_UPDATES_PAGE)}
                    >
                        {t('common:viewAll')}
                    </Link>
                    )
                </span>
            }
            placement="right"
            onClose={onClose}
            closeIcon={<CloseIcon />}
            open={visible}
            width={600}
            rootClassName={classNames(['fixed-header', styles.drawer])}
        >
            <List
                dataSource={drawerUpdates}
                loading={drawerUpdatesLoading}
                locale={{
                    emptyText: t(
                        'navigation:TopNav.UpdatesDrawer.emptyText',
                        'No recently created bulk updates.'
                    ),
                }}
                renderItem={(update) => (
                    <List.Item
                        actions={[
                            <AsyncUpdateAction
                                key="update-action"
                                update={update}
                                reloadData={reloadData}
                            />,
                        ]}
                        className={styles['drawer-list-item']}
                    >
                        <List.Item.Meta
                            avatar={
                                <AsyncUpdateStatus
                                    update={update}
                                    style={{ width: 145 }}
                                />
                            }
                            title={<AsyncUpdateDescription update={update} />}
                            description={
                                <p>
                                    <Trans i18nKey="navigation:TopNav.UpdatesDrawer.createdDate">
                                        <strong>Created Date:</strong>{' '}
                                        {{
                                            createdDate: formatCerebroDateTime(
                                                update.created_date
                                            ),
                                        }}
                                    </Trans>
                                </p>
                            }
                        />
                    </List.Item>
                )}
            />
        </Drawer>
    )
}

export default UpdatesDrawer
