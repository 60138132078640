import { createAction } from 'redux-actions'

export const mountAppRequest = createAction('MOUNT_APP_REQUEST')
export const mountAppSuccess = createAction('MOUNT_APP_SUCCESS')
export const mountAppFailure = createAction('MOUNT_APP_FAILURE')

// dynamic modules
export interface AsyncReducerHydratedPayload {
    key: string
    initialState: any
}
export const asyncReducerHydrated = createAction<AsyncReducerHydratedPayload>(
    'ASYNC_REDUCER_HYDRATED'
)

// currency settings
export const changeCurrencyCode = createAction('CHANGE_CURRENCY_CODE')
export const fetchCurrencySettingsSuccess = createAction(
    'FETCH_CURRENCY_SETTINGS_SUCCESS'
)

// hidden automation capabilities
export const setHiddenAutomationCapabilities = createAction(
    'SET_HIDDEN_AUTOMATION_CAPABILITIES'
)
export const fetchHiddenAutomationCapabilities = createAction(
    'FETCH_HIDDEN_AUTOMATION_CAPABILITIES'
)

// global notification
export const setGlobalNotification = createAction('SET_GLOBAL_NOTIFICATION')

// organization labels
export const fetchOrganizationLabelsRequest = createAction(
    'FETCH_ORGANIZATION_LABELS_REQUEST'
)
export const fetchOrganizationLabelsSuccess = createAction(
    'FETCH_ORGANIZATION_LABELS_SUCCESS'
)

// automation capabilities
export const fetchAutomationCapabilitiesSuccess = createAction(
    'FETCH_AUTOMATION_CAPABILITIES_SUCCESS'
)

// brand groups
export const fetchBrandGroupsSuccess = createAction(
    'FETCH_BRAND_GROUPS_SUCCESS'
)
export const createBrandGroupRequest = createAction(
    'CREATE_BRAND_GROUP_REQUEST'
)
export const createBrandGroupSuccess = createAction(
    'CREATE_BRAND_GROUP_SUCCESS'
)
export const updateBrandGroupRequest = createAction(
    'UPDATE_BRAND_GROUP_REQUEST'
)
export const updateBrandGroupSuccess = createAction(
    'UPDATE_BRAND_GROUP_SUCCESS'
)
export const deleteBrandGroupRequest = createAction(
    'DELETE_BRAND_GROUP_REQUEST'
)
export const deleteBrandGroupSuccess = createAction(
    'DELETE_BRAND_GROUP_SUCCESS'
)

// user survey
export const fetchUserSurveyLastReadDateSuccess = createAction(
    'FETCH_USER_SURVEY_LAST_READ_SUCCESS'
)
export const dismissUserSurveyRequest = createAction(
    'SET_USER_SURVEY_DISMISSED_REQUEST'
)
export const dismissUserSurveySuccess = createAction(
    'SET_USER_SURVEY_DISMISSED_SUCCESS'
)

// bulk update template download
export const generateBulkUpdateTemplateSuccess = createAction(
    'GENERATE_BULK_UPDATE_TEMPLATE_SUCCESS'
)

// download report
export const downloadReport = createAction('DOWNLOAD_REPORT')

// reports drawer
export const fetchReportsForDrawerRequest = createAction(
    'FETCH_REPORTS_FOR_DRAWER_REQUEST'
)
export const fetchReportsForDrawerSuccess = createAction(
    'FETCH_REPORTS_FOR_DRAWER_SUCCESS'
)
export const fetchReportsForDrawerFailure = createAction(
    'FETCH_REPORTS_FOR_DRAWER_FAILURE'
)

// download update csv file
export const downloadBulkUpdate = createAction('DOWNLOAD_BULK_UPDATE')

// initiate bulk update
export const initiateUpdateSuccess = createAction('INITIATE_UPDATE_SUCCESS')

// updates drawer
export const fetchUpdatesForDrawerRequest = createAction(
    'FETCH_UPDATES_FOR_DRAWER_REQUEST'
)
export const fetchUpdatesForDrawerSuccess = createAction(
    'FETCH_UPDATES_FOR_DRAWER_SUCCESS'
)
export const fetchUpdatesForDrawerFailure = createAction(
    'FETCH_UPDATES_FOR_DRAWER_FAILURE'
)
export const unmountAppDrawer = createAction('UNMOUNT_APP_DRAWER')
export const toggleReportsAppDrawer = createAction('TOGGLE_REPORTS_APP_DRAWER')
export const toggleBulkUpdatesAppDrawer = createAction(
    'TOGGLE_BULK_UPDATES_APP_DRAWER'
)

// alerts drawer
export const fetchTriggeredAlertsForDrawerRequest = createAction(
    'FETCH_TRIGGERED_ALERTS_FOR_DRAWER_REQUEST'
)
export const fetchTriggeredAlertsForDrawerSuccess = createAction(
    'FETCH_TRIGGERED_ALERTS_FOR_DRAWER_SUCCESS'
)
export const fetchTriggeredAlertsForDrawerFailure = createAction(
    'FETCH_TRIGGERED_ALERTS_FOR_DRAWER_FAILURE'
)

// disable main page content
export const disablePageContent = createAction('DISABLE_PAGE_CONTENT')

// toggle sidebar collapsed
export const toggleSidebarCollapsed = createAction('TOGGLE_SIDEBAR_COLLAPSED')

// home dashboard id
export const fetchHomeDashboardIdSuccess = createAction(
    'FETCH_HOME_DASHBOARD_ID_SUCCESS'
)
export const setHomeDashboardId = createAction('SET_HOME_DASHBOARD_ID')

// Custom Events
export const fetchCustomEventsRequest = createAction(
    'FETCH_CUSTOM_EVENTS_REQUEST'
)
export const fetchCustomEventsSuccess = createAction(
    'FETCH_CUSTOM_EVENTS_SUCCESS'
)

// SOV Permissions
export const fetchSovPermissionsSuccess = createAction(
    'FETCH_SOV_PERMISSIONS_SUCCESS'
)

export const updateChartColorsMap = createAction('UPDATE_CHART_COLORS_MAP')
