import { useMemo } from 'react'

import { QueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { CampaignFactRecord } from 'configuration/tables/campaignFactsTables'
import { formatFilters } from 'helpers/filters/campaignFacts'
import { formatAdAccountName } from 'helpers/formatting'
import {
    selectResourceOfPage,
    selectResourceParamsOfPage,
    selectTableSettings,
} from 'selectors/ui'
import {
    getCampaignFacts,
    getCampaignFactsExport,
} from 'services/cerebroApi/orgScope/campaignFactsApi'
import { RootReduxState } from 'types'

import {
    CerebroDownloadQueryProps,
    CerebroDownloadQueryResult,
    CerebroPaginatedMetricDataQueryProps,
    CerebroPaginatedMetricDataQueryResult,
    useCerebroDownload,
    useCerebroPaginatedMetricDataQuery,
} from '../CerebroPaginatedQuery'

const CAMPAIGN_QUERY_PREFIX = 'campaigns'

export function invalidateCampaigns(queryClient: QueryClient): void {
    queryClient.invalidateQueries({
        queryKey: [CAMPAIGN_QUERY_PREFIX],
    })
}

type CampaignSummaryTableDataQueryProps = Omit<
    CerebroPaginatedMetricDataQueryProps<CampaignFactRecord>,
    'queryKeyPrefix' | 'groupBy' | 'apiCall' | 'formatFilters'
>

export function useCampaignSummaryTableDataQuery({
    pagePath,
    tablePath,
    config,
    filters,
    currencyCode,
    options,
}: CampaignSummaryTableDataQueryProps): CerebroPaginatedMetricDataQueryResult<CampaignFactRecord> {
    const { columns } = useSelector((state: RootReduxState) =>
        selectTableSettings(state, tablePath)
    )
    const { adAccountId, portfolioId, labelId } = useSelector(
        (state: RootReduxState) =>
            selectResourceParamsOfPage(state, pagePath[0])
    )
    const additionalParams = useMemo(() => {
        const params: Record<string, unknown> = {}
        if (adAccountId) {
            params.profile = adAccountId
        }
        if (portfolioId) {
            params.portfolio__id = portfolioId
        }
        if (labelId) {
            params.campaign__labels_id_and_in = labelId
        }

        return params
    }, [adAccountId, labelId, portfolioId])

    // // Stream fields aren't automatically returned by Banner
    // // If the Budget Status column is visible, we manually query for them
    let group_by_stream_fields = ''
    if (
        columns.find(
            (column) =>
                column.isVisible && column.id === 'campaign__budget_status'
        )
    ) {
        group_by_stream_fields =
            ',budget_percentage,budget_updated_at,historical_budget'
    }

    return useCerebroPaginatedMetricDataQuery<CampaignFactRecord>({
        queryKeyPrefix: CAMPAIGN_QUERY_PREFIX,
        pagePath,
        tablePath,
        config,
        filters,
        currencyCode,
        options,
        groupBy: `campaign_id,profile_id,portfolio_id${group_by_stream_fields}`,
        additionalParams,
        apiCall: getCampaignFacts,
        formatFilters,
    })
}

type CampaignFactsDownloadProps<T> = Omit<
    CerebroDownloadQueryProps<T>,
    'reportName' | 'queryKeyPrefix' | 'groupBy' | 'apiCall' | 'formatFilters'
> & {
    pagePath: string[]
}

export function useCampaignFactsDownload({
    pagePath,
    tablePath,
    config,
    filters,
    currencyCode,
}: CampaignFactsDownloadProps<CampaignFactRecord>): CerebroDownloadQueryResult {
    const { adAccountId, portfolioId, labelId } = useSelector(
        (state: RootReduxState) =>
            selectResourceParamsOfPage(state, pagePath[0])
    )
    const resource = useSelector((state: RootReduxState) =>
        selectResourceOfPage(state, pagePath[0])
    )

    let reportName = 'All Campaigns Report'
    const additionalParams: Record<string, unknown> = {}
    if (adAccountId) {
        additionalParams.profile = adAccountId
        reportName = `Campaign Report - ${formatAdAccountName(resource)}`
    }
    if (portfolioId) {
        additionalParams.portfolio__id = portfolioId
        reportName = `Campaign Report - ${resource.name}`
    }
    if (labelId) {
        additionalParams.campaign__labels_id_and_in = labelId
        reportName = `Campaign Report - ${resource.name}`
    }

    return useCerebroDownload({
        queryKeyPrefix: CAMPAIGN_QUERY_PREFIX,
        tablePath,
        config,
        filters,
        currencyCode,
        additionalParams,
        groupBy: 'campaign_id',
        reportName,
        apiCall: getCampaignFactsExport,
        formatFilters,
    })
}
