import { useMemo } from 'react'

import { AxiosResponse } from 'axios'

import { useCerebroApiRequest } from 'hooks'
import { axiosInstanceOrganizations } from 'services/cerebroApi/orgScope/client'
import { PaginatedResponse } from 'types'

import {
    DataSource,
    DataSourceGroup,
    DataSourceGroupBy,
    DataSourceMetric,
    GlossaryItem,
} from './types'

export type DataSourceGroupListResponse = PaginatedResponse<DataSourceGroup>
export type DataSourceListResponse = PaginatedResponse<DataSource>
export type DataSourceGlossaryListResponse = PaginatedResponse<GlossaryItem>
export type DataSourceGroupByListResponse = PaginatedResponse<DataSourceGroupBy>
export type DataSourceMetricListResponse = PaginatedResponse<DataSourceMetric>

interface PageableQuery {
    offset?: number
    limit?: number
}

interface DataSourceGroupBysQuery extends PageableQuery {
    dataSourceSlug?: string
    includePreRelease?: boolean
    includeEndOfLife?: boolean
    includeNotVisible?: boolean
}

interface DataSourceMetricsQuery extends PageableQuery {
    dataSourceSlug?: string
    includePreRelease?: boolean
    includeEndOfLife?: boolean
    includeNotVisible?: boolean
}

interface DataSourceQuery {
    groupSlug?: string
    includePreRelease?: boolean
    includeEndOfLife?: boolean
}

interface MetricGlossaryQuery extends PageableQuery {
    text?: string
    group?: string
    dataSource?: string
    includePreRelease?: boolean
}

interface MetricDetailsQuery {
    slug: string
    includePreRelease?: boolean
}

interface MetricsGlossaryApi {
    getDataSourceGroupBys: (
        query: DataSourceGroupBysQuery
    ) => Promise<AxiosResponse<DataSourceGroupByListResponse> | null>
    getDataSourceMetrics: (
        query: DataSourceMetricsQuery
    ) => Promise<AxiosResponse<DataSourceMetricListResponse> | null>
    getMetricList: (
        query: MetricGlossaryQuery
    ) => Promise<AxiosResponse<DataSourceGlossaryListResponse> | null>
    getMetricDetails: (
        query: MetricDetailsQuery
    ) => Promise<AxiosResponse<GlossaryItem> | null>
    getDataSourceGroups: () => Promise<AxiosResponse<DataSourceGroupListResponse> | null>
    getDataSources: (
        query: DataSourceQuery
    ) => Promise<AxiosResponse<DataSourceListResponse> | null>
}

export function useMetricsGlossaryApi(): MetricsGlossaryApi {
    const makeCerebroApiRequest = useCerebroApiRequest({ throwOnError: true })

    return useMemo(
        () => ({
            getDataSourceGroupBys: ({
                dataSourceSlug,
                includeNotVisible = false,
                includePreRelease = false,
                offset = 0,
                limit = 1000,
            }: DataSourceMetricsQuery) => {
                return makeCerebroApiRequest({
                    request:
                        axiosInstanceOrganizations.get<DataSourceGroupByListResponse>(
                            `/api/data_source/${dataSourceSlug}/group_bys/`,
                            {
                                params: {
                                    data_source: dataSourceSlug,
                                    include_not_visible: includeNotVisible,
                                    include_pre_release: includePreRelease,
                                    limit,
                                    offset,
                                },
                            }
                        ),
                })
            },
            getDataSourceMetrics: ({
                dataSourceSlug,
                includeNotVisible = false,
                includePreRelease = false,
                includeEndOfLife = false,
                offset = 0,
                limit = 1000,
            }: DataSourceMetricsQuery) => {
                return makeCerebroApiRequest({
                    request:
                        axiosInstanceOrganizations.get<DataSourceMetricListResponse>(
                            `/api/data_source/${dataSourceSlug}/metrics/`,
                            {
                                params: {
                                    data_source: dataSourceSlug,
                                    include_not_visible: includeNotVisible,
                                    include_pre_release: includePreRelease,
                                    include_end_of_life: includeEndOfLife,
                                    limit,
                                    offset,
                                },
                            }
                        ),
                })
            },
            getMetricList: ({
                text,
                group,
                dataSource,
                includePreRelease = false,
                offset = 0,
                limit = 25,
            }: MetricGlossaryQuery) => {
                return makeCerebroApiRequest({
                    request:
                        axiosInstanceOrganizations.get<DataSourceGlossaryListResponse>(
                            `/api/data_sources/glossary/metrics/`,
                            {
                                params: {
                                    text,
                                    group,
                                    data_source: dataSource,
                                    include_pre_release: includePreRelease,
                                    limit,
                                    offset,
                                },
                            }
                        ),
                })
            },
            getMetricDetails: ({
                slug,
                includePreRelease = false,
            }: MetricDetailsQuery) => {
                return makeCerebroApiRequest({
                    request: axiosInstanceOrganizations.get<GlossaryItem>(
                        `/api/data_sources/glossary/metrics/${slug}/`,
                        {
                            params: {
                                include_pre_release: includePreRelease,
                            },
                        }
                    ),
                })
            },
            getDataSourceGroups: () => {
                return makeCerebroApiRequest({
                    request:
                        axiosInstanceOrganizations.get<DataSourceGroupListResponse>(
                            `/api/data_sources/groups/`
                        ),
                })
            },
            getDataSources: ({
                groupSlug,
                includePreRelease = false,
                includeEndOfLife = false,
            }: DataSourceQuery) => {
                return makeCerebroApiRequest({
                    request:
                        axiosInstanceOrganizations.get<DataSourceListResponse>(
                            `/api/data_sources/`,
                            {
                                params: {
                                    group: groupSlug,
                                    include_pre_release: includePreRelease,
                                    include_end_of_life: includeEndOfLife,
                                },
                            }
                        ),
                })
            },
        }),
        [makeCerebroApiRequest]
    )
}
