import { ReactElement, ReactNode, HTMLAttributes } from 'react'

import { Card } from 'antd'

interface Props extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
}

const ContainerWithUpperContent = ({
    className,
    children,
}: Props): ReactElement => (
    <Card
        className={className}
        styles={{ body: { padding: 0 } }}
        variant="outlined"
    >
        {children}
    </Card>
)

export default ContainerWithUpperContent
