import { Typeahead } from 'components/Typeahead'

import { FormikWithCustomHandlersPropTypes } from '../propTypes'
import withCustomHandlers from '../withCustomHandlers'

const FormikTypeahead = ({
    field: { value },
    form,
    handleChange,
    handleBlur,
    handleClick,
    ...props
}) => (
    <Typeahead
        values={value}
        onChange={(newValue) => {
            handleClick()
            handleChange(newValue)
        }}
        {...props}
    />
)

FormikTypeahead.propTypes = FormikWithCustomHandlersPropTypes

export default withCustomHandlers(FormikTypeahead)
