import { HTMLAttributes, ReactElement } from 'react'

import * as styles from './styles.scss'

const TextDividerVertical = ({
    ...props
}: HTMLAttributes<any>): ReactElement => (
    <span className={styles['text-divider-vertical']} {...props} />
)

export default TextDividerVertical
