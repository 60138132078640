import {
    memo,
    ReactElement,
    ReactNode,
    SyntheticEvent,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Table, TableProps as AntTableProps, Tooltip } from 'antd'
import { type PaginationConfig } from 'antd/es/pagination'
import { type Key, type SorterResult } from 'antd/es/table/interface'
import { ColumnType } from 'antd/lib/table/interface'
import produce from 'immer'
import flow from 'lodash/fp/flow'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import set from 'lodash/set'
import type { GetRowKey } from 'rc-table/lib/interface'
import { useTranslation } from 'react-i18next'
import { ResizeCallbackData } from 'react-resizable'

import { SIMPLE_PAGE_TABLE_SCROLL_REDUCER } from 'const/tables'
import { usePageContext } from 'context/PageContext'
import { getFormattedDateRange } from 'helpers/dateRange'
import { formatNumber } from 'helpers/formatting'
import { formatPeriodDeltaDateRange } from 'helpers/params'
import { isBoolean, isDefined } from 'helpers/typeGuard'
import { useDeepCompareCallback, useMonitorLoadTime } from 'hooks'
import useElementSize from 'hooks/useElementSize'
import useFeatureFlags from 'hooks/useFeatureFlags'
import {
    DatadogActionName,
    Field,
    Pagination,
    Path,
    PeriodDeltaType,
    PresetRange,
    Sorter,
} from 'types'

import EmptyTableStateProvider from '../EmptyTableStateProvider/EmptyTableStateProvider'

import { ToggleMetricDeltasCell, ToggleMetricDeltasTitle } from './Columns'
import { TableField } from './localTypes'
import * as styles from './styles.scss'
import { BodyCell, HeaderCell, RowWrapper } from './TableComponents'

interface Widths {
    [id: string]: {
        width?: number
        children?: (number | undefined)[]
    }
}

interface Props<RecordType extends object>
    extends Omit<AntTableProps<RecordType>, 'title'> {
    sorter: Sorter
    readonly?: boolean
    shortFormat?: boolean
    showPeriodDeltas?: boolean
    showTotalRow?: boolean
    shouldMonitorTime?: boolean
    hasUpperContent?: boolean

    updateColumns: (columns: Field<RecordType>[]) => void
    updateSorter: (sorter: Sorter) => void
    updatePagination: (pagination: Pagination) => void
    onViewChanged: (options?: { noCount?: boolean }) => void
    reloadData: (options?: { noCount?: boolean }) => void
    updateRecord: (data: { rowIndex: number; record: RecordType }) => void

    periodDeltaType?: PeriodDeltaType
    filterDates?: string[] | PresetRange
    periodDeltaDateRange?: string[]
    disablePriorPeriod?: boolean
    priorPeriodWarningMessage?: string
    rangeLag?: number

    // Ant Design required overrides
    columns: Field<RecordType>[]
    rowKey: string | GetRowKey<RecordType>
    loading: boolean

    title?: ReactNode | (() => ReactElement | null)
    reduceScrollSize?: number
    customEmptyComponent?: ReactElement
}

const isFieldEqual = ({ field }: Sorter, dataIndex?: Path): boolean => {
    if (typeof dataIndex === 'undefined') {
        return false
    }

    const comparableField = typeof field === 'string' ? [field] : field
    const comparableDataIndex =
        typeof dataIndex === 'string' ? [dataIndex] : dataIndex

    return isEqual(comparableField, comparableDataIndex)
}

function PaginatedTable<T extends object>({
    readonly = false,
    shortFormat = false,
    showPeriodDeltas = false,
    showTotalRow = false,
    shouldMonitorTime = true,
    hasUpperContent = false,

    updateColumns,
    updateSorter,
    updatePagination,
    onViewChanged,
    reloadData,
    updateRecord,

    periodDeltaType,
    filterDates,
    periodDeltaDateRange,
    disablePriorPeriod,
    priorPeriodWarningMessage,
    rangeLag,

    columns,
    sorter,
    pagination,

    title,
    reduceScrollSize = SIMPLE_PAGE_TABLE_SCROLL_REDUCER,
    sortDirections = ['descend', 'ascend'],
    tableLayout = 'fixed',
    locale,
    customEmptyComponent,
    ...rest
}: Props<T>): ReactElement {
    const { t } = useTranslation('table')

    const [resizing, setResizing] = useState(false)

    const [widths, setWidths] = useState<Widths>(() =>
        columns.reduce(
            (accumulator: Widths, current) =>
                produce(accumulator, (draft) => {
                    draft[current.id] = {
                        width: current.antTableColumnOptions.width,
                        ...(current.childrenFields
                            ? {
                                  children: current.childrenFields.map(
                                      (child) =>
                                          child.antTableColumnOptions.width
                                  ),
                              }
                            : {}),
                    }
                }),
            {}
        )
    )

    const components = useMemo(() => {
        return {
            header: { cell: HeaderCell },
            body: {
                cell: BodyCell,
                row: RowWrapper,
            },
        }
    }, [])

    const handleResize = useCallback(
        (id: string, childIndex?: number, minWidth?: number) =>
            (_e: SyntheticEvent, { size }: ResizeCallbackData) => {
                setResizing(true)
                if (!isUndefined(minWidth) && size.width < minWidth) {
                    return
                }

                const width = isUndefined(minWidth)
                    ? size.width
                    : Math.max(minWidth, size.width)

                setWidths(
                    produce((draft: Widths) => {
                        if (!isUndefined(childIndex)) {
                            set(draft, [id, 'children', childIndex], width)
                        } else {
                            set(draft, [id, 'width'], width)
                        }
                    })
                )
            },
        []
    )

    const handleResizeStop = useDeepCompareCallback(
        (id: string, childIndex?: number) =>
            (_e: SyntheticEvent, { size }: ResizeCallbackData) => {
                setResizing(false)
                updateColumns(
                    produce(columns, (draft) => {
                        const parentIndex = draft.findIndex(
                            (element) => element.id === id
                        )
                        if (childIndex) {
                            set(
                                draft,
                                [
                                    parentIndex,
                                    'childrenFields',
                                    childIndex,
                                    'antTableColumnOptions',
                                    'width',
                                ],
                                size.width
                            )
                        } else {
                            draft[parentIndex].antTableColumnOptions.width =
                                size.width
                        }
                    })
                )
            },
        [columns, updateColumns]
    )

    const areSorterEqual = (a: Sorter, b: Sorter): boolean => {
        if (a.order !== b.order) {
            return false
        }
        const aFields = a.field instanceof Array ? a.field : [a.field]
        const bFields = b.field instanceof Array ? b.field : [b.field]
        return isEqual(aFields, bFields)
    }

    const handleOnChange = useDeepCompareCallback(
        (
            nextPagination: PaginationConfig,
            _nextFilters: Record<string, Key[] | null>,
            nextSorter: SorterResult<T> | SorterResult<T>[]
        ): void => {
            let viewChanged = false

            // Update sorter
            if (
                !(nextSorter instanceof Array) &&
                !areSorterEqual(nextSorter, sorter)
            ) {
                updateSorter({
                    field: nextSorter.field,
                    order: nextSorter.order,
                })
                viewChanged = true
            }

            // Update pagination
            if (
                pagination &&
                (nextPagination.current !== pagination.current ||
                    nextPagination.pageSize !== pagination.pageSize)
            ) {
                updatePagination({
                    current: nextPagination.current,
                    pageSize: nextPagination.pageSize,
                })
                viewChanged = true
            }

            if (viewChanged) {
                onViewChanged({ noCount: true })
            }
        },
        [
            pagination,
            reloadData,
            onViewChanged,
            sorter.field,
            sorter.order,
            updatePagination,
            updateSorter,
        ]
    )

    const filterHiddenColumns = useCallback(
        (cols: TableField<T>[]): TableField<T>[] =>
            cols.filter((col) => col.isVisible || col.isVisible === null),
        []
    )

    const expandWithChildrenColumns = useDeepCompareCallback(
        (cols: TableField<T>[]): TableField<T>[] => {
            if (!showPeriodDeltas) {
                return cols
            }
            return cols.reduce((accumulator: TableField<T>[], current) => {
                accumulator.push(current)

                const childrenToExpand: TableField<T>[] = []
                if (
                    current.childrenFields &&
                    !isEmpty(current.childrenFields)
                ) {
                    let isCollapsed = true
                    current.childrenFields.forEach((child, childIndex) => {
                        if (
                            child.isVisible &&
                            (child.metricOptions?.isDelta ||
                                child.metricOptions?.isPriorPeriod)
                        ) {
                            childrenToExpand.push({
                                ...child,
                                id: current.id,
                                childIndex,
                            })
                            isCollapsed = false
                        }
                    })
                    childrenToExpand.unshift({
                        id: 'deltaColumn',
                        isVisible: true,
                        isResizeable: false,
                        minWidth: 36,
                        priorPeriodOptions: {
                            render: ({ record, rowIndex, isTotalCell }) => {
                                return (
                                    <ToggleMetricDeltasCell
                                        parent={current}
                                        record={record}
                                        columns={columns}
                                        rowIndex={rowIndex}
                                        reloadData={reloadData}
                                        readonly={readonly}
                                        shortFormat={shortFormat}
                                        updateColumns={updateColumns}
                                        isCollapsed={isCollapsed}
                                        updateRecord={updateRecord}
                                        isTotalCell={isTotalCell}
                                    />
                                )
                            },
                        },
                        antTableColumnOptions: {
                            title: (
                                <ToggleMetricDeltasTitle
                                    updateColumns={updateColumns}
                                    columns={columns}
                                    parentId={current.id}
                                    isCollapsed={isCollapsed}
                                />
                            ),
                            width: 36,
                        },
                    })
                }

                return [...accumulator, ...childrenToExpand]
            }, [])
        },
        [
            columns,
            readonly,
            reloadData,
            shortFormat,
            showPeriodDeltas,
            updateColumns,
            updateRecord,
        ]
    )

    const includeExtraPropsForCustomComponents = useDeepCompareCallback(
        (cols: TableField<T>[]): TableField<T>[] =>
            cols.map((col, idx) =>
                produce(col, (draft) => {
                    draft.antTableColumnOptions.onHeaderCell = ({ width }) => {
                        return {
                            isResizeable: col.isResizeable,
                            width,
                            onResize: handleResize(
                                col.id,
                                col.childIndex,
                                col.minWidth
                            ),
                            onResizeStop: handleResizeStop(
                                col.id,
                                col.childIndex
                            ),
                        }
                    }
                    draft.antTableColumnOptions.onCell = (
                        record,
                        rowIndex
                    ) => ({
                        columns,
                        value: col.dataIndex
                            ? get(record, col.dataIndex)
                            : null,
                        record,
                        rowIndex,
                        renderOptions: col.renderOptions,
                        metricOptions: col.metricOptions,
                        priorPeriodOptions: col.priorPeriodOptions,
                        isTotalSupported: col.isTotalSupported,
                        reloadData,
                        readonly:
                            readonly || !!col.antTableColumnOptions.readonly, // the cell is readonly if the whole table is readonly or the given column is
                        shortFormat,
                        updateColumns,
                        updateRecord,
                        colIndex: idx,
                    })
                })
            ),
        [
            columns,
            handleResize,
            handleResizeStop,
            readonly,
            reloadData,
            shortFormat,
            updateColumns,
            updateRecord,
        ]
    )

    const includeColumnSortOrder = useDeepCompareCallback(
        (cols: TableField<T>[]): TableField<T>[] =>
            cols.map((col) => {
                if (isFieldEqual(sorter, col.dataIndex) && sorter.order) {
                    return produce(col, (draft) => {
                        draft.antTableColumnOptions.sortOrder = sorter.order
                    })
                }
                return col
            }),
        [sorter]
    )

    const mergeWithLocalWidths = useDeepCompareCallback(
        (cols: TableField<T>[]): TableField<T>[] => {
            return cols.map((col) =>
                produce(col, (draft) => {
                    if (widths[draft.id]) {
                        let { width: localWidth } = widths[draft.id]
                        const { children } = widths[draft.id]
                        if (!isUndefined(col.childIndex) && children) {
                            localWidth = children[col.childIndex]
                        }
                        draft.antTableColumnOptions.width = localWidth
                    }
                })
            )
        },
        [widths]
    )

    const convertToAntColumnType = useCallback(
        (
            tableColumns: TableField<T>[]
        ): TableField<T>['antTableColumnOptions'][] => {
            const buildPriorPeriodHeaderTitle = (): ReactNode => {
                const headerLabel = t(
                    'table:fields.priorPeriod.name',
                    'Prior Period'
                )

                if (!filterDates || !periodDeltaType) {
                    return headerLabel
                }

                const previousDates =
                    periodDeltaType === 'custom' &&
                    periodDeltaDateRange &&
                    periodDeltaDateRange.length > 0
                        ? periodDeltaDateRange
                        : formatPeriodDeltaDateRange(
                              true,
                              periodDeltaType,
                              [],
                              filterDates,
                              'report_date',
                              rangeLag
                          )

                return (
                    <span>
                        {headerLabel} (
                        {getFormattedDateRange(Object.values(previousDates))})
                        {disablePriorPeriod && (
                            <Tooltip title={priorPeriodWarningMessage}>
                                <InfoCircleOutlined style={{ marginLeft: 4 }} />
                            </Tooltip>
                        )}
                    </span>
                )
            }

            const buildPriorPeriodColumns = (
                cols: TableField<T>[],
                antCols: Field<T>['antTableColumnOptions'][],
                priorPeriodCols: TableField<T>[]
            ): void => {
                const priorPeriodColsGroupById: {
                    [key: string]: TableField<T>[]
                } = priorPeriodCols.reduce((acc, curr) => {
                    acc[curr.id] = acc[curr.id] || []
                    acc[curr.id].push(curr)
                    return acc
                }, Object.create(null))

                let offset = 2
                Object.entries(priorPeriodColsGroupById).forEach(
                    ([id, priorCols]) => {
                        const antPriorPeriodCols = priorCols.map((col) => ({
                            ...col.antTableColumnOptions,
                        }))

                        const indexToInsert =
                            cols
                                .filter((col) => col.childIndex == null)
                                .findIndex((col) => col.id === id) + offset

                        antCols.splice(indexToInsert, 0, {
                            title: buildPriorPeriodHeaderTitle(),
                            width: 30,
                            align: 'center' as const,
                            children: antPriorPeriodCols,
                        } as Field<T>['antTableColumnOptions'])

                        offset += 1
                    }
                )
            }

            const antCols = tableColumns
                .filter((col) => col.childIndex == null)
                .map((col) => ({
                    ...col.antTableColumnOptions,
                }))

            const priorPeriodCols = tableColumns.filter(
                (col) => col.childIndex != null
            )

            if (priorPeriodCols.length > 0) {
                buildPriorPeriodColumns(tableColumns, antCols, priorPeriodCols)
            }

            return antCols
        },
        [filterDates, periodDeltaDateRange, periodDeltaType, t]
    )

    const processColumns = useCallback(
        (columnsToProcess: Field<T>[]) => {
            const fn = flow(
                filterHiddenColumns,
                expandWithChildrenColumns,
                includeExtraPropsForCustomComponents,
                includeColumnSortOrder
            )
            return fn(columnsToProcess)
        },
        [
            filterHiddenColumns,
            expandWithChildrenColumns,
            includeExtraPropsForCustomComponents,
            includeColumnSortOrder,
        ]
    )

    const processedColumns = useMemo(
        () => processColumns(columns),
        [columns, processColumns]
    )

    const mergeAndConvert = useCallback(
        (columnsToMerge: Field<T>[]) => {
            const fn = flow(mergeWithLocalWidths, convertToAntColumnType)
            return fn(columnsToMerge)
        },
        [mergeWithLocalWidths, convertToAntColumnType]
    )

    const tableColumns = useMemo(
        () => mergeAndConvert(processedColumns),
        [mergeAndConvert, processedColumns]
    )

    const leftFixedColumns: ColumnType<T>[] = []
    const rightFixedColumns: ColumnType<T>[] = []
    const otherColumns: ColumnType<T>[] = []

    tableColumns.forEach((column: ColumnType<T>) => {
        if (column.fixed === 'left') {
            leftFixedColumns.push(column)
        } else if (column.fixed === 'right') {
            rightFixedColumns.push(column)
        } else {
            otherColumns.push(column)
        }
    })

    const sortedTableColumns = [
        ...leftFixedColumns,
        ...otherColumns,
        ...rightFixedColumns,
    ]

    useMonitorLoadTime({
        actionName: DatadogActionName.LOAD_TABLE,
        loading: rest.loading,
        shouldMonitorTime,
        tableContext: {
            column_count: tableColumns.length,
            sort_by: {
                field: sorter.field,
                order: sorter.order,
            },
            pagination: {
                ...(isDefined(pagination) && !isBoolean(pagination)
                    ? {
                          pageSize: pagination.pageSize,
                          current: pagination.current,
                          total: pagination.total,
                      }
                    : {}),
            },
        },
    })

    const titleRef = useRef<HTMLDivElement>(null)
    const { height: titleHeight } = useElementSize<HTMLDivElement>(titleRef)

    // Filters are now inside the table title and thus have their height tracked
    // Increase the scroll size to account for them not being counted before
    // TODO: adjust the base values when compactFilterUx FF is removed
    const { compactFilterUx } = useFeatureFlags()
    const finalReduceScrollSize =
        compactFilterUx && reduceScrollSize !== SIMPLE_PAGE_TABLE_SCROLL_REDUCER
            ? reduceScrollSize - 44
            : reduceScrollSize

    const {
        headerSize: { height: headerHeight },
    } = usePageContext()
    const scroll = {
        y: `max(calc(100vh - ${headerHeight + titleHeight + finalReduceScrollSize}px), 400px)`,
    }

    return (
        <div
            className={`${styles['paginated-table-wrapper']} ${
                hasUpperContent ? styles['has-upper-content'] : ''
            } ${rest.loading ? '' : styles['table-loaded']}`}
        >
            {!!title && (
                <div ref={titleRef} className="ant-table-title">
                    {typeof title === 'function' ? title() : title}
                </div>
            )}
            <EmptyTableStateProvider
                isLoading={rest.loading}
                customEmptyComponent={customEmptyComponent}
            >
                <Table
                    columns={sortedTableColumns}
                    components={components}
                    scroll={scroll}
                    sortDirections={sortDirections}
                    onChange={handleOnChange}
                    locale={locale}
                    pagination={{
                        size: 'default',
                        showSizeChanger: true,
                        position: ['bottomCenter'],
                        showLessItems: true,
                        showTotal: (total, range) =>
                            t(
                                'table:PaginatedTable.paginationText',
                                '{{minRange}}-{{maxRange}} of {{total}} Total',
                                {
                                    minRange: formatNumber(range[0]),
                                    maxRange: formatNumber(range[1]),
                                    total: formatNumber(total),
                                }
                            ),
                        ...pagination,
                    }}
                    size="small"
                    showSorterTooltip={false}
                    tableLayout={tableLayout}
                    onRow={(record, rowIndex) =>
                        ({
                            record,
                            rowIndex,
                            resizing,
                            showTotalRow,
                        }) as any
                    }
                    {...rest}
                />
            </EmptyTableStateProvider>
        </div>
    )
}

export default memo(PaginatedTable, isEqual) as typeof PaginatedTable
