import { ReactElement, ComponentType } from 'react'

import { Flex, Typography } from 'antd'
import { type TooltipPlacement } from 'antd/es/tooltip'

import MarkdownRenderer from 'components/MarkdownRenderer/MarkdownRenderer'
import {
    TableColumnTooltipsMetadata,
    TooltipComponentType,
} from 'configuration/fieldCreators/localTypes'

import { DataSourceGroupBy } from '../types'

import FieldTooltip, { FieldTooltipProps } from './FieldTooltip'

export type GroupByTooltipAlertType = 'disallowed'

interface GroupByTooltipProps {
    groupBy?: DataSourceGroupBy
    children: ReactElement | string
    placement?: TooltipPlacement
    alertMessage?: ReactElement | string
    alertType?: GroupByTooltipAlertType
    customTooltip?: TooltipComponentType
    metadata?: TableColumnTooltipsMetadata
}

export default function GroupByTooltip({
    groupBy,
    children,
    placement,
    alertMessage,
    alertType,
    metadata,
    customTooltip = FieldTooltip,
}: GroupByTooltipProps): ReactElement {
    const title = (
        <Flex flex="1">
            <span
                style={{
                    fontSize: '13px',
                    lineHeight: '1.4',
                }}
            >
                {groupBy?.display_name}
            </span>
        </Flex>
    )

    const body =
        groupBy && groupBy.definition ? (
            <Typography.Title
                level={5}
                style={{
                    fontWeight: '400',
                    color: 'rgba(0, 0, 0, 0.75)',
                    margin: '4px 0 0',
                    fontSize: '12.6px',
                    lineHeight: '1.4',
                }}
            >
                <MarkdownRenderer markdown={groupBy.definition} />
            </Typography.Title>
        ) : undefined

    const TooltipType = customTooltip as ComponentType<FieldTooltipProps>

    return (
        <div>
            <TooltipType
                title={title}
                body={body}
                placement={placement}
                alertMessage={alertMessage}
                alertType={alertType}
                metadata={metadata}
            >
                {children}
            </TooltipType>
        </div>
    )
}
