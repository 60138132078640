import { HTMLAttributes, PropsWithChildren, ReactElement, JSX } from 'react'

import classNames from 'classnames'

import * as styles from './styles.scss'

interface StackProps
    extends Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'style'> {
    direction: 'row' | 'column'
    alignItems?: 'start' | 'center' | 'end' | 'stretch'
    justifyContent?: 'start' | 'center' | 'end' | 'between' | 'around'
    gap?: number
    component?: keyof JSX.IntrinsicElements
}

function Stack({
    direction,
    alignItems,
    justifyContent,
    gap,
    className,
    style,
    children,
    component: Component = 'div',
    ...rest
}: PropsWithChildren<StackProps>): ReactElement {
    let appliedStyle = style
    if (gap !== undefined) {
        appliedStyle = {
            ...appliedStyle,
            gap: `${gap}px`,
        }
    }

    return (
        <Component
            className={classNames(styles['stack'], className, {
                [styles['direction-column']]: direction === 'column',
                [styles['direction-row']]: direction === 'row',
                [styles['align-items-start']]: alignItems === 'start',
                [styles['align-items-center']]: alignItems === 'center',
                [styles['align-items-end']]: alignItems === 'end',
                [styles['align-items-stretch']]: alignItems === 'stretch',
                [styles['justify-content-start']]: justifyContent === 'start',
                [styles['justify-content-center']]: justifyContent === 'center',
                [styles['justify-content-end']]: justifyContent === 'end',
                [styles['justify-content-between']]:
                    justifyContent === 'between',
                [styles['justify-content-around']]: justifyContent === 'around',
            })}
            style={appliedStyle}
            {...rest}
        >
            {children}
        </Component>
    )
}

export default Stack
