export const formatDecimal = (value?: string | number): string => {
    if (!value) {
        return ''
    }
    const parts = value.toString().split('.')
    return parts
        .map((p, i) => {
            if (i > 0) {
                return p
            }
            return p.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        })
        .join('.')
}

export const parseDecimal = (value?: string): string =>
    (value ?? '').replace(/(,*)/g, '')

export const parseCurrency = (value?: string | number): string => {
    if (!value) {
        return ''
    }
    const parts = value.toString().split(' ')
    // everything before the last space is the currency symbol
    return parseDecimal(parts[parts.length - 1])
}

export const formatPercentageInput = (value: string): string =>
    value === '' ? '' : `${value}%`

export const parsePercentageInput = (value?: string): string =>
    (value ?? '').replace('%', '')
