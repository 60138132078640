import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import PortfolioIconSvg from './PortfolioIconSvg'

const PortfolioIcon = (props: IconProps): ReactElement => (
    <Icon component={PortfolioIconSvg} {...props} />
)

export default PortfolioIcon
