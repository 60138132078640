import { RumEvent, RumEventDomainContext } from '@datadog/browser-rum'

import { ignoreErrors } from 'configuration/monitoring'
import { isRegEx } from 'helpers/typeGuard'

interface BeforeSendProps {
    event: RumEvent
    context: RumEventDomainContext
}

export const isSuppressableError = (errorMessage: string): boolean => {
    return ignoreErrors.some((matchCriteria) => {
        if (isRegEx(matchCriteria)) {
            return matchCriteria.test(errorMessage)
        }
        return errorMessage.includes(matchCriteria)
    })
}

/**
 * Evaluates if an error should be suppressed from browser monitoring tools (e.g., Datadog)
 */
export const shouldSuppressError = ({
    event,
    context: _context,
}: BeforeSendProps): boolean => {
    if (event.type === 'error') {
        return isSuppressableError(event.error.message)
    }
    return false
}
