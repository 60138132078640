import { ReactElement } from 'react'

import { BreadcrumbCategory } from '../../../types/resources/product'

import * as styles from './styles.scss'

interface Props {
    breadcrumbTree: BreadcrumbCategory[]
}

const BreadcrumbTreeCell = ({ breadcrumbTree }: Props): ReactElement => {
    const sortedTree = breadcrumbTree.toSorted(
        (a, b) => a.position - b.position
    )
    const rootBreadcrumb = sortedTree.find(
        (breadcrumb) => breadcrumb.position === 0
    )
    const breadcrumbs = sortedTree.filter(
        (breadcrumb) => breadcrumb.position !== 0
    )

    return (
        <div className={styles.cell}>
            <div className={styles.root}>{rootBreadcrumb?.name}</div>
            <div className={styles.tail}>
                {breadcrumbs.map((breadcrumb) => (
                    <span key={breadcrumb.id}>
                        {' › '}
                        {breadcrumb.name}
                    </span>
                ))}
            </div>
        </div>
    )
}

export default BreadcrumbTreeCell
