import { ReactElement } from 'react'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import every from 'lodash/every'
import { useTranslation } from 'react-i18next'

import Stack from 'components/Layout/Stack'

import { ColumnConfig, FormValues } from './localTypes'
import * as styles from './styles.scss'

interface Props<KeywordField> {
    keywords: KeywordField[]
    columnsConfig: ColumnConfig[]
}

function CreateKeywordFormAllRow<KeywordField extends Record<string, any>>({
    keywords = [],
    columnsConfig,
}: Props<KeywordField>): ReactElement {
    const { t } = useTranslation('common')
    const { setValues } = useFormikContext<FormValues<KeywordField>>()

    const getInitialValue = (dataKey: string): string | undefined => {
        const keyword: Record<string, any> = keywords[0]

        return every(keywords, [dataKey, keyword[dataKey]])
            ? keyword[dataKey]
            : undefined
    }

    const handleChange = (val: string | number, field: string): void => {
        setValues({
            keywords: keywords.map((keyword) => ({
                ...keyword,
                ...{ [field]: val },
            })),
        })
    }

    return (
        <Stack
            direction="row"
            alignItems="start"
            className={styles['bulk-edit-row']}
        >
            <div
                style={{ flex: 1, marginRight: 4 }}
                className={`fg-text-light ${styles['text-field']}`}
            >
                <em>
                    {t('common:applyChangesInBulk', 'Apply changes in bulk')}:
                </em>
            </div>

            {columnsConfig.map(({ renderBulkField, dataKey, width }) => {
                const initialValue = getInitialValue(dataKey)

                return (
                    <Stack
                        direction="column"
                        alignItems="center"
                        className={styles.field}
                        style={{
                            width,
                            marginRight: 4,
                        }}
                        key={dataKey}
                    >
                        {renderBulkField(initialValue, (val) => {
                            handleChange(val, dataKey)
                        })}
                    </Stack>
                )
            })}

            <Stack
                direction="column"
                alignItems="center"
                className={classNames(styles.field, styles['remove-column'])}
                style={{ marginRight: 4 }}
            />
        </Stack>
    )
}

export default CreateKeywordFormAllRow
