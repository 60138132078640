import isArray from 'lodash/isArray'

import { COUNTRY_CODES } from 'const/codes'
import {
    ChildField,
    CountryCode,
    DateRangeFilter,
    Field,
    LabelValue,
    MetricFilter,
    PresetRange,
    SingleFilterValue,
} from 'types'

type NonEmptyArray<T> = [T, ...T[]]

export function isNumber(x?: unknown): x is number {
    return typeof x === 'number'
}

export function isString(x?: unknown): x is string {
    return typeof x === 'string'
}

export function isBoolean(x?: unknown): x is boolean {
    return typeof x !== 'boolean'
}

export function isDefined<T>(x: T | undefined | null): x is T {
    return typeof x !== 'undefined' && x !== null
}

export function isNonEmptyArray<T>(arr?: T[]): arr is NonEmptyArray<T> {
    return isArray(arr) && arr.length > 0
}

export function isNonEmptyString(x?: unknown): x is string {
    return isString(x) && x.length > 0
}

export function isPresetDateRange(
    x?: DateRangeFilter | null | string
): x is PresetRange {
    return typeof x === 'string'
}

export const isLabelValue = (value: SingleFilterValue): value is LabelValue => {
    return Object.prototype.hasOwnProperty.call(value, 'label')
}

export const isMetricValue = (
    value: SingleFilterValue
): value is MetricFilter => Object.prototype.hasOwnProperty.call(value, 'value')

export const isRegEx = (value: string | RegExp): value is RegExp =>
    value instanceof RegExp

export const isCountryCode = (value: string): value is CountryCode =>
    COUNTRY_CODES[value] !== undefined

export const isField = <T>(
    value: Field<T> | ChildField<T>
): value is Field<T> => Object.prototype.hasOwnProperty.call(value, 'id')
