import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import WalmartIconSvg from './WalmartIconSvg'

const WalmartIcon = (props: IconProps): ReactElement => (
    <Icon component={WalmartIconSvg} {...props} />
)

export default WalmartIcon
