import { ReactElement, useEffect, useState } from 'react'

import { EditOutlined } from '@ant-design/icons'
import { Alert, Button, Typography } from 'antd'
import { ErrorMessage, Form, Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { PhoneInput } from 'react-international-phone'
import { object, string } from 'yup'

import { IconButton } from 'components/Buttons'
import CodeInput from 'components/CodeInput/CodeInput'
import { CONTACT_US } from 'configuration/urls'
import { generateCodeSentNotification } from 'helpers/notifications'
import { useCerebroApiRequest } from 'hooks'
import { useAnalytics } from 'services/analytics'
import {
    triggerUserPhoneNumberVerification,
    verifyUserPhoneNumber,
} from 'services/cerebroApi/noScope/resourceApi'

import * as styles from './styles.scss'

interface VerifyPhoneNumberFormProps {
    accessToken: string
    phoneNumberToVerify: string
    onEditPhoneNumberRequested: () => void
    onPhoneNumberVerified: () => void
}

const MAX_SMS_RETRIES_ALLOWED = 5

const VerifyPhoneNumberForm = (
    props: VerifyPhoneNumberFormProps
): ReactElement => {
    const {
        accessToken,
        phoneNumberToVerify,
        onEditPhoneNumberRequested,
        onPhoneNumberVerified,
    } = props

    const [retryCount, setRetryCount] = useState(0)
    const [isSendingCode, setIsSendingCode] = useState(false)
    const [hasSentCode, setHasSentCode] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const { t } = useTranslation('account')
    const makeCerebroApiRequest = useCerebroApiRequest()

    const { trackEvent, trackCtaClick } = useAnalytics()

    const codeMissingErrorMessage = t(
        'account:VerifyPhoneNumberForm.code.required',
        'Please enter a valid 6-digit code.'
    )
    const validationSchema = object().shape({
        smsCode: string()
            .required(codeMissingErrorMessage)
            .length(6, codeMissingErrorMessage)
            .label(t('account:VerifyPhoneNumberForm.code', 'Code')),
    })

    async function triggerVerificationCode(isRetry?: boolean): Promise<void> {
        if (accessToken) {
            setIsSendingCode(true)
            await makeCerebroApiRequest({
                request: triggerUserPhoneNumberVerification(accessToken),
                onRequestSuccess: () => {
                    setIsSendingCode(false)
                    setHasSentCode(true)
                    if (isRetry) {
                        generateCodeSentNotification(phoneNumberToVerify)
                    }
                },
                onRequestFailure: (response) => {
                    setIsSendingCode(false)
                    if (response.status === 429) {
                        setErrorMessage(
                            t(
                                'account:VerifyPhoneNumberForm.tooManyAttempts',
                                'Too many attempts. Please wait a while and try again.'
                            )
                        )
                    }
                },
                suppressErrorAlerts: true,
            })
        }
    }

    async function retrySmsMessage(): Promise<void> {
        if (retryCount < MAX_SMS_RETRIES_ALLOWED) {
            setRetryCount(retryCount + 1)
            await triggerVerificationCode(true)
        }
    }

    useEffect(() => {
        if (!hasSentCode) {
            triggerVerificationCode()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function verifyPhoneNumber(
        smsCode: string,
        setSubmitting: (isSubmitting: boolean) => void
    ): Promise<void> {
        if (accessToken) {
            setSubmitting(true)
            await makeCerebroApiRequest({
                request: verifyUserPhoneNumber(smsCode, accessToken),
                onRequestSuccess: () => {
                    trackEvent('MFA - Phone Number Verify Success', {
                        status: 'success',
                        text: 'Verify Phone Number',
                    })
                    onPhoneNumberVerified()
                },
                onRequestFailure: () => {
                    trackEvent('MFA - Phone Number Verify Error', {
                        status: 'failure',
                        text: 'Invalid Verification Code',
                    })
                    setErrorMessage(
                        t(
                            'account:VerifyPhoneNumberForm.code.invalid',
                            'Invalid Verification Code'
                        )
                    )
                },
                suppressErrorAlerts: true,
            })
            setSubmitting(false)
        }
    }

    const retryAllowed = retryCount < MAX_SMS_RETRIES_ALLOWED

    const triggerCTAEvent = (destination: string, text: string): void => {
        trackCtaClick({
            destination,
            text,
            type: 'button',
            location: 'MFA - Verify Phone Number',
        })
    }

    return (
        <div>
            <div>
                <h1>
                    {t(
                        'account:VerifyPhoneNumberForm.header',
                        'Verify your number'
                    )}
                </h1>
                <Typography>
                    {t(
                        'account:VerifyPhoneNumberForm.description',
                        'Enter the code sent to your phone to verify.'
                    )}
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <PhoneInput
                        value={phoneNumberToVerify}
                        className={styles['phone-input-display']}
                        disabled
                    />
                    <IconButton
                        ghost
                        icon={<EditOutlined />}
                        onClick={() => {
                            triggerCTAEvent(
                                'MFA - Edit Phone Number Page',
                                'Edit Phone Number'
                            )
                            onEditPhoneNumberRequested()
                        }}
                        size="small"
                    />
                </div>
            </div>
            <div style={{ marginTop: 24 }}>
                <Formik
                    initialValues={{ smsCode: '' }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        triggerCTAEvent('Signed in - Landing page', 'Submit')
                        setErrorMessage(undefined)
                        verifyPhoneNumber(values.smsCode, setSubmitting)
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <div style={{ marginBottom: 8 }}>
                                <CodeInput
                                    length={6}
                                    onChange={(code) => {
                                        setFieldValue('smsCode', code)
                                    }}
                                />
                                <ErrorMessage
                                    name="smsCode"
                                    component="div"
                                    className="fg-control-text is-error"
                                />
                            </div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isSubmitting}
                                size="large"
                            >
                                {t('common:verify', 'Verify')}
                            </Button>
                            <div>
                                {retryAllowed && (
                                    <Button
                                        type="link"
                                        size="small"
                                        loading={
                                            retryCount > 0 && isSendingCode
                                        }
                                        onClick={() => {
                                            triggerCTAEvent(
                                                'Same Page',
                                                'Resend Code'
                                            )
                                            retrySmsMessage()
                                        }}
                                        style={{ padding: 0, marginTop: 8 }}
                                    >
                                        <span>
                                            {t(
                                                'account:VerifyPhoneNumberForm.resendCode',
                                                'Resend Code'
                                            )}
                                        </span>
                                    </Button>
                                )}
                                {!retryAllowed && (
                                    <Trans i18nKey="account:VerifyPhoneNumberForm.contacUs">
                                        Having issues?{' '}
                                        <Button
                                            href={CONTACT_US}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            type="link"
                                            size="small"
                                            style={{ padding: 0, marginTop: 8 }}
                                        >
                                            <span>Contact us</span>
                                        </Button>
                                    </Trans>
                                )}
                            </div>
                            {errorMessage && (
                                <Alert
                                    message={errorMessage}
                                    type="error"
                                    style={{ marginTop: 24 }}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default VerifyPhoneNumberForm
