import { ReactElement } from 'react'

import { Select, InputNumber } from 'antd'
import { FormikHelpers, Field } from 'formik'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import pick from 'lodash/pick'
import pluralize from 'pluralize'
import { array, number, object, string } from 'yup'

import { FormikSelect, FormikInputNumber } from 'components/formik'
import {
    KEYWORD_BID_MAX,
    KEYWORD_BID_MIN,
    KEYWORD_BID_PRECISION,
    KEYWORD_BID_STEP,
} from 'const/keywords'
import { MATCH_TYPE_OPTIONS, BROAD, EXACT, PHRASE } from 'const/matchTypes'
import { STATE_FIELD_OPTIONS, PAUSED, ENABLED } from 'const/resourceStates'
import { getMetricValueFormatter } from 'helpers/formatters'
import { parseCurrency } from 'helpers/inputNumber'
import { useCerebroApiRequest } from 'hooks'
import { createKeyword } from 'services/cerebroApi/orgScope/resourceApi'
import { Campaign, Keyword } from 'types'
import displayMessage from 'utilities/message'

import { CreateKeywordModal } from '../CreateKeywordModal'
import { ColumnConfig } from '../CreateKeywordModal/localTypes'
import {
    AdGroupSelectFormikField,
    AdGroupSelectField,
} from '../Shared/AdGroupSelectField'

import { FormValues, KeywordField } from './localTypes'

interface Props {
    campaign: Campaign
    isModalVisible: boolean
    onModalCancel: () => void
    toggleModalVisible: () => void
    reloadData: () => void
}

function CreateAmazonKeywordsModal({
    campaign,
    isModalVisible,
    onModalCancel,
    reloadData,
    toggleModalVisible,
}: Props): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const closeModal = (callback: any): void => {
        callback()
        toggleModalVisible()
        reloadData()
    }

    const submitForm = async (
        { keywords }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ): Promise<void> => {
        const promises = keywords.map(async (keyword) =>
            makeCerebroApiRequest<Keyword>({
                request: createKeyword({
                    text: keyword.text,
                    bid: keyword.bid,
                    match_type: keyword.matchType,
                    state: keyword.state,
                    ad_group_id: keyword.adGroupId,
                    campaign_id: campaign?.id,
                }),
            })
        )

        const responses = await Promise.all(promises)

        if (responses.every((response) => response !== null)) {
            displayMessage.success(
                `Successfully created ${pluralize(
                    'keyword',
                    keywords.length,
                    true
                )} on campaign "${campaign?.name}"`
            )
            closeModal(resetForm)
        } else if (responses.some((response) => !isNull(response))) {
            closeModal(resetForm)
        }
    }

    const initialValues: FormValues = { keywords: [] }

    const validationSchema = object().shape({
        keywords: array()
            .of(
                object().shape({
                    // static values
                    text: string().required(),

                    // form values
                    adGroupId: string().label('Ad Group Target').required(),
                    matchType: string()
                        .label('Match Type')
                        .matches(new RegExp(`${EXACT}|${PHRASE}|${BROAD}`))
                        .required(),
                    bid: number()
                        .label('Keyword Bid')
                        .max(KEYWORD_BID_MAX)
                        .min(KEYWORD_BID_MIN)
                        .nullable()
                        .required(),
                })
            )
            .min(1)
            .required(),
    })

    const serializeKeywordField = (keyword: string): KeywordField => {
        return {
            text: keyword,
            adGroupId: campaign?.ad_groups[0]?.id ?? '',
            state: ENABLED,
            matchType: EXACT,
            bid: KEYWORD_BID_MIN,
        }
    }

    const campaignCurrencyCode = campaign?.profile?.currency_code

    const columnsConfig: ColumnConfig[] = [
        {
            name: 'Match Type',
            dataKey: 'matchType',
            width: '15%',
            renderField: (fieldName) => (
                <Field
                    name={fieldName}
                    component={FormikSelect}
                    placeholder="Select Match Type"
                >
                    {MATCH_TYPE_OPTIONS.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                            {label}
                        </Select.Option>
                    ))}
                </Field>
            ),
            renderBulkField: (initialValue, handleChange) => (
                <Select
                    placeholder="Select Match Type"
                    value={initialValue}
                    onChange={handleChange}
                >
                    {MATCH_TYPE_OPTIONS.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                            {label}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            name: 'Ad Group',
            dataKey: 'adGroupId',
            width: '20%',

            renderField: (fieldName) => (
                <AdGroupSelectFormikField
                    name={fieldName}
                    campaign={campaign}
                />
            ),
            renderBulkField: (initialValue, handleChange) => (
                <AdGroupSelectField
                    selectedAdGroup={initialValue}
                    campaign={campaign}
                    onChange={handleChange}
                />
            ),
        },
        {
            name: 'State',
            dataKey: 'state',
            width: '12%',
            renderField: (fieldName) => (
                <Field
                    name={fieldName}
                    component={FormikSelect}
                    placeholder="Select State"
                >
                    {map(
                        pick(STATE_FIELD_OPTIONS, [ENABLED, PAUSED]),
                        (label, value) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        )
                    )}
                </Field>
            ),
            renderBulkField: (initialValue, handleChange) => (
                <Select
                    value={initialValue}
                    placeholder="Select State"
                    onChange={handleChange}
                >
                    {map(
                        pick(STATE_FIELD_OPTIONS, [ENABLED, PAUSED]),
                        (label, value) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        )
                    )}
                </Select>
            ),
        },
        {
            name: 'Bid',
            dataKey: 'bid',
            width: '11%',
            renderField: (fieldName) => (
                <Field
                    name={fieldName}
                    component={FormikInputNumber}
                    formatter={getMetricValueFormatter(
                        'currencyAmount',
                        campaignCurrencyCode
                    )}
                    parser={parseCurrency}
                    min={KEYWORD_BID_MIN}
                    max={KEYWORD_BID_MAX}
                    step={KEYWORD_BID_STEP}
                    precision={KEYWORD_BID_PRECISION}
                />
            ),
            renderBulkField: (initialValue, handleChange) => (
                <InputNumber
                    onChange={handleChange}
                    value={initialValue}
                    formatter={getMetricValueFormatter(
                        'currencyAmount',
                        campaignCurrencyCode
                    )}
                    parser={parseCurrency}
                    min={KEYWORD_BID_MIN}
                    max={KEYWORD_BID_MAX}
                    step={KEYWORD_BID_STEP}
                    precision={KEYWORD_BID_PRECISION}
                />
            ),
        },
    ]

    return (
        <CreateKeywordModal<KeywordField>
            isModalVisible={isModalVisible}
            onModalCancel={onModalCancel}
            toggleModalVisible={toggleModalVisible}
            columnsConfig={columnsConfig}
            initialValues={initialValues}
            validationSchema={validationSchema}
            submitForm={submitForm}
            serializeKeywordField={serializeKeywordField}
        />
    )
}

export default CreateAmazonKeywordsModal
