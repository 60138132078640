import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import EditIconSvg from './EditIconSvg'

const EditIcon = (props: IconProps): ReactElement => (
    <Icon component={EditIconSvg} {...props} />
)

export default EditIcon
