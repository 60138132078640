import { useMemo } from 'react'

import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { ProductAdFactRecord } from 'configuration/tables'
import { DATES } from 'const/filters'
import { useOrgContext } from 'context/OrgContext'
import { useUserOverrideContext } from 'context/UserOverrideContext'
import { userHasManageProductCogsPermissions } from 'helpers/featurePermissions'
import { formatFilters } from 'helpers/filters/productAdFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatColumns,
    formatCurrency,
    formatMetrics,
    formatPagination,
    formatPeriodDeltaDateRange,
    formatSorter,
} from 'helpers/params'
import { useCerebroApiRequest } from 'hooks'
import { selectUserOrganizationFeaturePermissions } from 'selectors/auth'
import {
    selectResourceOfPage,
    selectResourceParamsOfPage,
    selectTableSettings,
    selectVisibleColumnsOfTable,
    selectVisibleMetricsOfTable,
} from 'selectors/ui'
import {
    getProductAdFacts,
    getProductAdFactsExport,
} from 'services/cerebroApi/orgScope/productAdFactsApi'
import {
    CerebroPaginatedResponse,
    ProductFactRecord,
    RootReduxState,
} from 'types'

import {
    CerebroPaginatedMetricDataQueryProps,
    CerebroPaginatedMetricDataQueryResult,
} from '../CerebroPaginatedQuery'

const PRODUCT_ADS_QUERY_PREFIX = 'product_ads'

export function invalidateProductAds(queryClient: QueryClient): void {
    queryClient.invalidateQueries({
        queryKey: [PRODUCT_ADS_QUERY_PREFIX],
    })
}

type ProductAdsQueryProps<T extends ProductAdFactRecord | ProductFactRecord> =
    Omit<
        CerebroPaginatedMetricDataQueryProps<T>,
        'queryKeyPrefix' | 'groupBy' | 'apiCall' | 'formatFilters'
    > & {
        getChildProducts?: boolean
        groupBys?: string
    }

export function useProductAdsQuery<
    T extends ProductAdFactRecord | ProductFactRecord,
>({
    pagePath,
    tablePath,
    config,
    filters,
    currencyCode,
    options,
    groupBys = 'product_ad_id,ad_group_id,campaign_id',
    getChildProducts = false,
}: ProductAdsQueryProps<T>): CerebroPaginatedMetricDataQueryResult<T> {
    const queryClient = useQueryClient()

    const makeCerebroApiRequest = useCerebroApiRequest({ throwOnError: true })
    const { userOverride } = useUserOverrideContext()
    const { orgId } = useOrgContext()
    const currency = currencyCode

    const {
        campaignId,
        profileId,
        portfolioId,
        adAccountId,
        asin,
        countryCode,
        labelId,
    } = useSelector((state: RootReduxState) =>
        selectResourceParamsOfPage(state, pagePath[0])
    )

    const {
        columns,
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
        showTotalRow,
    } = useSelector((state: RootReduxState) =>
        selectTableSettings(state, tablePath)
    )

    const metrics = useSelector((state: RootReduxState) =>
        selectVisibleMetricsOfTable(state, tablePath, config)
    )

    let formattedMetrics = formatMetrics(
        metrics,
        showPeriodDeltas,
        showTotalRow
    )

    const hasProductCogsPermissions = userHasManageProductCogsPermissions(
        useSelector(selectUserOrganizationFeaturePermissions)
    )

    // COGS is calculated as a metric in Banner but treated as a column in Forge
    // We need to manually query it if visible
    if (hasProductCogsPermissions) {
        columns.forEach((column) => {
            if (column.isVisible && column.id.startsWith('sku__')) {
                if (formattedMetrics.metrics) {
                    formattedMetrics = {
                        metrics: [
                            column.id.replace('sku__', ''),
                            formattedMetrics.metrics,
                        ].join(','),
                    }
                } else {
                    formattedMetrics = {
                        metrics: column.id.replace('sku__', ''),
                    }
                }
            }
        })
    }

    const params: Record<string, unknown> = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formattedMetrics,
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        product_ad__asin__isnotnull: true,
        group_by: groupBys,
    }

    if (campaignId) {
        params.campaign_id = campaignId
    }
    if (portfolioId) {
        params.portfolio_id = portfolioId
    }
    if (profileId) {
        params.profile_id = profileId
    }
    if (adAccountId) {
        params.profile_id = adAccountId
    }
    if (asin) {
        if (getChildProducts) {
            params.product_ad__metadata__parent_asin = asin
        } else {
            params.product_ad__asin = asin
        }
    }
    if (countryCode) {
        params.profile__country_code = countryCode
    }
    if (labelId) {
        params.product__labels_id_and_in = labelId
    }

    const apiOptions = {
        headers: {
            userId: userOverride?.userId,
            orgId: userOverride?.organizationId,
            orgGroupId: userOverride?.organizationGroupId,
        },
    }

    const queryKey = [
        PRODUCT_ADS_QUERY_PREFIX,
        'summary',
        orgId,
        tablePath,
        {
            ...params,
        },
    ]

    const queryResult = useQuery({
        queryKey,
        ...options,
        queryFn: () =>
            makeCerebroApiRequest({
                request: getProductAdFacts(params, apiOptions),
            }),
    })

    return {
        invalidateAllRelatedQueries: () => {
            queryClient.invalidateQueries({
                queryKey: [PRODUCT_ADS_QUERY_PREFIX],
            })
        },
        result: queryResult,
        updateRecord: (data: { rowIndex: number; record: any }) => {
            queryClient.setQueryData<CerebroPaginatedResponse<ProductAdFactRecord> | null>(
                queryKey,
                (prev) => {
                    if (!prev) {
                        return prev
                    }

                    const newRecords = [...prev.data.results]
                    newRecords[data.rowIndex] = data.record

                    return {
                        ...prev,
                        data: {
                            ...prev.data,
                            results: newRecords,
                        },
                    }
                }
            )
        },
    }
}

type ProductAdsDownloadProps<
    T extends ProductAdFactRecord | ProductFactRecord,
> = Omit<ProductAdsQueryProps<T>, 'options'> & {
    reportName?: string
}

export function useProductAdsDownload<
    T extends ProductAdFactRecord | ProductFactRecord,
>({
    pagePath,
    tablePath,
    config,
    filters,
    currencyCode,
    groupBys = 'product_ad_id',
    reportName = 'Product Ads Report',
    getChildProducts = false,
}: ProductAdsDownloadProps<T>): {
    triggerReport: () => void
    isPending: boolean
} {
    const makeCerebroApiRequest = useCerebroApiRequest({ throwOnError: true })
    const { orgId } = useOrgContext()

    const currency = currencyCode

    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        useSelector((state: RootReduxState) =>
            selectTableSettings(state, tablePath)
        )

    const columns = useSelector((state: RootReduxState) =>
        selectVisibleColumnsOfTable(state, tablePath, config)
    )

    const { campaignId, portfolioId, asin, countryCode, labelId, adAccountId } =
        useSelector((state: RootReduxState) =>
            selectResourceParamsOfPage(state, pagePath[0])
        )
    const resource = useSelector((state: RootReduxState) =>
        selectResourceOfPage(state, pagePath[0])
    )

    const params: Record<string, unknown> = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: groupBys,
        async_download_name: reportName,
    }

    if (campaignId) {
        params.campaign_id = campaignId
        params.async_download_name = `Campaign ${reportName} -  ${resource.name}`
    }
    if (portfolioId) {
        params.portfolio_id = portfolioId
        params.async_download_name = `Portfolio ${reportName} -  ${resource.name}`
    }
    if (adAccountId) {
        params.profile_id = adAccountId
        params.async_download_name = `Ad Account ${reportName} -  ${resource.name}`
    }

    if (asin) {
        if (getChildProducts) {
            params.product_ad__metadata__parent_asin = asin
        } else {
            params.product_ad__asin = asin
        }
    }
    if (countryCode) {
        params.profile__country_code = countryCode
    }
    if (!!asin && !!countryCode) {
        params.async_download_name = `${reportName} - ${countryCode}_${asin}`
    }
    if (labelId) {
        params.product_ad__product__labels_id_and_in = labelId
        params.async_download_name = `Label ${reportName} -  ${resource.name}`
    }

    const mutationKey = [
        PRODUCT_ADS_QUERY_PREFIX,
        'download',
        orgId,
        tablePath,
        {
            ...params,
        },
    ]

    const { mutate, isPending } = useMutation({
        mutationKey,
        mutationFn: async () => {
            const response = await makeCerebroApiRequest({
                request: getProductAdFactsExport(params),
            })
            generateReportNotification(params.async_download_name as string)
            return response
        },
    })

    return useMemo(
        () => ({
            triggerReport: mutate,
            isPending,
        }),
        [mutate, isPending]
    )
}

export function useProductsQuery<
    T extends ProductAdFactRecord | ProductFactRecord,
>(params: ProductAdsQueryProps<T>): ReturnType<typeof useProductAdsQuery<T>> {
    return useProductAdsQuery({
        ...params,
        groupBys: 'product_ad__asin,profile__country_code,metadata_id',
    })
}

export function useProductsDownload<
    T extends ProductAdFactRecord | ProductFactRecord,
>(
    params: ProductAdsDownloadProps<T>
): ReturnType<typeof useProductAdsDownload> {
    return useProductAdsDownload({
        ...params,
        groupBys: 'product_ad__asin,profile__country_code',
        reportName: 'Products Report',
    })
}

export function useChildProductsQuery<
    T extends ProductAdFactRecord | ProductFactRecord,
>(params: ProductAdsQueryProps<T>): ReturnType<typeof useProductAdsQuery<T>> {
    return useProductAdsQuery({
        ...params,
        groupBys: 'product_ad__asin,profile__country_code,metadata_id',
        getChildProducts: true,
    })
}

export function useChildProductsDownload<
    T extends ProductAdFactRecord | ProductFactRecord,
>(
    params: ProductAdsDownloadProps<T>
): ReturnType<typeof useProductAdsDownload> {
    return useProductAdsDownload({
        ...params,
        groupBys: 'product_ad__asin,profile__country_code',
        reportName: 'Child Products Report',
        getChildProducts: true,
    })
}

export function useSkuProductsQuery<
    T extends ProductAdFactRecord | ProductFactRecord,
>(params: ProductAdsQueryProps<T>): ReturnType<typeof useProductAdsQuery<T>> {
    return useProductAdsQuery({
        ...params,
        groupBys:
            'product_ad__asin,product_ad__sku,profile__country_code,metadata_id',
    })
}

export function useSkuProductsDownload<
    T extends ProductAdFactRecord | ProductFactRecord,
>(
    params: ProductAdsDownloadProps<T>
): ReturnType<typeof useProductAdsDownload> {
    return useProductAdsDownload({
        ...params,
        groupBys: 'product_ad__asin,product_ad__sku,profile__country_code',
        reportName: 'Products/SKUs Report',
    })
}
