import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import LabelIconSvg from './LabelIconSvg'

const LabelIcon = (props: IconProps): ReactElement => (
    <Icon component={LabelIconSvg} {...props} />
)

export default LabelIcon
