import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import { SOV_WEIGHTS_MAP } from 'configuration/sovWeights'
import {
    ADVANCED_PRODUCT_TITLE,
    DATES,
    EQUALS,
    LABELS,
    PRODUCT_NUMBER_OF_ITEMS,
    RANK_BETTER_THAN_OR_EQUAL,
    RANK_WORSE_THAN_OR_EQUAL,
    RANK_WORSE_THAN,
    SEARCH_TERM_NAME,
    SOV_ASINS,
    SOV_BRANDS,
    SOV_BRANDS_LIMIT,
    SOV_BRANDS_WEIGHT,
    SOV_COUNTRIES,
    SOV_FOLDS,
    SOV_KEYWORDS,
    SOV_LANGUAGES,
    SOV_LAYOUT,
    SOV_METADATA_BRANDS,
    SOV_PLATFORMS,
    SOV_RANK,
    SOV_RESULT_TYPES,
    SOV_STATES,
} from 'const/filters'
import { SOV_KEYWORD_LABELS } from 'const/resourceTypes'
import { formatCerebroDate } from 'helpers/formatting'
import { FiltersState, MetricFilter, SovSearchResultsParams } from 'types'

import { presetRanges, calculateFromDateWithLag } from '../dateRange'
import { formatBooleanFilter } from '../filters'

export const formatFilters = (
    filters: FiltersState
): SovSearchResultsParams => {
    let params: SovSearchResultsParams = {}

    const dateRangeFilter = filters[DATES]
    if (!isEmpty(dateRangeFilter) && !!dateRangeFilter) {
        if (isArray(dateRangeFilter)) {
            params.search_time_min = formatCerebroDate(dateRangeFilter[0])
            params.search_time_max = formatCerebroDate(dateRangeFilter[1])
        } else {
            const { range } = presetRanges(
                calculateFromDateWithLag(filters.rangeLag),
                filters.rangeLag
            )[dateRangeFilter]
            params.search_time_min = formatCerebroDate(range[0])
            params.search_time_max = formatCerebroDate(range[1])
        }
    }

    if (!isEmpty(filters[SEARCH_TERM_NAME])) {
        params.keyword__text__icontains = filters[SEARCH_TERM_NAME]
    }

    if (!isEmpty(filters[SOV_PLATFORMS])) {
        params.keyword__platform__in = filters[SOV_PLATFORMS]?.map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[SOV_COUNTRIES])) {
        params.keyword__country_code__in = filters[SOV_COUNTRIES]?.map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[SOV_BRANDS])) {
        const brandGroups = filters[SOV_BRANDS]?.filter(
            (item) => item.type === 'brandGroup'
        )

        if (brandGroups?.length) {
            params.my_brand_group = brandGroups
                .map((item) => item.label)
                .join('|')
        }

        // NOTE: items that have not `type` specified are regarded as `brand` also
        const brands = filters[SOV_BRANDS]?.filter(
            (item) => item.type !== 'brandGroup'
        )

        if (brands?.length) {
            params.my_brand = brands.map((item) => item.label).join('|')
        }
    }

    if (!isEmpty(filters[LABELS])) {
        params.complex_label_filter = formatBooleanFilter(
            filters[LABELS],
            SOV_KEYWORD_LABELS
        )
    }

    if (!isEmpty(filters[SOV_KEYWORDS])) {
        params.keyword__id__in = filters[SOV_KEYWORDS]?.map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[SOV_LANGUAGES])) {
        params.keyword__language_code__in = filters[SOV_LANGUAGES]?.map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[SOV_STATES])) {
        params.keyword__state__in = filters[SOV_STATES]?.map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[SOV_RESULT_TYPES])) {
        params.result_type__in = filters[SOV_RESULT_TYPES]?.map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[SOV_FOLDS]) && filters[SOV_FOLDS]) {
        // Can either be stored as a single object (legacy)
        // or an array with one object
        let filtersObj: MetricFilter
        if (isArray(filters[SOV_FOLDS])) {
            filtersObj = filters[SOV_FOLDS][0]
        } else {
            filtersObj = filters[SOV_FOLDS]
        }

        const { operator, value } = filtersObj
        if (!isNull(operator) && !isNull(value) && value) {
            let key = 'fold'
            if (operator !== EQUALS) {
                key += `__${operator}`
            }
            params[key] = value - 1 // folds are 0 indexed
        }
    }

    if (filters[SOV_RANK]?.length) {
        const { operator, value } = filters[SOV_RANK][0]
        if (!isNull(operator) && !isNull(value)) {
            let key = 'rank'
            if (operator === RANK_WORSE_THAN_OR_EQUAL) {
                key += '__gte'
            } else if (operator === RANK_WORSE_THAN) {
                key += '__gt'
            } else if (operator === RANK_BETTER_THAN_OR_EQUAL) {
                key += '__lte'
            } else if (operator !== EQUALS) {
                key += `__${operator}`
            }
            params[key] = value
        }
    }

    if (filters[SOV_LAYOUT]?.length) {
        const { operator, value } = filters[SOV_LAYOUT][0]
        if (!isNull(operator) && !isNull(value)) {
            let key = 'layout'
            if (operator !== EQUALS) {
                key += `__${operator}`
            }
            params[key] = `grid/column_${value}`
        }
    }

    if (!isEmpty(filters[SOV_ASINS])) {
        params.asin__in = filters[SOV_ASINS]?.map((item) => item.value).join()
    }

    if (!isEmpty(filters[ADVANCED_PRODUCT_TITLE])) {
        params.boolean_filter = formatBooleanFilter(
            filters[ADVANCED_PRODUCT_TITLE],
            ADVANCED_PRODUCT_TITLE
        )
    }

    if (filters[PRODUCT_NUMBER_OF_ITEMS]?.length) {
        const { operator, value } = filters[PRODUCT_NUMBER_OF_ITEMS][0]
        if (!isNull(operator) && !isNull(value)) {
            let key = 'metadata__number_of_items'
            if (operator !== EQUALS) {
                key += `__${operator}`
            }
            params[key] = value
        }
    }

    if (!isEmpty(filters[SOV_METADATA_BRANDS])) {
        params.metadata__brand__in = filters[SOV_METADATA_BRANDS]?.map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters.autoFilter) && filters[SOV_BRANDS_WEIGHT]) {
        params = {
            ...params,
            ...filters.autoFilter,
            auto_filter_limit: filters[SOV_BRANDS_LIMIT],
            auto_filter_metric:
                SOV_WEIGHTS_MAP[filters[SOV_BRANDS_WEIGHT]].metric,
        }
    }

    return params
}
