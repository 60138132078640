import { ReactNode, useState } from 'react'

import { Skeleton } from 'antd'
import classnames from 'classnames'

import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { ImageNotFoundIcon } from 'components/Icons'
import { AsinLink } from 'components/Links'
import { ToolTip } from 'components/ToolTip'
import { CountryCode } from 'types'

import * as styles from './styles.scss'

interface ProductTitleProps {
    value: ReactNode
    productImg?: string | null
    productAsin?: string
    productMarketplace?: string
    showImage?: boolean
    variant?: 'default' | 'dashboard'
    isLoading?: boolean
    numChildren?: number
    isParent?: boolean
    titleFormatter?: (value: ReactNode) => ReactNode
    parentAsinMessage?: string
    additionalLinkContent?: ReactNode
}

function ProductTitle({
    value,
    productImg,
    productAsin,
    productMarketplace,
    showImage = true,
    variant = 'default',
    isLoading = false,
    numChildren = 0,
    isParent = false,
    titleFormatter,
    parentAsinMessage,
    additionalLinkContent,
}: ProductTitleProps): ReactNode {
    const [imageOK, setImageOK] = useState(true)

    const title = titleFormatter ? titleFormatter(value) : value

    return (
        <div className={styles.container}>
            {isLoading && (
                <div className={styles.text}>
                    <div className={styles.title}>
                        <Skeleton
                            active
                            title={{
                                width: 140,
                            }}
                            paragraph={{ rows: 1 }}
                        />
                    </div>
                </div>
            )}
            {!isLoading && (
                <>
                    {showImage && (
                        <div className={styles['image-outer']}>
                            {isParent && (
                                <div
                                    className={classnames(
                                        styles['image-wrapper'],
                                        styles['stack-base'],
                                        {
                                            [styles['image-small']]:
                                                variant === 'dashboard',
                                        }
                                    )}
                                />
                            )}
                            <div
                                className={classnames(styles['image-wrapper'], {
                                    [styles['image-small']]:
                                        variant === 'dashboard',
                                })}
                            >
                                {isParent && (
                                    <ConditionalWrapper
                                        condition={
                                            parentAsinMessage !== undefined
                                        }
                                        wrapper={(children) => (
                                            <ToolTip
                                                placement="top"
                                                title={parentAsinMessage}
                                            >
                                                {children}
                                            </ToolTip>
                                        )}
                                    >
                                        <div className={styles['child-count']}>
                                            {numChildren}
                                        </div>
                                    </ConditionalWrapper>
                                )}
                                <div className={styles.image}>
                                    {productImg && imageOK ? (
                                        <img
                                            src={productImg}
                                            alt={String(title)}
                                            onError={() => setImageOK(false)}
                                        />
                                    ) : (
                                        <ImageNotFoundIcon
                                            className={
                                                styles['missing-img-icon']
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.text}>
                        <div className={styles.title}>{title}</div>
                        {productAsin && productMarketplace && (
                            <div className={styles.link}>
                                {additionalLinkContent}
                                <AsinLink
                                    asin={productAsin}
                                    countryCode={
                                        productMarketplace as CountryCode
                                    }
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default ProductTitle
