/**
 * Page Names
 */
export const AD_ACCOUNT_PAGE = 'adAccountPage'
export const AD_ACCOUNTS_SUMMARY_PAGE = 'adAccountsSummaryPage'
export const APP = 'app'
export const AD_GROUP_PAGE = 'adGroupPage'
export const AD_GROUP_KEYWORD_PAGE = 'adGroupKeywordPage'
export const ALERT_PAGE = 'alertPage'
export const ALERT_CREATION_PAGE = 'alertCreationPage'
export const ALERTS_SUMMARY_PAGE = 'alertsSummaryPage'
export const AMAZON_CAMPAIGN_CREATION_PAGE = 'amazonCampaignCreationPage'
export const AUTH_FORGOT_PASSWORD_PAGE = 'authForgotPasswordPage'
export const AUTH_INVITATION_SIGN_UP_PAGE = 'authInvitationSignupPage'
export const AUTH_ORGANIZATION_PAGE = 'authOrganizationPage'
export const AUTH_PAGE = 'authPage'
export const AUTH_RESET_PASSWORD_PAGE = 'authResetPasswordPage'
export const AUTH_SIGNUP_PAGE = 'authSignupPage'
export const AUTH_SIGN_IN_MFA_PAGE = 'authSignInMfaPage'
export const SIGN_UP_MFA_SETUP_PAGE = 'authSignUpMfaSetupPage'
export const AUTH_VERIFY_EMAIL_PAGE = 'authVerifyEmailPage'
export const AUTOMATION_PAGE = 'automationPage'
export const AUTOMATION_PROFILE_PAGE = 'automationProfilePage'
export const BRAND_CAMPAIGN_PAGE = 'brandCampaignPage'
export const BRAND_CAMPAIGN_KEYWORD_PAGE = 'brandCampaignKeywordPage'
export const BRAND_CAMPAIGN_PRODUCT_AD_PAGE = 'brandCampaignProductAdPage'
export const BULK_UPDATES_PAGE = 'bulkUpdatesPage'
export const CAMPAIGNS_SUMMARY_PAGE = 'campaignsSummaryPage'
export const CAMPAIGN_PAGE = 'campaignPage'
export const CAMPAIGN_KEYWORD_PAGE = 'campaignKeywordPage'
export const CAMPAIGN_PRODUCT_AD_PAGE = 'campaignProductAdPage'
export const EVENTS_SUMMARY_PAGE = 'eventsSummaryPage'
export const DASHBOARD_PAGE = 'dashboardPage'
export const DASHBOARDS_SUMMARY_PAGE = 'dashboardsSummaryPage'
export const DASHBOARD_PRINT_PAGE = 'dashboardPrintPage'
export const DEBUG_COLUMNS_PAGE = 'debugColumnsPage'
export const DEBUG_ICONS_PAGE = 'debugIconsPage'
export const DEBUG_METRICS_PAGE = 'debugMetricsPage'
export const DSP_ADVERTISER_PAGE = 'dspAdvertiserPage'
export const DSP_LINE_ITEM_PAGE = 'dspLineItemPage'
export const DSP_ORDER_PAGE = 'dspOrderPage'
export const DSP_SUMMARY_PAGE = 'dspSummaryPage'
export const HOME_PAGE = 'homePage'
export const INSIGHTS_PAGE = 'insightsPage'
export const KEYWORD_PAGE = 'keywordPage'
export const TARGETING_SUMMARY_PAGE = 'targetingSummaryPage'
export const LABEL_PAGE = 'labelPage'
export const LABELS_SUMMARY_PAGE = 'labelsSummaryPage'
export const METRICS_GLOSSARY_PAGE = 'metricsGlossaryPage'
export const ORGANIZATION_PAGE = 'organizationPage'
export const ORGANIZATION_GROUP_PAGE = 'organizationGroupPage'
export const PORTFOLIO_PAGE = 'portfolioPage'
export const PORTFOLIOS_SUMMARY_PAGE = 'portfoliosSummaryPage'
export const PRODUCT_PAGE = 'productPage'
export const PRODUCTS_SUMMARY_PAGE = 'productsSummaryPage'
export const PRODUCT_AD_PAGE = 'productAdPage'
export const PRODUCT_CATALOG_PAGE = 'productCatalogPage'
export const PRODUCT_PRODUCT_AD_PAGE = 'productProductAdPage'
export const REPORTS_SUMMARY_PAGE = 'reportsSummaryPage'
export const SEGMENT_PAGE = 'segmentPage'
export const SEGMENT_CREATION_PAGE = 'segmentCreationPage'
export const SEGMENT_EDIT_PAGE = 'segmentEditPage'
export const SEGMENTS_SUMMARY_PAGE = 'segmentsSummaryPage'
export const SOV_KEYWORD_PAGE = 'sovKeywordPage'
export const SOV_KEYWORD_SEARCH_RESULT_PAGE = 'sovKeywordSearchResultPage'
export const SOV_KEYWORDS_SUMMARY_PAGE = 'sovKeywordsSummaryPage'
export const RULEBOOK_PAGE = 'rulebookPage'
export const KEYWORD_RESEARCH_PAGE = 'keywordResearchPage'
export const KEYWORD_RESEARCH_LISTS_PAGE = 'keywordResearchListsPage'
export const KEYWORD_RESEARCH_LIST_DETAIL_PAGE = 'keywordResearchListDetailPage'

// Walmart Pages
export const WALMART_AD_GROUP_PAGE = 'walmartAdGroupPage'
export const WALMART_CAMPAIGNS_SUMMARY_PAGE = 'walmartCampaignsSummaryPage'
export const WALMART_CAMPAIGN_PAGE = 'walmartCampaignPage'
export const WALMART_KEYWORDS_SUMMARY_PAGE = 'walmartKeywordsSummaryPage'
export const WALMART_ITEMS_SUMMARY_PAGE = 'walmartItemsSummaryPage'
export const WALMART_CAMPAIGN_CREATION_PAGE = 'walmartCampaignCreationPage'
