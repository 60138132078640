import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import CurrencyIconSvg from './CurrencyIconSvg'

const CurrencyIcon = (props: IconProps): ReactElement => (
    <Icon component={CurrencyIconSvg} {...props} />
)

export default CurrencyIcon
