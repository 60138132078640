/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { captureException } from '@sentry/browser'
import { call, put, select } from 'redux-saga/effects'

import {
    // Mounting
    makeMountTabFailure,
    makeMountTabSuccess,

    // Data Fetching
    makeFetchTabDataSuccess,
    makeFetchTabDataFailure,
    makeFetchTabDataRequest,
    MountTabRequestAction,
    FetchTabDataRequestAction,
} from 'actions/ui/shared/tab'
import { APP } from 'const/pages'
import {
    FILTERS,
    LOCAL_FILTERS,
    FILTER_UPDATED_AT,
    LOADED_AT,
} from 'const/reducerKeys'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectDomainValue as selectUiDomainValue } from 'selectors/ui'

/**
 * Mount Tab
 */

export function* mountTabWorker(action: MountTabRequestAction) {
    const { path } = action.payload
    const { active } = action.payload.data

    try {
        const mountTabSaga = uiSagaRegistry.lookupSaga(path, 'mountTabSaga')

        if (mountTabSaga) {
            yield call(mountTabSaga, path)
        }

        if (active) {
            yield put(makeFetchTabDataRequest(path)())
        }

        yield put(makeMountTabSuccess(path)())
    } catch (error) {
        yield call(captureException, error)
        yield put(makeMountTabFailure(path)(error as Error))
    }
}

export function* fetchTabDataWorker(action: FetchTabDataRequestAction) {
    const { path } = action.payload
    const pageName = path[0]

    const appFilters: string = yield select(selectUiDomainValue, [APP, FILTERS])
    const appFilterUpdatedAt: string = yield select(selectUiDomainValue, [
        APP,
        FILTER_UPDATED_AT,
    ])

    const pageFilters: string = yield select(selectUiDomainValue, [
        pageName,
        FILTERS,
    ])
    const pageFilterUpdatedAt: string = yield select(selectUiDomainValue, [
        pageName,
        FILTER_UPDATED_AT,
    ])
    const pageLocalFilters: string = yield select(selectUiDomainValue, [
        pageName,
        LOCAL_FILTERS,
    ])
    const pageLocalFilterUpdatedAt: string = yield select(selectUiDomainValue, [
        pageName,
        FILTER_UPDATED_AT,
    ])
    const tabFilters: string = yield select(selectUiDomainValue, [
        ...path,
        FILTERS,
    ])
    const tabFilterUpdatedAt: string = yield select(selectUiDomainValue, [
        ...path,
        FILTER_UPDATED_AT,
    ])
    const tabLocalFilters: string = yield select(selectUiDomainValue, [
        ...path,
        LOCAL_FILTERS,
    ])
    const tabLocalFilterUpdatedAt: string = yield select(selectUiDomainValue, [
        ...path,
        FILTER_UPDATED_AT,
    ])
    const tabLoadedAt: string = yield select(selectUiDomainValue, [
        ...path,
        LOADED_AT,
    ])

    if (
        !tabLoadedAt ||
        (appFilters && // Ignore comparing timestamp for pages that does not have filters
            (!appFilterUpdatedAt || tabLoadedAt < appFilterUpdatedAt)) ||
        (pageFilters && // Ignore comparing timestamp for pages that does not have filters
            (!pageFilterUpdatedAt || tabLoadedAt < pageFilterUpdatedAt)) ||
        (pageLocalFilters && // Ignore comparing timestamp for pages that does not have filters
            (!pageLocalFilterUpdatedAt ||
                tabLoadedAt < pageLocalFilterUpdatedAt)) ||
        (tabFilters && // Ignore comparing timestamp for pages that does not have filters
            (!tabFilterUpdatedAt || tabLoadedAt < tabFilterUpdatedAt)) ||
        (tabLocalFilters && // Ignore comparing timestamp for pages that does not have filters
            (!tabLocalFilterUpdatedAt || tabLoadedAt < tabLocalFilterUpdatedAt))
    ) {
        try {
            const fetchTabDataSaga = uiSagaRegistry.lookupSaga(
                path,
                'fetchTabDataSaga'
            )

            if (fetchTabDataSaga) {
                yield call(fetchTabDataSaga, path)
            }

            yield put(makeFetchTabDataSuccess(path)())
        } catch (error) {
            yield call(captureException, error)
            yield put(makeFetchTabDataFailure(path)(error as Error))
        }
    }
}
