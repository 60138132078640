import {
    PropsWithChildren,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'

import { ConfigProvider, Empty } from 'antd'
import { useTranslation } from 'react-i18next'

interface EmptyTableStateProps {
    customEmptyComponent?: ReactElement
    isLoading: boolean
    width: number
    minHeight?: number
}

const TABLE_SIZE_OFFSET = 55 // account for padding inside table to make empty width match visible content
const EMPTY_DEFAULT_MIN_HEIGHT = 300

function EmptyTableState({
    isLoading,
    width,
    minHeight = EMPTY_DEFAULT_MIN_HEIGHT,
    customEmptyComponent: emptyComponent,
}: EmptyTableStateProps): ReactElement {
    const { t } = useTranslation()
    return (
        <div
            style={{
                minHeight: minHeight,
                width: '100%',
                position: 'relative',
            }}
        >
            {!isLoading && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: width - TABLE_SIZE_OFFSET,
                        minHeight: minHeight,
                    }}
                >
                    {emptyComponent ? (
                        emptyComponent
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={t(
                                'table:data.emptyStateMessage',
                                'No data. Try adjusting your filters.'
                            )}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

interface EmptyTableStateProviderProps {
    customEmptyComponent?: ReactElement
    isLoading: boolean
    minHeight?: number
}

function EmptyTableStateProvider({
    customEmptyComponent,
    isLoading,
    minHeight,
    children,
}: PropsWithChildren<EmptyTableStateProviderProps>): ReactElement {
    // uses a wrapper ref so we can approximate the width of the table
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [containerWidth, setContainerWidth] = useState(0)
    const handleResize = useCallback(() => {
        setContainerWidth(wrapperRef.current?.offsetWidth ?? 0)
    }, [wrapperRef])
    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [handleResize])

    const renderEmptyState = useCallback(
        (componentName?: string) => {
            if (componentName === 'Table') {
                return (
                    <EmptyTableState
                        customEmptyComponent={customEmptyComponent}
                        isLoading={isLoading}
                        width={containerWidth}
                        minHeight={minHeight}
                    />
                )
            }
            return null
        },
        [customEmptyComponent, isLoading, containerWidth, minHeight]
    )

    return (
        <div ref={wrapperRef}>
            <ConfigProvider renderEmpty={renderEmptyState}>
                {children}
            </ConfigProvider>
        </div>
    )
}

export default EmptyTableStateProvider
