import { memo, ReactElement } from 'react'

import { AxiosResponse } from 'axios'
import { Field, Formik, FormikHelpers } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { FormikSwitch } from 'components/formik'
import { useCerebroApiRequest } from 'hooks'
import message from 'utilities/message'

interface Props<RecordType> {
    disabled?: boolean
    fieldName?: string
    fieldPath: string[]
    record: RecordType
    serializeFieldValues?: (values: { [fieldName: string]: any }) => object
    updateRequestApi: (...args: any) => Promise<AxiosResponse>
    updateRequestData: any
    updateRequestSuccessMesg?: ReactElement | string
}

function ToggleField<RecordType>({
    disabled = false,
    fieldName = 'state',
    fieldPath = [],
    record,
    serializeFieldValues = (values) => values,
    updateRequestApi,
    updateRequestData,
    updateRequestSuccessMesg,
}: Props<RecordType>): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()

    const onSubmit = async (
        values: any,
        actions: FormikHelpers<any>
    ): Promise<void> => {
        await makeCerebroApiRequest({
            request: updateRequestApi(
                updateRequestData,
                serializeFieldValues(values)
            ),
            onRequestSuccess: () => {
                if (updateRequestSuccessMesg) {
                    message.success(updateRequestSuccessMesg)
                }
                actions.resetForm({ values })
            },
            onRequestFailure: () => {
                actions.resetForm()
            },
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                [fieldName]: get(record, fieldPath) as any,
            }}
            onSubmit={onSubmit}
        >
            {({ isSubmitting, submitForm }) => (
                <Field
                    component={FormikSwitch}
                    name={fieldName}
                    disabled={disabled || isSubmitting}
                    handlePostChange={() => {
                        submitForm()
                    }}
                    loading={isSubmitting}
                />
            )}
        </Formik>
    )
}

export default memo(ToggleField, isEqual) as typeof ToggleField
