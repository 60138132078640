import { ReactElement } from 'react'

import classNames from 'classnames'

import Stack from 'components/Layout/Stack'

import * as styles from './styles.scss'

interface Props {
    type: string
}

export default function CreateProductAdFormheader({
    type,
}: Props): ReactElement {
    return (
        <Stack direction="row" alignItems="start">
            <div
                className="fg-control-label"
                style={{ flex: 1, marginTop: 8, marginRight: 4 }}
            >
                {type}
            </div>

            <div
                className={classNames(
                    'fg-control-label',
                    styles['header-label'],
                    styles['ad-group-column']
                )}
                style={{
                    textAlign: 'center',
                    marginTop: 8,
                    marginRight: 4,
                }}
            >
                Target Ad Group
            </div>

            <div
                className={classNames(
                    'fg-control-label',
                    styles['header-label'],
                    styles['state-column']
                )}
                style={{
                    textAlign: 'center',
                    marginTop: 8,
                    marginRight: 4,
                }}
            >
                State
            </div>

            <div
                className={classNames(
                    'fg-control-label',
                    styles['header-label'],
                    styles['remove-column']
                )}
                style={{
                    textAlign: 'center',
                    marginTop: 8,
                    marginRight: 4,
                }}
            >
                Remove
            </div>
        </Stack>
    )
}
