import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import PlusIconSvg from './PlusIconSvg'

const PlusIcon = (props: IconProps): ReactElement => (
    <Icon component={PlusIconSvg} {...props} />
)

export default PlusIcon
