import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import PriceIconSvg from './PriceIconSvg'

const PriceIcon = (props: IconProps): ReactElement => (
    <Icon component={PriceIconSvg} {...props} />
)

export default PriceIcon
