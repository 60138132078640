import {
    COUNTRIES,
    DATES,
    LABELS,
    METRIC_FILTERS,
    SC_ASINS,
    REGIONS,
    SC_ACCOUNTS,
    AD_ACCOUNT_TYPES,
    ADVANCED_PRODUCT_TITLE,
    BRANDS,
    PRODUCT_TITLE,
    PRODUCT_ASINS,
    ORG_BRANDS,
} from 'const/filters'
import { PRODUCT_LABELS } from 'const/resourceTypes'
import { formatCerebroDate } from 'helpers/formatting'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { FiltersState } from 'types'

import { presetRanges, calculateFromDateWithLag } from '../dateRange'
import { formatBooleanFilter } from '../filters'

import { formatMetricFiltersForParams } from './metrics'

export interface SellerCentralFilterParams {
    product_title__icontains?: string
    report_date_min?: string
    report_date_max?: string
    seller_central_account_id__in?: string
    seller_central_account__region__in?: string
    marketplace__in?: string
    asin__in?: string
    boolean_filter?: string
    complex_label_filter?: string
    account_type__in?: string
    profile__in?: string
    metadata__brand__in?: string
}

export const formatFilters = (
    filters: FiltersState
): SellerCentralFilterParams => {
    let params: SellerCentralFilterParams = {}

    const productTitle = filters[PRODUCT_TITLE]
    if (!!productTitle && productTitle !== '') {
        params.product_title__icontains = productTitle
    }

    const dates = filters[DATES]
    if (dates) {
        if (Array.isArray(dates)) {
            params.report_date_min = formatCerebroDate(dates[0])
            params.report_date_max = formatCerebroDate(dates[1])
        } else {
            const { range } = presetRanges(
                calculateFromDateWithLag(filters.rangeLag),
                filters.rangeLag
            )[dates]
            params.report_date_min = formatCerebroDate(range[0])
            params.report_date_max = formatCerebroDate(range[1])
        }
    }

    const scAccounts = filters[SC_ACCOUNTS]
    if (!!scAccounts && scAccounts.length > 0) {
        params.seller_central_account_id__in = scAccounts
            .map((item) => item.value)
            .join()
    }

    const regions = filters[REGIONS]
    if (!!regions && regions.length > 0) {
        params.seller_central_account__region__in = regions
            .map((item) => item.value)
            .join()
    }

    const countries = filters[COUNTRIES]
    if (!!countries && countries.length > 0) {
        params.marketplace__in = countries.map((item) => item.value).join()
    }

    const scAsins = filters[SC_ASINS]
    if (!!scAsins && scAsins.length > 0) {
        params.asin__in = scAsins.map((item) => item.value).join()
    }

    const productAsins = filters[PRODUCT_ASINS]
    if (isNonEmptyArray(productAsins)) {
        params.asin__in = productAsins.map((item) => item.value).join()
    }

    const advancedProductTitle = filters[ADVANCED_PRODUCT_TITLE]
    if (!!advancedProductTitle && advancedProductTitle.length > 0) {
        params.boolean_filter = formatBooleanFilter(
            advancedProductTitle,
            ADVANCED_PRODUCT_TITLE
        )
    }

    const labels = filters[LABELS]
    if (!!labels && labels.length > 0) {
        params.complex_label_filter = formatBooleanFilter(
            labels,
            PRODUCT_LABELS
        )
    }

    const adAccountTypes = filters[AD_ACCOUNT_TYPES]
    if (!!adAccountTypes && adAccountTypes.length > 0) {
        params.account_type__in = adAccountTypes
            .map((item) => item.value)
            .join()
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (!!metricFilters && metricFilters.length > 0) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters!) }
    }

    const brands = filters[BRANDS]
    if (!!brands && brands.length > 0) {
        params.profile__in = brands!.map((item) => item.value).join()
    }

    const org_brands = filters[ORG_BRANDS]
    if (!!org_brands && org_brands.length > 0) {
        params.metadata__brand__in = org_brands.map((item) => item.value).join()
    }

    return params
}
