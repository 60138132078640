import {
    ReactElement,
    ReactNode,
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react'

import { Badge, Button, Row } from 'antd'
import classNames from 'classnames'
import some from 'lodash/some'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { signOutRequest } from 'actions/auth'
import {
    downloadReport,
    fetchReportsForDrawerRequest,
    fetchTriggeredAlertsForDrawerRequest,
    fetchUpdatesForDrawerRequest,
    toggleBulkUpdatesAppDrawer,
    toggleReportsAppDrawer,
    unmountAppDrawer,
} from 'actions/ui/app'
import { useAppConfig } from 'appConfig'
import { AppBreadcrumb } from 'components/AppBreadcrumb'
import Stack from 'components/Layout/Stack'
import { usePageContext } from 'context/PageContext'
import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { useAction, useUserHasPermissions } from 'hooks/redux'
import useElementSize from 'hooks/useElementSize'
import { selectHasRecentIntegration } from 'selectors/auth'
import { selectCurrentOrganization } from 'selectors/orgs'
import { selectGlobalNotification } from 'selectors/ui'
import {
    Breadcrumb,
    GlobalNotification,
    Organization,
    RootReduxState,
} from 'types'
import {
    GlobalNotificationBanner,
    ImpersonationWarningBanner,
    IntegrationIssuesBanner,
    RecentIntegrationBanner,
    TrialNotificationBanner,
    UserFeedbackBanner,
} from 'views/AppLayout/Banners'

import {
    BellIcon,
    InsightsIconOutlined,
    ReportIcon,
    UploadIcon,
} from '../Icons'

import { AlertsDrawer, ReportsDrawer, UpdatesDrawer } from './Drawers'
import { GetHelpMenu, ProfileMenu } from './Dropdowns'
import StickyHeader from './StickyHeader'
import * as styles from './styles.scss'

interface PageHeaderProps {
    elevated?: boolean
    color?: 'transparent' | 'white'
    breadcrumbs?: Breadcrumb[]
    titleElement?: ReactElement
    filterGroupElement?: ReactElement
    stickyHeaderElement?: ReactElement
    stickyHeaderThresholdRef?: RefObject<HTMLDivElement>
    filterOverlay?: string
}

const PageHeader = ({
    elevated = false,
    color = 'white',
    filterGroupElement,
    stickyHeaderElement,
    stickyHeaderThresholdRef,
    breadcrumbs = [],
    titleElement,
    filterOverlay,
}: PageHeaderProps): ReactElement => {
    const { t } = useTranslation('common')
    const { isChinaBuild } = useAppConfig()

    const userEmail = useSelector((state: RootReduxState) => state.auth.email)
    const globalNotification = useSelector<
        RootReduxState,
        GlobalNotification | null | undefined
    >((state: RootReduxState) => selectGlobalNotification(state))
    const hasRecentIntegration = useSelector<RootReduxState, boolean>(
        (state: RootReduxState) => selectHasRecentIntegration(state)
    )
    const currentOrganization = useSelector<
        RootReduxState,
        Organization | undefined
    >((state) => selectCurrentOrganization(state))

    const unmountDrawer = useAction(unmountAppDrawer)
    const filtersRef = useRef<HTMLDivElement>(null)

    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    // Report drawer state management
    const newReports = useSelector(
        (state: RootReduxState) => state.ui.app.newReports
    )
    const drawerReports = useSelector(
        (state: RootReduxState) => state.ui.app.drawerReports.reports
    )
    const drawerReportsLoading = useSelector(
        (state: RootReduxState) => state.ui.app.drawerReports.loading
    )
    const isReportsDrawerOpen = useSelector(
        (state: RootReduxState) => state.ui.app.drawerReports.isVisible
    )
    const toggleReportsDrawer = useAction(toggleReportsAppDrawer)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isReportsDrawerOpen) {
            dispatch(fetchReportsForDrawerRequest())
        }
    }, [dispatch, isReportsDrawerOpen])

    const handleClickReportsButton = (): void => {
        toggleReportsDrawer(!isReportsDrawerOpen)
    }
    const handleCloseReportsDrawer = (): void => {
        unmountDrawer()
        toggleReportsDrawer(false)
    }

    // Updates drawer state management
    const newUpdates = useSelector(
        (state: RootReduxState) => state.ui.app.newUpdates
    )
    const drawerUpdates = useSelector(
        (state: RootReduxState) => state.ui.app.drawerUpdates.updates
    )
    const drawerUpdatesLoading = useSelector(
        (state: RootReduxState) => state.ui.app.drawerUpdates.loading
    )
    const isUpdatesDrawerOpen = useSelector(
        (state: RootReduxState) => state.ui.app.drawerUpdates.isVisible
    )
    const toggleBulkUpdatesDrawer = useAction(toggleBulkUpdatesAppDrawer)
    useEffect(() => {
        if (isUpdatesDrawerOpen) {
            dispatch(fetchUpdatesForDrawerRequest())
        }
    }, [dispatch, isUpdatesDrawerOpen])

    const handleClickUpdatesButton = (): void => {
        toggleBulkUpdatesDrawer(!isUpdatesDrawerOpen)
    }
    const handleCloseUpdatesDrawer = (): void => {
        unmountDrawer()
        toggleBulkUpdatesDrawer(false)
    }

    // Alerts drawer state management
    const triggeredAlerts = useSelector(
        (state: RootReduxState) => state.ui.app.triggeredAlerts.results
    )
    const [isAlertsDrawerOpen, setIsAlertsDrawerOpen] = useState(false)

    const handleClickAlertsButton = (): void => {
        fetchTriggeredAlertsForDrawerRequest()
        setIsAlertsDrawerOpen((open) => !open)
    }
    const handleCloseAlertsDrawer = (): void => {
        unmountDrawer()
        setIsAlertsDrawerOpen(false)
    }

    // Sticky header
    const renderStickyHeader = (): ReactElement | null => {
        const thresholdElement = (stickyHeaderThresholdRef || filtersRef)
            .current

        if (!stickyHeaderElement || !thresholdElement) {
            return null
        }

        const scrollTop = thresholdElement.offsetTop
        return (
            <StickyHeader topOffset={scrollTop}>
                {stickyHeaderElement}
            </StickyHeader>
        )
    }

    const renderAlertsNavItem = (): ReactNode => {
        const hasUnreadAlerts = some(triggeredAlerts, ['status', 'unread'])
        return (
            <Button
                color="default"
                variant="link"
                className=" ant-btn ant-btn-link fg-text-muted"
                onClick={handleClickAlertsButton}
            >
                <Badge dot={hasUnreadAlerts}>
                    <BellIcon />
                </Badge>
                <span>{t('common:alerts', 'Alerts')}</span>
            </Button>
        )
    }

    const headerRef = useRef<HTMLDivElement>(null)
    const headerSize = useElementSize<HTMLDivElement>(headerRef)
    const { setHeaderSize } = usePageContext()

    useEffect(() => {
        setHeaderSize(headerSize)
    }, [headerSize, setHeaderSize])

    return (
        <div ref={headerRef}>
            <GlobalNotificationBanner globalNotification={globalNotification} />
            <RecentIntegrationBanner
                hasRecentIntegration={hasRecentIntegration}
            />
            <TrialNotificationBanner organization={currentOrganization} />
            <ImpersonationWarningBanner />
            <UserFeedbackBanner />
            <IntegrationIssuesBanner />
            <div
                className={classNames(styles['wrapper-position'], {
                    [styles['color-white']]: color === 'white',
                    [styles.elevated]: elevated,
                })}
                style={{ paddingLeft: 16, paddingRight: 16 }}
            >
                <Stack
                    direction="row"
                    justifyContent="between"
                    style={{
                        paddingBottom: '5px',
                        paddingTop: 12,
                    }}
                >
                    <AppBreadcrumb items={breadcrumbs} />

                    <ul className={styles['header-nav']}>
                        {!dashboardOnlyExperience && (
                            <li>
                                <NavLink
                                    className={(isActive) =>
                                        !isActive ? 'fg-text-muted' : ''
                                    }
                                    to="/insights"
                                    style={{ marginLeft: 4 }}
                                >
                                    <InsightsIconOutlined />
                                    <span style={{ marginLeft: 8 }}>
                                        Insights
                                    </span>
                                </NavLink>
                            </li>
                        )}
                        {!dashboardOnlyExperience && (
                            <li>{renderAlertsNavItem()}</li>
                        )}
                        <li>
                            <Button
                                color="default"
                                variant="link"
                                className="fg-text-muted ant-btn ant-btn-link"
                                onClick={handleClickReportsButton}
                            >
                                <ReportIcon />
                                <span>{t('common:reports', 'Reports')}</span>
                                <Badge count={newReports.length} />
                            </Button>
                        </li>
                        {!dashboardOnlyExperience && (
                            <li>
                                <Button
                                    color="default"
                                    variant="link"
                                    className="ant-btn ant-btn-link fg-text-muted"
                                    onClick={handleClickUpdatesButton}
                                >
                                    <UploadIcon />
                                    <span>
                                        {t(
                                            'common:bulkUpdates',
                                            'Bulk Updates'
                                        )}
                                    </span>
                                    <Badge count={newUpdates.length} />
                                </Button>
                            </li>
                        )}
                        {!isChinaBuild && (
                            <li>
                                <GetHelpMenu />
                            </li>
                        )}
                        <li>
                            <ProfileMenu
                                signOutRequest={signOutRequest}
                                email={userEmail || ''}
                            />
                        </li>
                    </ul>
                </Stack>
                {titleElement && <Row>{titleElement}</Row>}
                {filterGroupElement && (
                    <div ref={filtersRef} className={styles['filters-wrap']}>
                        {filterOverlay && (
                            <div className={styles['filters-overlay']}>
                                {filterOverlay}
                            </div>
                        )}

                        {filterGroupElement}
                    </div>
                )}
            </div>
            {renderStickyHeader()}
            <AlertsDrawer
                triggeredAlerts={triggeredAlerts}
                visible={isAlertsDrawerOpen}
                onClose={handleCloseAlertsDrawer}
            />
            <ReportsDrawer
                newReports={newReports}
                drawerReports={drawerReports}
                drawerReportsLoading={drawerReportsLoading}
                downloadReport={downloadReport}
                visible={isReportsDrawerOpen}
                onClose={handleCloseReportsDrawer}
            />
            <UpdatesDrawer
                drawerUpdates={drawerUpdates}
                drawerUpdatesLoading={drawerUpdatesLoading}
                visible={isUpdatesDrawerOpen}
                onClose={handleCloseUpdatesDrawer}
            />
        </div>
    )
}

export default PageHeader
