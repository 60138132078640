import { ReactElement } from 'react'

import { Button } from 'antd'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { FormikInput, FormikInputNumber } from 'components/formik'
import Stack from 'components/Layout/Stack'
import {
    AD_GROUP_BID_MIN,
    AD_GROUP_BID_PRECISION,
    AD_GROUP_BID_STEP,
} from 'const/adGroup'
import { getSPAdGroupBudgetLimits } from 'helpers/amazonAdGroup'
import { getMetricValueFormatter } from 'helpers/formatters'
import { parseCurrency } from 'helpers/inputNumber'
import { adGroupUrl } from 'helpers/urls'
import { useCerebroApiRequest } from 'hooks'
import { createAdGroup as createAmazonAdGroup } from 'services/cerebroApi/orgScope/resourceApi'
import { Campaign } from 'types'
import { getCreationShapeValidator } from 'types/schemaShapeValidators/amazonAdGroup'
import message from 'utilities/message'

import CreateResourceModal from '../CreateResourceModal/CreateResourceModal'

interface Props {
    isModalVisible: boolean
    closeModal: () => void
    amazonCampaign: Campaign
}

interface FormValues {
    name: string | null
    default_bid: number
}

const CreateAmazonAdGroupModal = ({
    isModalVisible,
    closeModal,
    amazonCampaign,
}: Props): ReactElement => {
    const { t } = useTranslation(['campaigns', 'common'])
    const history = useHistory()
    const makeCerebroApiRequest = useCerebroApiRequest()

    const createAdGroup = async (
        values: FormValues,
        resetForm: () => void
    ): Promise<void> => {
        const params = {
            ...values,
            campaign_id: amazonCampaign.id,
            state: 'enabled',
        }
        await makeCerebroApiRequest({
            request: createAmazonAdGroup(params),
            onRequestSuccess: ({ data: createdAdGroup }) => {
                message.success(
                    <span>
                        {t(
                            'campaigns:AmazonCampaignCreation.createAdGroup.successMsg',
                            'Successfully saved your Amazon Ad Group.'
                        )}
                    </span>
                )
                resetForm()
                history.push(adGroupUrl(amazonCampaign.id, createdAdGroup.id))
            },
        })
    }

    const initialValues: FormValues = {
        name: null,
        default_bid: AD_GROUP_BID_MIN,
    }

    const { min: minDefaultBid, max: maxDefaultBid } = getSPAdGroupBudgetLimits(
        amazonCampaign.profile.country_code
    )

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values: FormValues, { resetForm }) => {
                await createAdGroup(values, resetForm)
            }}
            validationSchema={getCreationShapeValidator(t)}
        >
            {({ handleSubmit, isSubmitting }) => (
                <CreateResourceModal
                    title={t(
                        'campaigns:createAdGroup.label',
                        'Create Ad Group'
                    )}
                    open={isModalVisible}
                    onCancel={closeModal}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                closeModal()
                            }}
                            disabled={isSubmitting}
                            style={{ marginRight: 4 }}
                        >
                            {t('common:cancel', 'Cancel')}
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={() => handleSubmit()}
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            {t(
                                'campaigns:createAdGroup.label',
                                'Create Ad Group'
                            )}
                        </Button>,
                    ]}
                >
                    <Form>
                        <Stack direction="row">
                            <div
                                className="fg-form-control"
                                style={{
                                    flexGrow: 1,
                                    marginRight: 12,
                                    marginBottom: 0,
                                }}
                            >
                                <div className="fg-control-label">
                                    {t(
                                        'campaigns:adGroupName',
                                        'Ad Group Name'
                                    )}
                                </div>
                                <Field name="name" component={FormikInput} />
                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="fg-control-text is-error"
                                />
                            </div>
                            <div
                                className="fg-form-control"
                                style={{ marginBottom: 0, flexGrow: 2 }}
                            >
                                <div className="fg-control-label">
                                    {t('campaigns:defaultBid', 'Default Bid')}
                                </div>
                                <Field
                                    name="default_bid"
                                    component={FormikInputNumber}
                                    formatter={getMetricValueFormatter(
                                        'currencyAmount',
                                        amazonCampaign.profile.currency_code
                                    )}
                                    parser={parseCurrency}
                                    precision={AD_GROUP_BID_PRECISION}
                                    min={minDefaultBid}
                                    max={maxDefaultBid}
                                    step={AD_GROUP_BID_STEP}
                                />
                                <ErrorMessage
                                    name="default_bid"
                                    component="div"
                                    className="fg-control-text is-error"
                                />
                            </div>
                        </Stack>
                    </Form>
                </CreateResourceModal>
            )}
        </Formik>
    )
}

export default CreateAmazonAdGroupModal
