import { ReactElement } from 'react'

import { Divider } from 'antd'

import Stack from 'components/Layout/Stack'

import { AddKeywordForm } from './AddKeywordForm'
import { AdGroupLabel, CampaignLabel, KeywordLabel } from './Labels'
import { SelectedSearchTerm } from './localTypes'
import * as styles from './styles.scss'

interface Props {
    selectedSearchTerm: SelectedSearchTerm
    closeDrawer: () => void
}

const KeywordDrawerContent = ({
    selectedSearchTerm,
    closeDrawer,
}: Props): ReactElement => (
    <>
        <Stack direction="column">
            <div className={styles.term}>{selectedSearchTerm.id}</div>
            <KeywordLabel selectedSearchTerm={selectedSearchTerm} />
            <CampaignLabel selectedSearchTerm={selectedSearchTerm} />
            <AdGroupLabel selectedSearchTerm={selectedSearchTerm} />
        </Stack>

        <Divider />

        <AddKeywordForm
            selectedSearchTerm={selectedSearchTerm}
            handleCancel={closeDrawer}
        />
    </>
)

export default KeywordDrawerContent
