import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import LockedLockIconSvg from './LockedLockIconSvg'

const LockedLockIcon = (props: IconProps): ReactElement => (
    <Icon component={LockedLockIconSvg} {...props} />
)

export default LockedLockIcon
