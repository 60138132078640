import { memo, ReactElement } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Field } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { number, object } from 'yup'

import { FormikInputNumber } from 'components/formik'
import { UNDEFINED_VALUE } from 'const/formatting'
import { invalidateCampaigns } from 'features/FactData/AmazonAds/campaignFactsQueries'
import { formatCurrency } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import { useCerebroApiRequest } from 'hooks'
import { CurrencyCode } from 'types'
import message from 'utilities/message'

import { SingleValueField } from '../SingleValueField'

interface Props<RecordType> {
    budgetMax: number
    budgetMin: number
    budgetPrecision: number
    budgetStep: number
    currencyCode: CurrencyCode
    disabled?: boolean
    fieldName?: string
    fieldPath: string[]
    readOnly?: boolean
    record: RecordType
    serializeFieldValues?: (values: {
        [fieldName: string]: number | boolean
    }) => object
    updateRequestApi: (...args: any) => Promise<AxiosResponse<any>>
    updateRequestData: any
    updateRequestSuccessMesg: string
}

function BudgetField<RecordType>({
    budgetMax,
    budgetMin,
    budgetPrecision,
    budgetStep,
    currencyCode,
    disabled = false,
    fieldName = 'budget',
    fieldPath = [],
    readOnly = false,
    record,
    serializeFieldValues = (values) => values,
    updateRequestApi,
    updateRequestData,
    updateRequestSuccessMesg,
}: Props<RecordType>): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const validationSchema = object().shape({
        [fieldName]: number()
            .min(budgetMin, 'Must be above the minimum budget.')
            .max(budgetMax, 'Must be below the maximum budget.')
            .required(),
    })

    const queryClient = useQueryClient()
    const reloadCampaigns = (): void => {
        invalidateCampaigns(queryClient)
    }

    return (
        <SingleValueField
            disabled={disabled}
            fieldName={fieldName}
            formatFieldValue={(values) =>
                isUnset(values[fieldName])
                    ? UNDEFINED_VALUE
                    : formatCurrency(values[fieldName], {
                          currencyCode,
                          decimal: true,
                      })
            }
            initialValues={{ [fieldName]: get(record, fieldPath) }}
            onSave={(values) => {
                const updatedValues = {
                    ...values,
                    updated_via_forge: true,
                }

                return makeCerebroApiRequest({
                    request: updateRequestApi(
                        updateRequestData,
                        serializeFieldValues(updatedValues)
                    ),
                    onRequestSuccess: () => {
                        message.success(updateRequestSuccessMesg)
                        reloadCampaigns()
                    },
                })
            }}
            readOnly={readOnly}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <Field
                    component={FormikInputNumber}
                    disabled={isSubmitting}
                    max={budgetMax}
                    min={budgetMin}
                    name={fieldName}
                    placeholder="Budget"
                    precision={budgetPrecision}
                    step={budgetStep}
                />
            )}
        </SingleValueField>
    )
}

export default memo(BudgetField, isEqual) as typeof BudgetField
