import { ReactElement, useState } from 'react'

import { Alert, Button, Typography } from 'antd'
import { AxiosResponse } from 'axios'
import { ErrorMessage, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { PhoneInput } from 'react-international-phone'
import { object, string } from 'yup'

import { useCerebroApiRequest } from 'hooks'
import { useAnalytics } from 'services/analytics'
import { updateUserPhoneNumber } from 'services/cerebroApi/noScope/resourceApi'

interface AddPhoneNumberFormProps {
    accessToken: string
    onPhoneNumberUpdated: (phoneNumber: string) => void
}

const AddPhoneNumberForm = (props: AddPhoneNumberFormProps): ReactElement => {
    const { t } = useTranslation('account')
    const { accessToken, onPhoneNumberUpdated } = props

    const [errorMessage, setErrorMessage] = useState<string>()
    const { trackEvent, trackCtaClick } = useAnalytics()

    const makeCerebroApiRequest = useCerebroApiRequest()

    const validationSchema = object().shape({
        phoneNumber: string()
            .required()
            .label(t('account:AddPhoneNumberForm.phoneNumber', 'Phone Number')),
    })

    async function setPhoneNumber(phoneNumber: string): Promise<void> {
        if (accessToken) {
            await makeCerebroApiRequest({
                request: updateUserPhoneNumber(phoneNumber, accessToken),
                onRequestSuccess: () => {
                    trackEvent('MFA - Phone Number Add Success', {
                        phoneNumber,
                        status: 'success',
                        text: 'Add Phone Number',
                    })
                    onPhoneNumberUpdated(phoneNumber)
                },
                onRequestFailure: (response: AxiosResponse) => {
                    if (response.status === 429) {
                        setErrorMessage(
                            t(
                                'account:AddPhoneNumberForm.tooManyAttempts',
                                'Too many attempts. Please wait a while and try again.'
                            )
                        )
                    }
                    trackEvent('MFA - Phone Number Add Error', {
                        status: 'failure',
                        text: response.data.message,
                    })
                },
                suppressErrorAlerts: true,
            })
        }
    }

    return (
        <div>
            <div>
                <h1>
                    {t(
                        'account:AddPhoneNumberForm.header',
                        'Add your phone number'
                    )}
                </h1>
                <Typography>
                    {t(
                        'account:AddPhoneNumberForm.description',
                        'For added security, please set up two-factor authentication.'
                    )}
                </Typography>
            </div>
            <div style={{ marginTop: 24 }}>
                <Formik
                    initialValues={{ phoneNumber: '' }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        trackCtaClick({
                            destination: 'Signed in - Landing page',
                            text: 'Submit',
                            type: 'button',
                            location: 'MFA - Add Phone Number',
                        })
                        setPhoneNumber(values.phoneNumber)
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form autoComplete="off">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginBottom: 8,
                                }}
                            >
                                <PhoneInput
                                    onChange={(phone: string) =>
                                        setFieldValue('phoneNumber', phone)
                                    }
                                    inputProps={
                                        {
                                            id: 'phoneNumber',
                                            name: 'phoneNumber',
                                            autoFocus: true,
                                            'data-1p-ignore': true,
                                            'data-lastpass-ignore': true,
                                        } as any
                                    }
                                />
                                <ErrorMessage
                                    name="phoneNumber"
                                    component="div"
                                    className="fg-control-text is-error"
                                />
                            </div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isSubmitting}
                                size="large"
                            >
                                {t(
                                    'account:SmsPhoneNumberForm.submitBtnText',
                                    'Submit'
                                )}
                            </Button>
                            {errorMessage && (
                                <Alert
                                    message={errorMessage}
                                    type="error"
                                    style={{ marginTop: 24 }}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default AddPhoneNumberForm
