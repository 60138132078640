import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import MoreIconSvg from './MoreIconSvg'

const MoreIcon = (props: IconProps): ReactElement => (
    <Icon component={MoreIconSvg} {...props} />
)

export default MoreIcon
