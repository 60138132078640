/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all, put } from 'redux-saga/effects'

import {
    fetchTasksRequest,
    fetchAutomationProfilesRequest,
} from 'actions/ui/campaignPage/automation'
import { CAMPAIGN_PAGE } from 'const/pages'
import uiSagaRegistry from 'sagas/ui/registry'

const TAB_PATH = [CAMPAIGN_PAGE, 'keywords']

function* mountTabSaga() {
    yield all([
        // load automation tasks and bidding profiles, so Keywords table can
        // be conditionally formatted based on dayparting settings
        put(fetchTasksRequest()),
        put(fetchAutomationProfilesRequest()),
    ])
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    mountTabSaga,
})
