import { ReactElement, CSSProperties } from 'react'

import classnames from 'classnames'

import * as styles from './styles.scss'

interface Props {
    color: string
    className?: string
    style?: CSSProperties
}

export default function LabelColor({
    color,
    className,
    style,
    ...props
}: Props): ReactElement {
    return (
        <div
            className={classnames(styles['label-color'], className)}
            style={{
                ...style,
                background: color,
            }}
            {...props}
        />
    )
}
