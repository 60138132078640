import { memo, ReactElement, useEffect, useMemo, useState } from 'react'

import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons'
import { DragDropContext, DropResult } from '@hello-pangea/dnd'
import { Button, Input, List, Modal, Select, Switch, Tooltip } from 'antd'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'
import classNames from 'classnames'
import { produce } from 'immer'
import { type WritableDraft } from 'immer/dist/types/types-external'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import sortBy from 'lodash/sortBy'
import { useTranslation } from 'react-i18next'

import { DraggableListItem, DroppableList } from 'components/DragAndDropList'
import Stack from 'components/Layout/Stack'
import MomentDatePicker from 'components/MomentDatePicker'
import { DELTA_COLORS } from 'const/colors'
import { DAY_FORMAT } from 'const/formatting'
import { reorderArray } from 'helpers/arrays'
import { elementToString } from 'helpers/utilities'
import { Field, PeriodDeltaType } from 'types'
import moment from 'utilities/moment'

import Setting from './Setting/Setting'
import * as styles from './styles.scss'

function nonFixedColumns<T>(col: Field<T>): boolean {
    return !col.antTableColumnOptions.fixed
}

interface Props<T> {
    droppableId: string
    visible: boolean
    handleCancel: () => void
    handleOk: (
        columns: Field<T>[],
        periodDeltaType: PeriodDeltaType,
        periodDeltaDateRange: string[],
        showPeriodDeltas?: boolean,
        showTotalRow?: boolean
    ) => void
    columns: Field<T>[]
    showPeriodDeltas?: boolean
    periodDeltaType?: PeriodDeltaType
    periodDeltaDateRange?: string[]
    disablePriorPeriod?: boolean
    disabledPriorPeriodMessage?: string
    showTotalRow?: boolean
    displayStateDisabled?: (column: Field<T>) => boolean
    disabledTooltip?: string | null
}

function TableSettingsModal<T>({
    droppableId,
    visible,
    handleCancel,
    handleOk,
    columns,
    showPeriodDeltas,
    periodDeltaType = 'prior_period',
    periodDeltaDateRange = [],
    disablePriorPeriod,
    disabledPriorPeriodMessage,
    showTotalRow,
    displayStateDisabled = () => false,
    disabledTooltip = null,
}: Props<T>): ReactElement {
    const { t } = useTranslation(['common', 'table'])
    const [filterValue, setFilterValue] = useState('')

    const [dirtyShowPeriodDeltas, setDirtyShowPeriodDeltas] =
        useState(showPeriodDeltas)
    const [dirtyPeriodDeltaType, setDirtyPeriodDeltaType] =
        useState(periodDeltaType)
    const [dirtyPeriodDeltaDateRange, setDirtyPeriodDeltaDateRange] =
        useState(periodDeltaDateRange)

    const [dirtyShowTotalRow, setDirtyShowTotalRow] = useState(showTotalRow)

    const selectableColumns = useMemo(
        () => columns.filter(nonFixedColumns),
        [columns]
    )

    const [selectedColumns, setSelectedColumns] = useState(
        selectableColumns.filter((element) => element.isVisible)
    )

    const [unselectedColumns, setUnselectedColumns] = useState(
        sortBy(
            selectableColumns.filter((element) => !element.isVisible),
            ['name']
        )
    )

    useEffect(() => {
        setSelectedColumns(
            selectableColumns.filter((element) => element.isVisible)
        )
        setUnselectedColumns(
            sortBy(
                selectableColumns.filter((element) => !element.isVisible),
                ['name']
            )
        )
    }, [selectableColumns])

    const filteredUnselectedColumns = unselectedColumns.filter((col) => {
        const f = filterValue.toUpperCase()
        if (!col.name) {
            return false
        }
        const columnName = elementToString(col.name)
        return columnName.toUpperCase().indexOf(f) > -1
    })

    const mergeAllColumns = (): Field<T>[] => {
        const leftFixedColumns = columns.filter(
            (col) =>
                col.antTableColumnOptions.fixed === true ||
                col.antTableColumnOptions.fixed === 'left'
        )
        const rightFixedColumns = columns.filter(
            (col) => col.antTableColumnOptions.fixed === 'right'
        )
        return [
            ...leftFixedColumns,
            ...selectedColumns,
            ...unselectedColumns,
            ...rightFixedColumns,
        ]
    }

    const handleUnselectColumn = (
        event: CheckboxChangeEvent,
        column: Field<T>
    ): void => {
        // update-visibility
        const nextColumn = produce(column, (draft) => {
            draft.isVisible = event.target.checked
        })
        // remove from array with selected columns
        setSelectedColumns(
            produce((draft) => {
                const index = draft.findIndex((col) => col.id === nextColumn.id)
                draft.splice(index, 1)
            })
        )
        // add to front of array of unselected columns
        setUnselectedColumns(
            produce((draft) => {
                draft.unshift(nextColumn as WritableDraft<Field<T>>)
            })
        )
    }

    const handleSelectColumn = (
        event: CheckboxChangeEvent,
        column: Field<T>
    ): void => {
        // update visibility
        const nextColumn = produce(column, (draft) => {
            draft.isVisible = event.target.checked
        })
        // add to back of array with selected columns
        setSelectedColumns(
            produce((draft) => {
                draft.push(nextColumn as WritableDraft<Field<T>>)
            })
        )
        // remove-from-array-with-unselected-columns
        setUnselectedColumns(
            produce((draft) => {
                const index = draft.findIndex((col) => col.id === nextColumn.id)
                draft.splice(index, 1)
            })
        )
    }

    const toggleChangeColumns = (checked: boolean): void => {
        setDirtyShowPeriodDeltas(checked)
    }

    const handleDragEnd = (result: DropResult): void => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        setSelectedColumns(
            reorderArray(
                selectedColumns,
                result.source.index,
                result.destination.index
            )
        )
    }

    return (
        <Modal
            styles={{
                body: {
                    maxHeight: 'calc(100vh - 300px)',
                    overflowY: 'scroll',
                    paddingTop: 0,
                },
            }}
            wrapClassName={styles['modal-wrap']}
            title={t(
                'table:TableSettingsModal.title',
                'Customize Table Columns'
            )}
            open={visible}
            onCancel={handleCancel}
            width={604}
            footer={[
                <Button onClick={() => handleCancel()} key={1}>
                    {t('common:cancel', 'Cancel')}
                </Button>,

                <Button
                    type="primary"
                    onClick={() =>
                        handleOk(
                            mergeAllColumns(),
                            dirtyPeriodDeltaType,
                            dirtyPeriodDeltaDateRange,
                            dirtyShowPeriodDeltas,
                            dirtyShowTotalRow
                        )
                    }
                    key={2}
                >
                    {t('common:save', 'Save')}
                </Button>,
            ]}
        >
            {!isUndefined(showPeriodDeltas) && (
                <div className={styles['change-columns']}>
                    <div>
                        <Stack direction="row" alignItems="center">
                            <Switch
                                checked={
                                    disablePriorPeriod
                                        ? false
                                        : dirtyShowPeriodDeltas
                                }
                                onChange={toggleChangeColumns}
                                disabled={disablePriorPeriod}
                            />
                            <span
                                className={classNames({
                                    [styles['text-disabled']]:
                                        disablePriorPeriod,
                                })}
                                style={{ marginLeft: 8, marginRight: 8 }}
                            >
                                {t(
                                    'table:TableSettingsModal.PeriodDeltas.title',
                                    'Compare metrics with prior period'
                                )}
                            </span>
                            <ArrowUpOutlined
                                style={{ color: DELTA_COLORS.GREEN }}
                            />
                            <ArrowDownOutlined
                                style={{ color: DELTA_COLORS.RED }}
                            />
                            {disablePriorPeriod && (
                                <Tooltip title={disabledPriorPeriodMessage}>
                                    <InfoCircleOutlined
                                        style={{ marginLeft: 8 }}
                                    />
                                </Tooltip>
                            )}
                        </Stack>
                        {dirtyShowPeriodDeltas && !disablePriorPeriod && (
                            <div className={styles['period-delta-config']}>
                                <div
                                    className={
                                        styles['period-delta-config-type']
                                    }
                                >
                                    <span>
                                        {t(
                                            'table:TableSettingsModal.PeriodDeltas.label',
                                            'Compare to:'
                                        )}
                                    </span>
                                    <Select
                                        style={{
                                            marginLeft: '10px',
                                            flex: 1,
                                        }}
                                        value={dirtyPeriodDeltaType}
                                        onChange={(value) =>
                                            setDirtyPeriodDeltaType(value)
                                        }
                                    >
                                        <Select.Option value="prior_period">
                                            {t(
                                                'table:TableSettingsModal.PeriodDeltas.options.prior_period',
                                                'Prior Period'
                                            )}
                                        </Select.Option>
                                        <Select.Option value="prior_year">
                                            {t(
                                                'table:TableSettingsModal.PeriodDeltas.options.prior_year',
                                                'Year-Over-Year'
                                            )}
                                        </Select.Option>
                                        <Select.Option value="custom">
                                            {t(
                                                'table:TableSettingsModal.PeriodDeltas.options.custom',
                                                'Custom'
                                            )}
                                        </Select.Option>
                                    </Select>
                                </div>
                                {dirtyPeriodDeltaType === 'custom' && (
                                    <MomentDatePicker.RangePicker
                                        className={
                                            styles['period-delta-date-picker']
                                        }
                                        value={
                                            dirtyPeriodDeltaDateRange.length ===
                                            2
                                                ? [
                                                      moment(
                                                          dirtyPeriodDeltaDateRange[0]
                                                      ),
                                                      moment(
                                                          dirtyPeriodDeltaDateRange[1]
                                                      ),
                                                  ]
                                                : null
                                        }
                                        onChange={(values) =>
                                            setDirtyPeriodDeltaDateRange(
                                                values
                                                    ? values.map((date) =>
                                                          date
                                                              ? date.format(
                                                                    'YYYY-MM-DD'
                                                                )
                                                              : ''
                                                      )
                                                    : []
                                            )
                                        }
                                        format={DAY_FORMAT}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    {!isUndefined(showTotalRow) && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            style={{ marginLeft: 20 }}
                        >
                            <Switch
                                checked={dirtyShowTotalRow}
                                onChange={() => setDirtyShowTotalRow((b) => !b)}
                            />
                            <span style={{ marginLeft: 8, marginRight: 8 }}>
                                {t(
                                    'table:TableSettingsModal.TotalRow.title',
                                    'Show metric total row'
                                )}
                            </span>
                        </Stack>
                    )}
                </div>
            )}

            <DragDropContext onDragEnd={handleDragEnd}>
                <DroppableList droppableId={droppableId}>
                    {selectedColumns.map((column, index) => (
                        <DraggableListItem
                            key={column.id}
                            id={column.id}
                            index={index}
                        >
                            <Setting
                                column={column}
                                displayStateDisabled={displayStateDisabled}
                                disabledTooltip={disabledTooltip}
                                onCheckboxChange={handleUnselectColumn}
                            />
                        </DraggableListItem>
                    ))}
                </DroppableList>
            </DragDropContext>

            {unselectedColumns.length > 0 && (
                <List
                    header={
                        <Input.Search
                            placeholder={t(
                                'table:TableSettingsModal.columnSearch.placeholder',
                                'Search for columns to add...'
                            )}
                            onChange={(e) => {
                                setFilterValue(e.currentTarget.value)
                            }}
                            style={{ width: '100%' }}
                            value={filterValue}
                            allowClear
                        />
                    }
                    dataSource={filteredUnselectedColumns}
                    renderItem={(item) => (
                        <List.Item>
                            <Setting<T>
                                column={item}
                                displayStateDisabled={displayStateDisabled}
                                disabledTooltip={disabledTooltip}
                                onCheckboxChange={handleSelectColumn}
                                isDraggable={false}
                            />
                        </List.Item>
                    )}
                    style={{ marginTop: 4 }}
                />
            )}
        </Modal>
    )
}

export default memo(TableSettingsModal, isEqual) as typeof TableSettingsModal
