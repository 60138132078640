import isEqual from 'lodash/isEqual'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import {
    makeMountPageRequest,
    makeUnmountPageRequest,
} from 'actions/ui/shared/page'
import { selectDomainValue } from 'selectors/ui'
import { MetadataPageState, RootReduxState } from 'types'

import useDeepCompareEffect from '../useDeepCompareEffect'

import { useFiltersDispatch } from './filters'

export function useMountingState(page: string): boolean {
    return useSelector(
        (state: RootReduxState) => selectDomainValue(state, [page, 'mounting']),
        isEqual
    )
}

export const usePageState = (page: string): MetadataPageState => ({
    loading: useSelector(
        (state: RootReduxState) => selectDomainValue(state, [page, 'loading']),
        shallowEqual
    ),
    mounting: useSelector(
        (state: RootReduxState) => selectDomainValue(state, [page, 'mounting']),
        isEqual
    ),
})

export function useMountPage(page: string, options: object = {}): void {
    const dispatch = useDispatch()
    const { resetLocalFilters } = useFiltersDispatch({ pagePath: [page] })

    useDeepCompareEffect(() => {
        resetLocalFilters()
        dispatch(makeMountPageRequest(page)(options))
        return () => dispatch(makeUnmountPageRequest(page)())
    }, [dispatch, options, page])
}
