import { ReactElement } from 'react'

import { ColorPicker, type ColorPickerProps } from 'antd'
import { Color } from 'antd/es/color-picker'
import { type PresetsItem } from 'antd/es/color-picker/interface'
import classNames from 'classnames'
import { type FieldConfig, type FormikProps } from 'formik'

import * as styles from './styles.scss'

interface FormikColorPickerProps extends ColorPickerProps {
    field: FieldConfig
    form: FormikProps<unknown>
    presetColors?: PresetsItem[]
}

const DEFAULT_PRESET_COLORS = [
    {
        label: '',
        colors: [
            '#EB144C',
            '#FF6900',
            '#FCB900',
            '#00D084',
            '#7BDCB5',
            '#0693E3',
            '#8ED1FC',
            '#9900EF',
            '#F78DA7',
            '#ABB8C3',
        ],
        defaultOpen: true,
    },
]

function FormikCustomColorPicker({
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    presetColors = DEFAULT_PRESET_COLORS,
    ...props
}: FormikColorPickerProps): ReactElement {
    const handleChangeComplete = (color: Color): void => {
        setFieldValue(name, color.toHexString())
        setFieldTouched(name, true)
    }

    return (
        <ColorPicker
            value={value}
            onChange={handleChangeComplete}
            format={'hex'}
            presets={presetColors}
            panelRender={(_, { components: { Picker, Presets } }) => (
                <div
                    className={classNames(styles['color-picker-panel'], {
                        [styles['single-preset']]:
                            (presetColors?.length ?? 0) === 1,
                    })}
                >
                    <Picker />
                    {(presetColors?.length ?? 0) > 0 && <Presets />}
                </div>
            )}
            {...props}
        >
            <span
                style={{ background: value }}
                className={styles['color-picker-trigger']}
            />
        </ColorPicker>
    )
}

export default FormikCustomColorPicker
