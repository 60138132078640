import { memo, ReactElement, ReactNode } from 'react'

import { ExclamationOutlined } from '@ant-design/icons'
import { FormikProps } from 'formik'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Buttons'
import { CheckIcon, CloseIcon } from 'components/Icons'
import Stack from 'components/Layout/Stack'
import { useJustSaved } from 'hooks'

interface Props<T> {
    field?: ReactElement
    handleSubmit: FormikProps<T>['handleSubmit']
    handleCancel: () => void
    isSubmitting: FormikProps<T>['isSubmitting']
    disableSubmit?: boolean
    isValid: FormikProps<T>['isValid']
    submitFailure: boolean
    useApplyAndCancelButtons?: boolean
    extraContent?: ReactNode
}

function SingleValueFieldBody<T>({
    field,
    handleSubmit,
    handleCancel,
    isSubmitting,
    disableSubmit = false,
    isValid,
    submitFailure,
    useApplyAndCancelButtons = true,
    extraContent,
}: Props<T>): ReactElement {
    const justSaved = useJustSaved(isSubmitting)
    const { t } = useTranslation(['common'])

    const generateIcon = (): ReactElement => {
        const justSavedIcon = submitFailure ? (
            <ExclamationOutlined />
        ) : (
            <CheckIcon />
        )
        return justSaved ? justSavedIcon : <CheckIcon />
    }

    const justFailed = justSaved && submitFailure

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center">
                {field}
                {useApplyAndCancelButtons && (
                    <>
                        <IconButton
                            size="small"
                            type={justFailed ? 'link' : 'primary'}
                            danger={justFailed}
                            style={{ marginLeft: 8 }}
                            icon={generateIcon()}
                            tooltipTitle={t('common:save', 'Save')}
                            disabled={!isValid || disableSubmit}
                            loading={isSubmitting}
                            onClick={() => handleSubmit()}
                        />
                        <IconButton
                            size="small"
                            style={{ marginLeft: 4 }}
                            icon={<CloseIcon />}
                            tooltipTitle={t('common:cancel', 'Cancel')}
                            disabled={isSubmitting}
                            onClick={handleCancel}
                        />
                    </>
                )}
            </Stack>
            {extraContent}
        </>
    )
}

export default memo(
    SingleValueFieldBody,
    isEqual
) as typeof SingleValueFieldBody
