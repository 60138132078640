import { ReactElement, ReactNode } from 'react'

import { Button, Select } from 'antd'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import noop from 'lodash/noop'
import { Moment } from 'moment-timezone'
import { useSelector } from 'react-redux'
import { date, object, string } from 'yup'

import { fetchCustomEventsRequest } from 'actions/ui/app'
import {
    FormikInput,
    FormikDatePicker,
    FormikTextArea,
    FormikSelect,
} from 'components/formik'
import { OptionalFormLabel } from 'components/OptionalFormLabel'
import {
    BrandSearchSelect,
    PortfolioSearchSelect,
    CampaignSearchSelect,
    AdGroupSearchSelect,
    KeywordSearchSelect,
    SovKeywordSearchSelect,
    ProductAdSearchSelect,
    ProductSearchSelect,
} from 'components/SearchSelect'
import { DAY_FORMAT } from 'const/formatting'
import { titleCase } from 'helpers/formatting'
import { useCerebroApiRequest, useAction } from 'hooks'
import { selectHasNoIntegrations } from 'selectors/auth'
import { createCustomEvent } from 'services/cerebroApi/orgScope/customEventsApi'
import { RootReduxState, ResourceType, Resource } from 'types'
import message from 'utilities/message'

import CreateResourceModal from '../CreateResourceModal/CreateResourceModal'

const resourceTypes: ResourceType[] = [
    'global',
    'profile',
    'portfolio',
    'campaign',
    'ad_group',
    'product',
    'product_ad',
    'keyword',
    'sov_keyword',
]

const nonAmsResourceTypes: ResourceType[] = ['global', 'sov_keyword']

interface Props {
    isModalVisible: boolean
    closeModal: () => void
    reloadData?: () => void
    resourceType?: ResourceType
    resource?: Resource
    eventDate?: Moment | null
}

interface FormValues {
    description: string
    resource_type: ResourceType
    resource_id?: string
    date: Moment | null
    notes?: string
}

const CreateCustomEventForm = ({
    isModalVisible,
    closeModal,
    reloadData = noop,
    resourceType: initialResourceType,
    resource,
    eventDate = null,
}: Props): ReactElement => {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const fetchCustomEvents = useAction(fetchCustomEventsRequest)
    const hasNoIntegrations = useSelector<RootReduxState, boolean>(
        selectHasNoIntegrations
    )
    const resourceTypeOptions = hasNoIntegrations
        ? nonAmsResourceTypes
        : resourceTypes

    const getResourceTypeLabel = (resourceType: ResourceType): string => {
        if (resourceType === 'global') {
            return 'Global (Show everywhere)'
        }
        if (resourceType === 'profile') {
            return 'Advertising Account'
        }
        return titleCase(resourceType)
    }

    const renderSearchSelect = (
        values: FormValues,
        setFieldValue: FormikHelpers<FormValues>['setFieldValue']
    ): ReactNode => {
        const { resource_type, resource_id } = values

        if (resource_type === 'global') {
            return <Select disabled showArrow={false} />
        }

        const selectProps = {
            value: resource_id,
            onChange: (id: string) => setFieldValue('resource_id', id),
        }

        if (resource_type === 'profile') {
            return <BrandSearchSelect {...selectProps} />
        }
        if (resource_type === 'portfolio') {
            return <PortfolioSearchSelect {...selectProps} />
        }
        if (resource_type === 'campaign') {
            return <CampaignSearchSelect {...selectProps} />
        }
        if (resource_type === 'ad_group') {
            return <AdGroupSearchSelect {...selectProps} />
        }
        if (resource_type === 'product') {
            return <ProductSearchSelect {...selectProps} />
        }
        if (resource_type === 'product_ad') {
            return <ProductAdSearchSelect {...selectProps} />
        }
        if (resource_type === 'keyword') {
            return <KeywordSearchSelect {...selectProps} />
        }
        if (resource_type === 'sov_keyword') {
            return <SovKeywordSearchSelect {...selectProps} />
        }

        return null
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                description: '',
                resource_type: initialResourceType ?? 'global',
                resource_id: resource ? resource.id : undefined,
                date: eventDate,
                notes: undefined,
            }}
            onSubmit={async (values: FormValues, { resetForm }) => {
                await makeCerebroApiRequest({
                    request: createCustomEvent({
                        ...values,
                        date: values.date?.format('YYYY-MM-DD'),
                        resource_type:
                            values.resource_type === 'global'
                                ? undefined
                                : values.resource_type,
                    }),
                    onRequestSuccess: () => {
                        message.success('Successfully created new Custom Event')
                        resetForm()
                        closeModal()
                        reloadData()
                        fetchCustomEvents()
                    },
                })
            }}
            validationSchema={object().shape({
                description: string().required().label('Event Description'),
                date: date().required().label('Date'),
                resource_type: string().required(),
                resource_id: string().when('resource_type', {
                    is: 'global',
                    then: (schema) => schema.nullable(),
                    otherwise: (schema) => schema.required(),
                }),
            })}
        >
            {({
                handleSubmit,
                resetForm,
                isSubmitting,
                values,
                setFieldValue,
            }) => (
                <CreateResourceModal
                    title="Add a Custom Event"
                    open={isModalVisible}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                resetForm()
                                closeModal()
                            }}
                            disabled={isSubmitting}
                            style={{ marginRight: 4 }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={() => handleSubmit()}
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            Create Custom Event
                        </Button>,
                    ]}
                    onCancel={() => {
                        resetForm()
                        closeModal()
                    }}
                >
                    <Form>
                        <div className="fg-form-control">
                            <div className="fg-control-label">
                                Event Description
                            </div>
                            <Field name="description" component={FormikInput} />
                            <ErrorMessage
                                name="description"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>

                        <div className="fg-form-control">
                            <div className="fg-control-label">
                                Associated with
                            </div>
                            {resource ? (
                                <div>{resource.label}</div>
                            ) : (
                                <>
                                    <Field
                                        value={values.resource_type}
                                        onChange={(
                                            resourceType: FormValues['resource_type']
                                        ) => {
                                            setFieldValue(
                                                'resource_type',
                                                resourceType
                                            )
                                            setFieldValue(
                                                'resource_id',
                                                undefined
                                            )
                                        }}
                                        component={FormikSelect}
                                    >
                                        {resourceTypeOptions.map(
                                            (resourceType) => (
                                                <Select.Option
                                                    value={resourceType}
                                                    key={resourceType}
                                                >
                                                    {getResourceTypeLabel(
                                                        resourceType
                                                    )}
                                                </Select.Option>
                                            )
                                        )}
                                    </Field>
                                    <div style={{ marginTop: 8 }}>
                                        {renderSearchSelect(
                                            values,
                                            setFieldValue
                                        )}
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="fg-form-control">
                            <div className="fg-control-label">Date</div>
                            <Field
                                name="date"
                                component={FormikDatePicker}
                                format={DAY_FORMAT}
                            />
                            <ErrorMessage
                                name="date"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>

                        <div>
                            <div className="fg-control-label">
                                <OptionalFormLabel fieldName="Notes" />
                            </div>
                            <Field name="notes" component={FormikTextArea} />
                        </div>
                    </Form>
                </CreateResourceModal>
            )}
        </Formik>
    )
}

export default CreateCustomEventForm
