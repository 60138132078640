/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all, takeLatest } from 'redux-saga/effects'

import {
    updateLocalFilter,
    updateFilter,
    resetFilters,
    updateFilterSettings,
    setFilters,
} from 'actions/ui/shared/filter'

import {
    resetFiltersWorker,
    updateLocalFilterWorker,
    updateFilterWorker,
    updateFilterSettingsWorker,
    setFiltersWorker,
} from './workers'

export default function* filterWatcher() {
    yield all([
        takeLatest(resetFilters.toString(), resetFiltersWorker),

        takeLatest(updateLocalFilter.toString(), updateLocalFilterWorker),

        takeLatest(updateFilter.toString(), updateFilterWorker),

        takeLatest(updateFilterSettings.toString(), updateFilterSettingsWorker),

        takeLatest(setFilters.toString(), setFiltersWorker),
    ])
}
