import { ReactElement, ReactNode } from 'react'

import classNames from 'classnames'

import * as styles from './styles.scss'

interface Props {
    errorText?: ReactNode
    style?: 'error' | 'warning' | 'info'
}

const BulkEditError = ({
    errorText = null,
    style = 'error',
}: Props): ReactElement | null =>
    errorText ? (
        <div className={classNames('pt-1', styles[style])}>{errorText}</div>
    ) : null

export default BulkEditError
