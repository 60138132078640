import { Button, notification, Space } from 'antd'
import truncate from 'lodash/truncate'
import { Trans, Translation } from 'react-i18next'

import {
    toggleBulkUpdatesAppDrawer,
    toggleReportsAppDrawer,
} from 'actions/ui/app'
import storeRegistry from 'utilities/storeRegistry'

import { TransObj } from './translation'

const top = 50
const duration = 5

export const generateReportNotification = (reportName: string): void => {
    notification.config({
        top,
        duration,
    })
    const btn = (
        <Space>
            <Button
                type="link"
                size="small"
                onClick={() => {
                    notification.destroy()
                    storeRegistry.dispatch(toggleReportsAppDrawer(true))
                }}
            >
                View Reports
            </Button>
        </Space>
    )
    notification.success({
        message: truncate(reportName),
        description: 'Your report is being generated.',
        btn,
    })
}

interface UpdateNotificationOptions {
    name: string
    count?: number
    dryRun?: boolean
}

export const generateUpdateNotification = ({
    name,
    count,
    dryRun = false,
}: UpdateNotificationOptions): void => {
    notification.config({
        top,
        duration,
    })
    let description = <span />

    if (dryRun) {
        if (count) {
            description = (
                <Trans i18nKey="table:BulkActions.pastPageSubmit.dryRun.successDesc">
                    <span>
                        Dry run update for{' '}
                        <strong>
                            {{ total: count } as TransObj}{' '}
                            {{ name } as TransObj}(s)
                        </strong>
                        .
                    </span>
                </Trans>
            )
        } else {
            description = (
                <Trans i18nKey="table:BulkActions.pastPageSubmit.dryRun.noCount.successDesc">
                    <span>
                        Dry run update for{' '}
                        <strong>{{ name } as TransObj}(s)</strong>.
                    </span>
                </Trans>
            )
        }
    } else if (count) {
        description = (
            <Trans i18nKey="table:BulkActions.pastPageSubmit.bulkUpdate.successDesc">
                <span>
                    Bulk updating{' '}
                    <strong>
                        {{ total: count } as TransObj} {{ name } as TransObj}(s)
                    </strong>
                    .
                </span>
            </Trans>
        )
    } else {
        description = (
            <Trans i18nKey="table:BulkActions.pastPageSubmit.bulkUpdate.noCount.successDesc">
                <span>
                    Bulk updating <strong>{{ name } as TransObj}(s)</strong>.
                </span>
            </Trans>
        )
    }
    const btn = (
        <Space>
            <Button
                type="link"
                size="small"
                onClick={() => {
                    notification.destroy()
                    storeRegistry.dispatch(toggleBulkUpdatesAppDrawer(true))
                }}
            >
                <Translation>
                    {(t) => t('common:viewUpdates', 'View Bulk Updates')}
                </Translation>
            </Button>
        </Space>
    )
    notification.success({
        message: dryRun ? (
            <Translation>
                {(t) =>
                    t(
                        'table:BulkActions.pastPageSubmit.dryRun.successMsg',
                        'Dry Run Started'
                    )
                }
            </Translation>
        ) : (
            <Translation>
                {(t) =>
                    t(
                        'table:BulkActions.pastPageSubmit.bulkUpdate.successMsg',
                        'Bulk Update Started'
                    )
                }
            </Translation>
        ),
        description,
        btn,
    })
}

export const generateCodeSentNotification = (phoneNumber: string): void => {
    notification.config({
        top,
        duration,
        placement: 'topRight',
    })
    notification.success({
        message: (
            <Translation>
                {(t) =>
                    t(
                        'account:LoginForm.smsCodeSentNotification.successMsg',
                        'Code Sent'
                    )
                }
            </Translation>
        ),
        description: (
            <Translation>
                {(t) =>
                    t(
                        'account:LoginForm.smsCodeSentNotification.successDesc',
                        `Your code was sent to: ${phoneNumber}`
                    )
                }
            </Translation>
        ),
    })
}
