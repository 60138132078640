/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all, takeEvery } from 'redux-saga/effects'

import { fetchDataRequest, downloadDataRequest } from 'actions/ui/shared'

import { fetchDataWorker, downloadDataWorker } from './workers'

export default function* dataWatcher() {
    yield all([
        // Data
        takeEvery(fetchDataRequest.toString(), fetchDataWorker),

        // Download
        takeEvery(downloadDataRequest.toString(), downloadDataWorker),
    ])
}
