import { Translation } from 'react-i18next'

const translations = {
    segment_product_price: {
        name: (
            <Translation>
                {(t) => t('metrics:segment_product_price.name', 'Price')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:segment_product_price.shortName', 'Price')}
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:segment_product_price.helpText',
                        "The ASIN's last known offer price"
                    )
                }
            </Translation>
        ),
    },
}

export default translations
