import { ReactElement, CSSProperties, ReactNode } from 'react'

import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { Tooltip, Divider } from 'antd'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import Stack from 'components/Layout/Stack'
import { DELTA_COLORS } from 'const/colors'
import { UNDEFINED_VALUE } from 'const/formatting'
import { ChildField, Field } from 'types'

import * as styles from './styles.scss'

interface Props<RecordType> {
    parent: Field<RecordType>
    record: RecordType
    columns: Field<RecordType>[]
    rowIndex: number
    reloadData: (options?: { noCount?: boolean }) => void
    readonly: boolean
    shortFormat: boolean
    updateColumns: (columns: Field<RecordType>[]) => void
    isCollapsed: boolean
    updateRecord: (data: { rowIndex: number; record: RecordType }) => void
    isTotalCell?: boolean
}

interface Metric<RecordType> {
    child: ChildField<RecordType>
    value: number | string
    isInverse: boolean
}

function ToggleMetricDeltasCell<RecordType>({
    parent,
    record,
    columns,
    rowIndex,
    reloadData,
    readonly,
    shortFormat,
    updateColumns,
    isCollapsed,
    updateRecord,
    isTotalCell,
}: Props<RecordType>): ReactElement | null {
    const { t } = useTranslation('table')
    const getChildValue = (child: ChildField<RecordType>): number | string => {
        if (!child.dataIndex) {
            return UNDEFINED_VALUE
        }

        let targetIndex = child.dataIndex
        if (isTotalCell) {
            const splitName = targetIndex.slice(-1)[0].split('__')
            const [periodName, ...mainName] = [splitName.pop(), ...splitName]
            targetIndex = `${mainName.join('__')}__total__${periodName}`
        }

        return get(record, targetIndex, UNDEFINED_VALUE)
    }

    const renderDelta = ({
        value,
        child,
    }: Metric<RecordType>): string | ReactNode => {
        const { renderOptions, metricOptions } = child
        if (renderOptions) {
            return renderOptions.render({
                columns,
                value,
                record,
                rowIndex,
                reloadData,
                readonly,
                shortFormat,
                updateColumns,
                updateRecord,
                isTotalCell,
            })
        }
        if (metricOptions) {
            return metricOptions.render({
                columns,
                value,
                record,
                rowIndex,
                reloadData,
                readonly,
                shortFormat,
                updateColumns,
                updateRecord,
                isTotalCell,
            })
        }
        return `${value}`
    }

    if (!isCollapsed) {
        return null
    }

    const priorPeriodChildField = parent.childrenFields?.find(
        (child) => child.metricOptions?.isPriorPeriod
    )

    if (!priorPeriodChildField) {
        return null
    }

    const priorPeriod: Metric<RecordType> = {
        child: priorPeriodChildField,
        value: getChildValue(priorPeriodChildField),
        isInverse: priorPeriodChildField.metricOptions?.isInverse ?? false,
    }

    if (priorPeriod.value === UNDEFINED_VALUE) {
        return null
    }

    const deltas: Metric<RecordType>[] =
        parent.childrenFields?.reduce(
            (accumulator: Metric<RecordType>[], child) => {
                if (child.metricOptions?.isDelta) {
                    accumulator.push({
                        child,
                        value: getChildValue(child),
                        isInverse: child.metricOptions?.isInverse ?? false,
                    })
                }
                return accumulator
            },
            []
        ) ?? []

    const colors = parent.metricOptions?.isInverse
        ? [DELTA_COLORS.RED, DELTA_COLORS.GREEN]
        : [DELTA_COLORS.GREEN, DELTA_COLORS.RED]

    const style: CSSProperties = {
        fontSize: 12.6,
        color: Number(deltas[0]?.value ?? 0) > 0 ? colors[0] : colors[1],
    }

    const renderIcon = (): ReactElement =>
        Number(deltas[0]?.value ?? 0) > 0 ? (
            <ArrowUpOutlined style={style} />
        ) : (
            <ArrowDownOutlined style={style} />
        )

    // Show nothing if deltas is null or zero
    if (!deltas[0]?.value) {
        return null
    }

    const deltasRendered = deltas.reduce(
        (accumulator: ReactNode[], delta: Metric<RecordType>, index, array) => {
            accumulator.push(
                <div key={`${index}-delta`}>{renderDelta(delta)}</div>
            )
            if (array.length - 1 > index) {
                accumulator.push(
                    <Divider key={`${index}-divider`} type="vertical" />
                )
            }
            return accumulator
        },
        []
    )

    return (
        <Tooltip
            title={
                <>
                    <div className="delta-title">
                        {typeof parent.antTableColumnOptions.title ===
                        'function'
                            ? parent.antTableColumnOptions.title({}) // Does this need more params passed through?
                            : parent.antTableColumnOptions.title}
                    </div>
                    <Stack
                        direction="row"
                        alignItems="center"
                        style={{ flexWrap: 'nowrap' }}
                    >
                        {deltasRendered}
                    </Stack>
                    <div className="fg-text-light" style={{ marginTop: 8 }}>
                        {t(
                            'table:PeriodDeltas.vsPriorPeriod',
                            'vs prior period'
                        )}
                    </div>
                    <div className="fg-text-muted" style={{ marginTop: 4 }}>
                        {renderDelta(priorPeriod)}
                    </div>
                </>
            }
            placement="bottom"
            overlayClassName={styles['delta-tooltip']}
        >
            {renderIcon()}
        </Tooltip>
    )
}

export default ToggleMetricDeltasCell
