/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { array, number, object, string } from 'yup'

import { DISABLED, ENABLED } from 'const/resourceStates'
import {
    AUTO_TARGETING,
    WALMART_AD_ITEM_BID_MAX,
    WALMART_AD_ITEM_BID_MIN,
} from 'const/walmart'

import { WalmartTargetingType } from '../resources/walmart'

const getBaseSchema = (targetingType: WalmartTargetingType) => {
    return object().shape({
        itemId: string().required(),

        state: string()
            .label('State')
            .matches(new RegExp(`${ENABLED}|${DISABLED}`))
            .required(),
        ...(targetingType === AUTO_TARGETING && {
            bid: number()
                .label('Ad Item Bid (CPC)')
                .max(WALMART_AD_ITEM_BID_MAX)
                .min(WALMART_AD_ITEM_BID_MIN)
                .nullable()
                .required(),
        }),
    })
}

export const getBulkCreationShapeValidatorForAdGroup = (
    targetingType: WalmartTargetingType
) => {
    const baseSchema = getBaseSchema(targetingType)

    return object().shape({
        adItems: array().of(baseSchema).min(1).required(),
    })
}

export const getBulkCreationShapeValidatorForCampaign = (
    targetingType: WalmartTargetingType
) => {
    const extendedSchema = getBaseSchema(targetingType).shape({
        adGroupId: string().label('Ad Group Target').required(),
    })

    return object().shape({
        adItems: array().of(extendedSchema).min(1).required(),
    })
}
