/* eslint-disable @typescript-eslint/explicit-function-return-type */
import isEmpty from 'lodash/isEmpty'
import { Action } from 'redux-actions'
import { call, put, select } from 'redux-saga/effects'

import { makeUpdateFilter, makeUpdateLocalFilter } from 'actions/ui/shared'
import { makeExportWidgetToCsvSuccess } from 'actions/ui/shared/dashboard'
import { DATA_SOURCES, PAGE_DASHBOARD_CONFIG } from 'configuration/widgets'
import { DATES, RANGE_LAG } from 'const/filters'
import { DASHBOARD_PAGE, HOME_PAGE } from 'const/pages'
import { getFiltersHierarchy } from 'helpers/filters/dashboards'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatCurrency,
    formatPagination,
    formatPeriodDeltaDateRange,
    formatSorter,
    formatStringMetrics,
} from 'helpers/params'
import {
    formatAutoFilters,
    formatGoals,
    formatWidgetGroupBy,
    getWidgetExportApiFunc,
    getWidgetGroupBys,
    getWidgetMetrics,
} from 'helpers/ui/dashboardPage'
import { cerebroApiSaga } from 'sagas/common'
import {
    selectCurrencyCode,
    selectDomainValue as selectUiDomainValue,
    selectLocalFilters,
    selectVisibleCombinedFilters,
    selectVisibleFilters,
    selectWidgetTab,
} from 'selectors/ui'
import {
    CurrencyCode,
    DashboardTab,
    FiltersState,
    ModifiedWidget,
    PeriodDeltaType,
    Widget,
    WidgetFilterMap,
} from 'types'

function* getFilterConfiguration(pageName: string, widget: Widget) {
    // Cleaning up old page filters since they aren't needed anymore
    // After DOW-10221, the correct filters now come from tabs
    if (pageName === DASHBOARD_PAGE || pageName === HOME_PAGE) {
        const path = [pageName]

        const pageFilters: Partial<FiltersState> = yield select(
            selectVisibleFilters,
            path
        )
        if (!isEmpty(pageFilters?.[DATES])) {
            const updateFilter = makeUpdateFilter(path)
            yield put(updateFilter({ key: DATES, value: [] }))
        }

        const pageLocalFilters: Partial<FiltersState> = yield select(
            selectLocalFilters,
            path
        )
        if (!isEmpty(pageLocalFilters?.[DATES])) {
            const updateFilter = makeUpdateLocalFilter(path)
            yield put(updateFilter({ key: DATES, value: [] }))
        }
    }

    const tab: DashboardTab = yield select(selectWidgetTab, pageName, widget.id)
    const filters: WidgetFilterMap = yield select(
        selectVisibleCombinedFilters,
        [pageName, 'tabs', tab?.id]
    )

    const { result: hierarchy } = getFiltersHierarchy(
        filters || {},
        widget.filters,
        widget.data_source
    )

    Object.keys(hierarchy).forEach((filter) => {
        filters[filter] = hierarchy[filter].values
    })

    const formatFiltersFunc =
        DATA_SOURCES[widget.data_source]?.formatFiltersFunc ?? (() => ({}))

    const datePrefix = DATA_SOURCES[widget.data_source]?.datePrefix

    const { formatPageFiltersFunc } = PAGE_DASHBOARD_CONFIG[pageName]

    return {
        formatFiltersFunc,
        formatPageFiltersFunc,
        datePrefix,
        filters,
    }
}

export function* exportWidgetToCsvWorker(
    action: Action<{ pageName: string; data: { widgetId: string } }>
) {
    const {
        pageName,
        data: { widgetId },
    } = action.payload
    const widget: Widget = yield select(selectUiDomainValue, [
        pageName,
        'widgets',
        widgetId,
    ])
    const dashboardCurrency: CurrencyCode = yield select(selectUiDomainValue, [
        pageName,
        'currencyCode',
    ])
    const currency: CurrencyCode = yield select(selectCurrencyCode)
    const exportApiFunc = getWidgetExportApiFunc(widget)
    const metrics = getWidgetMetrics(widget)
    const groupBys = getWidgetGroupBys(widget)

    const { formatFiltersFunc, formatPageFiltersFunc, datePrefix, filters } =
        yield getFilterConfiguration(pageName, widget)

    const params = {
        ...formatStringMetrics(metrics),
        ...formatPageFiltersFunc(filters, datePrefix),
        ...formatFiltersFunc(filters),
        ...formatPagination(widget.pagination),
        ...formatSorter(widget.sorter),
        ...formatCurrency(dashboardCurrency || currency),
        ...formatWidgetGroupBy(
            { ...widget, group_by: groupBys } as unknown as ModifiedWidget,
            filters
        ),
        ...formatAutoFilters(widget),
        ...formatPeriodDeltaDateRange(
            !!widget.include_change_metrics,
            (widget.period_delta_type ?? 'prior_period') as PeriodDeltaType,
            widget.period_delta_date_range ?? [],
            filters[DATES],
            datePrefix,
            filters[RANGE_LAG]
        ),
        ...formatGoals(widget),
        async_download_name: widget.name,
    }

    yield call(
        cerebroApiSaga,
        makeExportWidgetToCsvSuccess(pageName),
        exportApiFunc,
        params
    )

    generateReportNotification(widget.name)
}
