import { useEffect } from 'react'

import { isArray } from 'lodash'
import isEmpty from 'lodash/isEmpty'

import { DATES, RANGE_LAG } from 'const/filters'
import { DateRangeWithLagUtil } from 'features/Filters/components/BaseFilterControls/DateRangeControl/DateRangeWithLagUtil'
import {
    calculateFromDateWithLag,
    getFormattedDateRange,
    presetRanges,
    twoSaturdaysAgoInHours,
} from 'helpers/dateRange'
import { useFiltersDispatch } from 'hooks'
import { DashboardTab, FiltersState } from 'types'
import message from 'utilities/message'

interface LagAdjustedDateRangesHookProps {
    pagePath: string[]
    tabPath: string[]
    tab: DashboardTab | null
    tabLocalFilters: FiltersState
    notifyOnChange?: boolean
}

interface LagAdjustedDateRangesHookResult {
    customRangeLag?: number
}

export function useLagAdjustedDateRanges({
    pagePath,
    tabPath,
    tab,
    tabLocalFilters,
    notifyOnChange = true,
}: LagAdjustedDateRangesHookProps): LagAdjustedDateRangesHookResult {
    const { updateTabLocalFilter } = useFiltersDispatch({
        pagePath,
        tabPath,
    })

    const widgetsDS = tab?.widgets
        .filter(
            (widget) => !widget.filters[DATES] || isEmpty(widget.filters[DATES])
        )
        .map((widget) => widget.data_source)

    const newBiggestDSLag =
        DateRangeWithLagUtil.getDatasourceWithBiggestLag(widgetsDS)

    const shelfWidgetsWithESV = tab?.widgets.filter((widget) => {
        const noDateFilter =
            !widget.filters[DATES] || isEmpty(widget.filters[DATES])

        const isSovSearchResults = widget.data_source === 'sov_search_results'

        const includesESV = widget.metrics.some(
            (metric) => metric.id === 'estimated_search_volume'
        )

        return noDateFilter && isSovSearchResults && includesESV
    })

    const customRangeLag = shelfWidgetsWithESV?.length
        ? twoSaturdaysAgoInHours()
        : undefined

    useEffect(() => {
        if (
            !tabLocalFilters.dates ||
            isArray(tabLocalFilters.dates) ||
            !DateRangeWithLagUtil.includePreset.includes(tabLocalFilters.dates)
        ) {
            return
        }

        let newLag
        if (
            customRangeLag !== undefined &&
            customRangeLag !== tabLocalFilters.rangeLag
        ) {
            newLag = customRangeLag
        } else if (
            customRangeLag === undefined &&
            newBiggestDSLag !== null &&
            newBiggestDSLag.lag !== tabLocalFilters.rangeLag
        ) {
            newLag = newBiggestDSLag.lag
        }

        if (newLag !== undefined) {
            const title = presetRanges()[tabLocalFilters.dates].name
            const prevDateFormatted = getFormattedDateRange(
                tabLocalFilters.dates,
                calculateFromDateWithLag(tabLocalFilters.rangeLag),
                true,
                tabLocalFilters.rangeLag
            )
            const newDateRangeFormatted = getFormattedDateRange(
                tabLocalFilters.dates,
                calculateFromDateWithLag(newLag),
                true,
                newLag
            )

            if (notifyOnChange) {
                message.info(
                    <>
                        Tab-Level Filter: {title} changed from{' '}
                        <s>{prevDateFormatted}</s> to {newDateRangeFormatted}
                    </>
                )
            }

            updateTabLocalFilter({ key: RANGE_LAG, value: newLag })
        }
    }, [
        customRangeLag,
        newBiggestDSLag,
        tabLocalFilters.dates,
        tabLocalFilters.rangeLag,
        updateTabLocalFilter,
        notifyOnChange,
    ])

    return { customRangeLag }
}
