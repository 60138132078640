import { profileLabelIdRenderer } from 'components/FieldRenderers/Renderers/brand'
import { adGroupId } from 'configuration/fieldCreators/adGroup'
import {
    brandCountryCode,
    brandId,
    brandLabelId,
    brandRegion,
    brandType,
} from 'configuration/fieldCreators/brand'
import {
    campaignAdFormat,
    campaignCapabilityId,
    campaignId,
    campaignLabelId,
    campaignState,
    campaignTargetingType,
    campaignType,
} from 'configuration/fieldCreators/campaign'
import {
    keywordId,
    keywordMatchType,
    keywordState,
} from 'configuration/fieldCreators/keyword'
import {
    acos,
    amazon_sales,
    aov,
    attributed_branded_searches_14_day__sum,
    attributed_conversions_14_day__sum,
    attributed_conversions_14_day_same_sku__sum,
    attributed_conversions_1_day__sum,
    attributed_conversions_1_day_same_sku__sum,
    attributed_conversions_30_day__sum,
    attributed_conversions_30_day_same_sku__sum,
    attributed_conversions_7_day__sum,
    attributed_conversions_7_day_same_sku__sum,
    attributed_detail_page_views_14_day__sum,
    attributed_detail_page_views_clicks_14_day__sum,
    attributed_order_rate_new_to_brand_14_day,
    attributed_orders_new_to_brand_14_day__sum,
    attributed_orders_new_to_brand_percentage_14_day,
    attributed_sales_14_day__sum,
    attributed_sales_14_day_same_sku__sum,
    attributed_sales_14_day_same_sku_rate,
    attributed_sales_1_day__sum,
    attributed_sales_1_day_same_sku__sum,
    attributed_sales_30_day__sum,
    attributed_sales_30_day_same_sku__sum,
    attributed_sales_7_day__sum,
    attributed_sales_7_day_same_sku__sum,
    attributed_sales_new_to_brand_14_day__sum,
    attributed_sales_new_to_brand_percentage_14_day,
    attributed_units_ordered_14_day__sum,
    attributed_units_ordered_14_day_same_sku__sum,
    attributed_units_ordered_1_day__sum,
    attributed_units_ordered_1_day_same_sku__sum,
    attributed_units_ordered_30_day__sum,
    attributed_units_ordered_30_day_same_sku__sum,
    attributed_units_ordered_7_day__sum,
    attributed_units_ordered_7_day_same_sku__sum,
    attributed_units_ordered_new_to_brand_14_day__sum,
    attributed_units_ordered_new_to_brand_percentage_14_day,
    attributed_units_sold_14_day__sum,
    cac,
    clicks__sum,
    combined_aov,
    combined_attributed_sales_14_day__sum,
    combined_conversion_rate_clicks,
    combined_conversion_rate_impressions,
    combined_conversions_14_day__sum,
    combined_cpa,
    combined_impressions__sum,
    conversion_rate_clicks,
    conversion_rate_impressions,
    cost__sum,
    cpa,
    cpc,
    cpm,
    ctr,
    impressions__sum,
    roas,
    roas_same_sku,
    roasp,
    spc,
    spend,
    spend__daily_avg,
    spend__daily_avg_forecast,
    spend__daily_avg_forecast_difference_from_goal,
    spend__daily_avg_forecast_pct_difference_from_goal,
    spend__difference_from_goal,
    spend__difference_from_goal_per_day,
    spend__goal,
    spend__pct_difference_from_goal,
    spend__t3_daily_avg,
    spend__t3_daily_avg_forecast,
    spend__t3_daily_avg_forecast_difference_from_goal,
    spend__t3_daily_avg_forecast_pct_difference_from_goal,
    spend__t7_daily_avg,
    spend__t7_daily_avg_forecast,
    spend__t7_daily_avg_forecast_difference_from_goal,
    spend__t7_daily_avg_forecast_pct_difference_from_goal,
    spm,
    video_complete_views__sum,
    video_first_quartile_views__sum,
    video_midpoint_views__sum,
    video_third_quartile_views__sum,
    video_unmutes__sum,
    view_attributed_branded_searches_14_day__sum,
    view_attributed_conversions_14_day__sum,
    view_attributed_detail_page_view_14_day__sum,
    view_attributed_orders_new_to_brand_14_day__sum,
    view_attributed_sales_14_day__sum,
    view_attributed_sales_new_to_brand_14_day__sum,
    view_attributed_units_ordered_14_day__sum,
    view_attributed_units_ordered_new_to_brand_14_day__sum,
    view_impressions__sum,
} from 'configuration/fieldCreators/metrics/ams'
import {
    product_cpu,
    product_cpu_goal,
    product_cpu_goal_delta,
    product_cpu_goal_performanceIndex,
    product_acos_goal,
    product_acos_goal_delta,
    product_acos_goal_performanceIndex,
    product_total_cpu_goal,
    product_tacos_goal,
} from 'configuration/fieldCreators/metrics/goals'
import {
    gross_profit_14_day_same_sku,
    gross_profit_1_day_same_sku,
    gross_profit_30_day_same_sku,
    gross_profit_7_day_same_sku,
    gross_profit_margin_14_day_same_sku,
    gross_profit_margin_1_day_same_sku,
    gross_profit_margin_30_day_same_sku,
    gross_profit_margin_7_day_same_sku,
    misc_cogs_weighted,
    raw_cogs_weighted,
    supplier_cogs_weighted,
    total_cogs_weighted,
} from 'configuration/fieldCreators/metrics/productAds'
import { placementText } from 'configuration/fieldCreators/placement'
import {
    portfolioId,
    portfolioLabelId,
} from 'configuration/fieldCreators/portfolio'
import { productAdId } from 'configuration/fieldCreators/product'
import { query } from 'configuration/fieldCreators/query'
import { Field, GoalOption } from 'types'

import { makeMapping } from './helpers'

export const TARGET_SUPPORTED_AMS_METRICS_COLUMNS_PAGES = [
    impressions__sum(),
    clicks__sum({ isVisible: true }),
    cost__sum({ isVisible: true }),
    roas(),
    roas_same_sku(),
    roasp(),
    acos({ isVisible: true }),
    spc(),
    cpc(),
    cpa(),
    cpm(),
    spm(),
    aov(),
    ctr(),
    conversion_rate_clicks(),
    conversion_rate_impressions(),
    attributed_conversions_14_day__sum(),
    attributed_conversions_14_day_same_sku__sum(),
    amazon_sales(),
    attributed_branded_searches_14_day__sum(),
    attributed_sales_14_day__sum({ isVisible: true }),
    attributed_sales_14_day_same_sku__sum(),
    attributed_units_ordered_14_day__sum(),
    attributed_units_ordered_14_day_same_sku__sum(),
    attributed_units_sold_14_day__sum(),
    attributed_orders_new_to_brand_14_day__sum(),
    attributed_orders_new_to_brand_percentage_14_day(),
    attributed_order_rate_new_to_brand_14_day(),
    attributed_sales_new_to_brand_14_day__sum(),
    attributed_sales_new_to_brand_percentage_14_day(),
    attributed_units_ordered_new_to_brand_14_day__sum(),
    attributed_units_ordered_new_to_brand_percentage_14_day(),
    attributed_detail_page_views_14_day__sum(),
    attributed_detail_page_views_clicks_14_day__sum(),
    attributed_sales_14_day_same_sku_rate(),
    view_impressions__sum(),
    view_attributed_conversions_14_day__sum(),
    view_attributed_units_ordered_14_day__sum(),
    view_attributed_sales_14_day__sum(),
    view_attributed_detail_page_view_14_day__sum(),
    view_attributed_orders_new_to_brand_14_day__sum(),
    view_attributed_sales_new_to_brand_14_day__sum(),
    view_attributed_units_ordered_new_to_brand_14_day__sum(),
    view_attributed_branded_searches_14_day__sum(),
    spend(),
    spend__daily_avg(),
    spend__t3_daily_avg(),
    spend__t7_daily_avg(),
    spend__daily_avg_forecast(),
    spend__t3_daily_avg_forecast(),
    spend__t7_daily_avg_forecast(),
    video_complete_views__sum(),
    video_first_quartile_views__sum(),
    video_midpoint_views__sum(),
    video_third_quartile_views__sum(),
    video_unmutes__sum(),
]

export const AMS_PACING_GOAL_COLUMNS = [
    spend__difference_from_goal(),
    spend__pct_difference_from_goal(),
    spend__difference_from_goal_per_day(),
    spend__goal(),
    spend__daily_avg_forecast_difference_from_goal(),
    spend__daily_avg_forecast_pct_difference_from_goal(),
    spend__t3_daily_avg_forecast_difference_from_goal(),
    spend__t3_daily_avg_forecast_pct_difference_from_goal(),
    spend__t7_daily_avg_forecast_difference_from_goal(),
    spend__t7_daily_avg_forecast_pct_difference_from_goal(),
]

export const AMS_METRICS_COLUMNS_PAGES = [
    ...TARGET_SUPPORTED_AMS_METRICS_COLUMNS_PAGES,
    attributed_conversions_1_day__sum(),
    attributed_conversions_1_day_same_sku__sum(),
    attributed_conversions_7_day__sum(),
    attributed_conversions_7_day_same_sku__sum(),
    attributed_conversions_30_day__sum(),
    attributed_conversions_30_day_same_sku__sum(),
    attributed_sales_1_day__sum(),
    attributed_sales_1_day_same_sku__sum(),
    attributed_sales_7_day__sum(),
    attributed_sales_7_day_same_sku__sum(),
    attributed_sales_30_day__sum(),
    attributed_sales_30_day_same_sku__sum(),
    attributed_units_ordered_1_day__sum(),
    attributed_units_ordered_1_day_same_sku__sum(),
    attributed_units_ordered_7_day__sum(),
    attributed_units_ordered_7_day_same_sku__sum(),
    attributed_units_ordered_30_day__sum(),
    attributed_units_ordered_30_day_same_sku__sum(),
]

const STREAM_SUPPORTED_AMS_METRICS_COLUMNS = [
    impressions__sum({ isVisible: true }),
    clicks__sum({ isVisible: true }),
    cost__sum({ isVisible: true }),
    cpc({ isVisible: true }),
    ctr({ isVisible: true }),
    acos({ isVisible: true }),
    roas({ isVisible: true }),
    cac({ isVisible: true }),
    attributed_conversions_14_day__sum({ isVisible: true }),
    attributed_conversions_14_day_same_sku__sum({ isVisible: true }),
    attributed_conversions_1_day__sum({ isVisible: true }),
    attributed_conversions_1_day_same_sku__sum({ isVisible: true }),
    attributed_conversions_30_day__sum({ isVisible: true }),
    attributed_conversions_30_day_same_sku__sum({ isVisible: true }),
    attributed_conversions_7_day__sum({ isVisible: true }),
    attributed_conversions_7_day_same_sku__sum({ isVisible: true }),
    attributed_sales_14_day__sum({ isVisible: true }),
    attributed_sales_14_day_same_sku__sum({ isVisible: true }),
    attributed_sales_1_day__sum({ isVisible: true }),
    attributed_sales_1_day_same_sku__sum({ isVisible: true }),
    attributed_sales_30_day__sum({ isVisible: true }),
    attributed_sales_30_day_same_sku__sum({ isVisible: true }),
    attributed_sales_7_day__sum({ isVisible: true }),
    attributed_sales_7_day_same_sku__sum({ isVisible: true }),
    attributed_units_ordered_14_day__sum({ isVisible: true }),
    attributed_units_ordered_14_day_same_sku__sum({
        isVisible: true,
    }),
    attributed_units_ordered_1_day__sum({ isVisible: true }),
    attributed_units_ordered_1_day_same_sku__sum({
        isVisible: true,
    }),
    attributed_units_ordered_30_day__sum({ isVisible: true }),
    attributed_units_ordered_30_day_same_sku__sum({
        isVisible: true,
    }),
    attributed_units_ordered_7_day__sum({ isVisible: true }),
    attributed_units_ordered_7_day_same_sku__sum({
        isVisible: true,
    }),
]

const AMS_COMMON_METRICS = [
    clicks__sum({ isVisible: true }),
    impressions__sum({ isVisible: true }),
    cost__sum({ isVisible: true }),
    roas({ isVisible: true }),
    roas_same_sku({ isVisible: true }),
    roasp({ isVisible: true }),
    acos({ isVisible: true }),
    spc({ isVisible: true }),
    cpc({ isVisible: true }),
    cpa({ isVisible: true }),
    cpm({ isVisible: true }),
    spm({ isVisible: true }),
    aov({ isVisible: true }),
    ctr({ isVisible: true }),
    conversion_rate_clicks({ isVisible: true }),
    conversion_rate_impressions({ isVisible: true }),
    attributed_conversions_14_day__sum({ isVisible: true }),
    attributed_conversions_14_day_same_sku__sum({ isVisible: true }),
    attributed_sales_14_day__sum({ isVisible: true }),
    amazon_sales({ isVisible: true }),
    attributed_sales_14_day_same_sku__sum({ isVisible: true }),
    attributed_units_ordered_14_day__sum({ isVisible: true }),
    attributed_units_ordered_14_day_same_sku__sum({
        isVisible: true,
    }),
    attributed_units_sold_14_day__sum({ isVisible: true }),
    attributed_orders_new_to_brand_14_day__sum({ isVisible: true }),
    attributed_orders_new_to_brand_percentage_14_day({
        isVisible: true,
    }),
    attributed_order_rate_new_to_brand_14_day({ isVisible: true }),
    attributed_sales_new_to_brand_14_day__sum({ isVisible: true }),
    attributed_sales_new_to_brand_percentage_14_day({
        isVisible: true,
    }),
    attributed_units_ordered_new_to_brand_14_day__sum({
        isVisible: true,
    }),
    attributed_units_ordered_new_to_brand_percentage_14_day({
        isVisible: true,
    }),
    attributed_detail_page_views_14_day__sum({ isVisible: true }),
    attributed_detail_page_views_clicks_14_day__sum({
        isVisible: true,
    }),
    attributed_sales_14_day_same_sku_rate({
        isVisible: true,
    }),
    spend({
        isVisible: true,
    }),
]

const AMS_COMMON_PACING_METRICS: Field<unknown>[] = [
    spend__difference_from_goal({
        isVisible: true,
    }),
    spend__pct_difference_from_goal({
        isVisible: true,
    }),
    spend__difference_from_goal_per_day({
        isVisible: true,
    }),
    spend__goal({
        isVisible: true,
    }),
    spend__daily_avg({
        isVisible: true,
    }),
    spend__daily_avg_forecast({
        isVisible: true,
    }),
    spend__daily_avg_forecast_difference_from_goal({
        isVisible: true,
    }),
    spend__daily_avg_forecast_pct_difference_from_goal({
        isVisible: true,
    }),
    spend__t3_daily_avg({
        isVisible: true,
    }),
    spend__t3_daily_avg_forecast({
        isVisible: true,
    }),
    spend__t3_daily_avg_forecast_difference_from_goal({
        isVisible: true,
    }),
    spend__t3_daily_avg_forecast_pct_difference_from_goal({
        isVisible: true,
    }),
    spend__t7_daily_avg({
        isVisible: true,
    }),
    spend__t7_daily_avg_forecast({
        isVisible: true,
    }),
    spend__t7_daily_avg_forecast_difference_from_goal({
        isVisible: true,
    }),
    spend__t7_daily_avg_forecast_pct_difference_from_goal({
        isVisible: true,
    }),
]

const AMS_SD_METRICS_COLUMNS = [
    attributed_branded_searches_14_day__sum({ isVisible: true }),
    view_impressions__sum({ isVisible: true }),
    view_attributed_conversions_14_day__sum({ isVisible: true }),
    view_attributed_units_ordered_14_day__sum({ isVisible: true }),
    view_attributed_sales_14_day__sum({ isVisible: true }),
    view_attributed_detail_page_view_14_day__sum({ isVisible: true }),
    view_attributed_orders_new_to_brand_14_day__sum({ isVisible: true }),
    view_attributed_sales_new_to_brand_14_day__sum({ isVisible: true }),
    view_attributed_units_ordered_new_to_brand_14_day__sum({ isVisible: true }),
    view_attributed_branded_searches_14_day__sum({ isVisible: true }),
    combined_attributed_sales_14_day__sum({ isVisible: true }),
    combined_conversions_14_day__sum({ isVisible: true }),
    combined_impressions__sum({ isVisible: true }),
    combined_conversion_rate_clicks({ isVisible: true }),
    combined_conversion_rate_impressions({ isVisible: true }),
    combined_cpa({ isVisible: true }),
    combined_aov({ isVisible: true }),
    video_complete_views__sum({ isVisible: true }),
    video_first_quartile_views__sum({ isVisible: true }),
    video_midpoint_views__sum({ isVisible: true }),
    video_third_quartile_views__sum({ isVisible: true }),
    video_unmutes__sum({ isVisible: true }),
]

const TARGET_SUPPORTED_AMS_METRICS_COLUMNS = [
    ...AMS_COMMON_METRICS,
    ...AMS_COMMON_PACING_METRICS,
    ...AMS_SD_METRICS_COLUMNS,
    attributed_sales_1_day__sum({ isVisible: true }),
    attributed_sales_7_day__sum({ isVisible: true }),
    attributed_sales_30_day__sum({ isVisible: true }),
    attributed_sales_1_day_same_sku__sum({ isVisible: true }),
    attributed_sales_7_day_same_sku__sum({ isVisible: true }),
    attributed_sales_30_day_same_sku__sum({ isVisible: true }),

    attributed_conversions_1_day__sum({ isVisible: true }),
    attributed_conversions_7_day__sum({ isVisible: true }),
    attributed_conversions_30_day__sum({ isVisible: true }),
    attributed_conversions_1_day_same_sku__sum({ isVisible: true }),
    attributed_conversions_7_day_same_sku__sum({ isVisible: true }),
    attributed_conversions_30_day_same_sku__sum({ isVisible: true }),

    attributed_units_ordered_1_day__sum({ isVisible: true }),
    attributed_units_ordered_7_day__sum({ isVisible: true }),
    attributed_units_ordered_30_day__sum({ isVisible: true }),
    attributed_units_ordered_1_day_same_sku__sum({
        isVisible: true,
    }),
    attributed_units_ordered_7_day_same_sku__sum({
        isVisible: true,
    }),
    attributed_units_ordered_30_day_same_sku__sum({
        isVisible: true,
    }),
]

const AMS_PRODUCT_GOAL_COLUMNS = [
    product_cpu({ isVisible: true }),
    product_cpu_goal({ isVisible: true }),
    product_cpu_goal_delta({ isVisible: true }),
    product_cpu_goal_performanceIndex({ isVisible: true }),
    product_acos_goal({ isVisible: true }),
    product_acos_goal_delta({ isVisible: true }),
    product_acos_goal_performanceIndex({ isVisible: true }),
    product_total_cpu_goal({ isVisible: true }),
    product_tacos_goal({ isVisible: true }),
]

const AMS_METRICS_COLUMNS = [
    ...AMS_COMMON_METRICS,
    ...AMS_COMMON_PACING_METRICS,
    attributed_conversions_1_day__sum({ isVisible: true }),
    attributed_conversions_1_day_same_sku__sum({ isVisible: true }),
    attributed_conversions_7_day__sum({ isVisible: true }),
    attributed_conversions_7_day_same_sku__sum({ isVisible: true }),
    attributed_conversions_30_day__sum({ isVisible: true }),
    attributed_conversions_30_day_same_sku__sum({ isVisible: true }),
    attributed_sales_1_day__sum({ isVisible: true }),
    attributed_sales_1_day_same_sku__sum({ isVisible: true }),
    attributed_sales_7_day__sum({ isVisible: true }),
    attributed_sales_7_day_same_sku__sum({ isVisible: true }),
    attributed_sales_30_day__sum({ isVisible: true }),
    attributed_sales_30_day_same_sku__sum({ isVisible: true }),
    combined_attributed_sales_14_day__sum({ isVisible: true }),
    attributed_units_ordered_1_day__sum({ isVisible: true }),
    attributed_units_ordered_1_day_same_sku__sum({
        isVisible: true,
    }),
    attributed_units_ordered_7_day__sum({ isVisible: true }),
    attributed_units_ordered_7_day_same_sku__sum({
        isVisible: true,
    }),
    attributed_units_ordered_30_day__sum({ isVisible: true }),
    attributed_units_ordered_30_day_same_sku__sum({
        isVisible: true,
    }),
]

const AMS_METRICS_COLUMNS_WITHOUT_PACING = [
    ...AMS_COMMON_METRICS,
    attributed_conversions_1_day__sum({ isVisible: true }),
    attributed_conversions_1_day_same_sku__sum({ isVisible: true }),
    attributed_conversions_7_day__sum({ isVisible: true }),
    attributed_conversions_7_day_same_sku__sum({ isVisible: true }),
    attributed_conversions_30_day__sum({ isVisible: true }),
    attributed_conversions_30_day_same_sku__sum({ isVisible: true }),
    attributed_sales_1_day__sum({ isVisible: true }),
    attributed_sales_1_day_same_sku__sum({ isVisible: true }),
    attributed_sales_7_day__sum({ isVisible: true }),
    attributed_sales_7_day_same_sku__sum({ isVisible: true }),
    attributed_sales_30_day__sum({ isVisible: true }),
    attributed_sales_30_day_same_sku__sum({ isVisible: true }),
    attributed_units_ordered_1_day__sum({ isVisible: true }),
    attributed_units_ordered_1_day_same_sku__sum({
        isVisible: true,
    }),
    attributed_units_ordered_7_day__sum({ isVisible: true }),
    attributed_units_ordered_7_day_same_sku__sum({
        isVisible: true,
    }),
    attributed_units_ordered_30_day__sum({ isVisible: true }),
    attributed_units_ordered_30_day_same_sku__sum({
        isVisible: true,
    }),
]

export const AMS_WITH_SD_METRICS_COLUMNS = [
    ...AMS_METRICS_COLUMNS,
    ...AMS_SD_METRICS_COLUMNS,
]

export const AMS_WITH_SD_METRICS_COLUMNS_NO_PACING = [
    ...AMS_METRICS_COLUMNS_WITHOUT_PACING,
    ...AMS_SD_METRICS_COLUMNS,
]

export const PRODUCT_AD_METRICS_COLUMNS = [
    ...AMS_WITH_SD_METRICS_COLUMNS,
    raw_cogs_weighted({
        isVisible: true,
    }),
    supplier_cogs_weighted({
        isVisible: true,
    }),
    misc_cogs_weighted({
        isVisible: true,
    }),
    total_cogs_weighted({
        isVisible: true,
    }),
    gross_profit_1_day_same_sku({
        isVisible: true,
    }),
    gross_profit_7_day_same_sku({
        isVisible: true,
    }),
    gross_profit_14_day_same_sku({
        isVisible: true,
    }),
    gross_profit_30_day_same_sku({
        isVisible: true,
    }),
    gross_profit_margin_1_day_same_sku({
        isVisible: true,
    }),
    gross_profit_margin_7_day_same_sku({
        isVisible: true,
    }),
    gross_profit_margin_14_day_same_sku({
        isVisible: true,
    }),
    gross_profit_margin_30_day_same_sku({
        isVisible: true,
    }),
]

export const AMS_GOAL_OPTIONS: GoalOption[] = [
    {
        id: 'spend__goal',
        name: 'Budget',
    },
]

export const TARGET_SUPPORTED_AMS_METRICS_CONFIG = makeMapping(
    TARGET_SUPPORTED_AMS_METRICS_COLUMNS
)
export const AMS_METRICS_CONFIG = makeMapping(
    AMS_METRICS_COLUMNS as Field<unknown>[]
)
export const AMS_WITH_SD_METRICS_CONFIG = makeMapping(
    AMS_WITH_SD_METRICS_COLUMNS as Field<unknown>[]
)
export const AMS_WITH_SD_METRICS_AND_GOAL_CONFIG = makeMapping([
    ...AMS_WITH_SD_METRICS_COLUMNS,
    ...(AMS_PRODUCT_GOAL_COLUMNS as Field<unknown>[]),
])

export const STREAM_SUPPORTED_AMS_METRICS_CONFIG = makeMapping(
    STREAM_SUPPORTED_AMS_METRICS_COLUMNS
)

export const SPECIAL_CAMPAIGN_GROUP_BY_COLUMNS = [
    campaignId({
        dataIndex: ['campaign', 'id'],
    }),
    campaignState({
        dataIndex: ['campaign__state'],
    }),
    campaignType({
        dataIndex: ['campaign__campaign_type'],
    }),
    campaignLabelId({
        dataIndex: ['campaign__label_id'],
    }),
    campaignCapabilityId({
        dataIndex: ['automation_capability_id'],
    }),
    campaignAdFormat({
        isVisible: true, // defaults to false
        dataIndex: ['campaign__ad_format'],
    }),
    campaignTargetingType({
        isVisible: true, // defaults to false
        dataIndex: ['campaign__targeting_type'],
    }),
]

export const CAMPAIGN_PLACEMENT_GROUP_BY_PLACEHOLDER_COLUMNS = [
    placementText({
        dataIndex: ['placement'],
    }),
]

export const SPECIAL_BRAND_GROUP_BY_COLUMNS = [
    brandId({
        dataIndex: ['profile', 'id'],
    }),
    brandRegion({
        dataIndex: ['profile__region'],
    }),
    brandCountryCode({
        dataIndex: ['profile__country_code'],
    }),
    brandType({
        dataIndex: ['profile__type'],
    }),
    brandLabelId({
        dataIndex: ['profile__label_id'],
        renderOptions: {
            render: (props) => profileLabelIdRenderer<any>(props),
        },
    }),
]

export const SPECIAL_PORTFOLIO_GROUP_BY_COLUMNS = [
    portfolioId({
        dataIndex: ['portfolio', 'id'],
    }),
    portfolioLabelId({
        dataIndex: ['portfolio__label_id'],
    }),
]

export const SPECIAL_AD_GROUP_GROUP_BY_COLUMNS = [
    adGroupId({
        dataIndex: ['ad_group', 'id'],
    }),
]

export const QUERY_GROUP_BY_COLUMNS = [query({ dataIndex: ['query'] })]

export const SPECIAL_KEYWORD_GROUP_BY_COLUMNS = [
    keywordId({
        dataIndex: ['keyword', 'id'],
    }),
    keywordMatchType({
        dataIndex: ['keyword__match_type'],
    }),
    keywordState({
        dataIndex: ['keyword__state'],
    }),
]

export const SPECIAL_PRODUCT_AD_GROUP_BY_COLUMNS = [
    productAdId({
        dataIndex: ['product_ad_id'],
    }),
]

export const SPECIAL_TARGET_GROUP_BY_COLUMNS = [
    // No TARGET_GROUP_BY_COLUMNS fields need custom rendering
]
