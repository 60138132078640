import { ReactElement, useEffect } from 'react'

import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import queryString from 'query-string'
import { Route, Router, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import { loadAuthRequest } from 'actions/auth'
import { DowntimePage } from 'components/DowntimePage'
import { ErrorBoundary, ErrorPage } from 'components/ErrorBoundary'
import { LoadingIndicator } from 'components/LoadingIndicator'
import { MaintenancePage } from 'components/MaintenancePage'
import { PrivateRoute } from 'components/PrivateRoute'
import {
    AUTH_PAGE,
    DASHBOARD_PRINT_PAGE,
    SIGN_UP_MFA_SETUP_PAGE,
} from 'const/pages'
import useAppOfflineStatus from 'helpers/appOfflineStatus'
import { getPath } from 'helpers/pages'
import { useAction, useAuthDomainValue } from 'hooks'
import history from 'utilities/history'
import { AppLayoutContainer } from 'views/AppLayout'
import { Auth } from 'views/Auth'
import SetUpPhoneNumberPage from 'views/AuthConfiguration/SetUpPhoneNumberPage'
import { DashboardPrintPage } from 'views/DashboardPrintPage'

function App(): ReactElement {
    /*
    If an _app_offline.json file is present in the root of the site, we will
    force the app to display the maintenance screen. This is useful for forcing
    the app into an offline state for maintenance purposes when the database may
    not be accessible and we can not enable the maintenance page through admin settings.
*/
    const { isOffline, customOfflineMessage: offlineMessage } =
        useAppOfflineStatus()

    const loadAuth = useAction(loadAuthRequest)
    const signedIn = useAuthDomainValue('signedIn')

    useEffect(() => {
        loadAuth()
    }, [loadAuth, signedIn])

    const isLoadingAuth = useAuthDomainValue('isLoadingAuth')
    const loadAuthError = useAuthDomainValue('loadAuthError')

    const displayMaintenancePageSetting = useAuthDomainValue(
        'displayMaintenancePage'
    )
    const displayDowntimePageSetting = useAuthDomainValue('displayDowntimePage')

    if (isLoadingAuth) {
        return (
            <LoadingIndicator
                size="small"
                style={{ position: 'relative', minHeight: '300px' }}
            />
        )
    }

    const showMaintenancePage = isOffline || displayMaintenancePageSetting

    if (showMaintenancePage) {
        return <MaintenancePage message={offlineMessage} />
    }

    if (displayDowntimePageSetting) {
        return <DowntimePage />
    }

    if (loadAuthError) {
        return <ErrorPage />
    }

    return (
        <Router history={history}>
            <QueryParamProvider
                adapter={ReactRouter5Adapter}
                options={{
                    searchStringToObject: queryString.parse,
                    objectToSearchString: queryString.stringify,
                }}
            >
                <Switch>
                    <Route
                        path={getPath(AUTH_PAGE)}
                        render={(props) => (
                            <ErrorBoundary key={props.location.pathname}>
                                <Auth {...props} />
                            </ErrorBoundary>
                        )}
                    />
                    <Route
                        path={getPath(SIGN_UP_MFA_SETUP_PAGE)}
                        component={SetUpPhoneNumberPage}
                    />
                    <PrivateRoute
                        exact
                        path={getPath(DASHBOARD_PRINT_PAGE)}
                        component={DashboardPrintPage}
                    />
                    <PrivateRoute component={AppLayoutContainer} />
                </Switch>
            </QueryParamProvider>
        </Router>
    )
}

export default withLDConsumer()(App)
