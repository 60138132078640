import { ReactElement } from 'react'

import { Select } from 'antd'

import { Campaign } from 'types'

import { useFetchAdGroupsCached } from './localHooks'

interface Props {
    campaign: Campaign
    onChange: (val: string) => void
    selectedAdGroup: string | undefined
}

const AdGroupSelectField = ({
    campaign,
    onChange,
    selectedAdGroup,
}: Props): ReactElement => {
    const [isFetching, options] = useFetchAdGroupsCached(campaign)

    return (
        <Select
            value={selectedAdGroup}
            placeholder="Select Target Ad Group"
            loading={isFetching}
            disabled={isFetching}
            onChange={onChange}
            style={{ width: '100%', maxWidth: 200 }}
        >
            {options.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                    {label}
                </Select.Option>
            ))}
        </Select>
    )
}

export default AdGroupSelectField
