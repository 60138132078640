import { ReactElement, useEffect, useMemo, useState } from 'react'

import { Alert, Button, Tabs } from 'antd'
import { useFormik } from 'formik'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import { CURRENCIES } from 'configuration/currency'
import { getCountryNames } from 'helpers/countries'
import { CurrencyCode } from 'types'
import { ProductCogsPeriod } from 'types/resources/productCogs'

import { CogsProductMetadata } from './localTypes'
import ProductCogsProducts from './ProductCogsProducts'
import ProductCogsTableHeaderRow from './ProductCogsTableHeaderRow'
import ProductCogsTableRow from './ProductCogsTableRow'
import * as styles from './styles.scss'

interface ProductCogsLookup {
    productMetadataId: string
    product: CogsProductMetadata
    cogs: ProductCogsPeriod[]
}

type ProductCogsFormValues = Record<string, ProductCogsLookup>

interface ProductCogsFormProps {
    sku: string
    products: CogsProductMetadata[]
    cogs: ProductCogsPeriod[]
    onSubmit: (cogs: ProductCogsPeriod[]) => any
    onCancel: () => any
}

const cogsSorter = (a: ProductCogsPeriod, b: ProductCogsPeriod): number => {
    if (a.start_date && b.start_date) {
        const a_moment = moment(a.start_date)
        const b_moment = moment(b.start_date)
        if (a_moment.isBefore(b_moment)) {
            return 1
        }
        if (a_moment.isAfter(b_moment)) {
            return -1
        }
    }
    return 0
}

const createNewCog = (productMetadataId: string): ProductCogsPeriod => {
    return {
        id: uuid(),
        product_metadata_id: productMetadataId,
        cost_of_goods_per_unit: 0,
        misc_cost_per_unit: 0,
        supplier_shipping_cost_per_unit: 0,
    } as ProductCogsPeriod
}

const ProductCogsForm = (props: ProductCogsFormProps): ReactElement => {
    const { sku, products, cogs, onSubmit: onSubmitForm, onCancel } = props
    const [submitAttempted, setSubmitAttempted] = useState(false)
    const [productMetadataIdRequiringSort, setProductMetadataIdRequiringSort] =
        useState<string>()

    const { t } = useTranslation('cogs')
    const duplicateStartDateErrorMsg = t(
        'cogs:CogsManagement.edit.validation.duplicateStartDate',
        'Duplicate start date'
    )
    const requiredErrorMsg = t(
        'cogs:CogsManagement.edit.validation.required',
        'Required'
    )
    const genericIncompleteErrorMsg = t(
        'cogs:CogsManagement.edit.validation.genericIncomplete',
        'You have incomplete information in one of your tabs'
    )
    const addCogsAction = t(
        'cogs:CogsManagement.edit.addCogs',
        '+ Add COGS Information'
    )

    const [selectedProductMetadataId, setSelectedProductMetadataId] =
        useState<string>(products[0].id)

    const initialFormValues = useMemo(() => {
        const lookup: ProductCogsFormValues = {}
        products.forEach((p) => {
            const initialCogs = cogs.filter(
                (c) => c.product_metadata_id === p.id
            )

            if (!initialCogs.length) {
                initialCogs.push(createNewCog(p.id))
            }
            lookup[p.id] = {
                productMetadataId: p.id,
                product: p,
                cogs: initialCogs,
            }
        })

        return lookup
    }, [cogs, products])

    const { values, errors, isSubmitting, setFieldValue, submitForm } =
        useFormik({
            initialValues: initialFormValues,
            onSubmit: async (val) => {
                const flattenedCogs = products
                    .map((p) => val[p.id].cogs)
                    .reduce((a, b) => a.concat(b), [])
                    .map((c) => {
                        const startDate = moment(c.start_date).format(
                            'YYYY-MM-DD'
                        )
                        const normalizedUtcMidnight = moment
                            .utc(`${startDate}`)
                            .toISOString()

                        return {
                            ...c,
                            cost_of_goods_per_unit:
                                c.cost_of_goods_per_unit ?? 0,
                            supplier_shipping_cost_per_unit:
                                c.supplier_shipping_cost_per_unit ?? 0,
                            misc_cost_per_unit: c.misc_cost_per_unit ?? 0,
                            start_date: normalizedUtcMidnight,
                        }
                    })

                await onSubmitForm(flattenedCogs)
            },
            validate: (currentValues) => {
                const validationErrors: Record<string, string> = {}

                products.forEach((p) => {
                    const productCogs = currentValues[p.id]
                    const uniqueDates = new Set<string>()
                    const duplicateDates = new Set<string>()

                    productCogs.cogs.forEach((c) => {
                        const dateString = moment(c.start_date).format(
                            'YYYY-MM-DD'
                        )
                        if (uniqueDates.has(dateString)) {
                            duplicateDates.add(dateString)
                        }
                        uniqueDates.add(dateString)
                    })

                    productCogs.cogs.forEach((c, i) => {
                        const key = `${p.id}.cogs.${i}.start_date`
                        const dateString = moment(c.start_date).format(
                            'YYYY-MM-DD'
                        )
                        if (!c.start_date) {
                            validationErrors[key] = requiredErrorMsg
                        } else if (duplicateDates.has(dateString)) {
                            validationErrors[key] = duplicateStartDateErrorMsg
                        }
                        uniqueDates.add(dateString)
                    })
                })
                return validationErrors
            },
            validateOnChange: submitAttempted,
        })

    useEffect(() => {
        if (productMetadataIdRequiringSort) {
            const productMetadataId = productMetadataIdRequiringSort
            const oldCogs = values[productMetadataId].cogs
            const sorted = [...oldCogs]
            sorted.sort(cogsSorter)
            setProductMetadataIdRequiringSort(undefined)
            setFieldValue(`${productMetadataId}.cogs`, sorted)
        }
    }, [productMetadataIdRequiringSort, values, setFieldValue])

    const hasError = (): boolean => {
        return Object.keys(errors).length > 0
    }

    const addRow = (productMetadataId: string) => (): void => {
        const oldCogs = values[productMetadataId].cogs
        const newCogs = [...oldCogs]
        newCogs.splice(0, 0, createNewCog(selectedProductMetadataId))
        setFieldValue(`${productMetadataId}.cogs`, newCogs)
    }

    const removeRow =
        (productMetadataId: string, index: number) => (): void => {
            const oldCogs = values[productMetadataId].cogs
            const newCogs = [...oldCogs]
            newCogs.splice(index, 1)
            setFieldValue(`${productMetadataId}.cogs`, newCogs)
        }

    const handleDateChange =
        (productMetadataId: string, index: number) =>
        async (val: Moment | null) => {
            await setFieldValue(
                `${productMetadataId}.cogs.${index}.start_date`,
                val
            )
            setProductMetadataIdRequiringSort(productMetadataId)
        }

    const extractErrorsForCog = (
        productMetadataId: string,
        index: number
    ): Record<string, string> => {
        const cogErrorKeyPrefix = `${productMetadataId}.cogs.${index}.`
        const cogErrors: Record<string, string> = {}
        Object.keys(errors)
            .filter((e) => e.startsWith(cogErrorKeyPrefix))
            .forEach((e) => {
                cogErrors[e.replace(cogErrorKeyPrefix, '')] = errors[
                    e
                ] as string
            })
        return cogErrors
    }

    const tabItems = [
        ...products.map((x) => {
            const productCogs = values[x.id]
            const { productMetadataId } = productCogs
            const currencyCode = (productCogs.product.currency_code ??
                'USD') as CurrencyCode
            const currency = CURRENCIES[currencyCode]

            return {
                key: productMetadataId,
                label: getCountryNames(t)[productCogs.product.marketplace],
                children: (
                    <table className={styles.cogsTable}>
                        <thead>
                            <ProductCogsTableHeaderRow
                                currencyCode={currencyCode}
                            />
                        </thead>
                        <tbody>
                            {(productCogs.cogs || []).map((cog, index) => {
                                const cogErrors = extractErrorsForCog(
                                    productMetadataId,
                                    index
                                )
                                return (
                                    <ProductCogsTableRow
                                        key={cog.id}
                                        cog={cog}
                                        previousCog={
                                            index > 0
                                                ? productCogs.cogs[index - 1]
                                                : undefined
                                        }
                                        currency={currency}
                                        onDateChange={handleDateChange(
                                            productMetadataId,
                                            index
                                        )}
                                        onNumberChange={(key, val) => {
                                            setFieldValue(
                                                `${productMetadataId}.cogs.${index}.${key}`,
                                                val
                                            )
                                        }}
                                        errors={cogErrors}
                                        onRemoveRequested={removeRow(
                                            productMetadataId,
                                            index
                                        )}
                                    />
                                )
                            })}
                            <tr>
                                <td colSpan={6}>
                                    <Button
                                        type="link"
                                        onClick={addRow(
                                            productCogs.productMetadataId
                                        )}
                                    >
                                        {addCogsAction}
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ),
            }
        }),
    ]

    return (
        <div>
            <div className={styles.productInfo}>
                {!!sku && !!products && (
                    <ProductCogsProducts
                        sku={sku}
                        products={products}
                        selectedProductId={selectedProductMetadataId}
                    />
                )}
            </div>
            {hasError() && (
                <div>
                    <Alert
                        showIcon
                        message={genericIncompleteErrorMsg}
                        type="error"
                    />
                </div>
            )}
            <Tabs
                activeKey={selectedProductMetadataId}
                items={tabItems}
                onChange={setSelectedProductMetadataId}
                className={styles.tabs}
            />
            <div className={styles.actions}>
                <Button
                    type="primary"
                    disabled={isSubmitting}
                    onClick={() => {
                        setSubmitAttempted(true)
                        submitForm()
                    }}
                >
                    {isSubmitting
                        ? t('common:saving', 'Saving...')
                        : t('common:save', 'Save')}
                </Button>
                <Button
                    key={1}
                    disabled={isSubmitting}
                    onClick={onCancel}
                    loading={isSubmitting}
                >
                    {t('common:cancel', 'Cancel')}
                </Button>
            </div>
        </div>
    )
}

export default ProductCogsForm
