import { ReactElement, ReactNode } from 'react'

import { Row, Divider } from 'antd'
import isUndefined from 'lodash/isUndefined'
import toNumber from 'lodash/toNumber'

import { ChangeDelta } from 'components/ChangeDelta'
import Stack from 'components/Layout/Stack'
import { CHANGE, PERCENTAGE_CHANGE } from 'const/metrics'
import {
    formatAbbreviatedMetric,
    formatCerebroDate,
    formatNumber,
} from 'helpers/formatting'
import { transformToString } from 'helpers/widgets'
import { Widget, FieldMapping } from 'types'

import { isPercentMetric } from '../TimeSeriesWidget/helpers'

import * as styles from './styles.scss'

interface Props {
    widget: Widget
    widgetData: any[]
    metricsConfig: FieldMapping
}

function MetricWidget({
    widget,
    widgetData,
    metricsConfig,
}: Props): ReactElement {
    const metricsObj = widgetData?.[0] ?? {}
    const widgetMetrics = transformToString(widget.metrics) as string[]

    function renderPeriodDelta(metric: string): ReactNode {
        if (widget.constant_value_for_prior_period) {
            return widget.constant_value_for_prior_period
        }

        const changeValue = metricsObj[`${metric}__${CHANGE}`]
        const percentageChangeValue =
            metricsObj[`${metric}__${PERCENTAGE_CHANGE}`]
        if (isUndefined(metricsConfig[metric])) {
            return null
        }
        const config = metricsConfig[metric]
        const metricOptions = config?.metricOptions

        if (isUndefined(changeValue)) {
            return null
        }

        const deltas = [
            <ChangeDelta
                key="change"
                value={toNumber(changeValue)}
                formatter={(value: number | string): string =>
                    widget.show_full_value
                        ? formatNumber(
                              value,
                              metricOptions?.format,
                              false,
                              metricOptions?.type === 'percentage_as_is'
                          )
                        : formatAbbreviatedMetric(
                              value,
                              metricOptions?.format,
                              metricOptions?.shortFormat,
                              false,
                              metricOptions?.type === 'percentage_as_is'
                          )
                }
                inverse={metricOptions?.isInverse}
                style={{ display: 'inline-block' }}
            />,
        ]

        if (percentageChangeValue && !isPercentMetric(metricOptions?.type)) {
            return deltas.concat([
                <Divider key="divider" type="vertical" />,
                <ChangeDelta
                    key="percentage_change"
                    value={toNumber(percentageChangeValue)}
                    formatter={(value) => `${formatNumber(value, '0,0.00')}%`}
                    inverse={metricOptions?.isInverse}
                    style={{ display: 'inline-block' }}
                />,
            ])
        }

        return deltas
    }

    function formatFullValueMetric(
        metric: string,
        metrics: Record<string, any>
    ): string {
        if (metricsConfig[metric]?.metricOptions?.format === 'YYYY-MM-DD') {
            return formatCerebroDate(metrics[metric])
        }
        return formatNumber(
            metrics[metric],
            metricsConfig[metric]?.metricOptions?.format,
            false,
            metricsConfig[metric]?.metricOptions?.type === 'percentage_as_is'
        )
    }

    return (
        <Row className="align-items-start align-content-start metrics-container">
            {widgetMetrics.flatMap((metric): ReactElement | ReactElement[] => {
                if (isUndefined(metricsConfig[metric])) {
                    return []
                }
                return (
                    <Stack
                        direction="column"
                        key={metric}
                        className={styles.metric}
                    >
                        <p className={styles['metric-title']}>
                            {(typeof metricsConfig[metric]?.shortName ==
                            'function'
                                ? metricsConfig[metric]?.shortName(false)
                                : metricsConfig[metric]?.shortName) ?? '-'}
                        </p>
                        <p className={styles['metric-value']}>
                            {widget.show_full_value
                                ? formatFullValueMetric(metric, metricsObj)
                                : formatAbbreviatedMetric(
                                      metricsObj[metric],
                                      metricsConfig[metric]?.metricOptions
                                          ?.format,
                                      metricsConfig[metric]?.metricOptions
                                          ?.shortFormat,
                                      false,
                                      metricsConfig[metric]?.metricOptions
                                          ?.type === 'percentage_as_is'
                                  )}
                        </p>
                        <div className={styles['metric-delta']}>
                            {renderPeriodDelta(metric)}
                        </div>
                    </Stack>
                )
            })}
        </Row>
    )
}

export default MetricWidget
