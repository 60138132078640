import { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import Stack from 'components/Layout/Stack'

import { ColumnConfig } from './localTypes'
import * as styles from './styles.scss'

interface Props {
    columnsConfig: ColumnConfig[]
}

const CreateAdItemFormHeader = ({ columnsConfig }: Props): ReactElement => {
    const { t } = useTranslation('common')

    return (
        <Stack direction="row" alignItems="start">
            <div
                style={{
                    width: '18%',
                    marginRight: 4,
                    marginTop: 8,
                    paddingLeft: 8,
                }}
                className={styles['ad-item-row-header']}
            >
                {t('common:itemId', 'Item ID')}
            </div>
            <div
                style={{
                    flex: 1,
                    marginRight: 4,
                    marginTop: 8,
                    paddingLeft: 8,
                }}
                className={styles['ad-item-row-header']}
            >
                {t('common:name', 'Name')}
            </div>

            {columnsConfig.map(({ width, name, dataKey }) => {
                return (
                    <div
                        className={styles['ad-item-row-header']}
                        style={{
                            width,
                            marginRight: 4,
                            marginTop: 8,
                        }}
                        key={dataKey}
                    >
                        {name}
                    </div>
                )
            })}

            <div
                className={styles['remove-column']}
                style={{
                    marginTop: 8,
                    marginRight: 4,
                }}
            />
        </Stack>
    )
}

export default CreateAdItemFormHeader
