import { ComponentType, lazy, ReactElement, Suspense } from 'react'

import { Redirect, Route, Switch } from 'react-router-dom'

import DynamicPageRoute from 'components/DynamicPageRoute/DynamicPageRoute'
import { PageRoute } from 'components/PageRoute'
import RouteLoadingPlaceholder from 'components/RouteLoadingPlaceholder/RouteLoadingPlaceholder'
import { SHARED_WITH_ME } from 'const/dashboards'
import {
    AD_ACCOUNT_PAGE,
    AD_ACCOUNTS_SUMMARY_PAGE,
    AD_GROUP_KEYWORD_PAGE,
    AD_GROUP_PAGE,
    ALERT_CREATION_PAGE,
    ALERT_PAGE,
    ALERTS_SUMMARY_PAGE,
    AMAZON_CAMPAIGN_CREATION_PAGE,
    AUTOMATION_PAGE,
    AUTOMATION_PROFILE_PAGE,
    BRAND_CAMPAIGN_KEYWORD_PAGE,
    BRAND_CAMPAIGN_PAGE,
    BRAND_CAMPAIGN_PRODUCT_AD_PAGE,
    BULK_UPDATES_PAGE,
    CAMPAIGN_KEYWORD_PAGE,
    CAMPAIGN_PAGE,
    CAMPAIGN_PRODUCT_AD_PAGE,
    CAMPAIGNS_SUMMARY_PAGE,
    DASHBOARD_PAGE,
    DASHBOARDS_SUMMARY_PAGE,
    DEBUG_COLUMNS_PAGE,
    DEBUG_ICONS_PAGE,
    DEBUG_METRICS_PAGE,
    DSP_ADVERTISER_PAGE,
    DSP_LINE_ITEM_PAGE,
    DSP_ORDER_PAGE,
    DSP_SUMMARY_PAGE,
    EVENTS_SUMMARY_PAGE,
    HOME_PAGE,
    INSIGHTS_PAGE,
    KEYWORD_PAGE,
    KEYWORD_RESEARCH_LIST_DETAIL_PAGE,
    KEYWORD_RESEARCH_LISTS_PAGE,
    KEYWORD_RESEARCH_PAGE,
    LABEL_PAGE,
    LABELS_SUMMARY_PAGE,
    METRICS_GLOSSARY_PAGE,
    ORGANIZATION_GROUP_PAGE,
    ORGANIZATION_PAGE,
    PORTFOLIO_PAGE,
    PORTFOLIOS_SUMMARY_PAGE,
    PRODUCT_CATALOG_PAGE,
    PRODUCT_PAGE,
    PRODUCT_PRODUCT_AD_PAGE,
    PRODUCTS_SUMMARY_PAGE,
    REPORTS_SUMMARY_PAGE,
    RULEBOOK_PAGE,
    SEGMENT_CREATION_PAGE,
    SEGMENT_EDIT_PAGE,
    SEGMENT_PAGE,
    SEGMENTS_SUMMARY_PAGE,
    SOV_KEYWORD_PAGE,
    SOV_KEYWORD_SEARCH_RESULT_PAGE,
    SOV_KEYWORDS_SUMMARY_PAGE,
    TARGETING_SUMMARY_PAGE,
    WALMART_AD_GROUP_PAGE,
    WALMART_CAMPAIGN_CREATION_PAGE,
    WALMART_CAMPAIGN_PAGE,
    WALMART_CAMPAIGNS_SUMMARY_PAGE,
    WALMART_ITEMS_SUMMARY_PAGE,
    WALMART_KEYWORDS_SUMMARY_PAGE,
} from 'const/pages'
import { DynamicModule } from 'dynamicModules/types'
import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { getPath } from 'helpers/pages'
import { dashboardsUrl } from 'helpers/urls'
import { useUserHasPermissions } from 'hooks'
import useFeatureFlags from 'hooks/useFeatureFlags'

const loading = <RouteLoadingPlaceholder />

const initDynamicModule = (
    module: DynamicModule
): { default: ComponentType<any> } => {
    return { default: DynamicPageRoute(module) }
}

const DashboardPage = lazy(
    () =>
        import(
            /* webpackChunkName: "dashboards" */ 'views/DashboardPage/DashboardPage'
        )
)
const DashboardsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "dashboards" */ 'views/DashboardsSummaryPage/DashboardsSummaryPage'
        )
)
const HomePage = lazy(
    () => import(/* webpackChunkName: "dashboards" */ 'views/HomePage/HomePage')
)
const AlertCreationPage = lazy(
    () =>
        import(
            /* webpackChunkName: "alerts" */ 'views/AlertCreationPage/AlertCreationPage'
        )
)
const AlertPage = lazy(
    () => import(/* webpackChunkName: "alerts" */ 'views/AlertPage/AlertPage')
)
const AlertsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "alerts" */ 'views/AlertsSummaryPage/AlertsSummaryPage'
        )
)
const AmazonAdGroupPage = lazy(
    () =>
        import(
            /* webpackChunkName: "amazon" */ 'views/AmazonAdGroupPage/AmazonAdGroupPage'
        )
)
const AmazonCampaignCreationPage = lazy(
    () =>
        import(
            /* webpackChunkName: "amazon" */ 'views/AmazonCampaignCreationPage/AmazonCampaignCreationPage'
        )
)
const AmazonCampaignPage = lazy(
    () =>
        import(
            /* webpackChunkName: "amazon" */ 'views/AmazonCampaignPage/AmazonCampaignPage'
        )
)
const AmazonCampaignsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "amazon" */ 'views/AmazonCampaignsSummaryPage/AmazonCampaignsSummaryPage'
        )
)
const AmazonKeywordPage = lazy(
    () =>
        import(
            /* webpackChunkName: "amazon" */ 'views/AmazonKeywordPage/AmazonKeywordPage'
        )
)
const AmazonPortfolioPage = lazy(
    () =>
        import(
            /* webpackChunkName: "amazon" */ 'views/AmazonPortfolioPage/AmazonPortfolioPage'
        )
)
const AmazonPortfoliosSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "amazon" */ 'views/AmazonPortfoliosSummaryPage/AmazonPortfoliosSummaryPage'
        )
)
const AmazonProductAdPage = lazy(
    () =>
        import(
            /* webpackChunkName: "amazon" */ 'views/AmazonProductAdPage/AmazonProductAdPage'
        )
)
const AmazonProductPage = lazy(
    () =>
        import(
            /* webpackChunkName: "amazon" */ 'views/AmazonProductPage/AmazonProductPage'
        )
)
const AmazonProductsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "amazon" */ 'views/AmazonProductsSummaryPage/AmazonProductsSummaryPage'
        )
)
const AutomationPage = lazy(
    () =>
        import(
            /* webpackChunkName: "automation" */ 'views/AutomationPage/AutomationPage'
        )
)
const AutomationProfilePage = lazy(
    () =>
        import(
            /* webpackChunkName: "automation" */ 'views/AutomationProfilePage/AutomationProfilePage'
        )
)
const RulebookPage = lazy(
    () =>
        import(
            /* webpackChunkName: "automation" */ 'views/RulebookPage/RulebookPage'
        )
)
const BrandPage = lazy(
    () => import(/* webpackChunkName: "brands" */ 'views/BrandPage/BrandPage')
)
const BrandsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "brands" */ 'views/BrandsSummaryPage/BrandsSummaryPage'
        )
)
const BulkUpdatesPage = lazy(
    () =>
        import(
            /* webpackChunkName: "bulk" */ 'views/BulkUpdatesPage/BulkUpdatesPage'
        )
)
const ColumnsDebuggerPage = lazy(() =>
    import(/* webpackChunkName: "devTools" */ 'dynamicModules/DevTools').then(
        (module) => initDynamicModule(module.ColumnsDebugger)
    )
)
const DspAdvertiserPage = lazy(
    () =>
        import(
            /* webpackChunkName: "dsp" */ 'views/DspAdvertiserPage/DspAdvertiserPage'
        )
)
const DspLineItemPage = lazy(
    () =>
        import(
            /* webpackChunkName: "dsp" */ 'views/DspLineItemPage/DspLineItemPage'
        )
)
const DspOrderPage = lazy(
    () =>
        import(/* webpackChunkName: "dsp" */ 'views/DspOrderPage/DspOrderPage')
)
const DspSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "dsp" */ 'views/DspSummaryPage/DspSummaryPage'
        )
)
const EventsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "events" */ 'views/EventsSummaryPage/EventsSummaryPage'
        )
)
const IconsPage = lazy(() =>
    import(/* webpackChunkName: "devTools" */ 'dynamicModules/DevTools').then(
        (module) => initDynamicModule(module.AllIcons)
    )
)
const InsightsPage = lazy(() =>
    import(
        /* webpackChunkName: "insightsNew" */ 'dynamicModules/Insights'
    ).then((module) => initDynamicModule(module.InsightsList))
)
const KeywordResearchPage = lazy(() =>
    import(
        /* webpackChunkName: "keywordResearch" */ 'dynamicModules/KeywordResearch'
    ).then((module) => initDynamicModule(module.KeywordResearch))
)
const KeywordResearchListsPage = lazy(() =>
    import(
        /* webpackChunkName: "keywordResearch" */ 'dynamicModules/KeywordResearch'
    ).then((module) => initDynamicModule(module.KeywordResearchLists))
)
const KeywordResearchListDetailPage = lazy(() =>
    import(
        /* webpackChunkName: "keywordResearch" */ 'dynamicModules/KeywordResearch'
    ).then((module) => initDynamicModule(module.keywordResearchListDetailPage))
)
const LabelPage = lazy(
    () => import(/* webpackChunkName: "labels" */ 'views/LabelPage/LabelPage')
)
const LabelsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "labels" */ 'views/LabelsSummaryPage/LabelsSummaryPage'
        )
)
const MetricsDebuggerPage = lazy(() =>
    import(/* webpackChunkName: "devTools" */ 'dynamicModules/DevTools').then(
        (module) => initDynamicModule(module.MetricsDebugger)
    )
)
const MetricsGlossaryPage = lazy(() =>
    import(
        /* webpackChunkName: "metricsGlossary" */ 'dynamicModules/MetricsGlossary'
    ).then((module) => initDynamicModule(module.MetricsGlossaryList))
)
const OrganizationGroupPage = lazy(
    () =>
        import(
            /* webpackChunkName: "orgs" */ 'views/OrganizationGroupPage/OrganizationGroupPage'
        )
)
const OrganizationPage = lazy(
    () =>
        import(
            /* webpackChunkName: "orgs" */ 'views/OrganizationPage/OrganizationPage'
        )
)
const ProductCatalogPage = lazy(() =>
    import(
        /* webpackChunkName: "productCatalog" */ 'dynamicModules/ProductCatalog'
    ).then((module) => initDynamicModule(module.ProductCatalog))
)
const ReportsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "reports" */ 'views/ReportsSummaryPage/ReportsSummaryPage'
        )
)
const SegmentCreatePage = lazy(() =>
    import(
        /* webpackChunkName: "segments" */ 'dynamicModules/SegmentManagement'
    ).then((module) => initDynamicModule(module.SegmentCreate))
)
const SegmentEditPage = lazy(() =>
    import(
        /* webpackChunkName: "segments" */ 'dynamicModules/SegmentManagement'
    ).then((module) => initDynamicModule(module.SegmentEdit))
)
const SegmentDetailPage = lazy(() =>
    import(/* webpackChunkName: "segments" */ 'dynamicModules/Segments').then(
        (module) => initDynamicModule(module.SegmentDetail)
    )
)
const SegmentsSummaryPage = lazy(() =>
    import(/* webpackChunkName: "segments" */ 'dynamicModules/Segments').then(
        (module) => initDynamicModule(module.SegmentsList)
    )
)
const SovKeywordPage = lazy(() =>
    import(/* webpackChunkName: "sov" */ 'dynamicModules/SovKeywords').then(
        (module) => initDynamicModule(module.SovKeywordDetail)
    )
)
const SovKeywordsSummaryPage = lazy(() =>
    import(/* webpackChunkName: "sov" */ 'dynamicModules/SovKeywords').then(
        (module) => initDynamicModule(module.SovOverview)
    )
)
const SovSearchResultsPage = lazy(() =>
    import(/* webpackChunkName: "sov" */ 'dynamicModules/SovKeywords').then(
        (module) => initDynamicModule(module.SovSearchResults)
    )
)
const TargetingSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "sov" */ 'views/TargetingSummaryPage/TargetingSummaryPage'
        )
)
const WalmartAdGroupPage = lazy(
    () =>
        import(
            /* webpackChunkName: "walmart" */ '../WalmartAdGroupPage/WalmartAdGroupPage'
        )
)
const WalmartCampaignCreationPage = lazy(
    () =>
        import(
            /* webpackChunkName: "walmart" */ '../WalmartCampaignCreationPage/WalmartCampaignCreationPage'
        )
)
const WalmartCampaignPage = lazy(
    () =>
        import(
            /* webpackChunkName: "walmart" */ '../WalmartCampaignPage/WalmartCampaignPage'
        )
)
const WalmartCampaignsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "walmart" */ '../WalmartCampaignsSummaryPage/WalmartCampaignsSummaryPage'
        )
)
const WalmartItemsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "walmart" */ '../WalmartItemsSummaryPage/WalmartItemsSummaryPage'
        )
)
const WalmartKeywordsSummaryPage = lazy(
    () =>
        import(
            /* webpackChunkName: "walmart" */ '../WalmartKeywordsSummaryPage/WalmartKeywordsSummaryPage'
        )
)

function Routes(): ReactElement {
    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    const { keywordLists } = useFeatureFlags()

    return (
        <Suspense fallback={loading}>
            <Switch>
                {dashboardOnlyExperience && (
                    <Route exact path={getPath(HOME_PAGE)}>
                        <Redirect to={dashboardsUrl(SHARED_WITH_ME)} />
                    </Route>
                )}
                <PageRoute
                    exact
                    pageName={DEBUG_METRICS_PAGE}
                    path={getPath(DEBUG_METRICS_PAGE)}
                    component={MetricsDebuggerPage}
                    restricted={process.env.NODE_ENV !== 'development'}
                />
                <PageRoute
                    exact
                    pageName={METRICS_GLOSSARY_PAGE}
                    path={getPath(METRICS_GLOSSARY_PAGE)}
                    component={MetricsGlossaryPage}
                />
                <PageRoute
                    exact
                    pageName={HOME_PAGE}
                    path={getPath(HOME_PAGE)}
                    component={HomePage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={AD_ACCOUNTS_SUMMARY_PAGE}
                    path={getPath(AD_ACCOUNTS_SUMMARY_PAGE)}
                    component={BrandsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={AD_ACCOUNT_PAGE}
                    path={getPath(AD_ACCOUNT_PAGE)}
                    component={BrandPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={BRAND_CAMPAIGN_PAGE}
                    path={getPath(BRAND_CAMPAIGN_PAGE)}
                    component={AmazonCampaignPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={BRAND_CAMPAIGN_KEYWORD_PAGE}
                    path={getPath(BRAND_CAMPAIGN_KEYWORD_PAGE)}
                    component={AmazonKeywordPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={BRAND_CAMPAIGN_PRODUCT_AD_PAGE}
                    path={getPath(BRAND_CAMPAIGN_PRODUCT_AD_PAGE)}
                    component={AmazonProductAdPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={CAMPAIGNS_SUMMARY_PAGE}
                    path={getPath(CAMPAIGNS_SUMMARY_PAGE)}
                    component={AmazonCampaignsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={CAMPAIGN_PAGE}
                    path={getPath(CAMPAIGN_PAGE)}
                    component={AmazonCampaignPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={AMAZON_CAMPAIGN_CREATION_PAGE}
                    path={getPath(AMAZON_CAMPAIGN_CREATION_PAGE)}
                    component={AmazonCampaignCreationPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={CAMPAIGN_KEYWORD_PAGE}
                    path={getPath(CAMPAIGN_KEYWORD_PAGE)}
                    component={AmazonKeywordPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={CAMPAIGN_PRODUCT_AD_PAGE}
                    path={getPath(CAMPAIGN_PRODUCT_AD_PAGE)}
                    component={AmazonProductAdPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={AD_GROUP_PAGE}
                    path={getPath(AD_GROUP_PAGE)}
                    component={AmazonAdGroupPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={AD_GROUP_KEYWORD_PAGE}
                    path={getPath(AD_GROUP_KEYWORD_PAGE)}
                    component={AmazonKeywordPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={PRODUCTS_SUMMARY_PAGE}
                    path={getPath(PRODUCTS_SUMMARY_PAGE)}
                    component={AmazonProductsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={PRODUCT_PAGE}
                    path={getPath(PRODUCT_PAGE)}
                    component={AmazonProductPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={PRODUCT_PRODUCT_AD_PAGE}
                    path={getPath(PRODUCT_PRODUCT_AD_PAGE)}
                    component={AmazonProductAdPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={PRODUCT_CATALOG_PAGE}
                    path={getPath(PRODUCT_CATALOG_PAGE)}
                    component={ProductCatalogPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={TARGETING_SUMMARY_PAGE}
                    path={getPath(TARGETING_SUMMARY_PAGE)}
                    component={TargetingSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={KEYWORD_PAGE}
                    path={getPath(KEYWORD_PAGE)}
                    component={AmazonKeywordPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={KEYWORD_RESEARCH_PAGE}
                    path={getPath(KEYWORD_RESEARCH_PAGE)}
                    component={KeywordResearchPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={KEYWORD_RESEARCH_LISTS_PAGE}
                    path={getPath(KEYWORD_RESEARCH_LISTS_PAGE)}
                    component={KeywordResearchListsPage}
                    restricted={!keywordLists || dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={KEYWORD_RESEARCH_LIST_DETAIL_PAGE}
                    path={getPath(KEYWORD_RESEARCH_LIST_DETAIL_PAGE)}
                    component={KeywordResearchListDetailPage}
                    restricted={!keywordLists || dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={SEGMENT_EDIT_PAGE}
                    path={getPath(SEGMENT_EDIT_PAGE)}
                    component={SegmentEditPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={SEGMENTS_SUMMARY_PAGE}
                    path={getPath(SEGMENTS_SUMMARY_PAGE)}
                    component={SegmentsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={SEGMENT_CREATION_PAGE}
                    path={getPath(SEGMENT_CREATION_PAGE)}
                    component={SegmentCreatePage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={SEGMENT_PAGE}
                    path={getPath(SEGMENT_PAGE)}
                    component={SegmentDetailPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={SOV_KEYWORDS_SUMMARY_PAGE}
                    path={getPath(SOV_KEYWORDS_SUMMARY_PAGE)}
                    component={SovKeywordsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={SOV_KEYWORD_PAGE}
                    path={getPath(SOV_KEYWORD_PAGE)}
                    component={SovKeywordPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={SOV_KEYWORD_SEARCH_RESULT_PAGE}
                    path={getPath(SOV_KEYWORD_SEARCH_RESULT_PAGE)}
                    component={SovSearchResultsPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={ORGANIZATION_PAGE}
                    path={getPath(ORGANIZATION_PAGE)}
                    component={OrganizationPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={ORGANIZATION_GROUP_PAGE}
                    path={getPath(ORGANIZATION_GROUP_PAGE)}
                    component={OrganizationGroupPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={AUTOMATION_PAGE}
                    path={getPath(AUTOMATION_PAGE)}
                    component={AutomationPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={AUTOMATION_PROFILE_PAGE}
                    path={getPath(AUTOMATION_PROFILE_PAGE)}
                    component={AutomationProfilePage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={LABELS_SUMMARY_PAGE}
                    path={getPath(LABELS_SUMMARY_PAGE)}
                    component={LabelsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={LABEL_PAGE}
                    path={getPath(LABEL_PAGE)}
                    component={LabelPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={DASHBOARDS_SUMMARY_PAGE}
                    path={getPath(DASHBOARDS_SUMMARY_PAGE)}
                    component={DashboardsSummaryPage}
                />
                <PageRoute
                    exact
                    pageName={DASHBOARD_PAGE}
                    path={getPath(DASHBOARD_PAGE)}
                    component={DashboardPage}
                />
                <PageRoute
                    exact
                    pageName={REPORTS_SUMMARY_PAGE}
                    path={getPath(REPORTS_SUMMARY_PAGE)}
                    component={ReportsSummaryPage}
                />
                <PageRoute
                    exact
                    pageName={PORTFOLIOS_SUMMARY_PAGE}
                    path={getPath(PORTFOLIOS_SUMMARY_PAGE)}
                    component={AmazonPortfoliosSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={PORTFOLIO_PAGE}
                    path={getPath(PORTFOLIO_PAGE)}
                    component={AmazonPortfolioPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={ALERTS_SUMMARY_PAGE}
                    path={getPath(ALERTS_SUMMARY_PAGE)}
                    component={AlertsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={ALERT_PAGE}
                    path={getPath(ALERT_PAGE)}
                    component={AlertPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={ALERT_CREATION_PAGE}
                    path={getPath(ALERT_CREATION_PAGE)}
                    component={AlertCreationPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={AUTOMATION_PAGE}
                    path={getPath(BULK_UPDATES_PAGE)}
                    component={BulkUpdatesPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={DEBUG_COLUMNS_PAGE}
                    path={getPath(DEBUG_COLUMNS_PAGE)}
                    component={ColumnsDebuggerPage}
                    restricted={process.env.NODE_ENV !== 'development'}
                />
                <PageRoute
                    exact
                    pageName={DEBUG_ICONS_PAGE}
                    path={getPath(DEBUG_ICONS_PAGE)}
                    component={IconsPage}
                    restricted={process.env.NODE_ENV !== 'development'}
                />
                <PageRoute
                    exact
                    pageName={INSIGHTS_PAGE}
                    path={getPath(INSIGHTS_PAGE)}
                    component={InsightsPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={EVENTS_SUMMARY_PAGE}
                    path={getPath(EVENTS_SUMMARY_PAGE)}
                    component={EventsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={RULEBOOK_PAGE}
                    path={getPath(RULEBOOK_PAGE)}
                    component={RulebookPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={DSP_SUMMARY_PAGE}
                    path={getPath(DSP_SUMMARY_PAGE)}
                    component={DspSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={DSP_ADVERTISER_PAGE}
                    path={getPath(DSP_ADVERTISER_PAGE)}
                    component={DspAdvertiserPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={DSP_ORDER_PAGE}
                    path={getPath(DSP_ORDER_PAGE)}
                    component={DspOrderPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={DSP_LINE_ITEM_PAGE}
                    path={getPath(DSP_LINE_ITEM_PAGE)}
                    component={DspLineItemPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={WALMART_CAMPAIGNS_SUMMARY_PAGE}
                    path={getPath(WALMART_CAMPAIGNS_SUMMARY_PAGE)}
                    component={WalmartCampaignsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={WALMART_AD_GROUP_PAGE}
                    path={getPath(WALMART_AD_GROUP_PAGE)}
                    component={WalmartAdGroupPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={WALMART_CAMPAIGN_CREATION_PAGE}
                    path={getPath(WALMART_CAMPAIGN_CREATION_PAGE)}
                    component={WalmartCampaignCreationPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={WALMART_CAMPAIGN_PAGE}
                    path={getPath(WALMART_CAMPAIGN_PAGE)}
                    component={WalmartCampaignPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={WALMART_KEYWORDS_SUMMARY_PAGE}
                    path={getPath(WALMART_KEYWORDS_SUMMARY_PAGE)}
                    component={WalmartKeywordsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
                <PageRoute
                    exact
                    pageName={WALMART_ITEMS_SUMMARY_PAGE}
                    path={getPath(WALMART_ITEMS_SUMMARY_PAGE)}
                    component={WalmartItemsSummaryPage}
                    restricted={dashboardOnlyExperience}
                />
            </Switch>
        </Suspense>
    )
}

export default Routes
