import { ReactElement } from 'react'

import { FieldConfig, FormikProps } from 'formik'
import { CirclePicker, ColorResult } from 'react-color'

import { DEFAULT_COLOR_PICKER_PROPS } from 'const/colors'

interface FormikColorPickerProps {
    field: FieldConfig
    form: FormikProps<unknown>
}

function FormikColorPicker({
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    ...props
}: FormikColorPickerProps): ReactElement {
    const handleChangeComplete = (color: ColorResult): void => {
        setFieldValue(name, color.hex)
        setFieldTouched(name, true)
    }

    return (
        <CirclePicker
            color={value}
            onChangeComplete={handleChangeComplete}
            {...DEFAULT_COLOR_PICKER_PROPS}
            {...props}
        />
    )
}

export default FormikColorPicker
