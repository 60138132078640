import {
    metadataIdRenderer,
    productLabelIdRenderer,
} from 'components/FieldRenderers/Renderers/productMetadata'
import { TitleRenderer } from 'components/FieldRenderers/Renderers/productMetadata'
import { Flag } from 'components/Flag'
import { COUNTRY_LABELS } from 'const/codes'
import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import { Field } from 'types'

import { createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function metadataId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: options.id ?? 'metadata_id',
        name: 'Product',
        shortName: 'Product',
        minWidth: 80,
        dataIndex: options.dataIndex ?? ['metadata_id'],
        renderOptions: { render: (props) => metadataIdRenderer<any>(props) },
        width: 100,
    })
}

export function metadataBrand<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'metadata__brand',
        name: 'Product Brand',
        shortName: 'Product Brand',
        minWidth: 80,
        dataIndex: options.dataIndex ?? ['metadata__brand'],
        width: 100,
    })
}

export function metadataTitle<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'metadata__title',
        name: 'Product Title',
        shortName: 'Product Title',
        minWidth: 80,
        dataIndex: options.dataIndex ?? ['metadata__title'],
        width: 100,
    })
}

export function productCatalogMetadataTitle<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: options.id ?? 'metadata_id',
        name: 'Product',
        shortName: 'Product',
        minWidth: options.minWidth ?? 80,
        dataIndex: options.dataIndex ?? ['metadata_id'],
        renderOptions: {
            render: (props) =>
                TitleRenderer<any>({ ...props, showImage: options.showImage }),
        },
        width: 300,
        exportIndex: 7,
    })
}

export function metadataMarketplace<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'metadata__marketplace',
        name: 'Marketplace',
        shortName: 'Marketplace',
        minWidth: 80,
        dataIndex: options.dataIndex ?? ['metadata__marketplace'],
        width: 100,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) =>
                isUnset(value) ? (
                    UNDEFINED_VALUE
                ) : (
                    <span className="fg-flag-container">
                        <Flag country={value} />
                        {COUNTRY_LABELS[value]}
                    </span>
                ),
        },
    })
}

export function productLabelId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'product__label_id',
        name: 'Product Label',
        shortName: 'Product Label',
        minWidth: 80,
        dataIndex: options.dataIndex ?? ['product__label_id'],
        renderOptions: {
            render: (props) => productLabelIdRenderer<any>(props),
        },
        width: 100,
        sorter: options.sorter ?? false,
    })
}
