import { ReactElement } from 'react'

import { HistoryOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { LogOutIcon, UserIcon } from 'components/Icons'
import { AUTH_PAGE, ORGANIZATION_PAGE } from 'const/pages'
import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { getPath } from 'helpers/pages'
import { useCurrentOrganization, useUserHasPermissions } from 'hooks'

import * as styles from './styles.scss'

interface Props {
    signOutRequest: () => void
    email: string
}

const ProfileMenu = ({ signOutRequest, email }: Props): ReactElement => {
    const { t } = useTranslation('common')
    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )
    const currentOrganization = useCurrentOrganization()
    const history = useHistory()
    const dispatch = useDispatch()

    const header: MenuProps['items'] = [
        {
            key: 'profileHeader',
            label: (
                <div className={styles['profile-info']}>
                    <div className={styles['organization-text']}>
                        {currentOrganization?.name ?? ''}
                    </div>
                    <div className={styles['email-text']}>{email}</div>
                </div>
            ),
            icon: <UserIcon className={styles['icon-circle']} />,
            onClick: () => history.push(getPath(ORGANIZATION_PAGE)),
        },
    ]

    const accountAndIntegrations: MenuProps['items'] = [
        {
            key: 'accountAndIntegrations',

            label: t('common:accountAndIntegrations', 'Account & Integrations'),
            icon: <SettingOutlined />,
            onClick: () => history.push(getPath(ORGANIZATION_PAGE)),
        },
    ]

    const logOut: MenuProps['items'] = [
        {
            key: 'logOut',
            label: t('common:logOut', 'Log Out'),
            icon: <LogOutIcon />,
            onClick: () => {
                dispatch(signOutRequest())
                history.push(getPath(AUTH_PAGE))
            },
        },
    ]

    const developmentActions: MenuProps['items'] = [
        {
            key: 'resetUi',
            label: 'Reset UI Customizations',
            icon: <HistoryOutlined />,
            onClick: () => {
                window.localStorage.removeItem('persist:ui')
            },
        },
    ]

    const items: MenuProps['items'] = [
        ...header,
        {
            type: 'divider',
        },
        ...(dashboardOnlyExperience ? [] : accountAndIntegrations),
        ...logOut,
        ...(process.env.NODE_ENV === 'development' ? developmentActions : []),
    ]

    return (
        <Dropdown
            overlayClassName={styles['profile-menu']}
            trigger={['click']}
            placement="bottomLeft"
            menu={{ items }}
        >
            <Button
                color="default"
                variant="link"
                className="fg-text-muted ant-btn ant-btn-link"
            >
                <UserIcon /> {t('common:profile', 'Profile')}
            </Button>
        </Dropdown>
    )
}

export default ProfileMenu
