import { addCache } from 'helpers/utilities'
import {
    CerebroApiOptions,
    BannerRequestBody,
    CerebroResourceResponse,
    CerebroPaginatedResponse,
    Report,
} from 'types'

import { axiosInstanceOrganizations } from './client'
import { getCerebroHeaders } from './headers'

/**
 * Gets relevant SC Sales and Traffic data, grouped and filtered
 * by specified parameters
 */
export const getScSalesAndTrafficData = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/seller_central_sales_and_traffic_facts/aggregate/banner/',
        params,
        { headers: getCerebroHeaders(options.headers) }
    )

/**
 * Get a CSV export for SC Sales and Traffic, grouped and filtered
 * by specified parameters
 */
export const getScSalesAndTrafficDataExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/seller_central_sales_and_traffic_facts/aggregate/banner/export/',
        params
    )

/**
 * Gets relevant SC Order Metrics & Metadata, grouped and filtered
 * by specified parameters
 */
export const getScOrderV2Metrics = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/seller_central_order_facts/aggregate/banner/',
        params,
        { headers: getCerebroHeaders(options.headers) }
    )
addCache(getScOrderV2Metrics)

/**
 * Get a CSV export for SC Order Metrics & Metadata, grouped and filtered
 * by specified parameters
 */
export const getScOrderV2MetricsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/seller_central_order_facts/aggregate/banner/export/',
        params
    )
