import { ReactElement, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'

import { RelationshipIcon } from 'components/Icons'
import { UNDEFINED_VALUE } from 'const/formatting'
import { CellRenderProps, CountryCode, ProductAdGroupBy } from 'types'

import ProductTitle from '../ProductTitle'

type DefaultGroupBy = ProductAdGroupBy

interface Props<RecordType> extends Pick<CellRenderProps<RecordType>, 'value'> {
    productAsin: string
    productMarketplace?: CountryCode
    productImg?: string | null
    showImage?: boolean
    variant?: 'default' | 'dashboard'
    numChildren?: number
}

function SegmentProductTitleCell<
    RecordType extends DefaultGroupBy = DefaultGroupBy,
>({
    value,
    productAsin,
    productMarketplace,
    productImg,
    showImage = true,
    variant = 'default',
    numChildren = 0,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation(['segments'])

    const isParent = numChildren > 0

    const parentAsinMessage = t(
        'segments:fields.product.parent',
        'Parent ASIN ({{count}} variant)',
        {
            count: numChildren,
        }
    )

    const titleFormatter = (val: ReactNode): ReactNode =>
        val ?? (isParent ? parentAsinMessage : val) ?? UNDEFINED_VALUE

    return (
        <ProductTitle
            value={value}
            productImg={productImg}
            productAsin={productAsin}
            productMarketplace={productMarketplace}
            showImage={showImage}
            variant={variant}
            isLoading={value === 'Loading...'}
            numChildren={numChildren}
            isParent={isParent}
            titleFormatter={titleFormatter}
            parentAsinMessage={parentAsinMessage}
            additionalLinkContent={
                isParent && (
                    <>
                        <RelationshipIcon />
                        <span>Parent:</span>
                    </>
                )
            }
        />
    )
}

export default SegmentProductTitleCell
