import isEmpty from 'lodash/isEmpty'

import {
    ADVANCED_PRODUCT_TITLE,
    COUNTRIES,
    DATES,
    LABELS,
    METRIC_FILTERS,
    PRODUCT_ASINS,
    PRODUCT_TITLE,
    SC_ACCOUNTS,
    VC_INTEGRATIONS,
} from 'const/filters'
import { PRODUCT_LABELS } from 'const/resourceTypes'
import { formatCerebroDate } from 'helpers/formatting'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { FiltersState } from 'types'

import { presetRanges, calculateFromDateWithLag } from '../dateRange'
import { formatBooleanFilter } from '../filters'

import { formatMetricFiltersForParams } from './metrics'

export interface VendorCentralFilterParams {
    request__account_integration__in?: string
    product_title__icontains?: string
    report_date_min?: string
    report_date_max?: string
    seller_central_account_id__in?: string
    marketplace__in?: string
    asin__in?: string
    boolean_filter?: string
    complex_label_filter?: string
}

export const formatFilters = (
    filters: FiltersState
): VendorCentralFilterParams => {
    let params: VendorCentralFilterParams = {}

    const productTitle = filters[PRODUCT_TITLE]
    if (!!productTitle && productTitle !== '') {
        params.product_title__icontains = productTitle
    }

    const dates = filters[DATES]
    if (dates) {
        if (Array.isArray(dates)) {
            params.report_date_min = formatCerebroDate(dates[0])
            params.report_date_max = formatCerebroDate(dates[1])
        } else {
            const { range } = presetRanges(
                calculateFromDateWithLag(filters.rangeLag),
                filters.rangeLag
            )[dates]
            params.report_date_min = formatCerebroDate(range[0])
            params.report_date_max = formatCerebroDate(range[1])
        }
    }

    const scAccounts = filters[SC_ACCOUNTS]
    if (!!scAccounts && scAccounts.length > 0) {
        params.seller_central_account_id__in = scAccounts
            .map((item) => item.value)
            .join()
    }

    const countries = filters[COUNTRIES]
    if (!!countries && countries.length > 0) {
        params.marketplace__in = countries.map((item) => item.value).join()
    }

    const productAsins = filters[PRODUCT_ASINS]
    if (isNonEmptyArray(productAsins)) {
        params.asin__in = productAsins.map((item) => item.value).join()
    }

    if (!isEmpty(filters[LABELS])) {
        params.complex_label_filter = formatBooleanFilter(
            filters[LABELS],
            PRODUCT_LABELS
        )
    }

    if (!isEmpty(filters[ADVANCED_PRODUCT_TITLE])) {
        params.boolean_filter = formatBooleanFilter(
            filters[ADVANCED_PRODUCT_TITLE],
            ADVANCED_PRODUCT_TITLE
        )
    }

    if (isNonEmptyArray(filters[VC_INTEGRATIONS])) {
        params.request__account_integration__in = filters[VC_INTEGRATIONS].map(
            (item) => item.value
        ).join()
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (!!metricFilters && metricFilters.length > 0) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters!) }
    }

    return params
}
