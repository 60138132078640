import { ReactElement } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover, Table } from 'antd'
import { useSelector } from 'react-redux'

import { ENABLED } from 'const/resourceStates'
import { formatCurrency, formatMultiplier, titleCase } from 'helpers/formatting'
import { getEffectiveMultiplier } from 'helpers/timeParting'
import {
    selectEffectiveDaypartingMultipliers,
    selectIsDaypartingEnabled,
} from 'selectors/ui'
import { Campaign, CurrencyCode } from 'types'
import moment from 'utilities/moment'

import * as styles from './styles.scss'

interface Props {
    timezone: string
    currencyCode: CurrencyCode
    campaign: Campaign
    baseBid: number
    activeBid: number
}

const renderKeyColumn = (text: string): ReactElement => {
    let asterisk
    switch (text) {
        case 'Target Bid':
            asterisk = <sup>*</sup>
            break
        case 'Active Bid':
            asterisk = <sup>**</sup>
            break
        default:
            asterisk = null
    }
    return (
        <span className={styles.key}>
            {text}
            {asterisk}
        </span>
    )
}

const renderNotes = (): ReactElement => (
    <div className={styles.notes}>
        <div>
            <sup>*</sup>
            Bid the dayparting system will eventually place.
        </div>
        <div>
            <sup>**</sup>
            Bid that is currently being placed. Due to system lag, the{' '}
            <strong>Active Bid</strong> may be different than{' '}
            <strong>Target Bid</strong>. These bids will converge to the{' '}
            <strong>Target Bid</strong> throughout the hour.
        </div>
    </div>
)

const isDaypartingActive = (
    isDaypartingEnabled: boolean,
    { state: campaignState }: { state: string }
): boolean => isDaypartingEnabled && campaignState === ENABLED

const isDaypartingEnabledAndInactive = (
    isDaypartingEnabled: boolean,
    { state: campaignState }: { state: string }
): boolean => isDaypartingEnabled && campaignState !== ENABLED

const ActiveBidCell = ({
    timezone,
    currencyCode,
    baseBid,
    activeBid,
    campaign,
}: Props): ReactElement => {
    const { daypartingMultipliers, isDaypartingEnabled } = useSelector(
        (state) => ({
            daypartingMultipliers: selectEffectiveDaypartingMultipliers(state),
            isDaypartingEnabled: selectIsDaypartingEnabled(state),
        })
    )

    const popoverContent = (): ReactElement => {
        if (isDaypartingActive(isDaypartingEnabled, campaign)) {
            const { effectiveTimeFormatted, multiplier } =
                getEffectiveMultiplier(
                    daypartingMultipliers,
                    timezone,
                    moment()
                )
            return (
                <div className={styles['popover-table-container']}>
                    <div className={styles.header}>Dayparting Bid Details</div>
                    <div className={styles.table}>
                        <Table
                            columns={[
                                {
                                    title: 'Key',
                                    dataIndex: 'key',
                                    key: 'key',
                                    render: renderKeyColumn,
                                },
                                {
                                    title: 'value',
                                    dataIndex: 'value',
                                    key: 'value',
                                },
                            ]}
                            dataSource={[
                                {
                                    key: 'Timezone',
                                    value: timezone,
                                },
                                {
                                    key: 'Effective Time',
                                    value: effectiveTimeFormatted,
                                },
                                {
                                    key: 'Bid',
                                    value: formatCurrency(baseBid, {
                                        decimal: true,
                                        currencyCode,
                                    }),
                                },
                                {
                                    key: 'Current Multiplier',
                                    value: formatMultiplier(multiplier),
                                },
                                {
                                    key: 'Target Bid',
                                    value: formatCurrency(
                                        (multiplier ?? 0) * baseBid,
                                        {
                                            decimal: true,
                                            currencyCode,
                                        }
                                    ),
                                },
                                {
                                    key: 'Active Bid',
                                    value: formatCurrency(activeBid, {
                                        decimal: true,
                                        currencyCode,
                                    }),
                                },
                            ]}
                            size="small"
                            bordered={false}
                            showHeader={false}
                            pagination={false}
                        />
                        {renderNotes()}
                    </div>
                </div>
            )
        }
        if (isDaypartingEnabledAndInactive(isDaypartingEnabled, campaign)) {
            return (
                <div className={styles['popover-text-container']}>
                    <strong>Active Bid</strong> is the same as{' '}
                    <strong>Bid</strong> when the Campaign is{' '}
                    <em>{titleCase(campaign.state)}</em>. The dayparting system
                    only makes bid changes for <em>Enabled</em> campaigns.
                </div>
            )
        }
        return (
            <div className={styles['popover-text-container']}>
                <strong>Active Bid</strong> is the same as <strong>Bid</strong>{' '}
                when Dayparting is <em>Disabled</em>.
            </div>
        )
    }

    return (
        <div className={styles['cell-container']}>
            <div>
                {formatCurrency(activeBid, {
                    decimal: true,
                    currencyCode,
                })}
            </div>
            <div>
                <Popover content={popoverContent()}>
                    <QuestionCircleOutlined />
                </Popover>
            </div>
        </div>
    )
}

export default ActiveBidCell
