import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import ImageNotFoundIconSvg from './ImageNotFoundIconSvg'

const ImageNotFoundIcon = (props: IconProps): ReactElement => (
    <Icon component={ImageNotFoundIconSvg} {...props} />
)

export default ImageNotFoundIcon
