import { ReactElement } from 'react'

import classNames from 'classnames'
import { ErrorMessage } from 'formik'

import { IconButton } from 'components/Buttons'
import { TrashIcon } from 'components/Icons'
import Stack from 'components/Layout/Stack'
import { UNDEFINED_VALUE } from 'const/formatting'

import { ColumnConfig, BaseAdItem } from './localTypes'
import * as styles from './styles.scss'

interface Props<AdItemField> {
    adItem: AdItemField
    adItemIndex: number
    remove: (index: number) => void
    columnsConfig: ColumnConfig[]
}

function CreateAdItemFormRow<AdItemField extends BaseAdItem>({
    adItem,
    adItemIndex,
    remove,
    columnsConfig,
}: Props<AdItemField>): ReactElement {
    return (
        <Stack
            direction="row"
            alignItems="start"
            className={styles['ad-item-row']}
            style={{ marginTop: 8, paddingBottom: 8 }}
        >
            <div
                style={{ width: '18%', marginRight: 4 }}
                className={styles['text-field']}
            >
                {adItem.itemId}
            </div>
            <div
                style={{ flex: 1, marginRight: 4 }}
                className={styles['text-field']}
            >
                <div className={styles['name-field']}>
                    {adItem.name ? adItem.name : UNDEFINED_VALUE}
                </div>
            </div>
            {columnsConfig.map(({ dataKey, width, renderField }) => {
                const fieldName = `adItems.${adItemIndex}.${dataKey}`
                return (
                    <Stack
                        direction="column"
                        alignItems="center"
                        className={styles.field}
                        style={{
                            minWidth: width,
                            width,
                            marginRight: 4,
                        }}
                        key={dataKey}
                    >
                        {renderField(fieldName)}
                        <ErrorMessage
                            name={fieldName}
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </Stack>
                )
            })}

            <Stack
                direction="column"
                alignItems="center"
                className={classNames(styles.field, styles['remove-column'])}
                style={{ marginRight: 4 }}
            >
                <IconButton
                    icon={<TrashIcon />}
                    danger
                    type="link"
                    onClick={() => {
                        remove(adItemIndex)
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default CreateAdItemFormRow
