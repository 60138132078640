import { ReactElement, useState } from 'react'

import { Popover, Divider } from 'antd'
import noop from 'lodash/noop'
import { useSelector } from 'react-redux'

import { LoadingIndicator } from 'components/LoadingIndicator'
import TimeSeriesWidget from 'components/Widgets/TimeSeriesWidget/TimeSeriesWidget'
import { formatFilters } from 'helpers/filters/keywordFacts'
import { formatPagination } from 'helpers/params'
import {
    formatWidgetGroupBy,
    getWidgetMetrics,
    getWidgetApiFunc,
} from 'helpers/ui/dashboardPage'
import { useAppFilters, useCerebroApiRequest } from 'hooks'
import { defaultTimeSeriesWidgetConfig } from 'reducers/ui/defaults'
import { selectCurrencyCode } from 'selectors/ui'
import {
    CerebroPaginatedResponse,
    Detail,
    FieldMapping,
    ModifiedWidget,
    NonTextWidgetDataSourceKey,
    WidgetFilterMap,
} from 'types'

import { Details } from '../ResourceDetailsV2/Details'

import * as styles from './styles.scss'

interface Props {
    title: ReactElement
    details: Detail[]
    filters: WidgetFilterMap
    dataSource: NonTextWidgetDataSourceKey
    onPopoverVisible?: () => void
    metricsConfig: FieldMapping
}

function AtAGlancePopover<T>({
    title,
    details,
    filters,
    dataSource,
    onPopoverVisible = noop,
    metricsConfig,
}: Props): ReactElement {
    const appFilters = useAppFilters()
    const makeCerebroApiRequest = useCerebroApiRequest()
    const currency = useSelector(selectCurrencyCode)
    const [timeSeriesLoading, setTimeSeriesLoading] = useState(true)
    const [timeSeriesData, setTimeSeriesData] = useState<T[]>([])

    const timeSeriesWidget: ModifiedWidget = {
        ...defaultTimeSeriesWidgetConfig(
            '',
            dataSource,
            '',
            ['cost__sum', 'attributed_sales_14_day__sum'],
            { x: 0, y: 0 }
        ),
        filters,
    }

    const handleVisibleChange = (visible: boolean): void => {
        if (visible) {
            onPopoverVisible()
            // Time series widget
            setTimeSeriesLoading(true)
            makeCerebroApiRequest<T, CerebroPaginatedResponse<T>>({
                request: getWidgetApiFunc(timeSeriesWidget)({
                    ...formatFilters({
                        ...appFilters,
                        ...filters,
                    }),
                    ...formatPagination(timeSeriesWidget.pagination),
                    ...formatWidgetGroupBy(timeSeriesWidget, appFilters),
                    currency,
                    metrics: getWidgetMetrics(timeSeriesWidget).join(),
                }),
                onRequestSuccess: (response) => {
                    setTimeSeriesData(response.data.results)
                    setTimeSeriesLoading(false)
                },
                onRequestFailure: () => setTimeSeriesLoading(false),
            })
        }
    }

    const content = (
        <div className={styles['popover-wrapper']}>
            <div className={styles['popover-title']}>{title}</div>
            <Details details={details} />
            <Divider className={styles['popover-divider']} />
            <div style={{ height: '185px' }}>
                {timeSeriesLoading ? (
                    <LoadingIndicator size="x_small" />
                ) : (
                    <div className="at-a-glance-chart">
                        <TimeSeriesWidget
                            widget={{
                                ...timeSeriesWidget,
                                ...formatWidgetGroupBy(
                                    timeSeriesWidget,
                                    appFilters,
                                    'array'
                                ),
                            }}
                            widgetData={timeSeriesData}
                            widgetPriorPeriodData={[]}
                            filters={appFilters}
                            chartHeight="40%"
                            showLegend={false}
                            metricsConfig={metricsConfig}
                        />
                    </div>
                )}
            </div>
        </div>
    )

    return (
        <Popover
            content={content}
            mouseEnterDelay={1}
            onOpenChange={handleVisibleChange}
            placement="right"
        >
            {title}
        </Popover>
    )
}

export default AtAGlancePopover
