import flow from 'lodash/fp/flow'
import mapValues from 'lodash/fp/mapValues'
import set from 'lodash/fp/set'
import update from 'lodash/fp/update'
import get from 'lodash/get'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import pick from 'lodash/pick'
import { Action } from 'redux-actions'
import { validate as isUuid } from 'uuid'

import {
    fetchFilterSettingsSuccess,
    fetchFiltersSuccess,
    resetFilters,
    resetLocalFilters,
    setFilters,
    setSavedFilterSet,
    updateFilter,
    updateFilterSettings,
    updateLocalFilter,
} from 'actions/ui/shared/filter'
import { AGGREGATION, DATES } from 'const/filters'
import { DASHBOARD_PAGE, HOME_PAGE } from 'const/pages'
import {
    FILTER_SETTINGS,
    FILTER_UPDATED_AT,
    FILTERS,
    LOCAL_FILTERS,
} from 'const/reducerKeys'
import { getCurrentTimestamp } from 'helpers/utils'
import {
    ActionForPathPayload,
    FiltersState,
    UiFilterSetConfiguration,
} from 'types'
import { Tab } from 'types/dashboards'

import { defaultDatesFilter } from '../defaults'
import { getPristineUiStateClone } from '../ui'

export const updateFilters = (
    targetFilters: any,
    sourceFilters: any
): boolean => {
    let updated = false
    if (targetFilters && sourceFilters) {
        Object.keys(targetFilters).forEach((filterKey) => {
            if (filterKey in sourceFilters) {
                targetFilters[filterKey] = sourceFilters[filterKey]
                updated = true
            } else if (
                filterKey !== DATES &&
                filterKey !== AGGREGATION &&
                !!targetFilters[filterKey]
            ) {
                // we need dates, so it's the only one we don't reset
                if (Array.isArray(targetFilters[filterKey])) {
                    updated = updated || targetFilters[filterKey].length > 0
                    targetFilters[filterKey] = []
                } else if (typeof targetFilters[filterKey] === 'object') {
                    updated =
                        updated ||
                        Object.keys(targetFilters[filterKey]).length > 0
                    targetFilters[filterKey] = {}
                } else if (typeof targetFilters[filterKey] === 'string') {
                    updated = updated || targetFilters[filterKey].length > 0
                    targetFilters[filterKey] = ''
                } else {
                    updated = updated || targetFilters[filterKey] !== undefined
                    targetFilters[filterKey] = undefined
                }
            }
        })
    }
    return updated
}

export const makeDesiredFiltersVisible = (
    targetFilterSettings: any,
    sourceFilters: any
): boolean => {
    let updated = false
    if (targetFilterSettings && sourceFilters) {
        if (!targetFilterSettings.displayState) {
            targetFilterSettings.displayState = {}
        }
        if (!targetFilterSettings.order) {
            targetFilterSettings.order = []
        }
        if (!targetFilterSettings.userAdded) {
            targetFilterSettings.userAdded = []
        }
        Object.keys(sourceFilters).forEach((filterKey) => {
            if (filterKey in targetFilterSettings.displayState) {
                targetFilterSettings.displayState[filterKey] = true
                updated = true
            }
            if (!targetFilterSettings.order.includes(filterKey)) {
                targetFilterSettings.order.push(filterKey)
                updated = true
            }
            if (!targetFilterSettings.userAdded.includes(filterKey)) {
                targetFilterSettings.userAdded.push(filterKey)
                updated = true
            }
        })
    }
    return updated
}

export default {
    // Filters
    [fetchFiltersSuccess.toString()](
        state: any,
        action: ReturnType<typeof fetchFiltersSuccess>
    ) {
        const {
            path,
            data: { filters },
        } = action.payload

        return flow(
            set([...path, FILTERS], filters),
            set([...path, FILTER_UPDATED_AT], getCurrentTimestamp())
        )(state)
    },
    [updateLocalFilter.toString()](
        state: any,
        action: ReturnType<typeof updateLocalFilter>
    ) {
        const {
            path,
            data: { key, value, automaticallyMade },
        } = action.payload

        let newState = flow(
            set([...path, LOCAL_FILTERS, key], value),
            set([...path, FILTER_UPDATED_AT], getCurrentTimestamp()),
            automaticallyMade
                ? (inFlightState) => inFlightState
                : set([...path, FILTER_SETTINGS, 'touched'], true),
            automaticallyMade
                ? (inFlightState) => inFlightState
                : set([...path, FILTER_SETTINGS, 'savedFilterSet'], undefined)
        )(state)

        const page = path[0]
        if (page === DASHBOARD_PAGE || page === HOME_PAGE) {
            newState = set([page, 'isDirty'], true, newState)
        }

        if (has(newState, [...path, 'tabs'])) {
            return update(
                [...path, 'tabs'],
                mapValues((tab: Partial<Tab>) => ({
                    ...tab,
                    loaded: false,
                }))
            )(newState)
        }

        return newState
    },
    [updateFilter.toString()](
        state: any,
        action: ReturnType<typeof updateFilter>
    ) {
        const {
            path,
            data: { key, value },
        } = action.payload

        const newState = flow(
            set([...path, FILTERS, key], value),
            set([...path, FILTER_UPDATED_AT], getCurrentTimestamp()),
            set([...path, FILTER_SETTINGS, 'touched'], true),
            set([...path, FILTER_SETTINGS, 'savedFilterSet'], undefined)
        )(state)

        if (has(newState, [...path, 'tabs'])) {
            return update(
                [...path, 'tabs'],
                mapValues((tab: Partial<Tab>) => ({
                    ...tab,
                    loaded: false,
                }))
            )(newState)
        }

        return newState
    },
    [resetFilters.toString()](
        state: any,
        action: ReturnType<typeof resetFilters>
    ) {
        const { path } = action.payload
        const defaultUiStateClone = getPristineUiStateClone()
        const pageDefaultState = get(defaultUiStateClone, path)

        if (pageDefaultState) {
            const newState = flow(
                set([...path, FILTERS], pageDefaultState[FILTERS]),
                set([...path, LOCAL_FILTERS], pageDefaultState[LOCAL_FILTERS]),
                set(
                    [...path, FILTER_SETTINGS],
                    pageDefaultState[FILTER_SETTINGS]
                ),
                set([...path, FILTER_UPDATED_AT], getCurrentTimestamp())
            )(state)

            if (has(newState, [...path, 'tabs'])) {
                return update(
                    [...path, 'tabs'],
                    mapValues((tab: Partial<Tab>) => ({
                        ...tab,
                        loaded: false,
                    }))
                )(newState)
            }

            return newState
        }

        return state
    },
    [setSavedFilterSet.toString()](
        state: any,
        action: Action<
            ActionForPathPayload<UiFilterSetConfiguration | undefined>
        >
    ) {
        const { path, data: filterSet } = action.payload
        return flow(
            set([...path, FILTER_SETTINGS, 'savedFilterSet'], filterSet),
            set([...path, FILTER_UPDATED_AT], getCurrentTimestamp())
        )(state)
    },
    [setFilters.toString()](
        state: any,
        action: Action<ActionForPathPayload<UiFilterSetConfiguration>>
    ) {
        const { path, data: filterSet } = action.payload
        const { config } = filterSet
        const { filters: actionFilters } = config

        const defaultUiStateClone = getPristineUiStateClone()
        const pageDefaultState = get(defaultUiStateClone, path)

        const defaultFilters: FiltersState = pageDefaultState?.[FILTERS] || {}
        const defaultLocalFilters: FiltersState =
            pageDefaultState?.[LOCAL_FILTERS] || {}
        const defaultOrder: string[] =
            pageDefaultState?.[FILTER_SETTINGS]?.order || []

        const desiredSettings = {
            anchored: [
                ...(pageDefaultState?.[FILTER_SETTINGS]?.anchored || []),
            ],
            order: [...defaultOrder],
            displayState: defaultOrder.reduce(
                (acc: { [key: string]: boolean }, key) => {
                    acc[key] = false
                    return acc
                },
                {}
            ),
            userAdded: [],
            touched: true,
            savedFilterSet: filterSet,
        }
        const desiredLocalFilters: FiltersState = JSON.parse(
            JSON.stringify(defaultLocalFilters)
        )
        const desiredFilters: FiltersState = JSON.parse(
            JSON.stringify(defaultFilters)
        )

        updateFilters(desiredLocalFilters, actionFilters)
        updateFilters(desiredFilters, actionFilters)
        makeDesiredFiltersVisible(desiredSettings, actionFilters)

        return flow(
            set([...path, FILTERS], desiredFilters),
            set([...path, LOCAL_FILTERS], desiredLocalFilters),
            set([...path, FILTER_SETTINGS], desiredSettings),
            set([...path, FILTER_UPDATED_AT], getCurrentTimestamp())
        )(state)
    },
    [resetLocalFilters.toString()](
        state: any,
        action: ReturnType<typeof resetLocalFilters>
    ) {
        const { path } = action.payload
        const defaultUiStateClone = getPristineUiStateClone()
        const pageDefaultState = get(defaultUiStateClone, path)

        if (pageDefaultState) {
            return set(
                [...path, LOCAL_FILTERS],
                pageDefaultState[LOCAL_FILTERS],
                state
            )
        }

        // If path refers to a specific tab
        const pathTail = path.slice(-2)
        if (pathTail[0] === 'tabs' && isUuid(pathTail[1])) {
            const page = path[0]
            return flow(
                set([page, 'isDirty'], true),
                set([...path, LOCAL_FILTERS], { [DATES]: defaultDatesFilter })
            )(state)
        }

        return state
    },

    // Filter Settings
    [updateFilterSettings.toString()](
        state: any,
        action: ReturnType<typeof updateFilterSettings>
    ) {
        const {
            path,
            data: { anchored, order, displayState, userAdded },
        } = action.payload

        return update(
            [...path, FILTER_SETTINGS],
            (filterSettings) => ({
                // Absorb anchored only when array is provided
                anchored: isArray(anchored)
                    ? anchored
                    : filterSettings.anchored,

                // Absorb order only when array is provided
                order: isArray(order) ? order : filterSettings.order,

                // We *MERGE* display state to update
                // the display state of filters given only
                displayState: {
                    ...filterSettings.displayState,
                    ...displayState,
                },

                userAdded: isArray(userAdded)
                    ? userAdded
                    : filterSettings.userAdded,
                touched: true,
                savedFilterSet: undefined,
            }),
            state
        )
    },
    [fetchFilterSettingsSuccess.toString()](
        state: any,
        action: ReturnType<typeof fetchFilterSettingsSuccess>
    ) {
        const { path, data: filterSettings } = action.payload

        return set(
            [...path, FILTER_SETTINGS],
            pick(filterSettings, ['order', 'anchored', 'displayState']),
            state
        )
    },
}
