import { JSXElementConstructor, ReactElement } from 'react'

import { Alert, Flex, Typography } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'

import LockIcon from 'components/Icons/LockIcon/LockIconSvg'
import SlashCircleIcon from 'components/Icons/SlashCircleIcon/SlashCircleIconSvg'
import CustomPopover from 'components/Popover/Popover'
import { TableColumnTooltipsMetadata } from 'configuration/fieldCreators/localTypes'

import * as styles from './styles.scss'

type MetricTooltipAlertType = 'disallowed' | 'locked'

export interface FieldTooltipProps {
    title: ReactElement | string
    subtext?: string | null | ReactElement
    body?: ReactElement | string
    children: ReactElement | string
    placement?: TooltipPlacement
    alertMessage?: ReactElement | string | null
    alertType?: MetricTooltipAlertType
    zIndex?: number
    callToAction?:
        | (() => ReactElement<any, string | JSXElementConstructor<any>>)
        | null
    metadata?: TableColumnTooltipsMetadata
}
export default function FieldTooltip({
    title,
    body,
    children,
    placement,
    alertMessage,
    alertType,
    zIndex,
    subtext = null,
    callToAction = null,
    metadata: _unused_metadata,
}: FieldTooltipProps): ReactElement {
    let alertIcon: ReactElement | undefined
    let subTextIcon: ReactElement | undefined

    if (alertType === 'disallowed') {
        alertIcon = (
            <SlashCircleIcon
                style={{
                    fontSize: 18,
                    opacity: 0.75,
                }}
            />
        )
    } else if (alertType === 'locked') {
        alertIcon = (
            <LockIcon
                style={{
                    fontSize: 18,
                    opacity: 0.5,
                }}
            />
        )
        subTextIcon = (
            <LockIcon
                style={{
                    fontSize: 14,
                    opacity: 0.5,
                }}
            />
        )
    }

    const titleContent = (
        <Flex
            vertical
            style={{
                maxWidth: 400,
                padding: '14px 16px 18px',
                boxSizing: 'border-box',
            }}
            onClick={(e) => {
                e.stopPropagation()
            }}
            gap={4}
        >
            {alertMessage && (
                <Alert
                    message={
                        <div>
                            <Typography.Paragraph
                                style={{
                                    color: 'rgba(0, 0, 0, 0.75)',
                                    fontSize: '12.6px',
                                    lineHeight: '1.4',
                                    margin: 0,
                                    fontWeight: '400',
                                    display: 'flex',
                                    alignItems: 'center', // Align items vertically
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {alertIcon && (
                                        <div
                                            style={{
                                                marginRight: '0.7em',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {alertIcon}
                                        </div>
                                    )}
                                    <span>{alertMessage}</span>
                                </div>
                            </Typography.Paragraph>
                            {callToAction && callToAction()}
                        </div>
                    }
                    showIcon={false}
                    style={{
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderBottom: '1px solid #d9d9d9',
                        borderRadius: '6px 6px 0 0',
                        backgroundColor: '#FAFAFA',
                        margin: '-14px -16px 16px',
                    }}
                />
            )}
            {title}
            {body}
        </Flex>
    )
    return (
        <CustomPopover
            title={titleContent}
            rootClassName={styles['field-tooltip']}
            placement={placement}
            zIndex={zIndex}
        >
            <div>
                <div>{children ?? '-'}</div>
                {subtext && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span className={styles['column-tooltip']}>
                            {subtext}
                        </span>
                        {subTextIcon}
                    </div>
                )}
            </div>
        </CustomPopover>
    )
}
