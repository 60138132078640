import get from 'lodash/get'
import startCase from 'lodash/startCase'

import { CURRENCIES } from 'configuration/currency'
import { SELLER_BRAND_TYPE } from 'const/brands'
import {
    DAY_FORMAT,
    MONTH_FORMAT,
    OTHER,
    UNDEFINED_VALUE,
} from 'const/formatting'
import {
    BROAD,
    EXACT,
    NEGATIVE_EXACT,
    NEGATIVE_PHRASE,
    PHRASE,
} from 'const/matchTypes'
import moment from 'utilities/moment'
import numeral from 'utilities/numeral'

import { isUnset } from './utilities'

export const titleCase = (value) => {
    if (typeof value !== 'string') {
        return value
    }
    return value ? startCase(value.toLowerCase()) : ''
}

/**
 *
 * ------------ DATE FORMATTING --------------- /
 *
 */

export const HC_DATE_FORMAT = {
    hour: '%l%P',
    day: '%b %e',
    week: '%b %e',
    month: "%b '%y",
    year: '%Y',
    full: '%b %e %H:%M',
}

export const formatCerebroDate = (value) =>
    value ? moment(value).format('YYYY-MM-DD') : ''

export const formatJSONDateTime = (value) =>
    value ? moment(value).format('YYYY-MM-DDTHH:mm') : ''

export const formatCerebroDateTime = (value) =>
    value
        ? moment(value).local().format('MM/DD/YYYY, h:mm:ss a')
        : UNDEFINED_VALUE

export const formatDay = (value) =>
    value ? moment(value).format(DAY_FORMAT) : UNDEFINED_VALUE

export const formatDayofWeek = (value) => {
    if (isUnset(value)) {
        return UNDEFINED_VALUE
    }
    const dayNumber = parseInt(value, 10) % 7
    if (dayNumber < 0) {
        return OTHER
    }
    return moment().day(dayNumber).format('dddd')
}

export const formatDayOfWeekFromRecord = (record, path) =>
    formatDayofWeek(get(record, path))

export const formatQuarter = (value) => {
    if (isUnset(value)) {
        return UNDEFINED_VALUE
    }
    const quarterNumber = parseInt(value, 10) % 4
    if (quarterNumber < 0) {
        return OTHER
    }
    return moment().quarter(quarterNumber).format('Qo')
}

export const formatHourOfDay = (militaryTimeHour) => {
    if (isUnset(militaryTimeHour)) {
        return UNDEFINED_VALUE
    }
    const hourNumber = parseInt(militaryTimeHour, 10) % 24
    if (hourNumber < 0) {
        return OTHER
    }
    return moment().hour(hourNumber).startOf('hour').format('ha')
}

export const formatHourOfDayFromRecord = (record, path) =>
    formatHourOfDay(get(record, path))

export const formatMonthName = (value) => {
    if (isUnset(value)) {
        return UNDEFINED_VALUE
    }
    const monthNumber = (parseInt(value, 10) - 1) % 12
    if (monthNumber < 0) {
        return OTHER
    }
    return moment().month(monthNumber).format('MMMM')
}

const formatMonthYear = (value) => {
    if (isUnset(value)) {
        return OTHER
    }
    return moment(value).format(MONTH_FORMAT)
}

export const formatMonthNameFromRecord = (record, path) =>
    formatMonthName(get(record, path))

export const formatMonthYearNameFromRecord = (record, path) =>
    formatMonthYear(get(record, path))

export const formatMatchType = (text) => {
    switch (text) {
        case EXACT:
        case PHRASE:
        case BROAD:
            return titleCase(text)
        case NEGATIVE_EXACT:
        case NEGATIVE_PHRASE:
            return titleCase(text.replace('negative', 'Negative '))
        default:
            return titleCase(text)
    }
}

export const formatPercentage = (value, format = '0.00%') => {
    if (isUnset(value)) {
        return UNDEFINED_VALUE
    }
    return numeral(value).format(format)
}

export const formatCurrency = (
    value,
    { decimal = false, abbreviate = false, currencyCode = '' }
) => {
    const originalLocale = numeral.options.currentLocale
    const currency = CURRENCIES[currencyCode]
    if (currency) {
        numeral.locale(currencyCode)
    }

    let returnValue
    if (decimal && abbreviate) {
        returnValue = numeral(value).format('$0.[00]a')
    } else if (decimal) {
        returnValue = numeral(value).format('$0,0.00')
    } else if (abbreviate) {
        returnValue = numeral(value).format('$0a')
    } else {
        returnValue = numeral(value).format('$0,0')
    }

    if (currency) {
        numeral.locale(originalLocale)
    } else {
        // Fallback for currencies we don't officially support
        return returnValue.replace('$', `${currencyCode}$`)
    }

    return returnValue
}

export const formatNumber = (
    value,
    format = '0,0',
    showUnsetAsNumber = true,
    convertToDecimalPercentage = false
) => {
    if (!showUnsetAsNumber && isUnset(value)) {
        return UNDEFINED_VALUE
    }

    const num = numeral(value)
    if (convertToDecimalPercentage) {
        num.divide(100)
    }
    return num.format(format)
}

export const formatAbbreviatedMetric = (
    value,
    format,
    shortFormat = '',
    showUnsetAsNumber = true,
    convert_to_decimal_percentage = false
) => {
    const abbreviationThreshold = 1000 // threshold value of abbreviation
    if (!showUnsetAsNumber && isUnset(value)) {
        return UNDEFINED_VALUE
    }

    if (format === 'YYYY-MM-DD') {
        return formatCerebroDate(value)
    }

    if (
        numeral._.includes(format, '%') ||
        shortFormat === '' ||
        format === shortFormat
    ) {
        return formatNumber(
            value,
            format,
            showUnsetAsNumber,
            convert_to_decimal_percentage
        )
    }

    return numeral(value).format(
        Number(value) >= abbreviationThreshold ? shortFormat : format
    )
}

export const formatMultiplier = (
    multiplier,
    multiplierFormatPercent = false
) => {
    if (multiplierFormatPercent) {
        return `${numeral(multiplier).format('%')}`
    }
    return `${numeral(multiplier).format('0.00')} x`
}

/**
 * @deprecated Use _formatAdAccountName_ instead.
 * @see formatAdAccountName
 */
export const formatBrandName = (
    {
        brand_name: brandName,
        country_code: countryCode,
        brand_entity_id: brandEntityId,
        type,
    },
    options = { appendCountry: true }
) => {
    let formattedBrandName

    if (brandName) {
        formattedBrandName = brandName
    } else if (type === SELLER_BRAND_TYPE) {
        formattedBrandName = `Seller Account ${brandEntityId}`
    } else {
        formattedBrandName = brandEntityId
    }

    if (options.appendCountry && countryCode) {
        formattedBrandName = `${formattedBrandName} (${countryCode})`
    }

    return formattedBrandName
}

/**
 * @todo Rename formatBrandName after removing all code references to it.
 * @see formatBrandName
 */
export const formatAdAccountName = (
    adAccount,
    options = { appendCountry: true }
) => formatBrandName(adAccount, options)

export const formatUrl = (url) => {
    if (!url.match(/^https?:\/\//)) {
        return `http://${url}`
    }
    return url
}

export const formatSovKeywordLabel = (keyword) => {
    const metadata = [
        keyword.country_code,
        keyword.language_code,
        titleCase(keyword.platform),
    ].join(' | ')
    return `${keyword.text} (${metadata})`
}

export const formatAccountType = (account_type) => {
    if (isUnset(account_type)) {
        return UNDEFINED_VALUE
    }

    if (typeof account_type !== 'string') {
        return account_type
    }

    if (account_type.toUpperCase() === account_type) {
        return account_type
    }
    return titleCase(account_type)
}

export const getValueFormatter = (type, currencyCode, format) => (value) => {
    return type === 'currencyAmount'
        ? formatCurrency(value, {
              decimal: true,
              abbreviate: true,
              currencyCode,
          })
        : formatNumber(value, format)
}

export const formatProductName = (record, path) => {
    const product_metadata = get(record, path)
    if (isUnset(product_metadata)) {
        const children_count = get(record, 'variant_count__max')
        const product_id = get(record, 'product_id')
        const ASIN = product_id?.split('_')[1]
        if (children_count > 0) {
            return `Parent ASIN (${children_count} variant${
                children_count > 1 ? 's' : ''
            }): ${ASIN}`
        }
        return UNDEFINED_VALUE
    }
    return product_metadata.title
}
