import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { AUTOMATION_ICONS } from 'const/automations'
import { AutomationCapabilityId } from 'types'

interface Props {
    capabilityId: AutomationCapabilityId
    className?: string
}

export default function AutomationCapabilityIcon({
    capabilityId,
    className,
}: Props): ReactElement {
    return (
        <Icon
            component={AUTOMATION_ICONS[capabilityId] as any}
            className={className}
            style={{ fontSize: '1.2rem', marginRight: 4 }}
        />
    )
}
