import {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import set from 'lodash/fp/set'
import noop from 'lodash/noop'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import {
    makeUpdateWidgetPagination,
    makeUpdateWidgetSorter,
    makeUpdateWidgetTableColumns,
} from 'actions/ui/shared/dashboard'
import { PaginatedTable } from 'components/PaginatedTable'
import { makeMapping } from 'configuration/dataSources/helpers'
import { DATES, RANGE_LAG } from 'const/filters'
import { WIDGET_GRID_Y_MARGIN, WIDGET_GRID_ROW_HEIGHT } from 'const/widgets'
import GroupByTooltip from 'features/MetricsGlossary/components/GroupByTooltip'
import MetricTooltip from 'features/MetricsGlossary/components/MetricTooltip'
import {
    useGetDataSourceGroupBysList,
    useGetDataSourceMetricsList,
} from 'features/MetricsGlossary/queries'
import { DataSource } from 'features/MetricsGlossary/types'
import {
    getResponsiveTablePaginationOptions,
    PaginationSize,
} from 'helpers/pagination'
import {
    deserializeColumns,
    serializeColumns,
} from 'helpers/tables/serialization'
import { isGroupedByDate } from 'helpers/ui/dashboardPage'
import {
    Widget as IWidget,
    Field,
    PeriodDeltaType,
    DateRangeFilter,
    WidgetFilterMap,
} from 'types'

import * as styles from './styles.scss'

interface Props {
    widget: IWidget
    isLoading: boolean
    widgetData: any[]
    totalCount?: number
    pageNumber: number
    onPageNumberChange: (page: number) => void
    filters: WidgetFilterMap
    dataSource?: DataSource
    metricsColumns: Field<any>[]
    groupByColumns: Field<any>[]
}

function calculatePaginationSize(width: number): PaginationSize {
    if (width < 500) {
        return 'xs'
    }
    if (width < 700) {
        return 'sm'
    }
    return 'default'
}

const HEADER_BASE_HEIGHT = 70
const TITLE_HEIGHT = 31
const DESCRIPTION_HEIGHT = 16
const PAGINATION_ROW_HEIGHT = 36

function getHeaderHeight(tableWrapper: HTMLDivElement | null): number {
    if (!tableWrapper) {
        return 0
    }
    const tableHeader = tableWrapper.querySelector('th')
    return tableHeader?.clientHeight ?? 0
}

function TableWidget({
    widget,
    isLoading,
    widgetData,
    totalCount,
    pageNumber,
    onPageNumberChange,
    filters,
    dataSource,
    metricsColumns,
    groupByColumns,
}: Props): ReactElement {
    const dispatch = useDispatch()

    const tableWidgetWrapper = useRef<HTMLDivElement>(null)
    const [tableHeaderHeight, setTableHeaderHeight] = useState(0)

    const { data: groupByListData } = useGetDataSourceGroupBysList(
        dataSource?.slug
    )
    const { data: metricListData } = useGetDataSourceMetricsList(
        dataSource?.slug
    )
    const [paginationSize, setPaginationSize] = useState(
        calculatePaginationSize(tableWidgetWrapper.current?.clientWidth ?? 0)
    )

    const handleResize = useCallback((): void => {
        const node = tableWidgetWrapper.current
        if (node) {
            const currentActualTableHeaderHeight = getHeaderHeight(node)
            setTableHeaderHeight(currentActualTableHeaderHeight)
            setPaginationSize(calculatePaginationSize(node.clientWidth))
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })

    const {
        sorter,
        group_by,
        metrics,
        include_change_metrics,
        layout,
        name,
        description,
        period_delta_type,
        period_delta_date_range,
    } = widget

    const pageSize = widget.pagination?.pageSize

    const data = widgetData ?? []

    useEffect(() => {
        handleResize()
    }, [layout.h, layout.w, handleResize])

    const groupByColumnsTransformed = (
        groupByColumns
            ? deserializeColumns<any>(group_by, groupByColumns, false)
            : []
    ).map((each) => {
        const matchingGroupBy =
            each.groupBy ??
            groupByListData?.data?.results.find((m) =>
                m.metadata_all_names.includes(each.id)
            )

        return {
            ...each,
            antTableColumnOptions: {
                ...each.antTableColumnOptions,
                title: matchingGroupBy ? (
                    <GroupByTooltip groupBy={matchingGroupBy}>
                        <div className={styles['dashboard-table-header-title']}>
                            {typeof each.shortName === 'function'
                                ? each.shortName(false)
                                : each.shortName}
                        </div>
                    </GroupByTooltip>
                ) : (
                    <div className={styles['dashboard-table-header-title']}>
                        {typeof each.antTableColumnOptions?.title === 'function'
                            ? each.antTableColumnOptions?.title({}) // do we need to pass more params?
                            : each.antTableColumnOptions?.title}
                    </div>
                ),
                className: styles['title-with-tooltip'],
            },
            data: undefined,
        }
    })

    // fixed first column to left
    groupByColumnsTransformed[0] = set(
        ['antTableColumnOptions', 'fixed'],
        'left'
    )(groupByColumnsTransformed[0])

    const glossaryMetrics = metricListData?.data?.results ?? []
    let metricsColumnsTransformed = (
        metricsColumns ? deserializeColumns(metrics, metricsColumns, false) : []
    ).map((each) => {
        const matchingMetric =
            each.metric ??
            glossaryMetrics.find((m) => m.metadata_key === each.id)

        return {
            ...each,
            antTableColumnOptions: {
                ...each.antTableColumnOptions,
                title:
                    matchingMetric && dataSource ? (
                        <MetricTooltip
                            metric={matchingMetric}
                            dataSource={dataSource}
                            placement="top"
                        >
                            <div
                                className={
                                    styles['dashboard-table-header-title']
                                }
                            >
                                {typeof each.shortName === 'function'
                                    ? each.shortName(false)
                                    : each.shortName}
                            </div>
                        </MetricTooltip>
                    ) : (
                        <div className={styles['dashboard-table-header-title']}>
                            {typeof each.antTableColumnOptions?.title ===
                            'function'
                                ? each.antTableColumnOptions?.title({}) // do we need to pass more params?
                                : each.antTableColumnOptions?.title}
                        </div>
                    ),
                className: styles['title-with-tooltip'],
            },
            data: undefined,
        }
    })

    // hide totals for last value metrics when grouped by date
    if (isGroupedByDate(group_by.map((each) => each?.id ?? each))) {
        metricsColumnsTransformed = metricsColumnsTransformed.map((each) =>
            each.metricOptions?.isLastValue
                ? {
                      ...each,
                      isTotalSupported: false,
                  }
                : each
        )
    }

    const paginationHeight =
        paginationSize === 'xs'
            ? 2 * PAGINATION_ROW_HEIGHT
            : PAGINATION_ROW_HEIGHT

    const headerHeight =
        HEADER_BASE_HEIGHT +
        (name.length > 0 ? TITLE_HEIGHT : 0) +
        (description.length > 0 ? DESCRIPTION_HEIGHT : 0)

    const approxWidgetHeight =
        layout.h * WIDGET_GRID_ROW_HEIGHT +
        (layout.h - 1) * WIDGET_GRID_Y_MARGIN

    const approxContentHeight =
        headerHeight +
        (widget.show_filter_row ? 30 : 0) +
        tableHeaderHeight +
        paginationHeight

    const scroll = useMemo(() => {
        return {
            y: approxWidgetHeight - approxContentHeight,
        }
    }, [approxWidgetHeight, approxContentHeight])

    const allColumns = groupByColumnsTransformed.concat(
        metricsColumnsTransformed
    )

    return (
        <div ref={tableWidgetWrapper}>
            <PaginatedTable
                readonly
                shouldMonitorTime={false} // loading time is monitored in the parent <Widget /> component
                rowKey="id"
                columns={allColumns}
                dataSource={(data ?? []).map((record) => ({
                    id: uuid(),
                    ...record,
                }))}
                loading={!!isLoading}
                pagination={{
                    pageSize,
                    total: totalCount,
                    current: pageNumber,
                    ...getResponsiveTablePaginationOptions({ paginationSize }),
                    position: ['bottomCenter'],
                }}
                scroll={scroll}
                updateColumns={(columns) => {
                    const mapping = makeMapping(
                        serializeColumns(columns) as Field<any>[]
                    )
                    dispatch(
                        makeUpdateWidgetTableColumns()({
                            widgetId: widget.id,
                            metrics: metrics.map((each) => {
                                const key =
                                    typeof each === 'string' ? each : each.id
                                const mapped = mapping[key]
                                return mapped ?? { id: key }
                            }),
                            group_by: group_by.map((each) => {
                                const key =
                                    typeof each === 'string' ? each : each.id
                                const mapped = mapping[key]
                                return mapped ?? { id: key }
                            }),
                        })
                    )
                }}
                sorter={sorter ?? {}}
                updatePagination={(newPagination) => {
                    // Pagination in widget is used for CSV export
                    // so we need to update it in the store
                    dispatch(
                        makeUpdateWidgetPagination()({
                            widgetId: widget.id,
                            pagination: newPagination,
                        })
                    )
                    if (newPagination.current !== pageNumber) {
                        onPageNumberChange(newPagination.current ?? 1)
                    }
                }}
                updateSorter={(newSorter) => {
                    dispatch(
                        makeUpdateWidgetSorter()({
                            widgetId: widget.id,
                            sorter: {
                                ...newSorter,
                                field: (newSorter?.field as any)?.join('__'),
                            },
                        })
                    )
                }}
                updateRecord={noop}
                reloadData={noop}
                onViewChanged={noop}
                showPeriodDeltas={include_change_metrics}
                showTotalRow={widget?.show_totals ?? false}
                periodDeltaType={period_delta_type as PeriodDeltaType}
                filterDates={filters[DATES] as DateRangeFilter}
                periodDeltaDateRange={period_delta_date_range}
                rangeLag={filters[RANGE_LAG] as unknown as number}
                onHeaderRow={() => ({
                    className: styles['dashboard-table-header'],
                })}
            />
        </div>
    )
}

export default TableWidget
