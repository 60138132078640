import { ReactElement } from 'react'

import classNames from 'classnames'

import * as styles from './styles.scss'

interface Props {
    label: string
    metadata?: string
}

const SelectOptionContent = ({ label, metadata = '' }: Props): ReactElement => (
    <div
        className={classNames(styles.container, {
            [styles['container-with-metadata']]: Boolean(metadata),
        })}
    >
        <div>{label}</div>
        {metadata && <div className={styles.metadata}>{metadata}</div>}
    </div>
)

export default SelectOptionContent
