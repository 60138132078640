import { ReactElement } from 'react'

import { Link } from 'react-router-dom'

import Stack from 'components/Layout/Stack'
import { campaignUrl } from 'helpers/urls'

import { SelectedSearchTerm } from '../localTypes'
import * as styles from '../styles.scss'

interface Props {
    selectedSearchTerm: SelectedSearchTerm
}

const CampaignLabel = ({
    selectedSearchTerm: {
        record: { campaign },
    },
}: Props): ReactElement => (
    <Stack direction="column">
        <div className={styles.label}>Source Campaign</div>
        <div className={styles.value}>
            <Link to={campaignUrl(campaign.id)}>{campaign.name}</Link>
        </div>
    </Stack>
)

export default CampaignLabel
