import { ReactElement } from 'react'

import { Tooltip } from 'antd'
import { TFunction } from 'i18next'
import { Translation } from 'react-i18next'

import { Flag } from 'components/Flag'
import {
    AmazonIcon,
    BookIcon,
    CreateIcon,
    CurrencyIcon,
    LocationPinIcon,
    ScheduleIcon,
} from 'components/Icons'
import { PriceIcon } from 'components/Icons/PriceIcon'
import { TimezoneIcon } from 'components/Icons/TimezoneIcon'
import Stack from 'components/Layout/Stack'
import { AsinLink } from 'components/Links'
import { DetailImage } from 'components/ResourceDetailsV2/Renderers/shared/DetailImage'
import { StatusIndicator } from 'components/StatusIndicator'
import { TextDividerVertical } from 'components/TextDividerVertical'
import { CURRENCIES } from 'configuration/currency'
import { ENABLED } from 'const/resourceStates'
import { getCountryNames } from 'helpers/countries'
import { formatDate } from 'helpers/dates'
import { formatCurrency, titleCase } from 'helpers/formatting'
import { CountryCode, CurrencyCode } from 'types'

import { DetailIcon } from './shared/DetailIcon'
import DetailTag from './shared/DetailTag'
import { DetailText } from './shared/DetailText'
import { DetailWrapper } from './shared/DetailWrapper'

export function createdDateRenderer(date: string): ReactElement {
    return (
        <DetailWrapper
            leftContent={
                <DetailIcon
                    icon={CreateIcon}
                    tooltipTitle={
                        <Translation>
                            {(t) => t('common:createdDate', 'Created Date')}
                        </Translation>
                    }
                />
            }
            rightContent={<DetailText text={date} />}
        />
    )
}

export function countryRenderer(country: CountryCode): ReactElement {
    return (
        <DetailWrapper
            leftContent={
                <DetailTag
                    text={
                        <Translation>
                            {(t) => t('common:country', 'Country')}
                        </Translation>
                    }
                />
            }
            rightContent={<DetailText text={<Flag country={country} />} />}
        />
    )
}

export function countryPinRenderer(
    countryCode: CountryCode,
    addIcon = true,
    showFullCountryName = false
): ReactElement {
    return (
        <DetailWrapper
            leftContent={
                addIcon && (
                    <DetailIcon
                        icon={LocationPinIcon}
                        tooltipTitle={
                            <Translation>
                                {(t) => t('common:country', 'Country')}
                            </Translation>
                        }
                    />
                )
            }
            rightContent={
                <DetailText
                    text={
                        <span className="fg-flag-container">
                            <Flag country={countryCode} />
                            <Translation>
                                {(t) => (
                                    <div>
                                        {showFullCountryName
                                            ? getCountryNames(t)[countryCode]
                                            : countryCode}
                                    </div>
                                )}
                            </Translation>
                        </span>
                    }
                />
            }
        />
    )
}

export function currencyRenderer(
    currencyCode: CurrencyCode | string
): ReactElement {
    const symbol =
        currencyCode in CURRENCIES ? CURRENCIES[currencyCode].symbol : '$'
    const text = `(${symbol}) ${currencyCode}`

    return (
        <DetailWrapper
            leftContent={
                <DetailIcon
                    icon={CurrencyIcon}
                    tooltipTitle={
                        <Translation>
                            {(t) => t('common:currency', 'Currency')}
                        </Translation>
                    }
                />
            }
            rightContent={<DetailText text={text} />}
        />
    )
}

export function timezoneRenderer(tz: string): ReactElement {
    return (
        <DetailWrapper
            leftContent={
                <DetailIcon
                    icon={TimezoneIcon}
                    tooltipTitle={
                        <Translation>
                            {(t) => t('common:timezone', 'Timezone')}
                        </Translation>
                    }
                />
            }
            rightContent={<DetailText text={tz} />}
        />
    )
}

export function urlRenderer(url: string): ReactElement {
    return (
        <DetailWrapper
            leftContent={<DetailTag text="URL" />}
            rightContent={<DetailText text={url} />}
        />
    )
}

export function budgetRenderer(
    budget: number,
    currencyCode: string
): ReactElement {
    const text = formatCurrency(budget, { currencyCode, decimal: true })
    return (
        <DetailWrapper
            leftContent={
                <DetailTag
                    text={
                        <Translation>
                            {(t) => t('common:budget', 'Budget')}
                        </Translation>
                    }
                />
            }
            rightContent={<DetailText text={text} />}
        />
    )
}

export function bidRenderer(bid: number, currencyCode: string): ReactElement {
    const text = formatCurrency(bid, { currencyCode, decimal: true })
    return (
        <DetailWrapper
            leftContent={
                <DetailTag
                    text={
                        <Translation>
                            {(t) => t('common:bid', 'Bid')}
                        </Translation>
                    }
                />
            }
            rightContent={<DetailText text={text} />}
        />
    )
}

export function scheduleRenderer(schedulable: {
    start_date: string | null
    end_date: string | null
}): ReactElement {
    const tooltip = (
        <>
            <Translation>
                {(t) => t('common:startDate', 'Start Date')}
            </Translation>
            {' \u2014 '}
            <Translation>{(t) => t('common:endDate', 'End Date')}</Translation>
        </>
    )

    const { start_date, end_date } = schedulable
    const startDate = start_date ? (
        formatDate(start_date, undefined, 'LL')
    ) : (
        <Translation>
            {(t) => t('common:noStartDate', 'No start date')}
        </Translation>
    )
    const endDate = end_date ? (
        formatDate(end_date, undefined, 'LL')
    ) : (
        <Translation>{(t) => t('common:noEndDate', 'No end date')}</Translation>
    )
    const text = (
        <>
            {startDate}
            {' \u2014 '}
            {endDate}
        </>
    )

    return (
        <DetailWrapper
            leftContent={
                <DetailIcon icon={ScheduleIcon} tooltipTitle={tooltip} />
            }
            rightContent={<DetailText text={text} />}
        />
    )
}

export function priceRenderer(
    price: number | null,
    currencyCode: CurrencyCode | string | null
): ReactElement {
    const text = formatCurrency(price, {
        currencyCode: currencyCode || '',
        decimal: true,
    })
    return (
        <DetailWrapper
            leftContent={
                <DetailIcon
                    icon={PriceIcon}
                    tooltipTitle={
                        <Translation>
                            {(t) => t('common:price', 'Price')}
                        </Translation>
                    }
                />
            }
            rightContent={<DetailText text={text} />}
        />
    )
}

export function imageRenderer(
    imageUrl: string | null,
    imageAlt: string
): ReactElement {
    return <DetailImage imageUrl={imageUrl} alt={imageAlt} />
}

export function asinRenderer(
    asin: string,
    countryCode: CountryCode,
    parentAsin: string | null
): ReactElement {
    const tooltip = (
        <>
            ASIN
            {parentAsin && (
                <>
                    <TextDividerVertical />
                    <Translation>
                        {(t) => t('common:parentAsin', 'Parent ASIN')}
                    </Translation>
                </>
            )}
        </>
    )

    const text = (
        <Stack component="span" direction="row" alignItems="center">
            <AsinLink
                asin={asin}
                countryCode={countryCode}
                showAmazonIcon={false}
            />
            {parentAsin && (
                <>
                    <TextDividerVertical />
                    <Translation>
                        {(t) => t('common:parent', 'Parent')}
                    </Translation>
                    <span style={{ marginRight: '5px' }}>:</span>
                    <AsinLink
                        asin={parentAsin}
                        countryCode={countryCode}
                        showAmazonIcon={false}
                    />
                </>
            )}
        </Stack>
    )

    return (
        <DetailWrapper
            leftContent={
                <DetailIcon icon={AmazonIcon} tooltipTitle={tooltip} />
            }
            rightContent={<DetailText text={text} />}
        />
    )
}

export function stateRenderer(
    state: string,
    tooltipText: string | null = null,
    enabledState: string = ENABLED
): ReactElement {
    const statusIndicator = (
        <StatusIndicator
            enabled={state === enabledState}
            text={titleCase(state)}
        />
    )
    return tooltipText ? (
        <Tooltip title={tooltipText} placement="top">
            <div>{statusIndicator}</div>
        </Tooltip>
    ) : (
        statusIndicator
    )
}

export function bookAuthorRenderer(
    t: TFunction,
    author: string | null,
    publisher: string | null
): ReactElement {
    const tooltip = (
        <>
            {t('common:author', 'Author')}
            <TextDividerVertical />
            {t('common:publisher', 'Publisher')}
        </>
    )

    const text = (
        <Stack component="span" direction="row" alignItems="center">
            {author || t('common:unknown', 'Unknown')}
            <TextDividerVertical />
            {publisher || t('common:unknown', 'Unknown')}
        </Stack>
    )

    return (
        <DetailWrapper
            leftContent={<DetailIcon icon={BookIcon} tooltipTitle={tooltip} />}
            rightContent={<DetailText text={text} />}
        />
    )
}
