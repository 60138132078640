import { ReactElement } from 'react'

import { Button } from 'antd'
import { Formik, FormikHelpers, FieldArray } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import { array, object, string } from 'yup'

import Stack from 'components/Layout/Stack'
import { NEGATIVE_EXACT, NEGATIVE_PHRASE } from 'const/matchTypes'
import { useCerebroApiRequest } from 'hooks'
import { createNegativeKeyword } from 'services/cerebroApi/orgScope/resourceApi'
import { AdGroup, Keyword } from 'types'
import message from 'utilities/message'

import CreateResourceModal from '../CreateResourceModal/CreateResourceModal'
import { BulkAddTextArea, Item } from '../Shared'

import CreateAdGroupNegativeKeywordForm from './CreateAdGroupNegativeKeywordForm'
import { FormValues, KeywordField } from './localTypes'

interface Props {
    adGroup: AdGroup | null
    isModalVisible: boolean
    onModalCancel: () => void
    toggleModalVisible: () => void
    reloadData: () => void
}

function CreateNegativeKeywordModal({
    adGroup,
    isModalVisible,
    onModalCancel,
    reloadData,
    toggleModalVisible,
}: Props): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const closeModal = (callback: any): void => {
        callback()
        toggleModalVisible()
        reloadData()
    }

    const submitForm = async (
        { keywords }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ): Promise<void> => {
        const promises = keywords.map(async (keyword) =>
            makeCerebroApiRequest<Keyword>({
                request: createNegativeKeyword({
                    text: keyword.searchTerm,
                    match_type: keyword.matchType,
                    campaign_id: adGroup?.campaign_id,
                    ad_group_id: adGroup?.id,
                }),
            })
        )

        const responses = await Promise.all(promises)

        if (responses.every((response) => response !== null)) {
            message.success(
                `Successfully created ${keywords.length} negative keywords on ad group "${adGroup?.name}"`
            )
            closeModal(resetForm)
        } else if (responses.some((response) => !isNull(response))) {
            closeModal(resetForm)
        }
    }

    const initialValues: FormValues = { keywords: [] }

    const validationSchema = object().shape({
        keywords: array().of(
            object().shape({
                // static values
                searchTerm: string().required(),

                // form values
                matchType: string()
                    .label('Match Type')
                    .matches(new RegExp(`${NEGATIVE_EXACT}|${NEGATIVE_PHRASE}`))
                    .required(),
            })
        ),
    })

    const deserializeInitialFieldArrayValue = (value: Item): KeywordField => {
        return {
            searchTerm: value.keyword as string,
            matchType: NEGATIVE_EXACT,
        }
    }

    const handleFieldArrayPush = (
        value: Item,
        push: (obj: any) => void
    ): void => {
        const keyword = deserializeInitialFieldArrayValue(value)
        push(keyword)
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={submitForm}
            validationSchema={validationSchema}
        >
            {({
                isSubmitting,
                resetForm,
                handleSubmit,
                values,
                setFieldValue,
            }) => (
                <CreateResourceModal
                    open={isModalVisible}
                    title={<div>Add Negative Keywords</div>}
                    onCancel={onModalCancel}
                    width={900}
                    footer={[
                        <Button
                            key={1}
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                resetForm()
                                toggleModalVisible()
                            }}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key={2}
                            type="primary"
                            onClick={() => handleSubmit()}
                            loading={isSubmitting}
                            disabled={isEmpty(values.keywords) || isSubmitting}
                        >
                            Create Keywords
                        </Button>,
                    ]}
                >
                    <FieldArray
                        name="keywords"
                        render={(arrayHelpers) => (
                            <Stack direction="column">
                                <BulkAddTextArea
                                    label="Negative Keyword"
                                    type="keyword"
                                    loading={false}
                                    push={(item) => {
                                        handleFieldArrayPush(
                                            item,
                                            arrayHelpers.push
                                        )
                                    }}
                                />
                                <CreateAdGroupNegativeKeywordForm
                                    keywords={values.keywords}
                                    remove={arrayHelpers.remove}
                                    setFieldValue={setFieldValue}
                                />
                            </Stack>
                        )}
                    />
                </CreateResourceModal>
            )}
        </Formik>
    )
}

export default CreateNegativeKeywordModal
