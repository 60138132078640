import { ReactElement, useEffect, useRef } from 'react'

import Quill from 'quill'

import { RichTextValue } from './types'

interface RichTextViewerProps {
    value?: RichTextValue
}

const RichTextViewer = ({ value }: RichTextViewerProps): ReactElement => {
    const quillRef = useRef<Quill | undefined>(undefined)

    const containerRef = useRef<HTMLDivElement>(null)
    const defaultValueRef = useRef(value)

    useEffect(() => {
        if (quillRef.current) {
            quillRef.current.setContents(value ?? [])
        }
    }, [value])

    useEffect(() => {
        const container = containerRef.current
        const editorContainer = container?.appendChild(
            container.ownerDocument.createElement('div')
        )
        const quill = new Quill(editorContainer!, {
            theme: undefined,
            readOnly: true,
        })
        quill.setContents(defaultValueRef.current ?? [])

        quillRef.current = quill

        return () => {
            if (quillRef.current) {
                quillRef.current = undefined
            }
            if (container) {
                container.innerHTML = ''
            }
        }
    }, [])

    return <div ref={containerRef}></div>
}

RichTextViewer.displayName = 'RichTextViewer'

export default RichTextViewer
