import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import ScheduleIconSvg from './ScheduleIconSvg'

const ScheduleIcon = (props: IconProps): ReactElement => (
    <Icon component={ScheduleIconSvg} {...props} />
)

export default ScheduleIcon
