import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { addCache } from 'helpers/utilities'
import {
    CerebroRequestParams,
    Dashboard,
    ForgeSettings,
    OrgAccountStatus,
    Organization,
    OrganizationGroup,
    OrganizationInvitation,
    OrganizationPermission,
    PaginatedResponse,
    User,
    UserMfaSettings,
    UserPermission,
    UserSettings,
} from 'types'

import { getCerebroHeaders } from '../orgScope/headers'

import { axiosInstance } from './client'

export const getInvitations = (
    params: AxiosRequestConfig
): Promise<AxiosResponse<OrganizationInvitation[]>> =>
    axiosInstance.get('/api/invitations/', { params })
addCache(getInvitations)

export const patchInvitation = (
    invitationId: number,
    params: AxiosRequestConfig
): Promise<AxiosResponse<OrganizationInvitation>> =>
    axiosInstance.patch(`/api/invitation/${invitationId}/`, params)

export const getCurrentUser = (): Promise<AxiosResponse<User>> =>
    axiosInstance.get(`/api/user/`)

export const getUserOrganizations = (
    params: CerebroRequestParams
): Promise<AxiosResponse<PaginatedResponse<Organization>>> =>
    axiosInstance.get(`/api/user/organizations/`, { params })

export const getUserOrganizationGroups = (
    params: CerebroRequestParams
): Promise<AxiosResponse<PaginatedResponse<OrganizationGroup>>> =>
    axiosInstance.get(`/api/user/organization_groups/`, { params })

export const getUserFeaturePermissions = (): Promise<
    AxiosResponse<UserPermission[]>
> => axiosInstance.get('/api/feature_permissions/')

export const getUserOrganizationFeaturePermissions = (
    organizationId: number
): Promise<AxiosResponse<OrganizationPermission[]>> =>
    axiosInstance.get('/api/feature_permissions/', {
        headers: {
            'x-dsi-org-id': organizationId,
        },
        params: { ordering: 'friendly_name' },
    })

export const getUserOrganizationGroupFeaturePermissions = (
    organizationGroupId: number
): Promise<AxiosResponse<OrganizationPermission[]>> =>
    axiosInstance.get('/api/feature_permissions/', {
        headers: {
            'x-dsi-org-group-id': organizationGroupId,
        },
        params: { ordering: 'friendly_name' },
    })

export const getPrintableDashboard = (
    dashboardId: string,
    options: AxiosRequestConfig = {}
): Promise<AxiosResponse<Dashboard>> => {
    return axiosInstance.get(`api/dashboard/${dashboardId}/`, {
        headers: getCerebroHeaders(options.headers),
    })
}

export const getOrganization = (
    organizationId: string | number
): Promise<AxiosResponse<Organization>> =>
    axiosInstance.get(`api/organization/${organizationId}/`, {
        validateStatus: (status) => status < 300,
    })
addCache(getOrganization)

export const getOrganizationAccountStatus = (
    organizationId: string | number
): Promise<AxiosResponse<OrgAccountStatus>> =>
    axiosInstance.get(`api/organization/${organizationId}/account_status/`)

export const getForgeSettings = async (): Promise<ForgeSettings> => {
    const {
        data: { results },
        status,
    } = await axiosInstance.get(`api/application_settings/`)

    if (status === 200) {
        const settings: {
            [key: string]: any
        } = {}
        results.forEach((item: any) => {
            settings[item.name] = item.value
        })

        let globalNotification = {}
        try {
            globalNotification = JSON.parse(
                settings.global_notification ?? '{}'
            )
        } catch (err) {
            console.error(
                'error while parsing the global notification (banner)',
                err
            )
        }

        return {
            displayDowntimePage: false,
            displayMaintenancePage:
                settings.display_maintenance_page === 'true',
            globalNotification,
        }
    }

    return {
        displayDowntimePage: true,
        displayMaintenancePage: false,
        globalNotification: {},
    }
}

export const getUserSettings = async (): Promise<
    Omit<UserSettings, 'lastUpdated'>
> => {
    const {
        data: { results },
    } = await axiosInstance.get(`api/user_settings/`)
    const settings = results?.[0] ?? {}

    return {
        organizationId: settings.organization ?? null,
        organizationGroupId: settings.organization_group ?? null,
        feedbackSurveyLastRead: settings.feedback_survey_last_read ?? null,
        latestNewFeatureId: settings.latest_new_feature_id ?? null,
        hiddenAutomationCapabilities: JSON.parse(
            settings.hidden_automation_capabilities ?? '[]'
        ),
        currency: JSON.parse(settings.currency ?? '{}'),
        homeDashboardId: JSON.parse(settings.home_dashboard_id ?? '{}'),
        gilId: settings.gil_id ?? null,
    }
}

export const updateUserSettings = (newSettings: UserSettings): Promise<void> =>
    axiosInstance.post(`api/user_settings/`, {
        organization_id: newSettings.organizationId,
        organization_group_id: newSettings.organizationGroupId,
        feedback_survey_last_read: newSettings.feedbackSurveyLastRead,
        latest_new_feature_id: newSettings.latestNewFeatureId,
        hidden_automation_capabilities: JSON.stringify(
            newSettings.hiddenAutomationCapabilities
        ),
        currency: JSON.stringify(newSettings.currency),
        home_dashboard_id: JSON.stringify(newSettings.homeDashboardId),
    })

export const getUserMfaSettings = (): Promise<AxiosResponse<UserMfaSettings>> =>
    axiosInstance.get(`api/user_mfa_settings/`)

export const updateUserPhoneNumber = (
    phoneNumber: string,
    accessToken: string
): Promise<AxiosResponse> =>
    axiosInstance.put(`api/user_mfa_settings/phone_number/`, {
        phone_number: phoneNumber,
        access_token: accessToken,
    })

export const triggerUserPhoneNumberVerification = (
    accessToken: string
): Promise<AxiosResponse> =>
    axiosInstance.post(
        `api/user_mfa_settings/phone_number/verification/codes/`,
        {
            access_token: accessToken,
        }
    )

export const verifyUserPhoneNumber = (
    smsCode: string,
    accessToken: string
): Promise<AxiosResponse> =>
    axiosInstance.post(`api/user_mfa_settings/phone_number/verification/`, {
        sms_code: smsCode,
        access_token: accessToken,
    })

export const getMfaSettingsAccessToken = (
    email: string,
    password: string
): Promise<AxiosResponse> => {
    return axiosInstance.post(`api/user_mfa_settings/token/`, {
        email,
        password,
    })
}
