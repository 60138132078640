import { ReactElement } from 'react'

import { Popover } from 'antd'
import { FieldConfig, FormikBag } from 'formik'
import { CirclePicker, ColorResult } from 'react-color'

import { ColorCircle } from 'components/ColorCircle'
import { DEFAULT_COLOR_PICKER_PROPS } from 'const/colors'

interface FormikColorPickerProps {
    field: FieldConfig
    form: FormikBag<any, any>
}

function FormikSmallColorPicker({
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    ...props
}: FormikColorPickerProps): ReactElement {
    const handleChangeComplete = (color: ColorResult): void => {
        setFieldValue(name, color.hex)
        setFieldTouched(name, true)
    }

    const picker = (
        <CirclePicker
            color={value}
            onChangeComplete={handleChangeComplete}
            {...DEFAULT_COLOR_PICKER_PROPS}
            {...props}
        />
    )

    return (
        <Popover title={picker}>
            <div style={{ display: 'inline-block' }}>
                <ColorCircle
                    color={value ?? DEFAULT_COLOR_PICKER_PROPS.colors[0]}
                />
            </div>
        </Popover>
    )
}

export default FormikSmallColorPicker
