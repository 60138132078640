import { CSSProperties, ReactElement, ReactNode } from 'react'

import { Button } from 'antd'

import { useAuthDomainValue } from 'hooks'

interface Props {
    children: ReactNode
    className?: string
    style?: CSSProperties
}

const SubmitButton = ({ children, ...rest }: Props): ReactElement => {
    const isSubmitting: boolean = useAuthDomainValue('isFetching')
    return (
        <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            size="large"
            data-cy="submit-button"
            {...rest}
        >
            {children}
        </Button>
    )
}

export default SubmitButton
