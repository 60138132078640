import { ReactNode, ReactElement, CSSProperties } from 'react'

import { MetricGoalField } from 'components/EditableFields/MetricGoalField'
import { UNDEFINED_VALUE } from 'const/formatting'
import { CellRenderProps, ProductFactRecord } from 'types'
import {
    MetricGoalDeltaMetadataKey,
    MetricGoalFormat,
    MetricGoalLastPeriodMetadataKey,
    MetricGoalMetadataKey,
    MetricGoalPerformanceIndexMetadataKey,
} from 'types/resources/metricGoals'
import numeral from 'utilities/numeral'

import * as variables from '../../../styles/_variables.scss'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    fieldName: MetricGoalMetadataKey | MetricGoalLastPeriodMetadataKey
    format?: MetricGoalFormat
}

export function productMetricGoalRenderer<
    RecordType extends ProductFactRecord,
>({
    cellRenderProps: { readonly, record },
    fieldName,
    format,
}: RenderProps<RecordType>): ReactNode {
    const resourceId = record.metadata?.id ?? record.product_id
    if (!resourceId) {
        return UNDEFINED_VALUE
    }
    return (
        <MetricGoalField
            goalFieldName={fieldName}
            readOnly={readonly}
            record={record}
            resourceType="product"
            resourceId={resourceId}
            format={format}
        />
    )
}

function MetricGoalDeltaField({
    deltaFieldName,
    record,
    format,
}: {
    deltaFieldName: MetricGoalDeltaMetadataKey | MetricGoalLastPeriodMetadataKey
    record: ProductFactRecord
    format?: MetricGoalFormat
}): ReactElement {
    const formatValue = (value?: number | null): string => {
        if (value == null) {
            return UNDEFINED_VALUE
        }
        if (format === 'currency') {
            return `$${value.toFixed(2)}`
        }
        if (format === 'percentage') {
            return `${numeral(value.toFixed(2)).format('0.[00]%')}`
        }
        return `${value.toFixed(2)}`
    }

    const calcColorStyles = (
        value?: number | null
    ): CSSProperties | undefined => {
        if (value == null) {
            return undefined
        }
        if (value > 0) {
            return { backgroundColor: variables.errorBackcolor }
        }
        if (value < 0) {
            return { backgroundColor: variables.successBackcolor }
        }
        return undefined
    }

    return (
        <span
            style={{
                ...calcColorStyles(record[deltaFieldName]),
                padding: '2px 4px',
                borderRadius: 4,
                display: 'inline-block',
            }}
        >
            {formatValue(record[deltaFieldName])}
        </span>
    )
}

type DeltaRenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    fieldName: MetricGoalDeltaMetadataKey | MetricGoalLastPeriodMetadataKey
    format?: MetricGoalFormat
}

export function productMetricGoalDifferenceRenderer<
    RecordType extends ProductFactRecord,
>({
    cellRenderProps: { record },
    fieldName,
    format,
}: DeltaRenderProps<RecordType>): ReactNode {
    return (
        <MetricGoalDeltaField
            deltaFieldName={fieldName}
            record={record}
            format={format}
        />
    )
}

function MetricGoalPerformanceIndexField({
    fieldName,
    record,
    format,
}: {
    fieldName:
        | MetricGoalPerformanceIndexMetadataKey
        | MetricGoalLastPeriodMetadataKey
    record: ProductFactRecord
    format?: MetricGoalFormat
}): ReactElement {
    const formatValue = (value?: number | null): string => {
        if (value == null) {
            return UNDEFINED_VALUE
        }
        if (format === 'currency') {
            return `$${value.toFixed(2)}`
        }
        return `${numeral(value.toFixed(2)).format('0.[00]%')}`
    }

    const calcColorStyles = (
        value?: number | null
    ): CSSProperties | undefined => {
        if (value == null) {
            return undefined
        }
        if (format === 'percentage') {
            if (Number(value.toPrecision(3)) > 1) {
                return { backgroundColor: variables.errorBackcolor }
            }
            if (value < 1) {
                return { backgroundColor: variables.successBackcolor }
            }
        }

        return undefined
    }

    return (
        <span
            style={{
                ...calcColorStyles(record[fieldName]),
                padding: '2px 4px',
                borderRadius: 4,
                display: 'inline-block',
            }}
        >
            {formatValue(record[fieldName])}
        </span>
    )
}

type PerformanceIndexRenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    fieldName:
        | MetricGoalPerformanceIndexMetadataKey
        | MetricGoalLastPeriodMetadataKey
    format?: MetricGoalFormat
}

export function productMetricGoalPerformanceIndexRenderer<
    RecordType extends ProductFactRecord,
>({
    cellRenderProps: { record },
    fieldName,
    format,
}: PerformanceIndexRenderProps<RecordType>): ReactNode {
    return (
        <MetricGoalPerformanceIndexField
            fieldName={fieldName}
            record={record}
            format={format}
        />
    )
}
