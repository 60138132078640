import get from 'lodash/get'

import { CURRENCIES } from 'configuration/currency'

import {
    formatDecimal,
    parseCurrency,
    parsePercentageInput,
} from './inputNumber'
import { isUnset } from './utilities'

export const getMetricValueFormatter =
    (metricType: string, currencyCode = 'USD') =>
    (value?: string | number): string => {
        if (isUnset(value)) {
            return ''
        }

        if (metricType === 'currencyAmount') {
            return `${get(
                CURRENCIES,
                [currencyCode, 'symbol'],
                ''
            )} ${formatDecimal(value)}`
        }

        if (metricType === 'percentage' || metricType === 'percentage_as_is') {
            return `${value}%`
        }

        return formatDecimal(value)
    }

export const getMetricValueParser =
    (metricType: string) =>
    (value: string): string => {
        if (metricType === 'currencyAmount') {
            return parseCurrency(value)
        }

        if (metricType === 'percentage' || metricType === 'percentage_as_is') {
            return parsePercentageInput(value)
        }

        return value
    }
