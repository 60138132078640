import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import TargetingIconSvg from './TargetingIconSvg'

const TargetingIcon = (props: IconProps): ReactElement => (
    <Icon component={TargetingIconSvg} {...props} />
)

export default TargetingIcon
