import get from 'lodash/get'
import { Layout } from 'react-grid-layout'

import { PAGE_DASHBOARD_CONFIG } from 'configuration/widgets'
import {
    CONVERSIONS_TAB,
    PERFORMANCE_TAB,
    REACH_TAB,
    ROI_TAB,
} from 'const/dashboards'
import { DATES } from 'const/filters'
import { FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { METRIC, TIME_SERIES } from 'const/widgets'
import { getWidgetLayoutSize } from 'helpers/layout'
import {
    FieldPropertiesSerializable,
    ModifiedWidget,
    NonTextWidgetDataSourceKey,
    PeriodDeltaType,
    Sorter,
    Widget,
} from 'types'

import { ListState, TableState } from '../shared/dataManagement'

export const defaultDatesFilter = 'last_week'

export const defaultTab = {
    mounting: true,
    loading: false,
    error: null,
}

export const defaultChart = {
    loading: false,
    downloading: false,
    error: null,
    data: [],
}

interface DefaultListOptions {
    sorter?: Sorter
}

export const getDefaultList = (options: DefaultListOptions = {}): ListState => {
    const { sorter } = options
    return {
        loading: false,
        downloading: false,
        error: null,
        data: [],
        listSettings: {
            sorter: sorter ?? {
                field: 'attributed_sales_14_day__sum',
                order: 'descend',
            },
        },
    }
}

export interface DefaultTableOptions {
    pagination?: any
    sorter?: Sorter
    showPeriodDeltas?: boolean
    showTotalRow?: boolean
}

export const getDefaultTable = (
    columns: FieldPropertiesSerializable[],
    options: DefaultTableOptions = {}
): TableState => {
    const { pagination, sorter, showPeriodDeltas, showTotalRow } = options
    return {
        loading: false,
        downloading: false,
        error: null,
        data: [],
        tableSettings: {
            pagination: pagination ?? {
                pageSize: 10,
                current: 1,
                total: 0,
            },
            sorter: sorter ?? {
                field: 'attributed_sales_14_day__sum',
                order: 'descend',
            },
            columns,
            showPeriodDeltas: showPeriodDeltas ?? false,
            periodDeltaType: 'prior_period',
            periodDeltaDateRange: [],
            showTotalRow: showTotalRow ?? false,
        },
    }
}

export const defaultAsyncState = {
    hydrated: false,
}

export const defaultPage = {
    error: null,
    mounting: true,
}

export const defaultResourcePage = {
    ...defaultPage,

    resourceParams: null,
    resource: null,
    addingLabels: false,
    updating: false,
}

export const defaultDashboardPage = {
    ...defaultPage,

    dashboardId: null,
    dashboard: null,
    name: null,
    description: null,
    tabs: {},
    widgets: {},
}

export const defaultOrganicSearchPositionChart = {
    loading: false,
    data: [],
    selectedAsin: undefined,
}

interface PaginationAndSorter {
    pagination: {
        pageSize?: number
        current: number
        total: number
    }
    sorter: Sorter
}

export const defaultPaginationAndSorter = (
    widget: Widget
): PaginationAndSorter => ({
    pagination: {
        pageSize: widget.limit,
        current: 1,
        total: 0,
    },
    sorter: {
        field: widget.order_by,
        order: widget.order_by_direction,
    },
})

export const defaultDashboardTabState = {
    loaded: false,
    mounting: null,
    loading: null,
    loadedAt: null,
    filterUpdatedAt: null,
    [FILTER_SETTINGS]: null,
    [FILTERS]: [DATES],
}

export const defaultWidgetState = {
    new: false, // TRUE if newly added or cloned
    pagination: {},
    sorter: {},
    isZoomedOut: false,
}

interface DefaultWidgetLayout {
    layout?: Layout
}

export const defaultWidgetLayout = (
    widget: Widget,
    y = 0
): DefaultWidgetLayout =>
    !widget.layout
        ? {
              layout: {
                  i: widget.id,
                  x: 0,
                  y,
                  ...getWidgetLayoutSize(widget),
              },
          }
        : {}

export const defaultResourcePageTabs = {
    [ROI_TAB]: {
        widgets: ['roi-metric', 'roi-line-1', 'roi-line-2', 'roi-line-3'],
        id: ROI_TAB,
        name: 'Return On Investment',
        loaded: false,
    },
    [REACH_TAB]: {
        widgets: ['reach-metric', 'reach-line-1', 'reach-line-2'],
        id: REACH_TAB,
        name: 'Reach',
        loaded: false,
    },
    [CONVERSIONS_TAB]: {
        widgets: [
            'conversions-metric',
            'conversions-line-1',
            'conversions-line-2',
        ],
        id: CONVERSIONS_TAB,
        name: 'Conversions',
        loaded: false,
    },
}

export const defaultWidgetConfig = {
    description: '',
    group_by: [],
    filters: {},
    stacked: false,
    json: {},
}

export const defaultTimeSeriesWidgetConfig = (
    id: string,
    data_source: NonTextWidgetDataSourceKey,
    name: string,
    metrics: string[],
    layout: Partial<Layout> & { x: number; y: number }
): ModifiedWidget => {
    return {
        ...defaultWidgetState,
        ...defaultWidgetConfig,
        type: TIME_SERIES,
        data_source,
        name,
        metrics,
        id,
        layout: {
            i: layout.i ?? id,
            w: layout.w ?? 6,
            h: layout.h ?? 12,
            x: layout.x,
            y: layout.y,
        },
        pagination: {
            pageSize: 10000,
            current: 1,
        },
        period_delta_date_range: [],
    }
}

export const defaultMetricWidgetConfig = (
    id: string,
    data_source: NonTextWidgetDataSourceKey,
    name: string,
    metrics: string[],
    layout: Partial<Layout> & { x: number; y: number },
    period_delta_type: PeriodDeltaType | undefined = undefined
): ModifiedWidget => {
    const defaultLayout = {
        i: id,
        w: 12,
        h: 5,
    }
    return {
        ...defaultWidgetState,
        ...defaultWidgetConfig,
        type: METRIC,
        data_source,
        name,
        metrics,
        id,
        layout: {
            ...defaultLayout,
            ...layout,
        },
        pagination: {
            pageSize: 1,
            current: 1,
        },
        include_change_metrics: true,
        period_delta_type,
        period_delta_date_range: [],
    }
}

export const defaultResourcePageWidgets = (
    pageName: string
): Record<string, ModifiedWidget> => {
    const dataSource = get(PAGE_DASHBOARD_CONFIG, [pageName, 'dataSource'])

    if (!dataSource) {
        return {}
    }

    return {
        'roi-metric': defaultMetricWidgetConfig(
            'roi-metric',
            dataSource,
            'Summary Metrics',
            [
                'cost__sum',
                'attributed_sales_14_day__sum',
                'roas',
                'acos',
                'cpc',
            ],
            { x: 0, y: 0 }
        ),
        'roi-line-1': defaultTimeSeriesWidgetConfig(
            'roi-line-1',
            dataSource,
            'Cost and Sales',
            ['cost__sum', 'attributed_sales_14_day__sum'],
            { x: 0, y: 5, w: 12 }
        ),
        'roi-line-2': defaultTimeSeriesWidgetConfig(
            'roi-line-2',
            dataSource,
            'RoAS and ACoS',
            ['roas', 'acos'],
            { x: 0, y: 17 }
        ),
        'roi-line-3': defaultTimeSeriesWidgetConfig(
            'roi-line-3',
            dataSource,
            'Cost and Sales Per Click',
            ['cpc', 'spc'],
            { x: 6, y: 17 }
        ),
        'reach-metric': defaultMetricWidgetConfig(
            'reach-metric',
            dataSource,
            'Summary Metrics',
            ['impressions__sum', 'clicks__sum', 'ctr'],
            { x: 0, y: 0 }
        ),
        'reach-line-1': defaultTimeSeriesWidgetConfig(
            'reach-line-1',
            dataSource,
            'Impressions and Clicks',
            ['impressions__sum', 'clicks__sum'],
            { x: 0, y: 5 }
        ),
        'reach-line-2': defaultTimeSeriesWidgetConfig(
            'reach-line-2',
            dataSource,
            'Click Through Rate',
            ['ctr'],
            { x: 6, y: 5 }
        ),
        'conversions-metric': defaultMetricWidgetConfig(
            'conversions-metric',
            dataSource,
            'Summary Metrics',
            [
                'attributed_conversions_14_day__sum',
                'conversion_rate_clicks',
                'conversion_rate_impressions',
            ],
            { x: 0, y: 0 }
        ),
        'conversions-line-1': defaultTimeSeriesWidgetConfig(
            'conversions-line-1',
            dataSource,
            'Conversions',
            ['attributed_conversions_14_day__sum'],
            { x: 0, y: 5 }
        ),
        'conversions-line-2': defaultTimeSeriesWidgetConfig(
            'conversions-line-2',
            dataSource,
            'Conversion Rate',
            ['conversion_rate_clicks', 'conversion_rate_impressions'],
            { x: 6, y: 5 }
        ),
    }
}

export const defaultDspResourcePageTabs = {
    [PERFORMANCE_TAB]: {
        widgets: [
            'performance-metric',
            'performance-line-1',
            'performance-line-2',
            'performance-line-3',
        ],
        id: PERFORMANCE_TAB,
        name: 'Performance',
        loaded: false,
    },
}

export const defaultDspResourcePageWidgets = (
    pageName: string
): Record<string, ModifiedWidget> => {
    const dataSource = get(PAGE_DASHBOARD_CONFIG, [pageName, 'dataSource'])

    if (!dataSource) {
        return {}
    }

    return {
        'performance-metric': defaultMetricWidgetConfig(
            'performance-metric',
            dataSource,
            '',
            [
                'total_cost__sum',
                'impressions__sum',
                'click_throughs__sum',
                'purchases_14_day__sum',
                'new_to_brand_purchases_14_day__sum',
                'sales_14_day__sum',
            ],
            { x: 0, y: 0 }
        ),
        'performance-line-1': defaultTimeSeriesWidgetConfig(
            'performance-line-1',
            dataSource,
            '',
            ['total_cost__sum', 'total_sales_14_day__sum'],
            { x: 0, y: 5, w: 12 }
        ),
        'performance-line-2': defaultTimeSeriesWidgetConfig(
            'performance-line-2',
            dataSource,
            '',
            ['impressions__sum', 'click_throughs__sum'],
            { x: 0, y: 17 }
        ),
        'performance-line-3': defaultTimeSeriesWidgetConfig(
            'performance-line-3',
            dataSource,
            '',
            ['new_to_brand_purchases_14_day__sum'],
            { x: 6, y: 17 }
        ),
    }
}
