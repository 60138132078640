import { ReactElement } from 'react'

import * as styles from './styles.scss'

interface OptionalFormLabelProps {
    fieldName: string
}

const OptionalFormLabel = ({
    fieldName,
}: OptionalFormLabelProps): ReactElement => (
    <span>
        {fieldName} <span className={styles['optional-label']}>(optional)</span>
    </span>
)

export default OptionalFormLabel
