/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { call, select } from 'redux-saga/effects'

import {
    makeFetchResourceSuccess,
    makeUpdateResourceSuccess,
} from 'actions/ui/shared'
import { AUTOMATION_PROFILE_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectCurrencyCode, selectResourceParamsOfPage } from 'selectors/ui'
import {
    getAutomationProfile,
    updateAutomationProfile,
} from 'services/cerebroApi/orgScope/resourceApi'
import { AutomationProfile } from 'types'

export function* fetchAutomationProfileSaga() {
    const { profileId } = yield select(
        selectResourceParamsOfPage,
        AUTOMATION_PROFILE_PAGE
    )

    const currency: string = yield select(selectCurrencyCode)

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(AUTOMATION_PROFILE_PAGE),
        getAutomationProfile,
        profileId,
        { currency }
    )
}

function* updateAutomationProfileSaga(
    { profileId }: { profileId: string },
    data: AutomationProfile
) {
    yield call(
        cerebroApiSaga,
        makeUpdateResourceSuccess(AUTOMATION_PROFILE_PAGE),
        updateAutomationProfile,
        profileId,
        data
    )
}

/**
 * Mounts the Page and fetches data
 */

function* mountPageSaga() {
    yield call(fetchAutomationProfileSaga)
}

uiSagaRegistry.registerSagas([AUTOMATION_PROFILE_PAGE], {
    updateResourceSaga: updateAutomationProfileSaga,
    mountPageSaga,
    fetchPageDataSaga: fetchAutomationProfileSaga,
})
