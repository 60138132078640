import { SegmentProductInfo } from 'components/FieldRenderers/Renderers/segmentProduct'
import { makeCountMetricFieldFunc } from 'configuration/fieldCreators/metrics/localUtils'

export const weight = makeCountMetricFieldFunc<SegmentProductInfo>({
    id: 'weight',
    name: 'Weight',
    shortName: 'Weight',
})

export const dimensions = makeCountMetricFieldFunc<SegmentProductInfo>({
    id: 'total_dimension',
    name: 'Dimensions',
    shortName: 'Dimensions',
})
