import { Translation } from 'react-i18next'

import {
    segmentNameRenderer,
    sellerNameRenderer,
} from 'components/FieldRenderers/Renderers/segment'
import {
    BreadcrumbTreeRenderer,
    RankingCategoriesRenderer,
    SegmentProductNameRenderer,
    TitleRenderer,
} from 'components/FieldRenderers/Renderers/segmentProduct'
import { Field } from 'types'

import {
    createCurrencyField,
    createField,
    createNumberField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'
import translations from './metrics/translations/segments'

export function asin<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['asin']
    return createField({
        ...options,
        id: 'asin',
        name: 'ASIN',
        shortName: 'ASIN',
        minWidth: 50,
        dataIndex,
        width: 100,
        exportIndex: 0,
    })
}

export function productInfo<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'segment_product_id',
        name: 'Product',
        shortName: 'Product',
        minWidth: options.minWidth ?? 80,
        dataIndex: options.dataIndex ?? ['product_id'],
        renderOptions: {
            render: (props) =>
                TitleRenderer<any>({
                    ...props,
                    showImage: options.showImage,
                }),
        },
        width: 120,
    })
}

export function productInfoPlaceholder<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const key = 'product_info'
    const displayName = 'Product (Image + Title)'

    return {
        ...productInfo(options),
        renderOptions: {
            render: (props) =>
                TitleRenderer<any>({
                    ...props,
                    showImage: options.showImage,
                    variant: 'dashboard',
                }),
        },
        id: 'product_info',
        // This is kind of a hardcoded group by entry shim since this field doesn't come from Banner.
        // Defining it here ensures that the group by can be selected in dashboards.
        groupBy: {
            id: key,
            metadata_key: key,
            slug: 'product-info',
            metadata_all_names: [key],
            metadata_display_name: displayName,
            metadata_format: 'string',
            metadata_is_deprecated: false,
            metadata_release_date: null,
            metadata_end_of_life_date: null,
            metadata_supports_total: false,
            display_name: displayName,
            short_name: 'Product',
            definition: 'Displays the product information.',
            is_visible: true,
        },
    }
}

export function title<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'segment_product_title',
        name: 'Product',
        shortName: 'Product',
        minWidth: options.minWidth ?? 80,
        dataIndex: options.dataIndex ?? ['title'],
        renderOptions: {
            render: (props) =>
                TitleRenderer<any>({ ...props, showImage: options.showImage }),
        },
        width: 300,
        exportIndex: 7,
    })
}

export function brand<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['brand']
    return createField({
        ...options,
        id: 'brand',
        name: 'Brand',
        shortName: 'Brand',
        minWidth: options.minWidth ?? 100,
        dataIndex,
        width: options.width ?? 180,
        exportIndex: 3,
    })
}

export function price<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createCurrencyField({
        ...options,
        id: 'segment_product_price',
        name: translations.segment_product_price.name,
        shortName: translations.segment_product_price.shortName,
        minWidth: 40,
        dataIndex: options.dataIndex ?? ['price'],
        align: 'right',
        localDefinition: translations.segment_product_price.helpText,
    })
}

export function seller_id<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['seller_id']
    return createField({
        ...options,
        id: 'seller_id',
        name: 'Seller ID',
        shortName: 'Seller ID',
        minWidth: 100,
        dataIndex,
        exportIndex: 4,
    })
}

export function seller_type<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['seller_type']
    return createField({
        ...options,
        id: 'seller_type',
        name: 'Seller Type',
        shortName: 'Seller Type',
        minWidth: 100,
        dataIndex,
    })
}

export function category<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['category']
    return createField({
        ...options,
        id: 'category',
        name: 'Parent Category',
        shortName: 'Category',
        minWidth: 50,
        dataIndex,
        width: 80,
        columnTitle: 'Parent Category',
        exportIndex: 5,
    })
}

export function sub_category<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['sub_category']
    return createField({
        ...options,
        id: 'sub_category',
        name: 'Primary Subcategory',
        shortName: 'Subcategory',
        minWidth: 50,
        dataIndex,
        width: 100,
        exportIndex: 6,
    })
}

export function breadcrumbTree<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['breadcrumb_tree']
    return createField({
        ...options,
        id: 'breadcrumb_tree',
        name: 'Categories',
        shortName: 'Categories',
        minWidth: 50,
        dataIndex,
        width: 100,
        renderOptions: {
            render: (props) => BreadcrumbTreeRenderer<any>(props),
        },
    })
}

export function rankingCategories<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['subcategories']
    return createField({
        ...options,
        id: 'ranking_categories',
        name: 'Best Seller Rank',
        shortName: 'Best Seller Rank',
        minWidth: 100,
        dataIndex,
        renderOptions: {
            render: (props) => RankingCategoriesRenderer<any>(props),
        },
        width: 200,
        sorter: false,
    })
}

export function estimatedRevenue<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['estimated_revenue']
    const name = (
        <Translation>
            {(t) => t('segments:estimatedRevenue', '30-Day Estimated Revenue')}
        </Translation>
    )
    return createCurrencyField({
        ...options,
        id: 'segment_product_estimated_revenue',
        name,
        shortName: '30-Day Est. Revenue',
        minWidth: 50,
        dataIndex,
        width: 70,
        align: 'right',
    })
}

export function estimatedSales<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['estimated_sales']
    const name = (
        <Translation>
            {(t) => t('segments:estimatedUnits', '30-Day Estimated Units')}
        </Translation>
    )
    return createNumberField({
        id: 'segment_product_estimated_sales',
        name,
        shortName: '30-Day Est. Units',
        isResizeable: true,
        minWidth: 50,
        isVisible: true,
        dataIndex,
        width: 70,
        align: 'right',
    })
}

export function productId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_id']
    const name = (
        <Translation>{(t) => t('common:product', 'Product')}</Translation>
    )
    return createField({
        ...options,
        id: 'product_id',
        name,
        shortName: name,
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) =>
                SegmentProductNameRenderer<any>({ cellRenderProps: props }, [
                    'product',
                ]),
        },
        width: 300,
    })
}

export function segmentId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['segment_id']
    const name = (
        <Translation>{(t) => t('common:segment', 'Segment')}</Translation>
    )
    return createField({
        ...options,
        id: 'segment_id',
        name,
        shortName: name,
        minWidth: 50,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                segmentNameRenderer<any>({ cellRenderProps }, ['segment']),
        },
        width: 100,
    })
}

export function sellerName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['seller_name']
    return createField({
        ...options,
        id: 'seller_name',
        name: 'Seller Name',
        shortName: 'Seller Name',
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                sellerNameRenderer<any>(cellRenderProps),
        },
    })
}

export function reviews<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = (
        <Translation>{(t) => t('segments:reviews', 'Reviews')}</Translation>
    )
    return createNumberField({
        ...options,
        id: 'segment_product_reviews',
        name,
        shortName: 'Reviews',
        minWidth: 40,
        dataIndex: options.dataIndex ?? ['reviews'],
        align: 'right',
    })
}

export function variantInfo<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['variant_info']
    return createField({
        ...options,
        id: 'variant_info',
        name: 'Variant Info',
        shortName: 'Variant Info',
        minWidth: 50,
        dataIndex,
        width: 100,
        exportIndex: 1,
    })
}

export function variantGroup<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['variant_group']
    return createField({
        ...options,
        id: 'variant_group',
        name: 'Variant Group',
        shortName: 'Variant Group',
        minWidth: 50,
        width: 100,
        dataIndex,
        exportIndex: 2,
    })
}

export function categoryBsr<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['category_rank__last_value']
    return createField({
        ...options,
        id: 'categoryBsr',
        name: 'Category BSR',
        shortName: 'Category BSR',
        minWidth: 50,
        dataIndex,
        width: 80,
    })
}

export function subcategoryBsr<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['subcategory_rank__last_value']
    return createField({
        ...options,
        id: 'subcategoryBsr',
        name: 'Subcategory BSR',
        shortName: 'Subcategory BSR',
        minWidth: 50,
        dataIndex,
        width: 80,
    })
}

export function imageUrl<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['image_url']
    return createField({
        ...options,
        id: 'imageUrl',
        name: 'Image',
        shortName: 'Image',
        minWidth: 50,
        dataIndex,
        width: 80,
    })
}
