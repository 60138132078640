import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import UserIconSvg from './UserIconSvg'

const UserIcon = (props: IconProps): ReactElement => (
    <Icon component={UserIconSvg} {...props} />
)

export default UserIcon
