import { ReactElement } from 'react'

import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Popover, Progress, Tooltip } from 'antd'
import classNames from 'classnames'

import { ConditionalWrapper } from 'components/ConditionalWrapper'
import BudgetStatusTooltip from 'components/FieldRenderers/BudgetStatusCell/BudgetStatusTooltip'

import { BudgetConfiguration, FullBudgetConfiguration } from './helpers'
import * as styles from './styles.scss'

interface Props {
    configuration: BudgetConfiguration
}

const BudgetStatusCell = ({ configuration }: Props): ReactElement => {
    if (!configuration.budget) {
        return (
            <ConditionalWrapper
                condition={!!configuration.text.tooltip}
                wrapper={(children) => (
                    <Tooltip title={configuration.text.tooltip}>
                        {children}
                    </Tooltip>
                )}
            >
                <div>
                    {configuration.text.title}
                    {configuration.text.tooltip && (
                        <QuestionCircleOutlined className={styles.icon} />
                    )}
                </div>
            </ConditionalWrapper>
        )
    }

    return (
        <Popover
            className={styles.cell}
            trigger="hover"
            content={
                <BudgetStatusTooltip
                    configuration={configuration as FullBudgetConfiguration}
                />
            }
        >
            <Progress
                strokeLinecap="butt"
                type="circle"
                trailColor="#D7D8D9"
                width={36}
                percent={configuration.budget.basePercentageSpent}
                strokeColor={configuration.colors?.progress}
                format={() => (
                    <span
                        style={{ color: configuration.colors?.text }}
                        className={classNames(styles.percentage, {
                            [styles['over-budget']]:
                                (configuration.budget?.basePercentageSpent ||
                                    0) > 100,
                        })}
                    >
                        {configuration.budget?.basePercentageSpent}%
                    </span>
                )}
            />
            <div className={styles['status-text']}>
                <span style={{ color: configuration.colors?.text }}>
                    {configuration.text.title}
                </span>
                <span>
                    {configuration.budget.spent} / {configuration.budget.active}
                </span>
            </div>
            {(configuration.budget.basePercentageSpent > 100 ||
                configuration.abcTask) && (
                <InfoCircleOutlined className={styles.icon} />
            )}
        </Popover>
    )
}

export default BudgetStatusCell
