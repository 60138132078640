import { ReactElement, useEffect } from 'react'

import { Layout } from 'antd'
import classNames from 'classnames'

import { ArrowIcon } from 'components/Icons'
import { LoadingIndicator } from 'components/LoadingIndicator'
import { getOrganizationTheme } from 'helpers/organizationTheme'
import { GlobalNotification, Organization, UserPermission } from 'types'

import MfaCheck from './MfaCheck'
import Routes from './Routes'
import { SideMenu } from './SideMenu'
import * as styles from './styles.scss'

export interface AppLayoutProps {
    hasRecentIntegration: boolean
    hasNoIntegrations: boolean
    currentOrganization?: Organization
    featurePermissions: UserPermission[]
    globalNotification?: GlobalNotification | null
    isSidebarCollapsed: boolean
    mounting: boolean
    mountAppRequest: () => void
    toggleSidebarCollapsed: () => void
}

function AppLayout({
    hasNoIntegrations,
    featurePermissions,
    currentOrganization,
    isSidebarCollapsed,
    mounting,
    mountAppRequest,
    toggleSidebarCollapsed,
}: AppLayoutProps): ReactElement {
    useEffect(() => {
        mountAppRequest()
    }, [mountAppRequest])

    if (mounting) {
        return (
            <LoadingIndicator
                size="small"
                style={{ position: 'relative', minHeight: '300px' }}
            />
        )
    }

    const theme = getOrganizationTheme(currentOrganization)

    return (
        <Layout>
            <Layout.Sider
                width={208}
                collapsible
                collapsed={isSidebarCollapsed}
                onCollapse={toggleSidebarCollapsed}
                className={classNames(styles.sider, {
                    [theme?.primary_color ?? '']: Boolean(theme?.primary_color),
                })}
                trigger={
                    <ArrowIcon
                        style={{
                            transform: isSidebarCollapsed
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                        }}
                    />
                }
            >
                <SideMenu
                    currentOrganization={currentOrganization}
                    isSidebarCollapsed={isSidebarCollapsed}
                    featurePermissions={featurePermissions}
                    hasNoIntegrations={hasNoIntegrations}
                />
            </Layout.Sider>

            <Layout
                className={classNames([styles['content-layout']], {
                    [styles.collapsed]: isSidebarCollapsed,
                })}
            >
                <Layout.Content className={styles.content}>
                    <MfaCheck />
                    <Routes />
                </Layout.Content>
            </Layout>
        </Layout>
    )
}

export default AppLayout
