import { Dispatch, ReactElement, SetStateAction } from 'react'

import { Button } from 'antd'
import { FieldArray, Formik, FormikHelpers } from 'formik'
import { Schema } from 'yup'

import Stack from 'components/Layout/Stack'

import CreateResourceModal from '../CreateResourceModal/CreateResourceModal'
import { BulkAddTextArea } from '../Shared'

import CreateKeywordForm from './CreateKeywordForm'
import { BaseKeyword, ColumnConfig, FormValues } from './localTypes'
import SuggestedKeywords from './SuggestedKeywords'

interface Props<KeywordField> {
    isModalVisible: boolean
    onModalCancel: () => void
    toggleModalVisible: () => void
    columnsConfig: ColumnConfig[]
    submitForm: (
        values: FormValues<KeywordField>,
        formikHelpers: FormikHelpers<FormValues<KeywordField>>
    ) => void | Promise<any>
    serializeKeywordField: (keyword: string) => KeywordField
    subtitle?: ReactElement | undefined
    initialValues: FormValues<KeywordField>
    validationSchema: Schema
    fetchSuggestedKeywords?: (
        setSuggestedKeywords: Dispatch<SetStateAction<string[]>>,
        setEmptyStateMsg: Dispatch<SetStateAction<string>>
    ) => Promise<void>
}

export default function CreateKeywordModal<KeywordField extends BaseKeyword>({
    isModalVisible,
    onModalCancel,
    toggleModalVisible,
    columnsConfig,
    initialValues,
    submitForm,
    validationSchema,
    serializeKeywordField,
    fetchSuggestedKeywords,
    subtitle,
}: Props<KeywordField>): ReactElement {
    const handleFieldArrayPush = (
        keyword: string,
        push: (obj: any) => void
    ): void => push(serializeKeywordField(keyword))

    const renderModalTitle = (): ReactElement => {
        return (
            <>
                <div>Add Keywords</div>
                {subtitle && subtitle}
            </>
        )
    }

    return (
        <Formik
            validateOnMount
            initialValues={initialValues}
            enableReinitialize
            onSubmit={submitForm}
            validationSchema={validationSchema}
        >
            {({
                isSubmitting,
                resetForm,
                handleSubmit,
                setFieldValue,
                values,
                isValid,
            }) => (
                <CreateResourceModal
                    open={isModalVisible}
                    title={renderModalTitle()}
                    onCancel={() => {
                        onModalCancel()
                        resetForm()
                    }}
                    width={900}
                    maskClosable={false}
                    destroyOnClose
                    footer={[
                        <Button
                            key={1}
                            onClick={() => {
                                resetForm()
                                toggleModalVisible()
                            }}
                            disabled={isSubmitting}
                            style={{ marginRight: 8 }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key={2}
                            type="primary"
                            onClick={() => handleSubmit()}
                            loading={isSubmitting}
                            disabled={
                                !isValid ||
                                values.keywords.length === 0 ||
                                isSubmitting
                            }
                        >
                            Add Keywords
                        </Button>,
                    ]}
                >
                    <FieldArray
                        name="keywords"
                        render={(arrayHelpers) => (
                            <Stack direction="column">
                                <div className="fg-font-size-medium fg-text-primary fg-font-weight-500">
                                    Add Keywords
                                </div>
                                <BulkAddTextArea
                                    label="Keywords"
                                    type="keyword"
                                    loading={false}
                                    push={(item) => {
                                        handleFieldArrayPush(
                                            item.keyword as string,
                                            arrayHelpers.push
                                        )
                                    }}
                                    style={{ marginTop: 8, marginBottom: 12 }}
                                />
                                {fetchSuggestedKeywords && (
                                    <SuggestedKeywords
                                        fetchSuggestedKeywords={
                                            fetchSuggestedKeywords
                                        }
                                        pushKeyword={(keyword) =>
                                            handleFieldArrayPush(
                                                keyword,
                                                arrayHelpers.push
                                            )
                                        }
                                    />
                                )}

                                <CreateKeywordForm<KeywordField>
                                    keywords={values.keywords}
                                    remove={(index) => {
                                        const { keywords } = values
                                        keywords.splice(index, 1)

                                        setFieldValue('keywords', keywords)
                                    }}
                                    columnsConfig={columnsConfig}
                                />
                            </Stack>
                        )}
                    />
                </CreateResourceModal>
            )}
        </Formik>
    )
}
