import { ReactElement } from 'react'

import RichTextViewer from 'components/RichText/RichTextViewer'
import { TextWidgetConfig } from 'types'

interface TextWidgetProps {
    widget: TextWidgetConfig
}

function TextWidget({ widget }: TextWidgetProps): ReactElement {
    return <RichTextViewer value={widget.json} />
}

export default TextWidget
