import { Field } from 'types'

import { createActionField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function query<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const key = 'query'
    const dataIndex = options.dataIndex ?? [key]
    const displayName = 'Search Term'
    return {
        ...createField({
            ...options,
            id: 'query',
            name: displayName,
            shortName: displayName,
            minWidth: 100,
            dataIndex,
            width: 200,
            sorter: options.sorter ?? false,
        }),
        // This is kind of a hardcoded group by entry shim since
        // this field is not present in some data sources but is selectable
        // and then the data source is swapped out for a different one that does have it.
        // Defining it here ensures that the group by tooltip will render as expected.
        groupBy: {
            id: key,
            metadata_key: key,
            slug: 'search-term',
            metadata_all_names: [key],
            metadata_display_name: displayName,
            metadata_format: 'string',
            metadata_is_deprecated: false,
            metadata_release_date: null,
            metadata_end_of_life_date: null,
            metadata_supports_total: true,
            display_name: displayName,
            short_name: displayName,
            definition: 'Displays the search query',
            is_visible: true,
        },
    }
}

export function addSearchTerm<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword', 'id']
    return createActionField({
        ...options,
        id: 'add_search_term_v2', // increment version because column width changed and column is fixed
        isResizeable: false,
        dataIndex,
        minWidth: 290,
        sorter: false,
        fixed: options.fixed ?? 'right',
        align: 'center',
    })
}
