import { Field } from 'types'

import { FieldCreatorOptions } from '../localTypes'

import {
    makeCountMetricFieldFunc,
    makeCurrencyMetricFieldFunc,
    makePercentageMetricFieldFunc,
    makeRankMetricFieldFunc,
} from './localUtils'
import translations from './translations/vendorCentral'

export const ordered_units__sum = makeCountMetricFieldFunc({
    name: 'Ordered Units',
    shortName: 'Ordered Units',
    deltas: ['change', 'percentage_change'],
    id: 'ordered_units__sum',
})

export const sub_category_sales_rank__avg = makeRankMetricFieldFunc({
    name: 'Sub Category Sales Rank Average',
    shortName: 'Sub Category Sales Rank',
    deltas: ['change', 'percentage_change'],
    id: 'sub_category_sales_rank__avg',
})

export const average_sales_price__avg = makeCurrencyMetricFieldFunc({
    name: 'Sales Price Average',
    shortName: 'Sales Price',
    deltas: ['change', 'percentage_change'],
    id: 'average_sales_price__avg',
})

export const rep_oos__avg = makePercentageMetricFieldFunc({
    name: 'Rep Out of Stock Average',
    shortName: 'Rep OOS Avg',
    deltas: ['change'],
    id: 'rep_oos__avg',
    isInverse: true,
})

export const rep_oos_of_total__avg = makePercentageMetricFieldFunc({
    name: 'Rep Out of Stock of Total Average',
    shortName: 'Rep OOS of Total Avg',
    deltas: ['change'],
    id: 'rep_oos_of_total__avg',
    isInverse: true,
})

export const lbb__avg = makePercentageMetricFieldFunc({
    name: 'LBB Average (Deprecated by Amazon)',
    shortName: 'LBB (Deprecated by Amazon)',
    deltas: ['change'],
    id: 'lbb__avg',
    isInverse: true,
})

export const ordered_revenue__sum = makeCurrencyMetricFieldFunc({
    name: 'Ordered Revenue',
    shortName: 'Ordered Revenue',
    deltas: ['change', 'percentage_change'],
    id: 'ordered_revenue__sum',
})

export const shipped_units__sum = makeCountMetricFieldFunc({
    name: 'Shipped Units',
    shortName: 'Shipped Units',
    deltas: ['change', 'percentage_change'],
    id: 'shipped_units__sum',
})

export const glance_view__sum = makeCountMetricFieldFunc({
    name: 'Glance Views',
    shortName: 'Glance Views',
    deltas: ['change', 'percentage_change'],
    id: 'glance_view__sum',
})

interface CustomerReturnOptions {
    deprecated: boolean
}

const inner_customer_returns__sum = ({
    deprecated,
}: CustomerReturnOptions): ((
    options?: FieldCreatorOptions<any>
) => Field<any>) => {
    const suffix = deprecated ? ' (Deprecated by Amazon)' : ''
    const name = `Customer Returns${suffix}`
    const shortName = `Returns${suffix}`
    return makeCountMetricFieldFunc({
        name,
        shortName,
        isInverse: true,
        deltas: ['change', 'percentage_change'],
        id: 'customer_returns__sum',
    })
}

export const customer_returns__sum = inner_customer_returns__sum({
    deprecated: false,
})
export const deprecated_customer_returns__sum = inner_customer_returns__sum({
    deprecated: true,
})

export const free_replacements__sum = makeCountMetricFieldFunc({
    name: 'Free Replacements (Deprecated by Amazon)',
    shortName: 'Replacements (Deprecated by Amazon)',
    deltas: ['change', 'percentage_change'],
    id: 'free_replacements__sum',
})

export const shipped_cogs__sum = makeCurrencyMetricFieldFunc({
    name: 'Shipped Cogs',
    shortName: 'Shipped Cogs',
    deltas: ['change', 'percentage_change'],
    id: 'shipped_cogs__sum',
})

export const shipped_revenue__sum = makeCurrencyMetricFieldFunc({
    name: 'Shipped Revenue',
    shortName: 'Shipped Revenue',
    deltas: ['change', 'percentage_change'],
    id: 'shipped_revenue__sum',
})

export const cost__sum = makeCurrencyMetricFieldFunc({
    name: 'Cost',
    shortName: 'Advertising Cost',
    localDefinition:
        'The total cost value of clicks generated by related product ads.',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'cost__sum',
})

export const manufacturer_advertising_cost_of_total_sales =
    makePercentageMetricFieldFunc({
        name: 'Advertising Cost of Total Sales (Old)',
        shortName: 'ACoOR',
        isInverse: true,
        deltas: ['change'],
        id: 'advertising_cost_of_total_sales',
    })

export const sourcing_advertising_cost_of_total_sales =
    makePercentageMetricFieldFunc({
        name: 'Advertising Cost of Total Sales (Old)',
        shortName: 'ACoSR',
        isInverse: true,
        deltas: ['change'],
        id: 'advertising_cost_of_total_sales',
    })

export const manufacturer_total_advertising_cost_of_sales =
    makePercentageMetricFieldFunc({
        name: 'Total Advertising Cost of Sales (Old)',
        shortName: 'TACoS (Ordered Rev.)',
        isInverse: true,
        deltas: ['change'],
        id: 'total_advertising_cost_of_sales',
    })

export const sourcing_total_advertising_cost_of_sales =
    makePercentageMetricFieldFunc({
        name: 'Total Advertising Cost of Sales (Old)',
        shortName: 'TACoS (Shipped Rev.)',
        isInverse: true,
        deltas: ['change'],
        id: 'total_advertising_cost_of_sales',
    })

export const advertising_cost_of_shipped_cogs = makePercentageMetricFieldFunc({
    name: 'Total Advertising Cost of Shipped Cogs',
    shortName: 'TACoS (Shipped Cogs)',
    isInverse: true,
    deltas: ['change'],
    id: 'advertising_cost_of_shipped_cogs',
})

export const advertising_cost_of_shipped_revenue =
    makePercentageMetricFieldFunc({
        name: 'Total Advertising Cost of Shipped Revenue',
        shortName: 'TACoS (Shipped Rev.)',
        isInverse: true,
        deltas: ['change'],
        id: 'advertising_cost_of_shipped_revenue',
    })

export const advertising_cost_of_ordered_revenue =
    makePercentageMetricFieldFunc({
        name: 'Total Advertising Cost of Ordered Revenue',
        shortName: 'TACoS (Ordered Rev.)',
        isInverse: true,
        deltas: ['change'],
        id: 'advertising_cost_of_ordered_revenue',
    })

export const fast_track_gv = makePercentageMetricFieldFunc({
    name: 'Fast Track Glance Views',
    shortName: 'Fast Track Glance Views',
    deltas: ['change'],
    id: 'fast_track_gv',
})

export const conversion_percent = makePercentageMetricFieldFunc({
    name: 'Conversion Rate',
    shortName: 'Conversion Rate',
    deltas: ['change'],
    id: 'conversion_percent',
})

export const percentage_of_total_gv = makePercentageMetricFieldFunc({
    name: 'Percentage of Total Glance Views',
    shortName: '% of Glance Views',
    deltas: ['change'],
    id: 'percentage_of_total_gv',
})

// Inventory Health Metrics
export const net_received_amount__sum = makeCurrencyMetricFieldFunc({
    name: 'Net Received Amount (Deprecated by Amazon)',
    shortName: 'Net Received (Deprecated by Amazon)',
    deltas: ['change', 'percentage_change'],
    id: 'net_received_amount__sum',
})

export const net_received_units__sum = makeCountMetricFieldFunc({
    name: 'Net Received Units (Deprecated by Amazon)',
    shortName: 'Net Received Units (Deprecated by Amazon)',
    deltas: ['change', 'percentage_change'],
    id: 'net_received_units__sum',
})

export const open_purchase_order_quantity__sum = makeCountMetricFieldFunc({
    name: 'Open Purchase Order Quantity (Deprecated by Amazon)',
    shortName: 'Open Purchase Order Quantity (Deprecated by Amazon)',
    deltas: ['change', 'percentage_change'],
    id: 'open_purchase_order_quantity__sum',
})

export const open_purchase_order_quantity__last_value =
    makeCountMetricFieldFunc({
        name: 'Open Purchase Order Quantity (Deprecated by Amazon)',
        shortName: 'Open Purchase Order Quantity (Deprecated by Amazon)',
        deltas: ['change', 'percentage_change'],
        id: 'open_purchase_order_quantity__last_value',
    })

export const sellable_on_hand_units__sum = makeCountMetricFieldFunc({
    name: 'Sellable On-Hand Units (Deprecated by Amazon)',
    shortName: 'Sellable On-Hand Units (Deprecated by Amazon)',
    deltas: ['change', 'percentage_change'],
    id: 'sellable_on_hand_units__sum',
})

export const sellable_on_hand_units__last_value = makeCountMetricFieldFunc({
    name: 'Sellable On Hand Units (Deprecated by Amazon)',
    shortName: 'Sellable On Hand Units (Deprecated by Amazon)',
    deltas: ['change', 'percentage_change'],
    id: 'sellable_on_hand_units__last_value',
})

export const unsellable_on_hand_units__sum = makeCountMetricFieldFunc({
    name: 'Unsellable On-Hand Units (Deprecated by Amazon)',
    shortName: 'Unsellable On-Hand Units (Deprecated by Amazon)',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'unsellable_on_hand_units__sum',
})

export const unsellable_on_hand_units__last_value = makeCountMetricFieldFunc({
    name: 'Unsellable On Hand Units (Deprecated by Amazon)',
    shortName: 'Unsellable On Hand Units (Deprecated by Amazon)',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'unsellable_on_hand_units__sum',
})

export const age_90_sellable_units__sum = makeCountMetricFieldFunc({
    name: 'Age 90 Sellable On-Hand Units',
    shortName: 'Age 90 Sellable On-Hand Units',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'age_90_sellable_units__sum',
})

export const aged_90_plus_days_sellable_units__last_value =
    makeCountMetricFieldFunc({
        name: 'Aged 90+ Days Sellable On Hand Units (Deprecated by Amazon)',
        shortName:
            'Aged 90+ Days Sellable On Hand Units (Deprecated by Amazon)',
        isInverse: true,
        deltas: ['change', 'percentage_change'],
        id: 'aged_90_plus_days_sellable_units__last_value',
    })

export const unhealthy_units__sum = makeCountMetricFieldFunc({
    name: 'Unhealthy Units',
    shortName: 'Unhealthy Units',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'unhealthy_units__sum',
})

export const unhealthy_units__last_value = makeCountMetricFieldFunc({
    name: 'Unhealthy Units',
    shortName: 'Unhealthy Units',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'unhealthy_units__last_value',
})

export const sellable_on_hand_inventory__sum = makeCurrencyMetricFieldFunc({
    name: 'Sellable On-Hand Inventory (Deprecated by Amazon)',
    shortName: 'Sellable On-Hand Inventory (Deprecated by Amazon)',
    deltas: ['change', 'percentage_change'],
    id: 'sellable_on_hand_inventory__sum',
})

export const sellable_on_hand_inventory__last_value =
    makeCurrencyMetricFieldFunc({
        name: 'Sellable On Hand Inventory (Deprecated by Amazon)',
        shortName: 'Sellable On Hand Inventory (Deprecated by Amazon)',
        deltas: ['change', 'percentage_change'],
        id: 'sellable_on_hand_inventory__last_value',
    })

export const unsellable_on_hand_inventory__sum = makeCurrencyMetricFieldFunc({
    name: 'Unsellable On-Hand Inventory (Deprecated by Amazon)',
    shortName: 'Unsellable On-Hand Inventory (Deprecated by Amazon)',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'unsellable_on_hand_inventory__sum',
})

export const unsellable_on_hand_inventory__last_value =
    makeCurrencyMetricFieldFunc({
        name: 'Unsellable On Hand Inventory (Deprecated by Amazon)',
        shortName: 'Unsellable On Hand Inventory (Deprecated by Amazon)',
        isInverse: true,
        deltas: ['change', 'percentage_change'],
        id: 'unsellable_on_hand_inventory__last_value',
    })

export const age_90_sellable_inventory__sum = makeCurrencyMetricFieldFunc({
    name: 'Age 90 Sellable On-Hand Inventory',
    shortName: 'Age 90 Sellable On-Hand Inventory',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'age_90_sellable_inventory__sum',
})

export const aged_90_plus_days_sellable_inventory__last_value =
    makeCurrencyMetricFieldFunc({
        name: 'Aged 90+ Days Sellable On Hand Inventory (Deprecated by Amazon)',
        shortName:
            'Aged 90+ Days Sellable On Hand Inventory (Deprecated by Amazon)',
        isInverse: true,
        deltas: ['change', 'percentage_change'],
        id: 'aged_90_plus_days_sellable_inventory__last_value',
    })

export const unhealthy_inventory__sum = makeCurrencyMetricFieldFunc({
    name: 'Unhealthy Inventory (Deprecated by Amazon)',
    shortName: 'Unhealthy Inventory (Deprecated by Amazon)',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'unhealthy_inventory__sum',
})

export const unhealthy_inventory__last_value = makeCurrencyMetricFieldFunc({
    name: 'Unhealthy Inventory (Deprecated by Amazon)',
    shortName: 'Unhealthy Inventory (Deprecated by Amazon)',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'unhealthy_inventory__last_value',
})

export const net_received_value__sum = makeCurrencyMetricFieldFunc({
    name: 'Net Received Value',
    shortName: 'Net Received Value',
    deltas: ['change', 'percentage_change'],
    id: 'net_received_value__sum',
})

export const sell_through_rate = makePercentageMetricFieldFunc({
    name: translations.sell_through_rate.name,
    shortName: translations.sell_through_rate.shortName,
    localDefinition: translations.sell_through_rate.helpText,
    isRatio: true,
    deltas: ['change'],
    id: 'sell_through_rate',
})

export const ad_contribution_14_day_shipped_revenue =
    makePercentageMetricFieldFunc({
        name: 'Ad Contribution of Shipped Revenue (14 Day Attribution)',
        shortName: 'Ad Contribution (Shipped Rev.)',
        isInverse: true,
        deltas: ['change'],
        id: 'ad_contribution_14_day_shipped_revenue',
    })

export const ad_contribution_14_day_same_sku_shipped_revenue =
    makePercentageMetricFieldFunc({
        name: 'Ad Contribution of Shipped Revenue (14 Day Attribution, Same SKU)',
        shortName: 'SKU Ad Contribution (Shipped Rev.)',
        isInverse: true,
        deltas: ['change'],
        id: 'ad_contribution_14_day_same_sku_shipped_revenue',
    })

export const organic_sales_14_day_shipped_revenue__sum =
    makeCurrencyMetricFieldFunc({
        name: 'Organic Shipped Revenue (14 Day Attribution)',
        shortName: 'Organic Shipped Rev.',
        deltas: ['change', 'percentage_change'],
        id: 'organic_sales_14_day_shipped_revenue__sum',
    })

export const organic_sales_1_day_same_sku_shipped_revenue__sum =
    makeCurrencyMetricFieldFunc({
        name: 'Organic Shipped Revenue (1 Day Attribution, Same SKU)',
        shortName: 'SKU Organic Shipped Rev.',
        deltas: ['change', 'percentage_change'],
        id: 'organic_sales_1_day_same_sku_shipped_revenue__sum',
    })

export const organic_sales_14_day_same_sku_shipped_revenue__sum =
    makeCurrencyMetricFieldFunc({
        name: 'Organic Shipped Revenue (14 Day Attribution, Same SKU)',
        shortName: 'SKU Organic Shipped Rev.',
        deltas: ['change', 'percentage_change'],
        id: 'organic_sales_14_day_same_sku_shipped_revenue__sum',
    })

export const ad_contribution_14_day_ordered_revenue =
    makePercentageMetricFieldFunc({
        name: 'Ad Contribution of Ordered Revenue (14 Day Attribution)',
        shortName: 'SKU Ad Contribution (Ordered Rev.)',
        isInverse: true,
        deltas: ['change'],
        id: 'ad_contribution_14_day_ordered_revenue',
    })

export const ad_contribution_14_day_same_sku_ordered_revenue =
    makePercentageMetricFieldFunc({
        name: 'Ad Contribution of Ordered Revenue (14 Day Attribution, Same SKU)',
        shortName: 'SKU Ad Contribution (Ordered Rev.)',
        isInverse: true,
        deltas: ['change'],
        id: 'ad_contribution_14_day_same_sku_ordered_revenue',
    })

export const organic_sales_14_day_ordered_revenue__sum =
    makeCurrencyMetricFieldFunc({
        name: 'Organic Ordered Revenue (14 Day Attribution)',
        shortName: 'Organic Ordered Revenue',
        deltas: ['change', 'percentage_change'],
        id: 'organic_sales_14_day_ordered_revenue__sum',
    })

export const organic_sales_1_day_same_sku_ordered_revenue__sum =
    makeCurrencyMetricFieldFunc({
        name: 'Organic Ordered Revenue (1 Day Attribution, Same SKU)',
        shortName: 'SKU Organic Ordered Revenue',
        deltas: ['change', 'percentage_change'],
        id: 'organic_sales_1_day_same_sku_ordered_revenue__sum',
    })

export const organic_sales_14_day_same_sku_ordered_revenue__sum =
    makeCurrencyMetricFieldFunc({
        name: 'Organic Ordered Revenue (14 Day Attribution, Same SKU)',
        shortName: 'SKU Organic Ordered Revenue',
        deltas: ['change', 'percentage_change'],
        id: 'organic_sales_14_day_same_sku_ordered_revenue__sum',
    })

export const ad_contribution_14_day_shipped_cogs =
    makePercentageMetricFieldFunc({
        name: 'Ad Contribution of Shipped Cogs (14 Day Attribution)',
        shortName: 'Ad Contribution (Shipped Cogs.)',
        isInverse: true,
        deltas: ['change'],
        id: 'ad_contribution_14_day_shipped_cogs',
    })

export const ad_contribution_14_day_same_sku_shipped_cogs =
    makePercentageMetricFieldFunc({
        name: 'Ad Contribution of Shipped Cogs (14 Day Attribution, Same SKU)',
        shortName: 'SKU Ad Contribution (Shipped Cogs.)',
        isInverse: true,
        deltas: ['change'],
        id: 'ad_contribution_14_day_same_sku_shipped_cogs',
    })

export const organic_sales_14_day_shipped_cogs__sum =
    makeCurrencyMetricFieldFunc({
        name: 'Organic Shipped Cogs (14 Day Attribution)',
        shortName: 'Organic Shipped Cogs',
        deltas: ['change', 'percentage_change'],
        id: 'organic_sales_14_day_shipped_cogs__sum',
    })

export const organic_sales_14_day_same_sku_shipped_cogs__sum =
    makeCurrencyMetricFieldFunc({
        name: 'Organic Shipped Cogs (14 Day Attribution, Same SKU)',
        shortName: 'SKU Organic Shipped Cogs',
        deltas: ['change', 'percentage_change'],
        id: 'organic_sales_14_day_same_sku_shipped_cogs__sum',
    })

export const glance_views__sum = makeCountMetricFieldFunc({
    name: 'Glance Views',
    shortName: 'Glance Views',
    deltas: ['change', 'percentage_change'],
    id: 'glance_views__sum',
})

export const conversion_rate_glances = makePercentageMetricFieldFunc({
    name: 'Conversion Rate (Ordered)',
    shortName: 'CVR (Ordered)',
    isRatio: true,
    deltas: ['change'],
    id: 'conversion_rate_glances',
})

export const conversion_rate_shipped_glances = makePercentageMetricFieldFunc({
    name: 'Conversion Rate (Shipped)',
    shortName: 'CVR (Shipped)',
    isRatio: true,
    deltas: ['change'],
    id: 'conversion_rate_shipped_glances',
    releaseDate: '2024-02-14',
})

export const average_unit_price_shipped_revenue__avg =
    makeCurrencyMetricFieldFunc({
        name: 'Average Sales Price - Shipped',
        shortName: 'Average Sales Price - Shipped',
        isRatio: true,
        deltas: ['change', 'percentage_change'],
        id: 'average_unit_price_shipped_revenue__avg',
    })

export const average_unit_price_ordered_revenue__avg =
    makeCurrencyMetricFieldFunc({
        name: 'Average Sales Price - Ordered',
        shortName: 'Average Sales Price - Ordered',
        isRatio: true,
        deltas: ['change', 'percentage_change'],
        id: 'average_unit_price_ordered_revenue__avg',
    })

export const net_ppm__sum = makePercentageMetricFieldFunc({
    name: 'Net Pure Product Margin',
    shortName: 'Net PPM',
    deltas: ['change'],
    id: 'net_pure_product_margin__sum',
})

export const open_purchase_order_units__last_value = makeCountMetricFieldFunc({
    name: translations.open_purchase_order_units.name,
    shortName: translations.open_purchase_order_units.shortName,
    localDefinition: translations.open_purchase_order_units.helpText,
    deltas: ['change', 'percentage_change'],
    id: 'open_purchase_order_units__last_value',
    isLastValue: true,
})

export const unfilled_customer_ordered_units__last_value =
    makeCountMetricFieldFunc({
        name: translations.unfilled_customer_ordered_units.name,
        shortName: translations.unfilled_customer_ordered_units.shortName,
        localDefinition: translations.unfilled_customer_ordered_units.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'unfilled_customer_ordered_units__last_value',
        isLastValue: true,
    })

export const sellable_on_hand_inventory_cost__last_value =
    makeCurrencyMetricFieldFunc({
        name: translations.sellable_on_hand_inventory_cost.name,
        shortName: translations.sellable_on_hand_inventory_cost.shortName,
        localDefinition: translations.sellable_on_hand_inventory_cost.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'sellable_on_hand_inventory_cost__last_value',
        isLastValue: true,
    })

export const sellable_on_hand_inventory_units__last_value =
    makeCountMetricFieldFunc({
        name: translations.sellable_on_hand_inventory_units.name,
        shortName: translations.sellable_on_hand_inventory_units.shortName,
        localDefinition: translations.sellable_on_hand_inventory_units.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'sellable_on_hand_inventory_units__last_value',
        isLastValue: true,
    })

export const unsellable_on_hand_inventory_cost__last_value =
    makeCurrencyMetricFieldFunc({
        name: translations.unsellable_on_hand_inventory_cost.name,
        shortName: translations.unsellable_on_hand_inventory_cost.shortName,
        localDefinition:
            translations.unsellable_on_hand_inventory_cost.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'unsellable_on_hand_inventory_cost__last_value',
        isLastValue: true,
    })

export const unsellable_on_hand_inventory_units__last_value =
    makeCountMetricFieldFunc({
        name: translations.unsellable_on_hand_inventory_units.name,
        shortName: translations.unsellable_on_hand_inventory_units.shortName,
        localDefinition:
            translations.unsellable_on_hand_inventory_units.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'unsellable_on_hand_inventory_units__last_value',
        isLastValue: true,
    })

export const aged_90_plus_days_sellable_inventory_cost__last_value =
    makeCurrencyMetricFieldFunc({
        name: translations.aged_90_plus_days_sellable_inventory_cost.name,
        shortName:
            translations.aged_90_plus_days_sellable_inventory_cost.shortName,
        localDefinition:
            translations.aged_90_plus_days_sellable_inventory_cost.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'aged_90_plus_days_sellable_inventory_cost__last_value',
        isLastValue: true,
    })

export const aged_90_plus_days_sellable_inventory_units__last_value =
    makeCountMetricFieldFunc({
        name: translations.aged_90_plus_days_sellable_inventory_units.name,
        shortName:
            translations.aged_90_plus_days_sellable_inventory_units.shortName,
        localDefinition:
            translations.aged_90_plus_days_sellable_inventory_units.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'aged_90_plus_days_sellable_inventory_units__last_value',
        isLastValue: true,
    })

export const average_vendor_lead_time_days = makeCountMetricFieldFunc({
    name: translations.average_vendor_lead_time_days.name,
    shortName: translations.average_vendor_lead_time_days.shortName,
    localDefinition: translations.average_vendor_lead_time_days.helpText,
    format: '0,0[.]00',
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'average_vendor_lead_time_days',
})

export const procurable_product_out_of_stock_rate =
    makePercentageMetricFieldFunc({
        name: translations.procurable_product_out_of_stock_rate.name,
        shortName: translations.procurable_product_out_of_stock_rate.shortName,
        localDefinition:
            translations.procurable_product_out_of_stock_rate.helpText,
        isRatio: true,
        deltas: ['change'],
        id: 'procurable_product_out_of_stock_rate',
    })

export const sourceable_product_out_of_stock_rate =
    makePercentageMetricFieldFunc({
        name: translations.sourceable_product_out_of_stock_rate.name,
        shortName: translations.sourceable_product_out_of_stock_rate.shortName,
        localDefinition:
            translations.sourceable_product_out_of_stock_rate.helpText,
        isRatio: true,
        deltas: ['change'],
        id: 'sourceable_product_out_of_stock_rate',
    })

export const receive_fill_rate = makePercentageMetricFieldFunc({
    name: translations.receive_fill_rate.name,
    shortName: translations.receive_fill_rate.shortName,
    localDefinition: translations.receive_fill_rate.helpText,
    isRatio: true,
    deltas: ['change'],
    id: 'receive_fill_rate',
})

export const vendor_confirmation_rate = makePercentageMetricFieldFunc({
    name: translations.vendor_confirmation_rate.name,
    shortName: translations.vendor_confirmation_rate.shortName,
    localDefinition: translations.vendor_confirmation_rate.helpText,
    isRatio: true,
    deltas: ['change'],
    id: 'vendor_confirmation_rate',
})
