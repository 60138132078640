import { ReactElement, ReactNode } from 'react'

import { Menu, type MenuItemProps } from 'antd'
import { useSelector } from 'react-redux'

import {
    changeOrganizationGroupRequest,
    changeOrganizationRequest,
} from 'actions/auth'
import { setHiddenAutomationCapabilities } from 'actions/ui/app'
import { userHasCustomerServicePermissions } from 'helpers/featurePermissions'
import {
    useAction,
    useAuthDomainValue,
    useModal,
    useUserHasPermissions,
} from 'hooks'
import {
    selectAutomationCapabilitiesMap,
    selectHiddenAutomationCapabilities,
    selectIsSidebarCollapsed,
} from 'selectors/ui'
import { Organization, OrganizationGroup, RootReduxState } from 'types'

import { getOrgGroupTitle } from '../../helpers'

import ImpersonationModal from './ImpersonationModal'
import OrganizationModal from './OrganizationModal'

type RenderProps = {
    isImpersonationMode: boolean
    toggleModal: () => void
}

// Menu.Item is passed props from Menu
// see https://github.com/react-component/menu/issues/142#issuecomment-413041068
interface Props extends Omit<MenuItemProps, 'children'> {
    currentOrganization: Organization
    currentOrganizationGroup: OrganizationGroup
    children: (renderProps: RenderProps) => ReactNode
}

const OrganizationMenuItem = ({
    currentOrganization,
    currentOrganizationGroup,
    children,
    ...menuItemProps
}: Props): ReactElement | null => {
    const isSidebarCollapsed = useSelector((state: RootReduxState) =>
        selectIsSidebarCollapsed(state)
    )

    const isImpersonating = useAuthDomainValue('userIsImpersonating')

    const isImpersonationMode =
        useUserHasPermissions(userHasCustomerServicePermissions) &&
        isImpersonating

    const automationCapabilities = useSelector(selectAutomationCapabilitiesMap)
    const hiddenAutomationCapabilities = useSelector(
        selectHiddenAutomationCapabilities
    )

    const {
        isModalVisible: isImpersonationModalVisible,
        toggleModalVisible: toggleImpersonationModal,
    } = useModal()
    const {
        isModalVisible: isOrganizationModalVisible,
        toggleModalVisible: toggleOrganizationModal,
    } = useModal()

    const changeOrganization = useAction(changeOrganizationRequest)
    const changeOrganizationGroup = useAction(changeOrganizationGroupRequest)
    const changeHiddenAutomationCapabilities = useAction(
        setHiddenAutomationCapabilities
    )

    const handleChangeOrganization = (organizationId: string): void => {
        if (currentOrganization.id !== organizationId) {
            changeOrganization({ organizationId })
        }
    }

    const handleChangeOrganizationGroup = (
        organizationId: string,
        organizationGroupId: string
    ): void => {
        if (currentOrganizationGroup.id !== organizationGroupId) {
            changeOrganizationGroup({
                organizationGroupId,
                organizationId,
            })
        }
    }

    if (
        !currentOrganization ||
        (isImpersonationMode && !currentOrganizationGroup)
    ) {
        return null
    }

    if (isImpersonationMode) {
        return (
            <>
                <Menu.Item
                    {...menuItemProps}
                    key="organization"
                    title={
                        isSidebarCollapsed
                            ? getOrgGroupTitle({
                                  organization: currentOrganization,
                                  organizationGroup: currentOrganizationGroup,
                              })
                            : null
                    }
                >
                    {children({
                        isImpersonationMode: true,
                        toggleModal: toggleImpersonationModal,
                    })}
                </Menu.Item>
                <ImpersonationModal
                    closeModal={toggleImpersonationModal}
                    modalVisible={isImpersonationModalVisible}
                    currentOrganization={currentOrganization}
                    currentOrganizationGroup={currentOrganizationGroup}
                    automationCapabilities={automationCapabilities}
                    hiddenAutomationCapabilities={hiddenAutomationCapabilities}
                    handleChangeOrganizationGroup={
                        handleChangeOrganizationGroup
                    }
                    handleChangeHiddenAutomationCapabilities={
                        changeHiddenAutomationCapabilities
                    }
                />
            </>
        )
    }

    return (
        <>
            <Menu.Item
                {...menuItemProps}
                key="organization"
                title={isSidebarCollapsed ? currentOrganization.name : null}
            >
                {children({
                    isImpersonationMode: false,
                    toggleModal: toggleOrganizationModal,
                })}
            </Menu.Item>
            <OrganizationModal
                modalVisible={isOrganizationModalVisible}
                closeModal={toggleOrganizationModal}
                currentOrganization={currentOrganization}
                handleChangeOrganization={handleChangeOrganization}
            />
        </>
    )
}

export default OrganizationMenuItem
