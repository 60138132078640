import { all } from 'redux-saga/effects'

import './keywords'
import './placements'
import './page'
import './budget'

import automationTabWatcher from './automation'

export default function* campaignPageWatcher() {
    yield all([
        // Automation Tab
        automationTabWatcher(),
    ])
}
