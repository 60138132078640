import { ReactElement } from 'react'

import { Select } from 'antd'
import classNames from 'classnames'
import { Field, ErrorMessage } from 'formik'
import map from 'lodash/map'
import pick from 'lodash/pick'

import { IconButton } from 'components/Buttons'
import { FormikSelect } from 'components/formik'
import { TrashIcon } from 'components/Icons'
import Stack from 'components/Layout/Stack'
import { STATE_FIELD_OPTIONS, PAUSED, ENABLED } from 'const/resourceStates'
import { Campaign } from 'types'

import { AdGroupSelectFormikField } from '../Shared/AdGroupSelectField'

import { ProductAdField } from './localTypes'
import * as styles from './styles.scss'

interface Props {
    campaign: Campaign
    product: ProductAdField
    productIndex: number
    remove: (index: number) => void
}

const CreateProductAdFormRow = ({
    campaign,
    product,
    productIndex,
    remove,
}: Props): ReactElement => {
    return (
        <Stack direction="row" alignItems="start" style={{ marginTop: 8 }}>
            <div style={{ flex: 1, marginRight: 4 }}>{product.asin}</div>

            <Stack
                direction="column"
                alignItems="center"
                style={{ marginRight: 4 }}
                className={classNames(styles.field, styles['ad-group-column'])}
            >
                <AdGroupSelectFormikField
                    name={`products.${productIndex}.adGroupId`}
                    campaign={campaign}
                />
                <ErrorMessage
                    name={`products.${productIndex}.adGroupId`}
                    component="div"
                    className="fg-control-text is-error"
                />
            </Stack>

            <Stack
                direction="column"
                alignItems="center"
                style={{ marginRight: 4 }}
                className={classNames(styles.field, styles['state-column'])}
            >
                <Field
                    name={`products.${productIndex}.state`}
                    component={FormikSelect}
                    placeholder="Select State"
                >
                    {map(
                        pick(STATE_FIELD_OPTIONS, [ENABLED, PAUSED]),
                        (label, value) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        )
                    )}
                </Field>
                <ErrorMessage
                    name={`products.${productIndex}.state`}
                    component="div"
                    className="fg-control-text is-error"
                />
            </Stack>

            <Stack
                direction="column"
                alignItems="center"
                style={{ marginRight: 4 }}
                className={classNames(styles.field, styles['remove-column'])}
            >
                <IconButton
                    icon={<TrashIcon />}
                    danger
                    type="link"
                    onClick={() => {
                        remove(productIndex)
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default CreateProductAdFormRow
