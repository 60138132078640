/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all, takeEvery } from 'redux-saga/effects'

import { exportWidgetToCsvRequest } from 'actions/ui/shared/dashboard'

import { exportWidgetToCsvWorker } from './workers'

export default function* dashboardWatcher() {
    yield all([
        takeEvery(exportWidgetToCsvRequest.toString(), exportWidgetToCsvWorker),
    ])
}
