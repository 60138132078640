import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import CloseIconSvg from './CloseIconSvg'

const CloseIcon = (props: IconProps): ReactElement => (
    <Icon component={CloseIconSvg} {...props} />
)

export default CloseIcon
