import { ReactElement, useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
    Alert,
    Button,
    Col,
    Flex,
    Input,
    Modal,
    Row,
    Switch,
    Tooltip,
} from 'antd'
import { useTranslation } from 'react-i18next'

import { WarningIcon } from 'components/Icons'
import { useOrgContext } from 'context/OrgContext'
import { useCerebroApiRequest } from 'hooks'
import { useIntegrationIssuesInfo } from 'hooks/useIntegrationIssuesInfo'
import { updateIntegration } from 'services/cerebroApi/orgScope/resourceApi'
import { Integration } from 'types'
import message from 'utilities/message'

import IntegrationProfilesTable from './IntegrationProfilesTable'
import useIntegrationProfilesTable from './useIntegrationProfilesTable'

interface Props {
    integration: Integration
    reloadData: () => void
}

const IntegrationDescriptionCell = ({
    integration,
    reloadData,
}: Props): ReactElement => {
    const integrationIssuesInfo = useIntegrationIssuesInfo()
    const orgContext = useOrgContext()
    const integrationFirstIssue =
        orgContext?.orgAccountStatus?.integration_issues?.find(
            (issue) =>
                issue.integration_id === integration.id &&
                issue.integration_alias === integration.alias
        )
    const {
        profiles,
        loadProfiles,
        loading,
        selectedRowKeys,
        setSelectedRowKeys,
    } = useIntegrationProfilesTable(integration.id)
    const [isSubmitting, setSubmitting] = useState(false)
    const [isModalVisible, setModalVisible] = useState(false)
    const [isAddingNewAccountsByDefault, setAddingNewAccountsByDefault] =
        useState(!!integration.auto_select_new_accounts)
    const [integrationAlias, setIntegrationAlias] = useState(integration.alias)
    const makeCerebroApiRequest = useCerebroApiRequest()
    const { t } = useTranslation(['common', 'table'])

    const hasSelectedAccountsLostAccess =
        (integration.selected_inactive_profile_count ?? 0) > 0

    const selectedProfilesLostAccess = profiles.filter(
        (profile) =>
            profile.status === 'inactive' &&
            profile.selected_status === 'active'
    )

    return (
        <>
            <Button
                type="link"
                onClick={() => {
                    loadProfiles()
                    setModalVisible(true)
                }}
                className="p-0"
            >
                {integration.alias}
                {!integrationFirstIssue && hasSelectedAccountsLostAccess && (
                    <>
                        {' '}
                        <Tooltip
                            title={t(
                                'table:fields.integrationDescription.inactiveAccountsTooltipTitle',
                                'This integration has lost access to an Advertising Account. View the integration details for more information.'
                            )}
                            placement="right"
                        >
                            <ExclamationCircleOutlined
                                className="fg-color-danger"
                                style={{ marginLeft: 4 }}
                            />
                        </Tooltip>
                    </>
                )}
                {integrationFirstIssue && (
                    <Tooltip
                        title={
                            (
                                integrationIssuesInfo[
                                    integrationFirstIssue.issue_key
                                ] ?? integrationIssuesInfo.default
                            ).shortDescription
                        }
                    >
                        <span>
                            <WarningIcon />
                        </span>
                    </Tooltip>
                )}
            </Button>
            <Modal
                title={t(
                    'table:fields.integrationDescription.modal.title',
                    'Integrate Specific Advertising Accounts'
                )}
                open={isModalVisible}
                style={{ top: 25 }}
                width={720}
                onCancel={() => setModalVisible(false)}
                footer={[
                    <Button
                        onClick={() => {
                            setIntegrationAlias(integration.alias)
                            setAddingNewAccountsByDefault(
                                !!integration.auto_select_new_accounts
                            )
                            setModalVisible(false)
                        }}
                        style={{ marginRight: 4 }}
                        key="cancel"
                    >
                        {t('common:cancel', 'Cancel')}
                    </Button>,
                    <Button
                        type="primary"
                        onClick={() => {
                            setSubmitting(true)
                            makeCerebroApiRequest({
                                request: updateIntegration(integration.id, {
                                    selected_profile_ids: selectedRowKeys,
                                    auto_select_new_accounts:
                                        isAddingNewAccountsByDefault,
                                    alias: integrationAlias,
                                }),
                                onRequestSuccess: () => {
                                    setSubmitting(false)
                                    setModalVisible(false)
                                    message.success(
                                        t(
                                            'table:fields.integrationDescription.modal.successMsg',
                                            'Successfully updated integration advertising accounts.'
                                        )
                                    )
                                    reloadData()
                                },
                            })
                        }}
                        loading={isSubmitting}
                        key="save"
                        disabled={!integrationAlias}
                    >
                        {t('common:save', 'Save')}
                    </Button>,
                ]}
            >
                <Row gutter={[6, 16]} style={{ width: '100%' }}>
                    <Col style={{ marginBottom: 4 }} span={24}>
                        <span>
                            {t(
                                'table:fields.integrationDescription.modal.integrationName',
                                'Integration Name'
                            )}
                        </span>
                        <Input
                            value={integrationAlias}
                            onChange={(e) =>
                                setIntegrationAlias(e.target.value)
                            }
                            placeholder={t(
                                'table:fields.integrationDescription.modal.integrationName',
                                'Integration Name'
                            )}
                        />
                        {!integrationAlias && (
                            <div className="fg-control-text is-error">
                                {t(
                                    'fields.integrationDescription.modal.integrationName.errorMsg',
                                    'Integration Name is a required field'
                                )}
                            </div>
                        )}
                    </Col>
                    <Col span={24}>
                        <Flex gap={4}>
                            <Switch
                                checked={isAddingNewAccountsByDefault}
                                onChange={setAddingNewAccountsByDefault}
                            />

                            {t(
                                'table:fields.integrationDescription.modal.integrateNewAccounts',
                                'Automatically select new advertising accounts as they are added to this integration.'
                            )}
                        </Flex>
                    </Col>
                    <Col span={24}>
                        {selectedProfilesLostAccess.length > 0 && (
                            <Alert
                                type="warning"
                                style={{ marginBottom: 16 }}
                                message={
                                    <div>
                                        <h4 style={{ margin: 0 }}>
                                            {t(
                                                'table:fields.integrationDescription.inactiveAccountsWarning.header',
                                                'This integration has lost access to the following selected Advertising Accounts: '
                                            )}
                                        </h4>
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                                padding: 4,
                                            }}
                                        >
                                            {selectedProfilesLostAccess
                                                .map(
                                                    (a) =>
                                                        a.profile.ams_brand_name
                                                )
                                                .join(', ')}
                                        </div>
                                        <div>
                                            {t(
                                                'table:fields.integrationDescription.inactiveAccountsWarning.footer',
                                                'If you no longer need access to these accounts, you can deselect them. Otherwise you may need to re-create the integration with an email that has sufficient access. Contact us if you need assistance.'
                                            )}
                                        </div>
                                    </div>
                                }
                            />
                        )}
                        <div style={{ marginBottom: 6 }}>
                            {t(
                                'table:fields.integrationDescription.modal.profilesList',
                                'Select accounts to synchronize data from'
                            )}
                        </div>
                        <div style={{ margin: '0 -15px' }}>
                            <IntegrationProfilesTable
                                profiles={profiles}
                                loading={loading}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                            />
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default IntegrationDescriptionCell
