/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { captureException } from '@sentry/browser'
import get from 'lodash/get'
import { call, put } from 'redux-saga/effects'

import {
    DownloadDataRequestAction,
    FetchDataRequestAction,
    makeDownloadDataFailure,
    makeFetchDataFailure,
} from 'actions/ui/shared'
import uiSagaRegistry from 'sagas/ui/registry'

/**
 * Fetch table data
 */

export function* fetchDataWorker(action: FetchDataRequestAction) {
    const { path, data } = action.payload
    try {
        const fetchDataSaga = uiSagaRegistry.lookupSaga(path, 'fetchDataSaga')
        if (fetchDataSaga) {
            yield call(fetchDataSaga, get(data, ['noCount']))
        } else {
            // backwards compatible with sagas that know they are shown as tables
            const fetchTableSaga = uiSagaRegistry.lookupSaga(
                path,
                'fetchTableSaga'
            )
            if (fetchTableSaga) {
                yield call(fetchTableSaga, get(data, ['noCount']))
            }
        }
    } catch (error) {
        yield call(captureException, error)
        yield put(makeFetchDataFailure(path)(error))
    }
}

/**
 * Download table data
 */

export function* downloadDataWorker(action: DownloadDataRequestAction) {
    const { path } = action.payload
    try {
        const downloadDataSaga = uiSagaRegistry.lookupSaga(
            path,
            'downloadDataSaga'
        )
        if (downloadDataSaga) {
            yield call(downloadDataSaga, path)
        } else {
            // backwards compatible with sagas that know they are shown as tables
            const downloadTableSaga = uiSagaRegistry.lookupSaga(
                path,
                'downloadTableSaga'
            )
            if (downloadTableSaga) {
                yield call(downloadTableSaga, path)
            }
        }
    } catch (error) {
        yield call(captureException, error)
        yield put(makeDownloadDataFailure(path)(error))
    }
}
