import { ReactElement, CSSProperties } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import classNames from 'classnames'

import * as styles from './styles.scss'

type Size = 'x_small' | 'small' | 'medium' | 'large'

const SIZE_MAP: Record<Size, number> = {
    x_small: 20,
    small: 50,
    medium: 80,
    large: 100,
}

interface LoadingIndicatorProps {
    size: Size
    style?: CSSProperties
    fullContent?: boolean
}

const LoadingIndicator = ({
    size = 'large',
    style = undefined,
    fullContent = true,
}: LoadingIndicatorProps): ReactElement => {
    return (
        <div
            className={classNames(styles['spin-wrapper'], {
                [styles['full-content']]: fullContent,
            })}
            style={style}
        >
            <Spin
                indicator={
                    <LoadingOutlined
                        spin
                        style={{ fontSize: SIZE_MAP[size] }}
                    />
                }
            />
        </div>
    )
}

export default LoadingIndicator
