import { ReactElement } from 'react'

import Stack from 'components/Layout/Stack'

import CreateAdGroupNegativeKeywordFormAllRow from './CreateAdGroupNegativeKeywordFormAllRow'
import CreateAdGroupNegativeKeywordFormHeader from './CreateAdGroupNegativeKeywordFormHeader'
import CreateAdGroupNegativeKeywordFormRow from './CreateAdGroupNegativeKeywordFormRow'
import { KeywordField } from './localTypes'
import * as styles from './styles.scss'

interface Props {
    keywords: KeywordField[]
    remove: (index: number) => void
    setFieldValue: (name: string, values: any) => void
}

const CreateAdGroupNegativeKeywordForm = ({
    keywords = [],
    remove,
    setFieldValue,
}: Props): ReactElement => {
    return (
        <Stack direction="column" style={{ flexShrink: 0, marginTop: 12 }}>
            {keywords.length > 0 ? (
                <div>
                    <CreateAdGroupNegativeKeywordFormHeader />
                    <CreateAdGroupNegativeKeywordFormAllRow
                        keywords={keywords}
                        setFieldValue={setFieldValue}
                    />
                    {keywords.map((keyword, idx) => (
                        <CreateAdGroupNegativeKeywordFormRow
                            key={idx}
                            keyword={keyword}
                            keywordIndex={idx}
                            remove={remove}
                        />
                    ))}
                </div>
            ) : (
                <div
                    className={styles['empty-table-text']}
                    style={{ marginTop: 8, padding: 12 }}
                >
                    To get started, add some negative keywords in the field
                    above
                </div>
            )}
            {/* </div> */}
        </Stack>
    )
}

export default CreateAdGroupNegativeKeywordForm
