/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all } from 'redux-saga/effects'

import bidWatcher from './bid'
import dashboardWatcher from './dashboard'
import dataWatcher from './data'
import filterWatcher from './filter'
import labelWatcher from './label'
import pageWatcher from './page'
import resourceWatcher from './resource'
import tabWatcher from './tab'

export default function* sharedWatcher() {
    yield all([
        pageWatcher(),
        filterWatcher(),
        tabWatcher(),
        dashboardWatcher(),
        dataWatcher(),
        resourceWatcher(),
        bidWatcher(),
        labelWatcher(),
    ])
}
