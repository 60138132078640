import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import {
    ADVANCED_TEXT,
    ADVANCED_PRODUCT_TITLE,
    BRANDS,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    CAMPAIGNS,
    COUNTRIES,
    DATES,
    EQUALS,
    FACT_TYPES,
    LABELS,
    METRIC_FILTERS,
    PARENT_ASINS,
    PORTFOLIOS,
    PRODUCT_AD_STATES,
    PRODUCT_ADS,
    PRODUCT_ASINS,
    PRODUCT_NUMBER_OF_ITEMS,
    PRODUCT_SKUS,
    REGIONS,
} from 'const/filters'
import { PRODUCT_LABELS } from 'const/resourceTypes'
import { formatCerebroDate } from 'helpers/formatting'

import { presetRanges, calculateFromDateWithLag } from '../dateRange'
import { formatBooleanFilter } from '../filters'

import { formatMetricFiltersForParams } from './metrics'

export const formatFilters = (filters) => {
    let params = {}

    if (!isEmpty(filters[DATES])) {
        if (isArray(filters[DATES])) {
            params.report_date_min = formatCerebroDate(filters[DATES][0])
            params.report_date_max = formatCerebroDate(filters[DATES][1])
        } else {
            const { range } = presetRanges(
                calculateFromDateWithLag(filters.rangeLag),
                filters.rangeLag
            )[filters[DATES]]
            params.report_date_min = formatCerebroDate(range[0])
            params.report_date_max = formatCerebroDate(range[1])
        }
    }

    if (!isEmpty(filters[REGIONS])) {
        params.profile__region__in = filters[REGIONS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[COUNTRIES])) {
        params.profile__country_code__in = filters[COUNTRIES].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[BRANDS])) {
        params.profile__in = filters[BRANDS].map((item) => item.value).join()
    }

    if (!isEmpty(filters[PORTFOLIOS])) {
        params.campaign__portfolio__id__in = filters[PORTFOLIOS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[CAMPAIGNS])) {
        params.campaign__in = filters[CAMPAIGNS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[CAMPAIGN_STATES])) {
        params.campaign__state__in = filters[CAMPAIGN_STATES].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[CAMPAIGN_TARGETING_TYPES])) {
        params.campaign__targeting_type__in = filters[
            CAMPAIGN_TARGETING_TYPES
        ].map((item) => item.value).join()
    }

    if (!isEmpty(filters[ADVANCED_PRODUCT_TITLE])) {
        params.boolean_filter = formatBooleanFilter(
            filters[ADVANCED_PRODUCT_TITLE],
            ADVANCED_PRODUCT_TITLE
        )
    }

    if (!isEmpty(filters[PRODUCT_NUMBER_OF_ITEMS])) {
        const { operator, value } = filters[PRODUCT_NUMBER_OF_ITEMS][0]
        if (!isNull(operator) && !isNull(value)) {
            let key = 'product_ad__metadata__number_of_items'
            if (operator !== EQUALS) {
                key += `__${operator}`
            }
            params[key] = value
        }
    }

    if (!isEmpty(filters[PRODUCT_ASINS])) {
        params.product_ad__asin__in = filters[PRODUCT_ASINS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[PRODUCT_SKUS])) {
        params.product_ad__sku_in = filters[PRODUCT_SKUS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[PARENT_ASINS])) {
        params.product_ad__metadata__parent_asin__in = filters[
            PARENT_ASINS
        ].map((item) => item.value).join()
    }

    if (!isEmpty(filters[PRODUCT_ADS])) {
        params.product_ad__in = filters[PRODUCT_ADS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[PRODUCT_AD_STATES])) {
        params.product_ad__state__in = filters[PRODUCT_AD_STATES].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[LABELS])) {
        params.complex_label_filter = formatBooleanFilter(
            filters[LABELS],
            PRODUCT_LABELS
        )
    }

    if (!isEmpty(filters[ADVANCED_TEXT])) {
        params.complex_text_filter = formatBooleanFilter(filters[ADVANCED_TEXT])
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (!isEmpty(metricFilters)) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters) }
    }

    if (!isEmpty(filters[FACT_TYPES])) {
        params.campaign__campaign_type__in = filters[FACT_TYPES].map(
            (item) => item.value
        ).join()
    }

    return params
}
