import { ReactElement, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgSimpleLeftArrowIcon(props: Props): ReactElement {
    return (
        <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1 1L5 5L1 9"
                stroke="#2C2F31"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                transform="scale(-1, 1) translate(-6, 0)"
            />
        </svg>
    )
}

export default SvgSimpleLeftArrowIcon
