import { ReactElement } from 'react'

import classNames from 'classnames'
import { ErrorMessage } from 'formik'

import { IconButton } from 'components/Buttons'
import { TrashIcon } from 'components/Icons'
import Stack from 'components/Layout/Stack'

import { ColumnConfig, BaseKeyword } from './localTypes'
import * as styles from './styles.scss'

interface Props<KeywordField> {
    keyword: KeywordField
    keywordIndex: number
    remove: (index: number) => void
    columnsConfig: ColumnConfig[]
}

function CreateKeywordFormRow<KeywordField extends BaseKeyword>({
    keyword,
    keywordIndex,
    remove,
    columnsConfig,
}: Props<KeywordField>): ReactElement {
    return (
        <Stack
            direction="row"
            alignItems="start"
            className={styles['keyword-row']}
            style={{ marginTop: 8, paddingBottom: 8 }}
        >
            <div
                style={{ flex: 1, marginRight: 4 }}
                className={styles['text-field']}
            >
                {keyword.text}
            </div>
            {columnsConfig.map(({ dataKey, width, renderField }) => {
                const fieldName = `keywords.${keywordIndex}.${dataKey}`
                return (
                    <Stack
                        direction="column"
                        alignItems="center"
                        className={styles.field}
                        style={{
                            width,
                            marginRight: 4,
                        }}
                        key={dataKey}
                    >
                        {renderField(fieldName)}
                        <ErrorMessage
                            name={fieldName}
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </Stack>
                )
            })}

            <Stack
                direction="column"
                alignItems="center"
                className={classNames(styles.field, styles['remove-column'])}
                style={{ marginRight: 4 }}
            >
                <IconButton
                    icon={<TrashIcon />}
                    danger
                    type="link"
                    onClick={() => {
                        remove(keywordIndex)
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default CreateKeywordFormRow
