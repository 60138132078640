import { ReactElement } from 'react'

import * as styles from './styles.scss'

interface Props {
    /**
     * By default the component used for table titles is an `<h2 />`.
     * In cases where the table is the only item on the page, set the `component` prop to 'h1'
     */
    component?: 'h1' | 'h2'
    /**
     * Text of the table's title
     */
    titleText: string
}

export default function TableTitle({
    component = 'h2',
    titleText,
}: Props): ReactElement {
    return component === 'h1' ? (
        <h1 className={styles['main-heading']}>{titleText}</h1>
    ) : (
        <h2 className={styles['secondary-heading']}>{titleText}</h2>
    )
}
