import { memo, ReactElement, useState } from 'react'

import { Button, type PopconfirmProps } from 'antd'
import { type TooltipPlacement } from 'antd/es/tooltip'
import { AxiosResponse } from 'axios'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Buttons'
import { DeleteConfirm } from 'components/DeleteConfirm/DeleteConfirm'
import { TrashIcon } from 'components/Icons'
import { useCerebroApiRequest } from 'hooks'
import message from 'utilities/message'

interface Props {
    disabled: boolean
    okText?: PopconfirmProps['okText']
    title?: PopconfirmProps['title']
    danger?: boolean
    icon?: ReactElement
    updateRequestApi: (...args: any) => Promise<AxiosResponse>
    updateRequestCallback?: () => void
    updateRequestData: any
    updateRequestParams?: any
    updateRequestSuccessMesg: string | ReactElement
    tooltipTitle?: string | ReactElement
    tooltipPlacement?: TooltipPlacement
    buttonType?: 'icon' | 'button'
}

function DeleteField<RecordType>({
    disabled = false,
    okText = 'Delete',
    title = 'Are you sure you want to delete this record',
    danger = true,
    icon = <TrashIcon />,
    updateRequestApi,
    updateRequestCallback = noop,
    updateRequestData,
    updateRequestParams = {},
    updateRequestSuccessMesg,
    tooltipTitle,
    tooltipPlacement,
    buttonType = 'icon',
}: Props): ReactElement {
    const { t } = useTranslation('common')
    const [loading, setLoading] = useState(false)
    const makeCerebroApiRequest = useCerebroApiRequest()

    const onConfirm = async (): Promise<void> => {
        setLoading(true)

        await makeCerebroApiRequest<RecordType>({
            request: updateRequestApi(updateRequestData, updateRequestParams),
            onRequestSuccess: () => {
                message.success(updateRequestSuccessMesg)
                updateRequestCallback()
            },
        })

        setLoading(false)
    }

    return (
        <DeleteConfirm
            disabled={disabled}
            onConfirm={onConfirm}
            title={title}
            okText={okText}
        >
            {buttonType === 'icon' ? (
                <IconButton
                    danger={danger}
                    disabled={disabled}
                    icon={icon}
                    loading={loading}
                    type="link"
                    tooltipTitle={tooltipTitle}
                    tooltipPlacement={tooltipPlacement}
                />
            ) : (
                <Button
                    danger={danger}
                    disabled={disabled}
                    icon={icon}
                    loading={loading}
                    type="link"
                >
                    {t('common:delete')}
                </Button>
            )}
        </DeleteConfirm>
    )
}

export default memo(DeleteField, isEqual) as typeof DeleteField
