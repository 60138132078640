import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import OrganizationIconSvg from './OrganizationIconSvg'

const OrganizationIcon = (props: IconProps): ReactElement => (
    <Icon component={OrganizationIconSvg} {...props} />
)

export default OrganizationIcon
