import { ReactElement } from 'react'

import classNames from 'classnames'

import Stack from 'components/Layout/Stack'

import * as styles from './styles.scss'

export default function CreateCampaignNegativeKeywordFormheader(): ReactElement {
    return (
        <Stack direction="row" alignItems="start">
            <div
                style={{ flex: 1, marginTop: 8, marginRight: 4 }}
                className="fg-control-label"
            >
                Keyword
            </div>
            <div
                className={classNames(
                    'fg-control-label',
                    styles['header-label'],
                    styles['match-type-column']
                )}
                style={{
                    textAlign: 'center',
                    marginTop: 8,
                    marginRight: 4,
                }}
            >
                Match Type
            </div>

            <div
                className={classNames(
                    'fg-control-label',
                    styles['header-label'],
                    styles['remove-column']
                )}
                style={{
                    textAlign: 'center',
                    marginTop: 8,
                    marginRight: 4,
                }}
            >
                Remove
            </div>
        </Stack>
    )
}
