import { connect } from 'react-redux'

import { selectAutomationCapabilitiesMap } from 'selectors/ui/page'
import { RootReduxState } from 'types'

import Automation from './Automation'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootReduxState) => ({
    capabilitiesMap: selectAutomationCapabilitiesMap(state),
})

export default connect(mapStateToProps)(Automation)
