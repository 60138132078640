import { ReactElement, useMemo, useState } from 'react'

import { Link } from 'react-router-dom'

import AtAGlancePopover from 'components/AtAGlancePopover/AtAGlancePopover'
import { ResearchKeywordLink } from 'components/Links/ResearchKeywordLink/ResearchKeywordLink'
import { makeMapping } from 'configuration/dataSources/helpers'
import { AUTOMATION_KEYS } from 'const/automations'
import { KEYWORDS } from 'const/filters'
import { useGetDataSourceMetricsColumns } from 'features/MetricsGlossary/queries'
import { keywordUrl } from 'helpers/urls'
import { useCerebroApiRequest } from 'hooks'
import { getAutomationTasks } from 'services/cerebroApi/orgScope/resourceApi'
import {
    KeywordFact,
    Detail,
    Keyword,
    AutomationTask,
    CerebroPaginatedResponse,
} from 'types'

import {
    keywordStateRenderer,
    keywordMatchTypeRenderer,
    keywordBidRenderer,
    keywordActiveBidRenderer,
    campaignRenderer,
} from '../ResourceDetailsV2/Renderers'

import * as styles from './styles.scss'

interface Props {
    keyword: Keyword
}

function AtAGlanceKeywordPopover({ keyword }: Props): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const [daypartingTasks, setDaypartingTasks] = useState<AutomationTask[]>([])
    const { data: metricsColumnsData } =
        useGetDataSourceMetricsColumns('keywords')

    const metricsConfig = useMemo(() => {
        return metricsColumnsData ? makeMapping(metricsColumnsData) : {}
    }, [metricsColumnsData])

    const details: Detail[] = [
        {
            render: () => keywordStateRenderer(keyword),
        },
        {
            render: () => keywordActiveBidRenderer(keyword),
            show: daypartingTasks.length > 0,
        },
        {
            render: () => keywordBidRenderer(keyword),
        },
        {
            render: () => keywordMatchTypeRenderer(keyword),
        },
        {
            render: () => campaignRenderer(keyword.campaign),
        },
    ]

    const filters = {
        [KEYWORDS]: [{ value: keyword.id }],
    }

    return (
        <div className={styles.field}>
            <AtAGlancePopover<KeywordFact>
                title={
                    <Link
                        className={styles['keyword-link']}
                        to={keywordUrl(keyword.id)}
                    >
                        {keyword.text}
                    </Link>
                }
                details={details}
                filters={filters}
                dataSource="keyword_facts"
                metricsConfig={metricsConfig}
                onPopoverVisible={() => {
                    makeCerebroApiRequest<
                        AutomationTask,
                        CerebroPaginatedResponse<AutomationTask>
                    >({
                        request: getAutomationTasks(
                            {
                                campaign__id: keyword.campaign_id,
                                capability__id: AUTOMATION_KEYS.time_parting,
                                enabled: true,
                            },
                            { headers: { noCount: true } }
                        ),
                        onRequestSuccess: ({ data }) =>
                            setDaypartingTasks(data.results),
                    })
                }}
            />
            {keyword.campaign?.targeting_type !== 'auto' && (
                <ResearchKeywordLink
                    countryCode={keyword.profile.country_code}
                    keyword={keyword.text}
                />
            )}
        </div>
    )
}

export default AtAGlanceKeywordPopover
