import { memo, ReactElement, useState, ReactNode } from 'react'

import { Button, Popover } from 'antd'
import {
    Formik,
    FormikProps,
    FormikHelpers,
    FormikValues,
    FormikErrors,
} from 'formik'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import { Schema } from 'yup'

import SingleValueFieldBody from './SingleValueFieldBody'
import * as styles from './styles.scss'

interface FormStatus<FormValues> {
    lastValues: FormValues
}

interface ChildProps<FormValues> {
    isSubmitting: FormikProps<FormValues>['isSubmitting']
    closeForm?: () => void
    resetForm?: () => void
    setFieldValue?: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void
    values: FormValues
    errors: FormikErrors<FormValues>
}

interface Props<FormValues extends FormikValues> {
    children: (props: ChildProps<FormValues>) => ReactElement
    disabled?: boolean
    disableSubmit?: boolean
    fieldName: keyof FormValues
    formatFieldValue: (values: FormValues) => ReactNode
    initialValues: FormValues
    onSave: (values: FormValues) => Promise<{ status: number } | null>
    readOnly?: boolean
    validationSchema: Schema
    updateRequestCallback?: () => void
    useApplyAndCancelButtons?: boolean
    getButtonClassName?: (values: FormValues) => string | null
    extraContent?: ReactNode
}

function SingleValueField<FormValues extends FormikValues>({
    children,
    disabled = false,
    disableSubmit = false,
    fieldName,
    formatFieldValue,
    initialValues,
    onSave,
    readOnly = false,
    validationSchema,
    updateRequestCallback = noop,
    useApplyAndCancelButtons = true,
    getButtonClassName,
    extraContent,
}: Props<FormValues>): ReactElement {
    const [editing, setEditing] = useState(false)
    const [failure, setFailure] = useState(false)

    const toggleEditing = (): void => {
        setEditing((isEditing) => !isEditing)
    }

    const handleOptimisticClose = (
        status: FormStatus<FormValues>,
        values: FormValues,
        isSubmitting: FormikProps<FormValues>['isSubmitting'],
        setFieldValue: FormikProps<FormValues>['setFieldValue']
    ): void => {
        if (!isSubmitting) {
            toggleEditing()
        }

        if (!isEqual(status.lastValues, values)) {
            setFieldValue(fieldName as string, status.lastValues[fieldName])
        }
    }

    const onSubmit = async (
        values: FormValues,
        actions: FormikHelpers<FormValues>
    ): Promise<void> => {
        /**
         * Delay closing popover so user can
         * briefly see the "check" when field is saved
         */
        const delayClosePopover = (): void => {
            setTimeout(() => {
                toggleEditing()
                actions.setStatus({
                    lastValues: values,
                })
            }, 600)
        }

        const response = await onSave(values)
        actions.setSubmitting(false)

        if ((response?.status ?? 0) >= 200 && (response?.status ?? 0) < 300) {
            setFailure(false)
            delayClosePopover()
            updateRequestCallback()
        } else {
            setFailure(true)
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            initialStatus={{ lastValues: initialValues }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur
            validateOnChange
            validateOnMount
        >
            {({
                isSubmitting,
                isValid,
                setFieldValue,
                handleSubmit,
                values,
                errors,
                status,
                resetForm,
            }) =>
                disabled || readOnly ? (
                    <div style={{ marginLeft: 8, marginRight: 8 }}>
                        {formatFieldValue(values)}
                    </div>
                ) : (
                    <Popover
                        content={
                            <SingleValueFieldBody
                                handleCancel={() => {
                                    handleOptimisticClose(
                                        status,
                                        values,
                                        isSubmitting,
                                        setFieldValue
                                    )
                                }}
                                handleSubmit={handleSubmit}
                                isSubmitting={isSubmitting}
                                disableSubmit={disableSubmit}
                                isValid={isValid}
                                submitFailure={failure}
                                field={children({
                                    isSubmitting,
                                    closeForm: toggleEditing,
                                    resetForm,
                                    setFieldValue,
                                    values,
                                    errors,
                                })}
                                useApplyAndCancelButtons={
                                    useApplyAndCancelButtons
                                }
                                extraContent={
                                    !isEqual(status.lastValues, values)
                                        ? extraContent
                                        : null
                                }
                            />
                        }
                        trigger="click"
                        open={editing}
                        onOpenChange={() => {
                            handleOptimisticClose(
                                status,
                                values,
                                isSubmitting,
                                setFieldValue
                            )
                        }}
                    >
                        <Button
                            className={
                                getButtonClassName?.(values) ||
                                styles['editable-cell']
                            }
                            size="small"
                            tabIndex={0}
                            disabled={isSubmitting}
                        >
                            {formatFieldValue(values)}
                        </Button>
                    </Popover>
                )
            }
        </Formik>
    )
}

export default memo(SingleValueField, isEqual) as typeof SingleValueField
