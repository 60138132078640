import { ReactNode } from 'react'

import { List, Popover, Tooltip } from 'antd'
import { Link } from 'react-router-dom'

import {
    RemoveSovKeywordFromLabelField,
    SovStateField,
} from 'components/EditableFields/SovFields'
import { ResearchKeywordLink } from 'components/Links/ResearchKeywordLink/ResearchKeywordLink'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { labelUrl, sovKeywordUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import {
    CellRenderProps,
    KeywordResearchList,
    Label,
    SovFact,
    SovKeywordGroupBy,
    SovKeywordResearch,
} from 'types'

import * as styles from './styles.scss'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
}

export function sovKeywordIdRenderer<RecordType extends SovKeywordGroupBy>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) ? (
        undefinedValue
    ) : (
        <div className={styles['field-with-actions']}>
            <ResourceLink className={styles.text} to={sovKeywordUrl(value)}>
                {record.keyword.text}
            </ResourceLink>
            <ResearchKeywordLink
                countryCode={record.keyword.country_code}
                keyword={record.keyword.text}
            />
        </div>
    )
}

export function sovKeywordStateRenderer<RecordType extends SovKeywordGroupBy>({
    cellRenderProps: { record, readonly },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <SovStateField
            record={record}
            readOnly={readonly}
            dataIndex={dataIndex}
        />
    )
}

export function sovKeywordLabelIdRenderer<
    RecordType extends SovKeywordGroupBy & {
        keyword__label: Label
    },
>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }
    return (
        <ResourceLink
            to={{
                pathname: labelUrl(value),
                search: '?tab=sovKeywords',
            }}
        >
            {record.keyword__label?.name}
        </ResourceLink>
    )
}

export function removeSovKeywordFromLabelRenderer<RecordType extends SovFact>({
    cellRenderProps: { reloadData, readonly, record },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <RemoveSovKeywordFromLabelField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}

export function sovKeywordListsRenderer<RecordType extends SovKeywordResearch>({
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (!record.lists?.length) {
        return undefinedValue
    }

    const renderSingleList = (list: KeywordResearchList): ReactNode => {
        return (
            <Tooltip key={list.id} title={list.description}>
                <Link to={`/keyword-research-lists/${list.id}`}>
                    {list.name}
                </Link>
            </Tooltip>
        )
    }

    if (record.lists.length === 1) {
        return renderSingleList(record.lists[0])
    }

    return (
        <Popover
            content={
                <List
                    size="small"
                    split={false}
                    dataSource={record.lists}
                    renderItem={(list) => (
                        <List.Item>{renderSingleList(list)}</List.Item>
                    )}
                />
            }
        >
            <span>In {record.lists.length} Lists</span>
        </Popover>
    )
}
