import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import DownstreamLogoIconSvg from './DownstreamLogoIconSvg'

const DownstreamLogoIcon = (props: IconProps): ReactElement => (
    <Icon component={DownstreamLogoIconSvg} {...props} />
)

export default DownstreamLogoIcon
