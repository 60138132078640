import { AVAILABLE_CHART_COLORS, CODED_COLORS } from 'const/colors'
import { hashCode } from 'helpers/hashCode'

const stringToNumber = (str: string, integrity: number): number => {
    const hash = hashCode(str)
    return hash % integrity
}

/**
 * Generates a color value from any string
 * @param str string to be converted
 */
export const stringToColor = (str: string): string => {
    const strLower = str.toLowerCase()
    const codedColor = CODED_COLORS[strLower]
    if (codedColor) {
        return codedColor
    }

    const index = stringToNumber(str, AVAILABLE_CHART_COLORS.length)
    return AVAILABLE_CHART_COLORS[index]
}

export function calculateUsageColor(
    usage: number,
    limit: number,
    defaultColor: string
): string {
    const usagePercentage = (usage / limit) * 100
    if (usagePercentage >= 89 && usagePercentage < 99) {
        return '#F9C402' // yellow
    }
    if (usagePercentage >= 99) {
        return '#F41E28' // red
    }
    return defaultColor
}
