import { handleActions } from 'redux-actions'

import { walmartCampaignAdGroupsTabTableColumnsConfig } from 'configuration/tables'
import { DATES, AD_GROUPS, AD_GROUP_STATES } from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const defaultState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [AD_GROUPS]: [],
        [AD_GROUP_STATES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [AD_GROUPS, AD_GROUP_STATES],
        displayState: {
            [AD_GROUPS]: true,
            [AD_GROUP_STATES]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(walmartCampaignAdGroupsTabTableColumnsConfig),
        { showTotalRow: true }
    ),
}

export default handleActions({}, defaultState)
