import { ReactElement, ReactNode } from 'react'

import { RemoveBrandFromLabelField } from 'components/EditableFields/BrandFields'
import { AdAccountLink } from 'components/Links'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { labelUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import { CellRenderProps, ProfileGroupBy, ProfileLabelGroupBy } from 'types'

type RenderPropsWithDataIndex<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex?: string[]
}

export function profileIdRenderer<RecordType extends ProfileGroupBy>({
    record,
}: CellRenderProps<RecordType>): ReactElement {
    return <AdAccountLink adAccount={record.profile} appendCountry={false} />
}

export function profileLabelIdRenderer<RecordType extends ProfileLabelGroupBy>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }
    return (
        <ResourceLink
            to={{
                pathname: labelUrl(value),
                search: '?tab=brands',
            }}
        >
            {record.profile__label.name}
        </ResourceLink>
    )
}

export function labelProfileRemoveFromLabelRenderer<
    RecordType extends ProfileGroupBy,
>({
    cellRenderProps: { record, readonly, reloadData },
    dataIndex = ['profile', 'id'],
}: RenderPropsWithDataIndex<RecordType>): ReactElement {
    return (
        <RemoveBrandFromLabelField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}
