import { ReactElement, useEffect, useState } from 'react'

import { Button, Modal } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { PlusIcon } from 'components/Icons'
import { formatCurrency } from 'helpers/formatting'
import { useCerebroApiRequest, useModal } from 'hooks'
import { useAnalytics } from 'services/analytics'
import {
    getProductSkuCogs,
    putProductSkuCogs,
} from 'services/cerebroApi/orgScope/productCogsApi'
import { ProductCogs, ProductCogsPeriod } from 'types/resources/productCogs'

import ProductCogsForm from './ProductCogsForm'
import * as styles from './styles.scss'

interface ProductCogsModalProps {
    sku?: string
    cogsValue?: number
    currencyCode?: string
    onChange?: () => any
}

const ProductCogsModal = (
    props: ProductCogsModalProps
): ReactElement | null => {
    const { cogsValue, sku, currencyCode, onChange } = props
    const { t } = useTranslation('cogs')
    const [isLoading, setIsLoading] = useState(false)
    const { isModalVisible, toggleModalVisible, onModalCancel } = useModal()
    const [skuCogsData, setSkuCogsData] = useState<ProductCogs>()
    const makeCerebroApiRequest = useCerebroApiRequest()
    const { trackCtaClick } = useAnalytics()

    useEffect(() => {
        async function getCogsData(productSku: string): Promise<void> {
            setIsLoading(true)
            await makeCerebroApiRequest({
                request: getProductSkuCogs(productSku),
                onRequestSuccess: (response) => {
                    setSkuCogsData(response.data)
                    setIsLoading(false)
                },
                onRequestFailure: () => {
                    setSkuCogsData(undefined)
                    setIsLoading(false)
                },
            })
        }

        if (isModalVisible && sku) {
            getCogsData(sku)
        }
    }, [isModalVisible, makeCerebroApiRequest, sku])

    const saveCogs = async (cogs: ProductCogsPeriod[]): Promise<void> => {
        if (sku) {
            await makeCerebroApiRequest({
                request: putProductSkuCogs(sku, { cogs }),
                onRequestSuccess: (response) => {
                    onChange?.()
                    setSkuCogsData(response.data)
                    toggleModalVisible()
                },
            })
        }

        trackCtaClick({
            destination: 'Same Page',
            text: 'Save',
            type: 'Button',
            location: 'Product Cogs',
        })
    }

    const allProducts = skuCogsData?.products || []

    const addCogsForSkuLinkText = t('cogs:CogsManagement.add.link', 'Add COGS')
    const editCogsForSkuTitle = t(
        'cogs:CogsManagement.edit.title',
        'Edit COGS for SKU: {{ sku }}',
        { sku }
    )

    const formatterOptions = {
        decimal: true,
        currencyCode,
    }

    const displayedCogs = cogsValue
        ? formatCurrency(cogsValue, formatterOptions)
        : addCogsForSkuLinkText

    if (!sku) {
        return null
    }

    return (
        <div>
            <Button
                type="link"
                onClick={() => {
                    if (!isModalVisible) {
                        trackCtaClick({
                            destination: 'Modal',
                            text: 'Edit',
                            type: 'Button',
                            location: 'Product Cogs',
                        })
                    }
                    toggleModalVisible()
                }}
                icon={!cogsValue && <PlusIcon />}
                className={classNames(styles['cogs-cell'], {
                    [styles['cogs-value']]: !!cogsValue,
                    [styles['modal-link']]: !cogsValue,
                })}
            >
                {displayedCogs}
            </Button>
            <Modal
                title={editCogsForSkuTitle}
                open={isModalVisible}
                width={1076}
                footer={false}
                onCancel={onModalCancel}
            >
                <div>
                    {!isLoading &&
                        !!sku &&
                        !!skuCogsData &&
                        !!allProducts?.length && (
                            <ProductCogsForm
                                sku={sku}
                                products={allProducts}
                                cogs={skuCogsData.cogs}
                                onSubmit={saveCogs}
                                onCancel={onModalCancel}
                            />
                        )}
                </div>
            </Modal>
        </div>
    )
}

export default ProductCogsModal
