import {
    productMetricGoalDifferenceRenderer,
    productMetricGoalPerformanceIndexRenderer,
    productMetricGoalRenderer,
} from 'components/FieldRenderers/Renderers/metricGoals'
import {
    product_acos_goal,
    product_acos_goal_delta,
    product_acos_goal_performanceIndex,
    product_cpu_goal,
    product_cpu_goal_delta,
    product_cpu_goal_performanceIndex,
    product_tacos_goal,
    product_tacos_goal_delta,
    product_tacos_goal_ordered_delta,
    product_tacos_goal_ordered_performanceIndex,
    product_tacos_goal_performanceIndex,
    product_tacos_goal_shipped_delta,
    product_tacos_goal_shipped_performanceIndex,
    product_total_cpu_goal,
    product_total_cpu_goal_ordered_delta,
    product_total_cpu_goal_ordered_performance_index,
    product_total_cpu_goal_shipped_delta,
    product_total_cpu_goal_shipped_performance_index,
} from 'configuration/fieldCreators/metrics/goals'
import { Field } from 'types'

const PRODUCT_CPU_CONFIGURABLE_GOALS = [
    product_cpu_goal({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalRenderer({
                    cellRenderProps,
                    fieldName: 'product_cpu_goal',
                    format: 'currency',
                }),
        },
    }),
]

const PRODUCT_CPU_GOALS: Field<any>[] = [
    ...PRODUCT_CPU_CONFIGURABLE_GOALS,

    product_cpu_goal_delta({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_cpu_goal_delta',
                    format: 'currency',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_cpu_goal_delta__last_period',
                    format: 'currency',
                }),
        },
    }),
    product_cpu_goal_performanceIndex({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName: 'product_cpu_goal_performance_index',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_cpu_goal_performance_index__last_period',
                    format: 'percentage',
                }),
        },
    }),
]

const PRODUCT_ACOS_CONFIGURABLE_GOALS = [
    product_acos_goal({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalRenderer({
                    cellRenderProps,
                    fieldName: 'product_acos_goal',
                    format: 'percentage',
                }),
        },
    }),
]

const PRODUCT_ACOS_GOALS = [
    ...PRODUCT_ACOS_CONFIGURABLE_GOALS,
    product_acos_goal_delta({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_acos_goal_delta',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_acos_goal_delta__last_period',
                    format: 'percentage',
                }),
        },
    }),
    product_acos_goal_performanceIndex({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName: 'product_acos_goal_performance_index',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_acos_goal_performance_index__last_period',
                    format: 'percentage',
                }),
        },
    }),
]

const PRODUCT_TOTAL_CPU_CONFIGURABLE_GOALS = [
    product_total_cpu_goal({
        isVisible: false,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalRenderer({
                    cellRenderProps,
                    fieldName: 'product_total_cpu_goal',
                    format: 'currency',
                }),
        },
    }),
]

const PRODUCT_TOTAL_CPU_ORDERED_GOALS = [
    product_total_cpu_goal_ordered_delta({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_total_cpu_goal_ordered_delta',
                    format: 'currency',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_total_cpu_goal_ordered_delta__last_period',
                    format: 'currency',
                }),
        },
    }),
    product_total_cpu_goal_ordered_performance_index({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_total_cpu_goal_ordered_performance_index',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_total_cpu_goal_ordered_performance_index__last_period',
                    format: 'percentage',
                }),
        },
    }),
]

const PRODUCT_TOTAL_CPU_SHIPPED_GOALS = [
    product_total_cpu_goal_shipped_delta({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_total_cpu_goal_shipped_delta',
                    format: 'currency',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_total_cpu_goal_shipped_delta__last_period',
                    format: 'currency',
                }),
        },
    }),
    product_total_cpu_goal_shipped_performance_index({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_total_cpu_goal_shipped_performance_index',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_total_cpu_goal_shipped_performance_index__last_period',
                    format: 'percentage',
                }),
        },
    }),
]

const PRODUCT_TACOS_CONFIGURABLE_GOALS = [
    product_tacos_goal({
        isVisible: false,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalRenderer({
                    cellRenderProps,
                    fieldName: 'product_tacos_goal',
                    format: 'percentage',
                }),
        },
    }),
]

const PRODUCT_TACOS_GOALS = [
    product_tacos_goal_delta({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_tacos_goal_delta',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_tacos_goal_delta__last_period',
                    format: 'percentage',
                }),
        },
    }),
    product_tacos_goal_performanceIndex({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName: 'product_tacos_goal_performance_index',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_tacos_goal_performance_index__last_period',
                    format: 'percentage',
                }),
        },
    }),
]

const PRODUCT_TACOS_ORDERED_GOALS = [
    product_tacos_goal_ordered_delta({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_tacos_goal_ordered_delta',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_tacos_goal_ordered_delta__last_period',
                    format: 'percentage',
                }),
        },
    }),
    product_tacos_goal_ordered_performanceIndex({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName: 'product_tacos_goal_ordered_performance_index',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_tacos_goal_ordered_performance_index__last_period',
                    format: 'percentage',
                }),
        },
    }),
]

const PRODUCT_TACOS_SHIPPED_GOALS = [
    product_tacos_goal_shipped_delta({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_tacos_goal_shipped_delta',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalDifferenceRenderer({
                    cellRenderProps,
                    fieldName: 'product_tacos_goal_shipped_delta__last_period',
                    format: 'percentage',
                }),
        },
    }),
    product_tacos_goal_shipped_performanceIndex({
        isVisible: true,
        renderOptions: {
            render: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName: 'product_tacos_goal_shipped_performance_index',
                    format: 'percentage',
                }),
            lastPeriodRender: (cellRenderProps) =>
                productMetricGoalPerformanceIndexRenderer({
                    cellRenderProps,
                    fieldName:
                        'product_tacos_goal_shipped_performance_index__last_period',
                    format: 'percentage',
                }),
        },
    }),
]
export const PRODUCT_AD_GOAL_METRICS: Field<any>[] = [
    ...PRODUCT_CPU_GOALS,
    ...PRODUCT_ACOS_GOALS,
    ...PRODUCT_TOTAL_CPU_CONFIGURABLE_GOALS,
    ...PRODUCT_TACOS_CONFIGURABLE_GOALS,
]

export const SALES_AND_TRAFFIC_GOAL_METRICS: Field<any>[] = [
    ...PRODUCT_TOTAL_CPU_CONFIGURABLE_GOALS,
    ...PRODUCT_TOTAL_CPU_ORDERED_GOALS,
    ...PRODUCT_ACOS_GOALS,
    ...PRODUCT_TACOS_CONFIGURABLE_GOALS,
    ...PRODUCT_TACOS_GOALS,
]

export const VC_SALES_AND_TRAFFIC_MANUFACTURING_GOAL_METRICS: Field<any>[] = [
    ...PRODUCT_TOTAL_CPU_CONFIGURABLE_GOALS,
    ...PRODUCT_TOTAL_CPU_ORDERED_GOALS,
    ...PRODUCT_TOTAL_CPU_SHIPPED_GOALS,
    ...PRODUCT_ACOS_GOALS,
    ...PRODUCT_TACOS_CONFIGURABLE_GOALS,
    ...PRODUCT_TACOS_ORDERED_GOALS,
    ...PRODUCT_TACOS_SHIPPED_GOALS,
]

export const VC_SALES_AND_TRAFFIC_SOURCING_GOAL_METRICS: Field<any>[] = [
    ...PRODUCT_TOTAL_CPU_CONFIGURABLE_GOALS,
    ...PRODUCT_TOTAL_CPU_SHIPPED_GOALS,
    ...PRODUCT_ACOS_GOALS,
    ...PRODUCT_TACOS_CONFIGURABLE_GOALS,
    ...PRODUCT_TACOS_SHIPPED_GOALS,
]
