/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { captureException } from '@sentry/browser'
import { call, put } from 'redux-saga/effects'

import {
    makeMountPageSuccess,
    makeMountPageFailure,
    makeFetchPageDataSuccess,
    makeFetchPageDataFailure,
} from 'actions/ui/shared/page'
import uiSagaRegistry from 'sagas/ui/registry'

/**
 * Mount Page
 */

export function* mountPageWorker(action: any) {
    const { pageName, data } = action.payload

    try {
        const mountPageSaga = uiSagaRegistry.lookupSaga(
            [pageName],
            'mountPageSaga'
        )

        if (mountPageSaga) {
            yield call(mountPageSaga, data)
        }

        yield put(makeMountPageSuccess(pageName)())
    } catch (error) {
        yield call(captureException, error)
        yield put(makeMountPageFailure(pageName)(error))
    }
}

/**
 * Unmount Page
 */

export function* unmountPageWorker(action: any) {
    const { pageName } = action.payload

    try {
        const unmountPageSaga = uiSagaRegistry.lookupSaga(
            [pageName],
            'unmountPageSaga'
        )

        if (unmountPageSaga) {
            yield call(unmountPageSaga)
        }
    } catch (error) {
        yield call(captureException, error)
    }
}

export function* fetchPageDataWorker(action: any) {
    const { pageName } = action.payload

    try {
        const fetchPageDataSaga = uiSagaRegistry.lookupSaga(
            [pageName],
            'fetchPageDataSaga'
        )

        if (fetchPageDataSaga) {
            yield call(fetchPageDataSaga, pageName)
        }

        yield put(makeFetchPageDataSuccess(pageName)())
    } catch (error) {
        yield call(captureException, error)
        yield put(makeFetchPageDataFailure(pageName)(error))
    }
}
