import { ReactElement } from 'react'

import { List, Popover } from 'antd'
import compact from 'lodash/compact'

import Stack from 'components/Layout/Stack'
import { AutomationCapability } from 'types'

import CapabilityElement from '../AutomationCapabilityElement'
import CapabilityIcon from '../AutomationCapabilityIcon'

import * as classes from './styles.scss'

interface Props {
    capabilities: AutomationCapability[]
}

export default function AutomationCapabilityGroup({
    capabilities,
}: Props): ReactElement {
    const renderGroupedIcons = (): ReactElement => {
        const capabilitiesPreview = compact(
            capabilities.map((capability, idx) => idx < 3 && capability)
        )

        return (
            <Stack
                direction="row"
                alignItems="center"
                className={classes['grouped-capabilities--wrapper']}
            >
                <div
                    style={{
                        width: `${capabilitiesPreview.length * 20}px`,
                        position: 'relative',
                    }}
                    className={classes['capability-group-icon--wrapper']}
                >
                    {capabilitiesPreview.map((capability, idx) => (
                        <CapabilityIcon
                            key={`${capability.id}-${idx}`}
                            capabilityId={capability.id}
                            className={classes['capability-group-icon']}
                        />
                    ))}
                </div>
                <span>{capabilities.length} Automations</span>
            </Stack>
        )
    }

    return (
        <Popover
            content={
                <List
                    size="small"
                    split={false}
                    dataSource={capabilities}
                    renderItem={(capability) => (
                        <List.Item>
                            <CapabilityElement capabilityId={capability.id} />
                        </List.Item>
                    )}
                />
            }
        >
            {renderGroupedIcons()}
        </Popover>
    )
}
