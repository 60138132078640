import { AxiosResponse } from 'axios'

import { CerebroResourceResponse, Report } from 'types'

import { axiosInstanceOrganizations } from './client'

interface UpdateMetricGoalData {
    goal_metric_metadata_key: string
    resource_type: string
    resource_id: string
    value: number | null
}

interface MetricGoal {
    goal_metric_metadata_key: string
    resource_type: string
    resource_id: string
    value: number | null
}

export const updateMetricGoal = (
    data: UpdateMetricGoalData
): Promise<AxiosResponse<MetricGoal>> =>
    axiosInstanceOrganizations.put(`/api/metric_goals/`, data)

export interface MetricGoalsFilter {
    marketplace__in?: string
    complex_label_filter?: string
    profile__in?: string
}

export const getProductGoalsExportAsyncExport = (
    params: MetricGoalsFilter
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.get(`/api/product_goals/export/async/`, {
        params,
    })
