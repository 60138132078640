import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useMemo,
    useState,
} from 'react'

import { ElementSize } from 'hooks/useElementSize'

interface PageContext {
    pageName: string
    headerSize: ElementSize
    setHeaderSize: (size: ElementSize) => void
}

const PageContextInstance = createContext<PageContext>({
    pageName: '',
    headerSize: { width: 0, height: 0 },
    setHeaderSize: () => {},
})

export const usePageContext = (): PageContext => useContext(PageContextInstance)

export function PageContextProvider(props: {
    pageName: string
    children: ReactNode
}): ReactElement {
    const { pageName, children } = props

    const [headerSize, setHeaderSize] = useState<ElementSize>({
        width: 0,
        height: 0,
    })
    const contextVal: PageContext = useMemo(
        () => ({ pageName, headerSize, setHeaderSize }),
        [headerSize, pageName]
    )

    return (
        <PageContextInstance.Provider value={contextVal}>
            {children}
        </PageContextInstance.Provider>
    )
}
