export const ASSUME_ANY_ORGANIZATION_GROUP =
    'feature_assume_any_organization_group'
export const MANAGE_AMS = 'feature_manage_ams_data'
export const MANAGE_AUTOMATION = 'feature_manage_automation_tasks'
export const MANAGE_LABELS = 'feature_manage_org_labels'
export const ORGANIZATION_ADMIN = 'feature_org_admin'
export const ORGANIZATION_GROUP_ADMIN = 'feature_org_group_admin'
export const SOV_RAW_DATA = 'feature_sov_raw_data'
export const SOV_READ = 'feature_sov_read'
export const SOV_WRITE = 'feature_sov_write'
export const VENDOR_CENTRAL_READ = 'feature_vendor_central_read'
export const SELLER_CENTRAL_READ = 'feature_seller_central_read'
export const MANAGE_RULEBOOKS = 'feature_manage_org_rulebooks'
export const ASSUME_ANY_USER = 'feature_assume_any_user'
export const DASHBOARDS_ONLY = 'feature_dashboard_only_experience'
export const AMAZON_ADVERTISING = 'feature_amazon_advertising'
export const WALMART_MARKETPLACE_READ = 'feature_read_walmart_marketplace'
export const WALMART_MARKETPLACE_WRITE = 'feature_write_walmart_marketplace'
export const WALMART_ADVERTISING_READ = 'feature_read_walmart_advertising'
export const WALMART_ADVERTISING_WRITE = 'feature_write_walmart_advertising'
export const READ_SEGMENTS = 'feature_read_org_segments'
export const MANAGE_SEGMENTS = 'feature_manage_org_segments'
export const MANAGE_COGS = 'feature_manage_org_cogs'
export const DASHBOARD_TEMPLATE_FEATURE =
    'feature_create_dashboard_from_template'
export const USE_SEGMENT_MODEL_VERSION = 'feature_segment_model_version'
export const MANAGE_METRIC_GOALS = 'feature_manage_metric_goals'
