import { ReactElement } from 'react'

import { Button } from 'antd'
import { Field, ErrorMessage, Form, Formik, FormikHelpers } from 'formik'
import { useTranslation, Trans } from 'react-i18next'
import { object, string } from 'yup'

import { FormikInput } from 'components/formik'
import { titleCase } from 'helpers/formatting'
import { useCerebroApiRequest } from 'hooks'
import { sendIntegrationInvitation } from 'services/cerebroApi/orgScope/resourceApi'
import { SellingPartnerIntegration } from 'types'
import message from 'utilities/message'

interface FormValues {
    email: string
}

interface Props {
    integration: SellingPartnerIntegration
}

function SCIntegrationUpdateInviteForm({ integration }: Props): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const { t } = useTranslation(['common', 'account'])

    const integrationType = titleCase(
        integration.seller_central_account.integration_type
    )
    const onSubmit = async (
        { email }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ): Promise<void> => {
        await makeCerebroApiRequest({
            request: sendIntegrationInvitation({
                email,
                account_alias: integration.alias,
                integration_type:
                    integration.seller_central_account.integration_type,
                integration_id: integration.id,
                metadata: {
                    country: integration.seller_central_account.country,
                },
            }),
            onRequestSuccess: () => {
                message.success(
                    t(
                        'account:SCIntegrationUpdateInviteForm.email.successMsg',
                        'Integration link sent to {{email}}',
                        { email }
                    )
                )
                resetForm()
            },
        })
    }

    return (
        <Formik
            initialValues={{
                email: '',
            }}
            enableReinitialize
            validationSchema={object().shape({
                email: string()
                    .required()
                    .email()
                    .label(t('account:email', 'Email')),
            })}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <div>
                    <p>
                        <Trans i18nKey="account:SCIntegrationUpdateInviteForm.email.info">
                            If you do not have full access in{' '}
                            {{ integrationType }} you can send an email to
                            someone else to complete the reauthorization.
                            <br />
                            The link recipient will be redirected to{' '}
                            {{ integrationType }} where they will authenticate
                            using their <strong>Primary Account Manager</strong>{' '}
                            credentials.
                        </Trans>
                    </p>

                    <Form>
                        <div style={{ marginBottom: 12 }}>
                            <div className="fg-form-control">
                                <div className="fg-control-label">
                                    {t('account:email', 'Email')}
                                </div>
                                <Field
                                    name="email"
                                    label={t('account:email', 'Email')}
                                    component={FormikInput}
                                />
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="fg-control-text is-error"
                                />
                            </div>
                        </div>
                        <div>
                            <Button
                                type="primary"
                                ghost
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                style={{ marginRight: 8 }}
                            >
                                {t(
                                    'account:SCIntegrationUpdateInviteForm.email.button',
                                    'Send Reauthorize Link Email'
                                )}
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    )
}

export default SCIntegrationUpdateInviteForm
