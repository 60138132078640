import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import InsightsIconOutlinedSvg from './InsightsIconOutlinedSvg'

const InsightsIconOutlined = (props: IconProps): ReactElement => (
    <Icon component={InsightsIconOutlinedSvg} {...props} />
)

export default InsightsIconOutlined
