import { ReactElement } from 'react'

import { CloseOutlined, PushpinOutlined } from '@ant-design/icons'
import { Tooltip, Typography } from 'antd'
import noop from 'lodash/noop'

import { SerializableOrgGroup } from './serializableOrgGroup'
import * as styles from './styles.scss'

const { Text } = Typography

interface Props {
    serializable: SerializableOrgGroup
    showAction?: boolean
    isPinned?: boolean
    onPin?: (serializable: SerializableOrgGroup) => void
    onUnpin?: (serializable: SerializableOrgGroup) => void
}

const SerializableOrgGroupDisplay = ({
    serializable,
    showAction,
    isPinned,
    onPin = noop,
    onUnpin = noop,
}: Props): ReactElement => {
    const { orgId, label } = serializable

    const actionElement = isPinned ? (
        <CloseOutlined
            onClick={(e) => {
                onUnpin(serializable)
                e.stopPropagation()
            }}
        />
    ) : (
        <PushpinOutlined
            onClick={(e) => {
                onPin(serializable)
                e.stopPropagation()
            }}
        />
    )

    return (
        <div className={styles.display}>
            <Text className={styles.id}>({orgId})</Text>
            <Text style={{ flexGrow: 1 }}>{label}</Text>
            {showAction && (
                <Tooltip
                    className={styles.action}
                    title={isPinned ? 'Remove pin' : 'Pin for quick access'}
                >
                    {actionElement}
                </Tooltip>
            )}
        </div>
    )
}

export default SerializableOrgGroupDisplay
