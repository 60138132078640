/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { call, put, select, all } from 'redux-saga/effects'

import {
    changeCurrencyCode,
    fetchBrandGroupsSuccess,
    fetchAutomationCapabilitiesSuccess,
} from 'actions/ui/app'
import {
    fetchDashboardSuccess,
    fetchOrganizationSuccess,
} from 'actions/ui/dashboardPrintPage'
import { DASHBOARD_PRINT_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectDomainValue } from 'selectors/ui'
import { getPrintableDashboard } from 'services/cerebroApi/noScope/resourceApi'
import {
    getOrganization,
    getBrandGroups,
    getAutomationCapabilities,
} from 'services/cerebroApi/orgScope/resourceApi'

function* selectDashboard(): any {
    return yield select(selectDomainValue, [DASHBOARD_PRINT_PAGE])
}

function* fetchOrganizationSaga(payload: any): any {
    const { userId, orgGroupId } = payload
    const dashboard: any = yield call(selectDashboard)
    if (dashboard) {
        yield call(
            cerebroApiSaga,
            fetchOrganizationSuccess,
            getOrganization,
            dashboard.organizationId,
            { headers: { userId, orgGroupId } }
        )
    }
}

function* fetchDashboardSaga(payload: any) {
    const { dashboardId, userId, orgId, orgGroupId } = payload
    yield call(
        cerebroApiSaga,
        fetchDashboardSuccess,
        getPrintableDashboard,
        dashboardId,
        { headers: { userId, orgId, orgGroupId } }
    )
}

function* fetchBrandGroupsSaga(payload: any) {
    const { userId, orgId, orgGroupId } = payload
    yield call(
        cerebroApiSaga,
        fetchBrandGroupsSuccess,
        getBrandGroups,
        {},
        { headers: { noCount: true, userId, orgId, orgGroupId } }
    )
}

function* fetchAutomationCapabilitiesSaga(payload: any) {
    const { userId, orgId, orgGroupId } = payload
    yield call(
        cerebroApiSaga,
        fetchAutomationCapabilitiesSuccess,
        getAutomationCapabilities,
        {},
        { headers: { noCount: true, userId, orgId, orgGroupId } }
    )
}

/**
 * Mounts the Dashboard Page and fetches data
 */

function* mountPageSaga(payload: any) {
    const { currencyCode } = payload

    yield put(changeCurrencyCode({ currencyCode }))

    yield call(fetchDashboardSaga, payload)
    yield all([
        call(fetchAutomationCapabilitiesSaga, payload),
        call(fetchBrandGroupsSaga, payload),
        call(fetchOrganizationSaga, payload),
    ])
}

uiSagaRegistry.registerSagas([DASHBOARD_PRINT_PAGE], {
    mountPageSaga,
})
