// This file can not be a TypeScript file or Highcharts will fail
import Highcharts from 'highcharts'

/**
 * Wraps the legend rendering function of Highcharts to
 * allow for splitting the legend into two columns.
 */
function wrapLegendRendering(H) {
    function wrapLegend(proceed, ...args) {
        // Silly highcharts use `this` to give us the legend
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const legend = this

        // Run the original proceed method
        proceed.apply(legend, args)

        if (!legend.options.doSplit) {
            return
        }

        const middle = legend.legendWidth / 2
        const whiteSpaceSize = 0.05 * legend.legendWidth

        const rightLegendBegin = middle + whiteSpaceSize
        const leftLegendEnd = middle - whiteSpaceSize

        let currentXLeftLegend = 50
        let currentXRightLegend = legend.legendWidth
        let currentYLeftLegend = 0
        let currentYRightLegend = 0
        const legendLineHeight = legend.itemHeight + 3

        legend.allItems
            .sort((a, b) => b.options.yAxis - a.options.yAxis)
            .forEach((item) => {
                if (item.legendGroup || item.legendItem?.group) {
                    const theGroup = item.legendGroup ?? item.legendItem.group
                    if (item.userOptions.side === 'right') {
                        currentXRightLegend -= item.itemWidth
                        if (currentXRightLegend < rightLegendBegin) {
                            currentXRightLegend =
                                legend.legendWidth - item.itemWidth
                            currentYRightLegend += legendLineHeight
                        }
                        theGroup.attr({
                            translateX: currentXRightLegend,
                            translateY: currentYRightLegend,
                        })
                        if (legend.options.considerSymbols) {
                            currentXRightLegend += 10
                        }
                    } else if (item.userOptions.side === 'left') {
                        if (
                            currentXLeftLegend + item.itemWidth >
                            leftLegendEnd
                        ) {
                            currentXLeftLegend = 50
                            currentYLeftLegend += legendLineHeight
                        }
                        theGroup.attr({
                            translateX: currentXLeftLegend,
                            translateY: currentYLeftLegend,
                        })
                        currentXLeftLegend =
                            currentXLeftLegend +
                            item.itemWidth -
                            (legend.options.considerSymbols ? 10 : 0)
                    }
                }
            })
    }

    H.wrap(H.Legend.prototype, 'render', wrapLegend)
}

wrapLegendRendering(Highcharts)
