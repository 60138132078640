import { ReactElement, useState } from 'react'

import { ImageNotFoundIcon } from 'components/Icons'

import * as styles from './styles.scss'

interface Props {
    imgUrl: string
    item: any
}

export const ItemImage = ({ imgUrl, item }: Props): ReactElement => {
    const [imageOK, setImageOK] = useState(true)
    return (
        <div className={styles['image-outer']}>
            <div className={styles['image-wrapper']}>
                <div className={styles['image-container']}>
                    {imgUrl && imageOK ? (
                        <img
                            src={imgUrl}
                            alt={item?.item_name}
                            onError={() => setImageOK(false)}
                        />
                    ) : (
                        <ImageNotFoundIcon
                            className={styles['missing-img-icon']}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
