import { memo, ReactElement, ReactNode } from 'react'

import isEqual from 'lodash/isEqual'

import { HEADLINE_SEARCH } from 'const/factTypes'
import { UNDEFINED_GROUP_BY_VALUE } from 'const/formatting'
import {
    SP_PLACEMENT_MULTIPLIER_MIN,
    SP_PLACEMENT_MULTIPLIER_MAX,
    SP_PLACEMENT_MULTIPLIER_PRECISION,
    SB_PLACEMENT_MULTIPLIER_MIN,
    SB_PLACEMENT_MULTIPLIER_MAX,
    SB_PLACEMENT_MULTIPLIER_PRECISION,
    PLACEMENT_MULTIPLIER_STEP,
} from 'const/placements'
import { getMetricValueFormatter } from 'helpers/formatters'
import { placementImmutable, placementApiField } from 'helpers/placements'
import { isUnset } from 'helpers/utilities'
import { updateCampaign } from 'services/cerebroApi/orgScope/resourceApi'
import { CampaignFact, BidAdjustmentType } from 'types'
import { MULTIPLIER_ADJUSTMENT_TYPES } from 'types/components/fields'

import { MultiplierField } from '../../SharedFields/MultiplierField'

import * as styles from './styles.scss'

type PlacementRecord = CampaignFact

interface Props<RecordType> {
    dataIndex?: string[]
    readOnly: boolean
    record: RecordType
    type: BidAdjustmentType
    rowIndex: number
    updateRecord: (data: { rowIndex: number; record: RecordType }) => void
}

function PlacementMultiplierField<RecordType extends PlacementRecord>({
    dataIndex = ['multiplier'],
    readOnly = false,
    record,
    type,
    rowIndex,
    updateRecord,
}: Props<RecordType>): ReactElement {
    const resourceId = record.campaign_id
    const fieldName = 'multiplier'
    const campaignType = record.campaign.campaign_type
    const originalValues = record.campaign.bid_adjustments
    const getIsDisabled = (): boolean => {
        const userCanManageCampaign = record.campaign?.user_can_manage ?? true
        const autoBiddingEnabled = record.campaign.bid_optimization
        return (
            !userCanManageCampaign ||
            autoBiddingEnabled ||
            placementImmutable(type, campaignType)
        )
    }

    const serializeFieldValues = (values: {
        [fieldName: string]: number
    }): object => {
        const typeKey = placementApiField(type, campaignType)
        return {
            bid_adjustments: {
                ...originalValues,
                [typeKey]: values[fieldName],
            },
        }
    }

    const updateRowData = (values: { [fieldName: string]: number }): void => {
        const updatedBidAdjustments = serializeFieldValues(values)

        const newRecord = {
            ...record,
            campaign: {
                ...record.campaign,
                ...updatedBidAdjustments,
            },
        }

        updateRecord?.({
            rowIndex,
            record: newRecord,
        })
    }

    const getButtonClassName = (values: {
        [fieldName: string]: number
    }): string | null => {
        const value = values[fieldName]

        if (isUnset(value)) {
            return null
        }
        if (value === 0) {
            return styles['editable-cell-zero']
        }
        if (value > 0) {
            return styles['editable-cell-positive']
        }
        return styles['editable-cell-negative']
    }

    const formatFieldValue = (values: {
        [fieldName: string]: number
    }): ReactNode => {
        const value = values[fieldName] || 0

        if (getIsDisabled()) {
            return UNDEFINED_GROUP_BY_VALUE
        }

        const formattedValue = getMetricValueFormatter('percentage')(
            Math.abs(value)
        )
        const typePrefix = value >= 0 ? '+' : '-'

        return `${typePrefix} ${formattedValue}`
    }

    const isSponsoredBrand = campaignType === HEADLINE_SEARCH
    const multiplierMax = isSponsoredBrand
        ? SB_PLACEMENT_MULTIPLIER_MAX
        : SP_PLACEMENT_MULTIPLIER_MAX

    const multiplierMin = isSponsoredBrand
        ? SB_PLACEMENT_MULTIPLIER_MIN
        : SP_PLACEMENT_MULTIPLIER_MIN

    const multiplierPrecision = isSponsoredBrand
        ? SB_PLACEMENT_MULTIPLIER_PRECISION
        : SP_PLACEMENT_MULTIPLIER_PRECISION

    const adjustmentType = isSponsoredBrand
        ? MULTIPLIER_ADJUSTMENT_TYPES.BOTH
        : MULTIPLIER_ADJUSTMENT_TYPES.INCREASE
    return (
        <MultiplierField
            multiplierMax={multiplierMax}
            multiplierMin={multiplierMin}
            multiplierPrecision={multiplierPrecision}
            multiplierStep={PLACEMENT_MULTIPLIER_STEP}
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            readOnly={readOnly}
            record={record}
            serializeFieldValues={serializeFieldValues}
            updateRequestApi={updateCampaign}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Successfully updated placement multiplier"
            onUpdateRequestSuccess={(response: any) => updateRowData(response)}
            getButtonClassName={getButtonClassName}
            formatFieldValue={formatFieldValue}
            adjustmentType={adjustmentType}
        />
    )
}

export default memo(
    PlacementMultiplierField,
    isEqual
) as typeof PlacementMultiplierField
