import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import BellIconSvg from './BellIconSvg'

const BellIcon = (props: IconProps): ReactElement => (
    <Icon component={BellIconSvg} {...props} />
)

export default BellIcon
