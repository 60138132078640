import MomentDatePicker from 'components/MomentDatePicker'

import { FormikWithCustomHandlersPropTypes } from '../propTypes'
import withCustomHandlers from '../withCustomHandlers'

const FormikDateRangePicker = ({
    field: { value },
    form,
    handleChange,
    handleBlur,
    handleClick,
    ...props
}) => (
    <MomentDatePicker.RangePicker
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
    />
)

FormikDateRangePicker.propTypes = FormikWithCustomHandlersPropTypes

export default withCustomHandlers(FormikDateRangePicker)
