import { ReactElement, useCallback, useEffect } from 'react'

import { InputNumberProps, Select } from 'antd'
import { Field } from 'formik'
import { useSelector } from 'react-redux'

import {
    FormikCheckbox,
    FormikInput,
    FormikInputNumber,
    FormikCustomColorPicker,
} from 'components/formik'
import Stack from 'components/Layout/Stack'
import { CURRENCIES } from 'configuration/currency'
import { DataSourceMetricMetadataFormat } from 'features/MetricsGlossary/types'
import { titleCase } from 'helpers/formatting'
import { selectCurrencyCode } from 'selectors/ui'
import { Field as DatasourceField, ModifiedWidget } from 'types'

import timeSeriesConfigTranslations from './timeSeriesConfigTranslations'

interface SelectedMetric {
    id: string
    metric: string
    axisSide: 'left' | 'right'
}

function useSelectedMetrics(values: ModifiedWidget): SelectedMetric[] {
    const leftAxisMetrics = (
        values.metrics_by_axis?.left?.map((m) => m.metrics) ?? []
    )
        .map((metricList) =>
            metricList.map(
                (m) =>
                    ({
                        id: `${m.metric}|left`,
                        metric: m.metric,
                        axisSide: 'left',
                    }) as SelectedMetric
            )
        )
        .reduce((a, b) => a.concat(b), [])

    const rightAxisMetrics = (
        values.metrics_by_axis?.right?.map((m) => m.metrics) ?? []
    )
        .map((metricList) =>
            metricList.map(
                (m) =>
                    ({
                        id: `${m.metric}|right`,
                        metric: m.metric,
                        axisSide: 'right',
                    }) as SelectedMetric
            )
        )
        .reduce((a, b) => a.concat(b), [])

    const selectedMetrics = [...leftAxisMetrics, ...rightAxisMetrics]
    return selectedMetrics
}

type ReferenceLineValueInputProps = Pick<
    InputNumberProps,
    'formatter' | 'parser' | 'step' | 'addonBefore' | 'addonAfter'
>

export const formatFractionalPercentageInput = (
    value: unknown | undefined,
    _info: { userTyping: boolean; input: string }
): string =>
    value === '' ? '' : `${Number((Number(value ?? 0) * 100).toFixed(2))}`

export const parseFractionalPercentageInput = (value?: string): string =>
    Number((value ?? '').replace('%', '')) / 100 + ''

function useMetricValueInputConfig(
    values: ModifiedWidget,
    metricType?: DataSourceMetricMetadataFormat
): ReferenceLineValueInputProps {
    const currencyCode = useSelector(selectCurrencyCode)
    const isStackedPercentArea =
        values.stacked && values.stacked_type === 'percent'

    const resolvedAxisType = isStackedPercentArea
        ? 'percentage_as_is'
        : metricType

    switch (resolvedAxisType) {
        case 'currency': {
            const currencySymbol = CURRENCIES[currencyCode]?.symbol
            return {
                formatter: undefined,
                parser: undefined,
                step: 1,
                addonBefore: currencySymbol,
            }
        }
        case 'percentage':
            return {
                formatter: formatFractionalPercentageInput,
                parser: parseFractionalPercentageInput,
                step: 0.01,
                addonAfter: '%',
            }
        case 'percentage_as_is':
            return {
                formatter: undefined,
                parser: undefined,
                step: 1,
                addonAfter: '%',
            }

        default:
            return { formatter: undefined, parser: undefined }
    }
}

export const DEFAULT_REFERENCE_LINE_COLOR = '#eb144c'

interface TimeSeriesRefernceLineConfigProps {
    values: ModifiedWidget
    metricOptions: DatasourceField<any>[]
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

function TimeSeriesReferenceLineConfig({
    values,
    metricOptions,
    setFieldValue,
}: TimeSeriesRefernceLineConfigProps): ReactElement {
    const clearReferenceLine = useCallback(
        (hide: boolean): void => {
            setFieldValue('reference_line', undefined)
            if (hide) {
                setFieldValue('show_reference_line', false)
            }
        },
        [setFieldValue]
    )

    // Gets selected metrics in a format that
    // can be used to populate the reference line metric dropdown
    // where we need to know if it's a left or right axis metric
    const selectedMetrics = useSelectedMetrics(values)

    // Use this to detect if same metric is in multiple axes
    // so we can show the axis side in the dropdown to differentiate
    // Edge case, but avoids weirdness if user is experimenting with axes
    const metricUniqueCounts: Record<string, number> = {}
    selectedMetrics.forEach((m) => {
        metricUniqueCounts[m.metric] = (metricUniqueCounts[m.metric] ?? 0) + 1
    })

    const yAxisMetricNotValid =
        selectedMetrics.length > 0 &&
        !!values.reference_line &&
        !selectedMetrics.find(
            (metric) =>
                metric.metric === values.reference_line?.metric_key &&
                metric.axisSide === values.reference_line.axis_side
        )

    // assigning these here to simplify the useEffect dependency array
    const firstMetricKey = selectedMetrics[0]?.metric
    const firstMetricAxisSide = selectedMetrics[0]?.axisSide

    const initReferenceLine = useCallback((): void => {
        if (values.show_reference_line && !values.reference_line) {
            setFieldValue('reference_line', {
                metric_key: firstMetricKey,
                axis_side: firstMetricAxisSide,
                value: 0,
                color: DEFAULT_REFERENCE_LINE_COLOR,
                show_value_in_label: true,
            })
        }
    }, [
        firstMetricAxisSide,
        firstMetricKey,
        setFieldValue,
        values.reference_line,
        values.show_reference_line,
    ])

    useEffect(() => {
        if (selectedMetrics.length === 0 && !!values.reference_line) {
            // if there are no metrics selected, clear the reference line
            clearReferenceLine(false)
        } else if (
            selectedMetrics.length > 0 &&
            !values.reference_line?.metric_key
        ) {
            // if there is at least one metric the reference line is not set, set it
            // to avoid the user having to select it manually
            initReferenceLine()
        } else if (yAxisMetricNotValid) {
            // if the selected metric is no longer available, clear the reference line
            clearReferenceLine(false)
        }
    }, [
        selectedMetrics.length,
        values.reference_line,
        yAxisMetricNotValid,
        initReferenceLine,
        clearReferenceLine,
        firstMetricKey,
        firstMetricAxisSide,
    ])

    const mappedMetricOptions: Record<string, DatasourceField<unknown>> = {}
    metricOptions.forEach((m) => {
        mappedMetricOptions[m.id] = m
    })

    const selectedReferenceLineMetric = values.reference_line?.metric_key
        ? mappedMetricOptions[values.reference_line.metric_key]
        : undefined

    const selectedReferenceLineMetricValue =
        !!values.reference_line?.metric_key &&
        !!values.reference_line?.axis_side
            ? `${values.reference_line.metric_key}|${values.reference_line.axis_side}`
            : undefined

    const metricValueInputProps = useMetricValueInputConfig(
        values,
        selectedReferenceLineMetric?.metric?.metadata_format
    )

    return selectedMetrics.length === 0 ? (
        <div className="fg-control-text" style={{ fontStyle: 'italic' }}>
            {timeSeriesConfigTranslations.selectMetricForReferenceLine}
        </div>
    ) : (
        <Stack direction="row" alignItems="center" gap={8}>
            <Field
                name="reference_line.color"
                component={FormikCustomColorPicker}
                disabledAlpha
                style={{ width: 32 }}
            />
            <Select
                value={selectedReferenceLineMetricValue}
                style={{ width: 200 }}
                listHeight={500}
                popupMatchSelectWidth={false}
                showSearch
                onChange={(val: string) => {
                    const match = selectedMetrics.find((m) => m.id === val)
                    setFieldValue('reference_line.metric_key', match?.metric)
                    setFieldValue('reference_line.axis_side', match?.axisSide)
                }}
            >
                {selectedMetrics.map((each) => {
                    return (
                        <Select.Option key={each.id} value={each.id}>
                            <div>
                                {mappedMetricOptions[each.metric]?.name ?? '-'}
                                {metricUniqueCounts[each.metric] > 1
                                    ? ` (${titleCase(each.axisSide)} Y-Axis)`
                                    : ''}
                            </div>
                        </Select.Option>
                    )
                })}
            </Select>
            <Field
                name="reference_line.value"
                component={FormikInputNumber}
                disabled={!values.reference_line?.metric_key}
                style={{ width: 120 }}
                controls={false}
                {...metricValueInputProps}
            />
            <div style={{ flex: 1 }}>
                <Field
                    name="reference_line.label"
                    component={FormikInput}
                    placeholder="Label your line"
                    disabled={!values.reference_line?.metric_key}
                />
            </div>
            <Field
                name="reference_line.show_value_in_label"
                component={FormikCheckbox}
                disabled={!values.reference_line?.metric_key}
                controls={false}
                label={'asdsadsa'}
                style={{ width: 152, marginLeft: 8 }}
            >
                <span>{timeSeriesConfigTranslations.showValueInLabel}</span>
            </Field>
        </Stack>
    )
}

export default TimeSeriesReferenceLineConfig
