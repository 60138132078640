import { ReactElement } from 'react'

import Stack from 'components/Layout/Stack'
import { AUTOMATIONS } from 'const/automations'
import { AutomationCapabilityId } from 'types'

import CapabilityIcon from '../AutomationCapabilityIcon'

interface Props {
    capabilityId: AutomationCapabilityId
}

export default function AutomationCapabilityElement({
    capabilityId,
}: Props): ReactElement {
    return (
        <Stack direction="row" alignItems="center">
            <CapabilityIcon capabilityId={capabilityId} />
            {AUTOMATIONS[capabilityId].name}
        </Stack>
    )
}
