import { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import Stack from 'components/Layout/Stack'
import { BRAND_COLOR } from 'const/colors'

import * as styles from './styles.scss'

interface Props {
    enabled: boolean
    text?: string
}

export default function StatusIndicator({
    enabled,
    text,
}: Props): ReactElement {
    const { t } = useTranslation('common')
    const style = {
        color: enabled ? BRAND_COLOR.PRIMARY : '#989A9E',
    }

    let title
    if (text) {
        title = text
    } else {
        title = enabled
            ? t('common:enabled', 'Enabled')
            : t('common:notEnabled', 'Not Enabled')
    }

    return (
        <Stack direction="row" alignItems="center">
            <div className={styles.badge} style={style} />
            <div className={styles.label} style={{ ...style, marginLeft: 8 }}>
                {title}
            </div>
        </Stack>
    )
}
