import { RefObject, useEffect, useState } from 'react'

import debounce from 'lodash/debounce'

export interface ElementSize {
    width: number
    height: number
}

const useElementSize = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T | null>
): ElementSize => {
    const [size, setSize] = useState<ElementSize>({
        width: 0,
        height: 0,
    })

    useEffect(() => {
        const element = ref?.current
        if (!element) {
            return
        }

        const handleResize = debounce(() => {
            if (element) {
                const boundingRect = element.getBoundingClientRect()
                setSize({
                    width: boundingRect.width,
                    height: boundingRect.height,
                })
            }
        }, 100)

        const observer = new ResizeObserver(handleResize)
        observer.observe(element)

        return () => {
            if (element) {
                observer.unobserve(element)
            } else {
                observer.disconnect()
            }
        }
    }, [ref])

    return size
}

export default useElementSize
