import { Trans, Translation } from 'react-i18next'

import { MAX_DAYS_RANGE_FOR_HOURLY_DATA } from 'const/widgets'
import { TransObj } from 'helpers/translation'

const timeSeriesConfigTranslations = {
    decreaseDateRange: (
        <Trans i18nKey="dashboards:timeSeriesConfiguration.decreaseDateRange">
            Adjust your date range to{' '}
            {{ maxDays: MAX_DAYS_RANGE_FOR_HOURLY_DATA }} days or less to make
            this option available.
        </Trans>
    ),
    disableCompareMetrics: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.disableCompareMetrics',
                    'Disable "Compare Metrics to Prior Period" to make this option available.'
                )
            }
        </Translation>
    ),
    enableStacked: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.enableStacked',
                    'Enable "Stacked" to make this option available.'
                )
            }
        </Translation>
    ),
    metricTypeMessage: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.metricTypeMessage',
                    'Only metrics of the same unit type can be added to an axis'
                )
            }
        </Translation>
    ),
    removeBothChartType: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.removeBothChartType',
                    'Select a different "Chart Type" to make this option available.'
                )
            }
        </Translation>
    ),
    removeGroupBy: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.removeGroupBy',
                    'Remove the "Group By" to make this option available.'
                )
            }
        </Translation>
    ),
    removeRightMetric: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.removeRightMetric',
                    'This option is only available when there is only 1 metric.'
                )
            }
        </Translation>
    ),
    selectFirstMetric: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.selectFirstMetric',
                    'Add metrics to the first axis before adding a new one'
                )
            }
        </Translation>
    ),
    selectGroupBy: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.selectGroupBy',
                    'Select a "Group By" to make this option available.'
                )
            }
        </Translation>
    ),
    selectMetricForReferenceLine: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.selectMetricForReferenceLine',
                    'Add a Metric above to configure your reference line.'
                )
            }
        </Translation>
    ),
    referenceLineStackedPercentMessage: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.referenceLineStackedPercentMessage',
                    'Reference lines are not available for "Stacked 100% Area" charts.'
                )
            }
        </Translation>
    ),
    showValueInLabel: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.showValueInLabel',
                    'Show value in label'
                )
            }
        </Translation>
    ),
    selectLeftMetric: (
        <Translation>
            {(t) =>
                t(
                    'dashboards:timeSeriesConfiguration.selectLeftMetric',
                    'Add metrics to the first "Left Y-Axis" to make this option available.'
                )
            }
        </Translation>
    ),
    zoomedOutMessage: (
        <Trans i18nKey="dashboards:timeSeriesConfiguration.zoomedOutWarning">
            Your <b>Hourly</b> time aggregate (X-axis) has automatically been
            updated to <b>Daily</b>. Adjusting your date range to{' '}
            <b>
                {{ maxDays: MAX_DAYS_RANGE_FOR_HOURLY_DATA } as TransObj} days
                or less
            </b>{' '}
            will automatically change this widget back to an Hourly time
            aggregate.{' '}
            <i>
                Tip: If the widget-level date range isn’t set, check your
                tab-level date range.
            </i>
        </Trans>
    ),
}

export default timeSeriesConfigTranslations
