import { memo, ReactElement } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Field } from 'formik'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { number, object } from 'yup'

import { SingleValueField } from 'components/EditableFields/SharedFields/SingleValueField'
import { FormikInputNumber } from 'components/formik'
import { UNDEFINED_VALUE } from 'const/formatting'
import { invalidateProductAds } from 'features/FactData/AmazonAds/productAdFactsQueries'
import { userHasManageMetricGoals } from 'helpers/featurePermissions'
import { formatCurrency, formatPercentage } from 'helpers/formatting'
import { useCerebroApiRequest, useUserHasPermissions } from 'hooks'
import { updateMetricGoal } from 'services/cerebroApi/orgScope/metricGoalsApi'
import { CurrencyCode, ProductFactRecord } from 'types'
import {
    MetricGoalFormat,
    MetricGoalLastPeriodMetadataKey,
    MetricGoalMetadataKey,
    MetricGoalResourceType,
} from 'types/resources/metricGoals'
import message from 'utilities/message'

interface ProductGoalFieldProps<RecordType> {
    goalFieldName: MetricGoalMetadataKey | MetricGoalLastPeriodMetadataKey
    resourceType: MetricGoalResourceType
    resourceId: string
    readOnly: boolean
    record: RecordType
    format?: MetricGoalFormat
    min?: number
    max?: number
}

function ProductGoalField<RecordType extends ProductFactRecord>({
    goalFieldName,
    resourceType,
    resourceId,
    readOnly = false,
    record,
    format = 'currency',
    max,
    min,
}: ProductGoalFieldProps<RecordType>): ReactElement {
    const currencyCode = record.metadata?.currency_code as
        | CurrencyCode
        | undefined

    const makeCerebroApiRequest = useCerebroApiRequest()
    const { t } = useTranslation(['metrics'])

    const hasManageMetricGoalPermissions = useUserHasPermissions(
        userHasManageMetricGoals
    )

    const queryClient = useQueryClient()
    const reloadProductAds = (): void => {
        invalidateProductAds(queryClient)
    }

    const validationSchema = object().shape({
        [goalFieldName]: number().nullable(),
    })

    const adjustIncomingValue = (value: number | null): number | null => {
        if (value == null) {
            return value
        }

        if (format === 'percentage') {
            return value * 100
        }

        return value
    }

    const adjustOutgoingValue = (value: number | null): number | null => {
        if (value == null) {
            return value
        }

        if (format === 'percentage') {
            return value / 100
        }

        return value
    }

    const initialValues = {
        [goalFieldName]: adjustIncomingValue(record[goalFieldName] ?? null),
    }

    const formatFieldValue = (values: typeof initialValues): string => {
        const value = values[goalFieldName]
        if (value == null) {
            return UNDEFINED_VALUE
        }

        if (format === 'percentage') {
            return formatPercentage(value / 100)
        }

        return formatCurrency(value, {
            currencyCode,
            decimal: true,
        })
    }

    return (
        <SingleValueField
            disabled={!hasManageMetricGoalPermissions}
            fieldName={goalFieldName}
            formatFieldValue={formatFieldValue}
            initialValues={initialValues}
            onSave={(values) => {
                return makeCerebroApiRequest({
                    request: updateMetricGoal({
                        goal_metric_metadata_key: goalFieldName,
                        resource_type: resourceType,
                        resource_id: resourceId,
                        value: adjustOutgoingValue(values[goalFieldName]),
                    }),
                    onRequestSuccess: () => {
                        message.success(
                            t(
                                'metrics:updateGoal.success',
                                'Successfully updated metric goal'
                            )
                        )
                        reloadProductAds()
                    },
                })
            }}
            readOnly={readOnly}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <Field
                    component={FormikInputNumber}
                    disabled={isSubmitting}
                    max={max}
                    min={min}
                    name={goalFieldName}
                    precision={2}
                />
            )}
        </SingleValueField>
    )
}

export default memo(ProductGoalField, isEqual) as typeof ProductGoalField
