/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { call, put, select } from 'redux-saga/effects'

import { makeFetchListRequest, makeFetchListSuccess } from 'actions/ui/shared'
import { RULEBOOK_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import { getRulebookVersions } from 'services/cerebroApi/orgScope/rulebooksApi'

const TAB_PATH = [RULEBOOK_PAGE, 'rules']
const LIST_PATH = [...TAB_PATH, 'list']

function* fetchSaga(): any {
    const resourceParams = yield select(
        selectResourceParamsOfPage,
        RULEBOOK_PAGE
    )

    const { rulebookId } = resourceParams
    const params = {
        rulebook: rulebookId,
        ordering: '-created_at',
        limit: 1,
    }
    yield call(
        cerebroApiSaga,
        makeFetchListSuccess(LIST_PATH),
        getRulebookVersions,
        params
    )
}

function* fetchTabDataSaga() {
    yield put(makeFetchListRequest(LIST_PATH)())
}

// Register sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(LIST_PATH, {
    fetchDataSaga: fetchSaga,
})
