import { ReactNode } from 'react'

import { CellRenderProps, CountryCode, ProductMetadataGroupBy } from 'types'

import ProductTitle from '../ProductTitle'

interface ProductTitleCellProps<RecordType>
    extends Pick<CellRenderProps<RecordType>, 'value'> {
    productImg?: string | null
    productAdId?: string
    profileCountryCode?: string
    showImage?: boolean
    productAsin?: string
    productMarketplace?: CountryCode
}

function ProductTitleCell<RecordType extends ProductMetadataGroupBy>({
    value,
    productImg,
    showImage = true,
    productAsin,
    productMarketplace,
}: ProductTitleCellProps<RecordType>): ReactNode {
    return (
        <ProductTitle
            value={value}
            productImg={productImg}
            productAsin={productAsin}
            productMarketplace={productMarketplace}
            showImage={showImage}
            isLoading={value === 'Loading...'}
        />
    )
}

export default ProductTitleCell
