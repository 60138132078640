/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all, takeLatest } from 'redux-saga/effects'

import {
    mountPageRequest,
    fetchPageDataRequest,
    unmountPageRequest,
} from 'actions/ui/shared/page'

import {
    mountPageWorker,
    fetchPageDataWorker,
    unmountPageWorker,
} from './workers'

export default function* pageWatcher() {
    yield all([
        takeLatest(mountPageRequest.toString(), mountPageWorker),
        takeLatest(unmountPageRequest.toString(), unmountPageWorker),
        takeLatest(fetchPageDataRequest.toString(), fetchPageDataWorker),
    ])
}
