import { ReactElement } from 'react'

import { Select } from 'antd'
import classNames from 'classnames'
import every from 'lodash/every'
import map from 'lodash/map'
import pick from 'lodash/pick'

import Stack from 'components/Layout/Stack'
import { STATE_FIELD_OPTIONS, PAUSED, ENABLED } from 'const/resourceStates'
import { Campaign } from 'types'

import { AdGroupSelectField } from '../Shared/AdGroupSelectField'

import { ProductAdField } from './localTypes'
import * as styles from './styles.scss'

interface Props {
    campaign: Campaign
    products: ProductAdField[]
    setFieldValue: (name: string, values: any) => void
}

const CreateProductAdFormAllRow = ({
    campaign,
    products = [],
    setFieldValue,
}: Props): ReactElement => {
    const adGroupIdValue = every(products, ['adGroupId', products[0].adGroupId])
        ? products[0].adGroupId
        : undefined
    const stateValue = every(products, ['state', products[0].state])
        ? products[0].state
        : undefined

    const handleChange = (val: string | number, field: string): void => {
        products.forEach((_keyword, index) => {
            setFieldValue(`products.${index}.${field}`, val)
        })
    }

    return (
        <Stack
            direction="row"
            alignItems="start"
            className={styles['bulk-edit-row']}
        >
            {/* TEXT COLUMN PLACEHOLDER */}
            <div style={{ flex: 1, marginRight: 4 }} />

            <Stack
                direction="column"
                alignItems="center"
                className={classNames(styles.field, styles['ad-group-column'])}
                style={{ marginRight: 4 }}
            >
                <AdGroupSelectField
                    selectedAdGroup={adGroupIdValue}
                    campaign={campaign}
                    onChange={(val) => {
                        handleChange(val, 'adGroupId')
                    }}
                />
            </Stack>

            <Stack
                direction="column"
                alignItems="center"
                className={classNames(styles.field, styles['state-column'])}
                style={{ marginRight: 4 }}
            >
                <Select
                    value={stateValue}
                    placeholder="Select State"
                    onChange={(val) => {
                        handleChange(val, 'state')
                    }}
                >
                    {map(
                        pick(STATE_FIELD_OPTIONS, [ENABLED, PAUSED]),
                        (label, value) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        )
                    )}
                </Select>
            </Stack>

            <Stack
                direction="column"
                alignItems="center"
                className={classNames(styles.field, styles['remove-column'])}
                style={{ marginRight: 4 }}
            />
        </Stack>
    )
}

export default CreateProductAdFormAllRow
