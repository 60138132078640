import { ReactElement, MouseEvent } from 'react'

import { Popconfirm, type PopconfirmProps } from 'antd'
import { noop } from 'lodash'
import { useTranslation } from 'react-i18next'

interface Props {
    disabled?: boolean
    okText?: PopconfirmProps['okText']
    title?: PopconfirmProps['title']
    onConfirm: (e?: MouseEvent<HTMLElement>) => Promise<void>
    onCancel?: (e?: MouseEvent<HTMLElement>) => void
    children: ReactElement
}

export const DeleteConfirm = ({
    disabled = false,
    okText = 'Delete',
    title = 'Are you sure you want to delete this record?',
    onConfirm,
    onCancel = noop,
    children,
}: Props): ReactElement => {
    const { t } = useTranslation('common')

    return (
        <Popconfirm
            title={title}
            onConfirm={onConfirm}
            onCancel={onCancel}
            okText={okText}
            disabled={disabled}
            placement="topRight"
            okButtonProps={{
                danger: true,
            }}
            okType="primary"
            cancelText={t('common:cancel', 'Cancel')}
        >
            {children}
        </Popconfirm>
    )
}
