/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all } from 'redux-saga/effects'

import './skuCogsBulkEdit'

import parentProductsTabWatcher from './parentProducts'

export default function* productsSummaryPageWatcher() {
    yield all([parentProductsTabWatcher()])
}
