import { ReactElement, ReactNode, MouseEventHandler } from 'react'

import classNames from 'classnames'
import noop from 'lodash/noop'

import * as styles from './styles.scss'

interface Props {
    children?: ReactNode
    icon?: ReactNode
    onClick?: MouseEventHandler
    className?: string
    link?: boolean
    disabled?: boolean
}

const TextButton = ({
    children = null,
    icon = null,
    onClick = noop,
    className = '',
    link = false,
    disabled = false,
}: Props): ReactElement => {
    return (
        <div
            className={classNames(className, {
                [styles.button]: !disabled,
                [styles.disabled]: disabled,
                [styles.link]: link,
            })}
            onClick={disabled ? undefined : onClick}
            role="button"
            tabIndex={-1}
            onKeyPress={noop}
        >
            {icon}
            {children && <span>{children}</span>}
        </div>
    )
}

export default TextButton
