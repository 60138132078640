import isEmpty from 'lodash/isEmpty'

import {
    BRANDS,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    CAMPAIGNS,
    COUNTRIES,
    DATES,
    FACT_TYPES,
    LABELS,
    METRIC_FILTERS,
    PORTFOLIOS,
    REGIONS,
    TARGET_STATES,
    SEARCH_TERM,
    AD_GROUPS,
    AD_GROUP_STATES,
    TARGET_SEARCH_TEXT,
    TARGET_TEXT,
} from 'const/filters'
import { calculateFromDateWithLag } from 'helpers/dateRange'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { formatDateFromFilter } from 'helpers/utils'
import { FiltersState, TargetFactsParams } from 'types'

import { formatBooleanFilter } from '../filters'

import { formatMetricFiltersForParams } from './metrics'

export const formatFilters = (filters: FiltersState): TargetFactsParams => {
    let params: TargetFactsParams = {}

    const dates = filters[DATES]
    const { start, end } = formatDateFromFilter(
        dates,
        calculateFromDateWithLag(filters.rangeLag),
        filters.rangeLag
    )
    params.report_date_min = start
    params.report_date_max = end

    if (!isEmpty(filters[TARGET_SEARCH_TEXT])) {
        params.query__icontains = filters[TARGET_SEARCH_TEXT]
    }

    const targetStates = filters[TARGET_STATES]
    if (isNonEmptyArray(targetStates)) {
        params.target__state__in = targetStates.map((item) => item.value).join()
    }

    const regions = filters[REGIONS]
    if (isNonEmptyArray(regions)) {
        params.profile__region__in = regions.map((item) => item.value).join()
    }

    const countries = filters[COUNTRIES]
    if (isNonEmptyArray(countries)) {
        params.profile__country_code__in = countries
            .map((item) => item.value)
            .join()
    }

    const brands = filters[BRANDS]
    if (isNonEmptyArray(brands)) {
        params.profile__in = brands.map((item) => item.value).join()
    }

    const portfolios = filters[PORTFOLIOS]
    if (isNonEmptyArray(portfolios)) {
        params.campaign__portfolio__id__in = portfolios
            .map((item) => item.value)
            .join()
    }

    const campaigns = filters[CAMPAIGNS]
    if (isNonEmptyArray(campaigns)) {
        params.campaign__in = campaigns.map((item) => item.value).join()
    }

    const campaignStates = filters[CAMPAIGN_STATES]
    if (isNonEmptyArray(campaignStates)) {
        params.campaign__state__in = campaignStates
            .map((item) => item.value)
            .join()
    }

    const campaignTargetingTypes = filters[CAMPAIGN_TARGETING_TYPES]
    if (isNonEmptyArray(campaignTargetingTypes)) {
        params.campaign__targeting_type__in = campaignTargetingTypes
            .map((item) => item.value)
            .join()
    }

    const labels = filters[LABELS]
    if (isNonEmptyArray(labels)) {
        params.complex_label_filter = formatBooleanFilter(filters[LABELS])
    }

    const factTypes = filters[FACT_TYPES]
    if (isNonEmptyArray(factTypes)) {
        params.campaign__campaign_type__in = factTypes
            .map((item) => item.value)
            .join()
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (isNonEmptyArray(metricFilters)) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters) }
    }

    const searchTerm = filters[SEARCH_TERM]
    if (isNonEmptyArray(searchTerm)) {
        const query = searchTerm[0].value
        if (query) {
            params.query__icontains = String(query)
        }
    }

    const adGroups = filters[AD_GROUPS]
    if (isNonEmptyArray(adGroups)) {
        params.ad_group__in = adGroups.map((item) => item.value).join()
    }

    const adGroupStates = filters[AD_GROUP_STATES]
    if (isNonEmptyArray(adGroupStates)) {
        params.ad_group__state__in = adGroupStates
            .map((item) => item.value)
            .join()
    }

    const targetText = filters[TARGET_TEXT]
    if (!isEmpty(targetText)) {
        params.target__expression_description__icontains = filters[TARGET_TEXT]
    }

    return params
}
