import color from 'color'

import * as variables from '../styles/_variables.scss'

type RGB = `rgb(${number}, ${number}, ${number})`
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`
type HEX = `#${string}`

export type Color = RGB | RGBA | HEX

export const BRAND_COLOR: Record<'PRIMARY', Color> = {
    PRIMARY: variables.colorPrimary as Color,
}

export const DELTA_COLORS: Record<'GREEN' | 'RED', Color> = {
    GREEN: variables.colorDeltaGreen as Color,
    RED: variables.colorDeltaRed as Color,
}

export const SEGMENT_STATUS: Record<string, Color> = {
    paused: '#C6C9CC',
    active: '#43C798',
    pausedBackground: '#F8F8F8',
    activeBackground: '#F5FAF8',
}

export const AVAILABLE_CHART_COLORS: Color[] = [
    '#f26c44',
    '#99a535',
    '#00ccca',
    '#596ad4',
    '#c84fbb',
    '#ff9b2b',
    '#338620',
    '#00b1e8',
    '#8877bf',
    '#a2467f',
    '#ffb424',
    '#236d4b',
    '#0089ba',
    '#8b3da5',
    '#e42e6a',
    '#b3c421',
    '#00b18a',
    '#006bbb',
    '#ce7fd0',
    '#e05467',
]

export const COLOR_PICKER_OPTIONS: Color[] = [
    '#a82e26',
    '#7c1e4d',
    '#431e86',
    '#2b218c',
    '#182579',
    '#1b489b',
    '#205796',
    '#265e63',
    '#1f4c41',
    '#c23e37',
    '#b32f5c',
    '#702b9c',
    '#4b33a2',
    '#31419a',
    '#3475cb',
    '#3788cb',
    '#4195a4',
    '#33766a',
    '#e25241',
    '#d73965',
    '#8f35a9',
    '#5f40b0',
    '#4154af',
    '#4596ec',
    '#48a8ee',
    '#4daec4',
    '#419286',
    '#274b35',
    '#406729',
    '#756c25',
    '#bd9315',
    '#cb8414',
    '#d65b26',
    '#b04121',
    '#3b2824',
    '#283137',
    '#518c46',
    '#759d47',
    '#949635',
    '#c8b21d',
    '#e49813',
    '#e37f1d',
    '#d5552d',
    '#584138',
    '#495963',
    '#67ab5b',
    '#97c15b',
    '#a8b142',
    '#e1cd32',
    '#ecb01e',
    '#f59631',
    '#ed6338',
    '#74574a',
    '#667c89',
]

export const DEFAULT_COLOR_PICKER_PROPS = {
    colors: COLOR_PICKER_OPTIONS,
    circleSize: 18,
    circleSpacing: 8,
}

const NEUTRAL_COLOR: Color = '#E5E5E5'
/*
    Neutral colors for specific strings to
    avoid adding noise for catch-all strings
    like 'others', 'n/a', etc.
*/
export const CODED_COLORS: Record<string, Color> = {
    others: NEUTRAL_COLOR,
    unknown: color(NEUTRAL_COLOR).darken(0.2).hex() as Color,
    'n/a': color(NEUTRAL_COLOR).darken(0.2).hex() as Color,
    '-': color(NEUTRAL_COLOR).darken(0.4).hex() as Color,
}

export type ColorPaletteName =
    | 'colorful'
    | 'coolTones'
    | 'colorblindFriendly'
    | 'consistent'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'green'
    | 'teal'
    | 'blue'
    | 'purple'
    | 'grayscale'
    | 'customized'

export interface WidgetPalette {
    name: ColorPaletteName
    colors?: Color[]
}

export interface ColorPalette {
    name: string
    colors?: Color[]
    previewRange?: [number, number]
}

export const DEFAULT_WIDGET_PALETTE: WidgetPalette = { name: 'consistent' }

export const PALETTE_GROUPS: Record<string, ColorPaletteName[]> = {
    predefined: ['colorful', 'coolTones', 'colorblindFriendly', 'consistent'],
    monotone: [
        'red',
        'orange',
        'yellow',
        'green',
        'teal',
        'blue',
        'purple',
        'grayscale',
    ],
    customized: ['customized'],
}
export const COLOR_PALETTES: Record<ColorPaletteName, ColorPalette> = {
    colorful: {
        name: 'Colorful',
        colors: [
            '#D33B4E',
            '#E68437',
            '#E0C112',
            '#6FD287',
            '#578EED',
            '#7657C7',
            '#C566CA',
        ],
    },
    coolTones: {
        name: 'Cool Tones',
        colors: [
            '#7134EA',
            '#5561F6',
            '#82B7EC',
            '#269696',
            '#80C6AD',
            '#A4F2B5',
            '#AADB95',
        ],
    },
    colorblindFriendly: {
        name: 'Colorblind Friendly',
        colors: [
            '#B63B56',
            '#0072B2',
            '#E69F00',
            '#009E73',
            '#111111',
            '#56B4E9',
            '#F0E442',
        ],
    },
    consistent: { name: 'Consistent' },
    red: {
        name: 'Red',
        colors: [
            '#7C1002',
            '#B92916',
            '#EB4F3A',
            '#F18474',
            '#F7B5AB',
            '#F5D2CE',
            '#F9E9E7',
        ],
        previewRange: [2, 7],
    },
    orange: {
        name: 'Orange',
        colors: [
            '#954801',
            '#CA6906',
            '#EE892C',
            '#F1AB6B',
            '#F4CAA3',
            '#F5DCC7',
            '#F6E9DE',
        ],
        previewRange: [2, 7],
    },
    yellow: {
        name: 'Yellow',
        colors: [
            '#B38E00',
            '#D3AB13',
            '#EFC629',
            '#F2D463',
            '#F4E19B',
            '#F7EEC9',
            '#F9F3DD',
        ],
        previewRange: [2, 7],
    },
    green: {
        name: 'Green',
        colors: [
            '#007805',
            '#00A006',
            '#31C737',
            '#76DC7B',
            '#A7E8AB',
            '#CBF0CC',
            '#E5F7E7',
        ],
        previewRange: [2, 7],
    },
    teal: {
        name: 'Teal',
        colors: [
            '#017F7F',
            '#10A8A8',
            '#32C0C0',
            '#67D3D3',
            '#9EE3E3',
            '#C6EEEE',
            '#E4F6F6',
        ],
        previewRange: [2, 7],
    },
    blue: {
        name: 'Blue',
        colors: [
            '#0D3789',
            '#194EB6',
            '#4477DC',
            '#789BDE',
            '#AEC5EF',
            '#D0DDF7',
            '#E2EBFE',
        ],
        previewRange: [2, 7],
    },
    purple: {
        name: 'Purple',
        colors: [
            '#57027F',
            '#7D0EB1',
            '#9B2BD0',
            '#B56AD9',
            '#D39FEC',
            '#E8CDF5',
            '#F3E2FA',
        ],
        previewRange: [2, 7],
    },
    grayscale: {
        name: 'Grayscale',
        colors: [
            '#161616',
            '#373737',
            '#5C5C5C',
            '#8F8F8F',
            '#BDBDBD',
            '#DADADA',
            '#E6E6E6',
        ],
        previewRange: [2, 7],
    },
    customized: {
        name: 'Customized',
        // Won't be actually shown, but having this here simplifies our logic
        colors: ['#000', '#111', '#222', '#333', '#444'],
    },
}
