import { Button } from 'antd'

import { FormikWithCustomHandlersPropTypes } from '../propTypes'
import withCustomHandlers from '../withCustomHandlers'

const FormikDaySelect = ({ field: { value }, handleChange }) => {
    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

    // enable the first day to "explain" how it works to users
    const defaultValue = `1${Array(days.length - 1)
        .fill('0')
        .join('')}`

    const valueFixed =
        value && value.length === days.length ? value : defaultValue

    const onClick = (idx) => {
        const newValue =
            valueFixed.substr(0, idx) +
            (valueFixed[idx] === '1' ? '0' : '1') +
            valueFixed.substr(idx + 1)

        handleChange(newValue)
    }
    return (
        <div>
            {days.map((day, idx) => (
                <span key={idx}>
                    <Button
                        type={valueFixed[idx] === '1' ? 'primary' : 'default'}
                        shape="circle"
                        size="small"
                        onClick={() => onClick(idx)}
                        style={{ marginRight: 8 }}
                    >
                        {day}
                    </Button>
                    {idx % 7 === 6 && <div style={{ marginBottom: 8 }} />}
                </span>
            ))}
        </div>
    )
}

FormikDaySelect.propTypes = FormikWithCustomHandlersPropTypes

export default withCustomHandlers(FormikDaySelect)
