import { ReactElement, useMemo } from 'react'

import classNames from 'classnames'

import { TypeaheadOption } from 'types'

import * as styles from './styles.scss'
import TypeaheadMenuItem from './TypeaheadMenuItem'
import { TypeaheadMenuProps, TypeaheadMenuItemProps } from './types'

const defaultRenderItem = (props: TypeaheadMenuItemProps): ReactElement => (
    <TypeaheadMenuItem {...props} />
)

const TypeaheadMenu = ({
    downshift,
    filterOptions = true,
    baseIndex = 0,
    title,
    selectLimit = Infinity,
    options = [],
    values,
    renderItem = defaultRenderItem,
    onToggleOption,
}: TypeaheadMenuProps): ReactElement | null => {
    const { inputValue, highlightedIndex, getMenuProps, getItemProps } =
        downshift

    const stringValues = useMemo(
        () => values.filter((v) => !!v).map((v) => v.value.toString()),
        [values]
    )
    const isSelectableItem = (item: TypeaheadOption): boolean =>
        !!item && !!item.label && !!item.value

    const filteredOptions = useMemo(
        () =>
            filterOptions && inputValue
                ? options.filter(
                      (item) =>
                          isSelectableItem(item) &&
                          !!item.label
                              ?.toLowerCase()
                              .includes(inputValue.toLowerCase())
                  )
                : options.filter((item) => isSelectableItem(item)),
        [filterOptions, inputValue, options]
    )

    return filteredOptions.length > 0 ? (
        <div className={styles.menu}>
            {title && <div className={styles['menu-title']}>{title}</div>}

            <ul {...getMenuProps({})}>
                {filteredOptions.map((item, index) => {
                    const stringValue = item.value.toString()
                    const highlightIndex = baseIndex + index
                    const highlighted = highlightedIndex === highlightIndex
                    const selected = stringValues.includes(stringValue)
                    const disabled = values.length === selectLimit && !selected

                    return (
                        <li
                            key={stringValue}
                            {...getItemProps({
                                index: highlightIndex,
                                item,
                                disabled,
                                className: classNames(
                                    styles['typeahead-item'],
                                    {
                                        [styles[
                                            'typeahead-item-option-active'
                                        ]]: highlighted,
                                        [styles[
                                            'typeahead-item-option-selected'
                                        ]]: selected,
                                        [styles[
                                            'typeahead-item-option-disabled'
                                        ]]: disabled,
                                    }
                                ),
                                onClick: () => onToggleOption(item),
                            })}
                        >
                            {renderItem({
                                item,
                                query: inputValue,
                                highlightQuery: filterOptions,
                            })}
                        </li>
                    )
                })}
            </ul>
        </div>
    ) : null
}

export default TypeaheadMenu
