import { ReactElement } from 'react'

import { Select } from 'antd'
import classNames from 'classnames'
import every from 'lodash/every'

import Stack from 'components/Layout/Stack'
import { NEGATIVE_MATCH_TYPE_OPTIONS } from 'const/matchTypes'

import { KeywordField } from './localTypes'
import * as styles from './styles.scss'

interface Props {
    keywords: KeywordField[]
    setFieldValue: (name: string, values: any) => void
}

const CreateAdGroupNegativeKeywordFormAllRow = ({
    keywords = [],
    setFieldValue,
}: Props): ReactElement => {
    const matchTypeValue = every(keywords, ['matchType', keywords[0].matchType])
        ? keywords[0].matchType
        : undefined

    const handleChange = (val: string | number, field: string): void => {
        keywords.forEach((_keyword, index) => {
            setFieldValue(`keywords.${index}.${field}`, val)
        })
    }

    return (
        <Stack
            direction="row"
            alignItems="start"
            className={styles['bulk-edit-row']}
        >
            <div style={{ flex: 1, marginRight: 4 }} />
            <Stack
                direction="column"
                alignItems="center"
                className={classNames(
                    styles.field,
                    styles['match-type-column']
                )}
                style={{ marginRight: 4 }}
            >
                <Select
                    placeholder="Select Match Type"
                    value={matchTypeValue}
                    onChange={(val) => {
                        handleChange(val, 'matchType')
                    }}
                >
                    {NEGATIVE_MATCH_TYPE_OPTIONS.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                            {label}
                        </Select.Option>
                    ))}
                </Select>
            </Stack>
            <Stack
                direction="column"
                alignItems="center"
                style={{ marginRight: 4 }}
                className={classNames(styles.field, styles['remove-column'])}
            />
        </Stack>
    )
}

export default CreateAdGroupNegativeKeywordFormAllRow
