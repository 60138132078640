import { useRef, useState } from 'react'

import { datadogRum } from '@datadog/browser-rum'

import { WidgetDataSourceKey } from 'const/dataSources'
import type { DatadogActionName, Widget } from 'types'

interface WidgetContext {
    type: Widget['type']
    name: Widget['name']
    data_source: WidgetDataSourceKey
    metric_count: number
    group_by_count: number
    sort_by: Widget['sorter']
    pagination: Partial<Widget['pagination']>
}

interface TableContext {
    column_count: number
    sort_by: Widget['sorter']
    pagination: Partial<Widget['pagination']>
}

interface TypeaheadContext {
    query: string | null
}

interface ExtraContext {
    [key: string]: string | number
}

export interface MonitorLoadTimeProps {
    actionName: DatadogActionName
    loading: boolean
    shouldMonitorTime?: boolean
    widgetContext?: WidgetContext
    tableContext?: TableContext
    typeaheadContext?: TypeaheadContext
    extraContext?: ExtraContext
}

interface Context {
    startTime: number
    loadTime: number
    isInitialLoad: boolean
    widget?: WidgetContext
    table?: TableContext
    typeahead?: TypeaheadContext
    extra?: ExtraContext
}

const useMonitorLoadTime = ({
    actionName,
    loading,
    shouldMonitorTime = true,
    widgetContext,
    tableContext,
    typeaheadContext,
    extraContext,
}: MonitorLoadTimeProps): void => {
    const startTime = useRef<null | number>(null)
    const isInitialLoad = useRef(true)
    const [prevLoading, setPrevLoading] = useState<boolean>()

    if (shouldMonitorTime && loading !== prevLoading) {
        setPrevLoading(loading)

        if (loading) {
            startTime.current = new Date().getTime()
        } else if (startTime.current !== null) {
            const now = new Date().getTime()
            const loadTime = now - startTime.current
            const context: Context = {
                startTime: startTime.current,
                loadTime,
                isInitialLoad: isInitialLoad.current,
                ...(widgetContext ? { widget: widgetContext } : {}),
                ...(tableContext ? { table: tableContext } : {}),
                ...(typeaheadContext ? { typeahead: typeaheadContext } : {}),
                ...(extraContext ? { extra: extraContext } : {}),
            }
            datadogRum.addAction(actionName, context)
            startTime.current = null
            isInitialLoad.current = false
        }
    }
}

export default useMonitorLoadTime
