import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function ProductsIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 15.062L10.5491 19V10.8072L18 7.00882V15.062ZM9.40278 10.7799V18.9739L2 15.062V6.96226L9.40278 10.7799ZM5.4962 4.43344L12.9769 8.29196L10.0011 9.80903L2.60639 5.99479L5.4962 4.43344ZM17.3959 6.04021L14.2252 7.65607L6.70784 3.77824L10 2L17.3959 6.04021Z"
            />
        </svg>
    )
}

export default ProductsIcon
