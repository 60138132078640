import moment from 'moment'

import {
    AUTOMATION_PROFILES,
    ADVANCED_AUTOMATIONS,
    BRANDS,
    CAMPAIGN_AD_FORMATS,
    CAMPAIGN_NAME,
    CAMPAIGN_BUDGET,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    CAMPAIGNS,
    COUNTRIES,
    DATES,
    EQUALS,
    FACT_TYPES,
    LABELS,
    METRIC_FILTERS,
    PORTFOLIOS,
    REGIONS,
    ADVANCED_TEXT,
    BID_MULTIPLIERS,
    CAMPAIGN_START_DATE,
} from 'const/filters'
import { CAMPAIGN_LABELS, CAMPAIGN_AUTOMATIONS } from 'const/resourceTypes'
import { MAX_DAYS_RANGE_FOR_HOURLY_DATA } from 'const/widgets'
import { calculateFromDateWithLag } from 'helpers/dateRange'
import { isDefined, isNonEmptyArray, isNonEmptyString } from 'helpers/typeGuard'
import { formatDateFromFilter } from 'helpers/utils'
import { CampaignFactsParams, FiltersState, MetricFilter } from 'types'

import { formatBooleanFilter } from '../filters'

import { formatMetricFiltersForParams } from './metrics'

export const formatFilters = (
    filters: FiltersState,
    hasHourlyData = false
): CampaignFactsParams => {
    let params: CampaignFactsParams = {}

    const dates = filters[DATES]
    let { start, end } = formatDateFromFilter(
        dates,
        calculateFromDateWithLag(filters.rangeLag),
        filters.rangeLag
    )

    if (!!start && !!end) {
        const dateRangeInDays = moment(end).diff(moment(start), 'days')
        if (hasHourlyData && dateRangeInDays < MAX_DAYS_RANGE_FOR_HOURLY_DATA) {
            start = `${start}T00:00`
            end = `${end}T23:59`
        }

        params.report_date_min = start
        params.report_date_max = end
    }

    const regions = filters[REGIONS]
    if (isNonEmptyArray(regions)) {
        params.profile__region__in = regions.map((item) => item.value).join()
    }

    const countries = filters[COUNTRIES]
    if (isNonEmptyArray(countries)) {
        params.profile__country_code__in = countries
            .map((item) => item.value)
            .join()
    }

    const brands = filters[BRANDS]
    if (isNonEmptyArray(brands)) {
        params.profile__in = brands.map((item) => item.value).join()
    }

    const portfolios = filters[PORTFOLIOS]
    if (isNonEmptyArray(portfolios)) {
        params.portfolio__id__in = portfolios.map((item) => item.value).join()
    }

    const campaigns = filters[CAMPAIGNS]
    if (isNonEmptyArray(campaigns)) {
        params.campaign__in = campaigns.map((item) => item.value).join()
    }

    const campaignStates = filters[CAMPAIGN_STATES]
    if (isNonEmptyArray(campaignStates)) {
        params.campaign__state__in = campaignStates
            .map((item) => item.value)
            .join()
    }

    const campaignTargetingTypes = filters[CAMPAIGN_TARGETING_TYPES]
    if (isNonEmptyArray(campaignTargetingTypes)) {
        params.campaign__targeting_type__in = campaignTargetingTypes
            .map((item) => item.value)
            .join()
    }

    const adFormats = filters[CAMPAIGN_AD_FORMATS]
    if (isNonEmptyArray(adFormats)) {
        params.campaign__ad_format__in = adFormats
            .map((item) => item.value)
            .join()
    }

    const campaignName = filters[CAMPAIGN_NAME]
    if (isNonEmptyString(campaignName)) {
        params.campaign__name__icontains = campaignName
    }

    const campaignBudget = filters[CAMPAIGN_BUDGET]
    if (isDefined(campaignBudget)) {
        // Can either be stored as a single object (legacy)
        // or an array with one object
        let filtersObj: MetricFilter
        if (isNonEmptyArray(campaignBudget)) {
            filtersObj = campaignBudget[0]
        } else {
            filtersObj = campaignBudget as MetricFilter
        }

        const { operator, value } = filtersObj
        if (isDefined(operator) && isDefined(value)) {
            let key = 'campaign__budget'
            if (operator !== EQUALS) {
                key += `__${operator}`
            }
            params[key] = value
        }
    }

    const campaignStartDate = filters[CAMPAIGN_START_DATE]
    if (isNonEmptyArray(campaignStartDate)) {
        params.campaign__start_date__custom = campaignStartDate[0].value
    }

    const automations = filters[ADVANCED_AUTOMATIONS]
    if (isNonEmptyArray(automations)) {
        params.complex_automation_filter = formatBooleanFilter(
            automations,
            CAMPAIGN_AUTOMATIONS
        )
    }

    const advancedText = filters[ADVANCED_TEXT]
    if (isNonEmptyArray(advancedText)) {
        params.complex_text_filter = formatBooleanFilter(advancedText)
    }

    const automationProfiles = filters[AUTOMATION_PROFILES]
    if (isNonEmptyArray(automationProfiles)) {
        params.campaign__automation__automation_profile__in = automationProfiles
            .map((item) => item.value)
            .join()
    }

    const labels = filters[LABELS]
    if (isNonEmptyArray(labels)) {
        params.complex_label_filter = formatBooleanFilter(
            labels,
            CAMPAIGN_LABELS
        )
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (isNonEmptyArray(metricFilters)) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters) }
    }

    const bidMultipliers = filters[BID_MULTIPLIERS]
    if (isNonEmptyArray(bidMultipliers)) {
        params.complex_bid_multiplier_filter =
            formatBooleanFilter(bidMultipliers)
    }

    const factTypes = filters[FACT_TYPES]
    if (isNonEmptyArray(factTypes)) {
        params.campaign__campaign_type__in = factTypes
            .map((item) => item.value)
            .join()
    }

    return params
}
