import {
    ReactElement,
    useEffect,
    useState,
    useCallback,
    ReactNode,
} from 'react'

import { Spin } from 'antd'
import { Field } from 'formik'
import round from 'lodash/round'

import { FormikInputNumber } from 'components/formik'
import Stack from 'components/Layout/Stack'
import {
    KEYWORD_BID_MAX,
    KEYWORD_BID_MIN,
    KEYWORD_BID_PRECISION,
    KEYWORD_BID_STEP,
} from 'const/keywords'
import { dayRange } from 'helpers/dateRange'
import { getMetricValueFormatter } from 'helpers/formatters'
import { formatCerebroDate } from 'helpers/formatting'
import { parseCurrency } from 'helpers/inputNumber'
import { useCerebroApiRequest } from 'hooks'
import { getKeywordQueryFacts } from 'services/cerebroApi/orgScope/keywordQueryFactsApi'
import { getTargetQueryFacts } from 'services/cerebroApi/orgScope/targetQueryFactsApi'
import {
    AdGroupGroupBy,
    CampaignGroupBy,
    KeywordGroupBy,
    PortfolioGroupBy,
    ProfileGroupBy,
    QueryFact,
    ResolvedExpressionGroupBy,
    CerebroPaginatedResponse,
} from 'types'

import { SelectedSearchTerm } from '../localTypes'

interface Props {
    selectedSearchTerm: SelectedSearchTerm
}

type QueryFactRecord = QueryFact<
    ProfileGroupBy &
        PortfolioGroupBy &
        CampaignGroupBy &
        AdGroupGroupBy &
        KeywordGroupBy &
        ResolvedExpressionGroupBy
>

const PRIOR_DAYS = 14
const [startDate, endDate] = dayRange(PRIOR_DAYS)

const KeywordBidNumberInputField = ({
    selectedSearchTerm,
}: Props): ReactElement => {
    const {
        id: searchTerm,
        record: { campaign, resolved_expression, keyword },
    } = selectedSearchTerm

    const { profile } = campaign

    const formatCPC = getMetricValueFormatter(
        'currencyAmount',
        profile.currency_code
    )

    const [loading, setLoading] = useState<boolean>(false)

    const [cpc, setCpc] = useState<number | null>(null)

    const makeCerebroApiRequest = useCerebroApiRequest()

    const loadCpc = useCallback(async (): Promise<void> => {
        const apiFunc = keyword ? getKeywordQueryFacts : getTargetQueryFacts
        await makeCerebroApiRequest<
            QueryFactRecord,
            CerebroPaginatedResponse<QueryFactRecord>
        >({
            request: apiFunc(
                {
                    limit: 1,
                    report_date_min: formatCerebroDate(startDate),
                    report_date_max: formatCerebroDate(endDate),
                    query: searchTerm,
                    metrics: 'cpc',
                    ordering: '-cpc',
                    campaign: campaign.id,
                    profile: profile.id,
                    currency: profile.currency_code,
                    ...(keyword ? { keyword: keyword.id } : {}),
                    ...(resolved_expression ? { resolved_expression } : {}),
                },
                {
                    headers: { noCount: true },
                }
            ),
            onRequestSuccess: (response) => {
                setCpc(response.data?.results[0]?.cpc ?? null)
            },
        })
        setLoading(false)
    }, [
        campaign.id,
        keyword,
        makeCerebroApiRequest,
        profile.id,
        profile.currency_code,
        resolved_expression,
        searchTerm,
    ])

    useEffect(() => {
        setLoading(true)
        loadCpc()
    }, [loadCpc])

    const renderCpc = (): ReactNode => {
        if (loading) {
            return <Spin size="small" />
        }
        if (cpc === null) {
            return '-'
        }
        return formatCPC(round(cpc, 2))
    }

    return (
        <Stack direction="row" alignItems="center">
            <div>
                <Field
                    name="bid"
                    component={FormikInputNumber}
                    formatter={formatCPC}
                    parser={parseCurrency}
                    min={KEYWORD_BID_MIN}
                    max={KEYWORD_BID_MAX}
                    step={KEYWORD_BID_STEP}
                    precision={KEYWORD_BID_PRECISION}
                />
            </div>
            <div style={{ marginLeft: 12 }}>
                <em>
                    Last {PRIOR_DAYS} days CPC: {renderCpc()}
                </em>
            </div>
        </Stack>
    )
}

export default KeywordBidNumberInputField
