import { ReactElement } from 'react'

import { Link } from 'react-router-dom'

import { PortfolioIcon } from 'components/Icons'
import Stack from 'components/Layout/Stack'
import { TextDividerVertical } from 'components/TextDividerVertical'
import { DAY_FORMAT, UNDEFINED_VALUE } from 'const/formatting'
import { formatDate } from 'helpers/dates'
import { formatCurrency } from 'helpers/formatting'
import { portfolioUrl } from 'helpers/urls'
import { Portfolio } from 'types'

import { DetailWrapper, DetailIcon, DetailText, DetailTag } from './shared'

export function portfolioRenderer(portfolio: Portfolio | null): ReactElement {
    let text
    if (portfolio) {
        text = <Link to={portfolioUrl(portfolio.id)}>{portfolio.name}</Link>
    } else {
        text = UNDEFINED_VALUE
    }

    return (
        <DetailWrapper
            leftContent={
                <DetailIcon icon={PortfolioIcon} tooltipTitle="Portfolio" />
            }
            rightContent={<DetailText text={text} />}
        />
    )
}

export function portfolioBudgetRenderer({
    budget,
    start_date,
    end_date,
    profile,
}: Portfolio): ReactElement {
    const currencyCode = profile.currency_code

    const budgetCap = formatCurrency(budget, {
        currencyCode,
        decimal: true,
    })

    const budgetStartDate = start_date ? (
        formatDate(start_date, undefined, DAY_FORMAT)
    ) : (
        <em>No budget start</em>
    )

    const budgetEndDate = end_date ? (
        formatDate(end_date, undefined, DAY_FORMAT)
    ) : (
        <em>No budget end</em>
    )

    const text = (
        <Stack component="span" direction="row" alignItems="center">
            {budgetCap} <TextDividerVertical />
            <span style={{ color: 'rgba(127, 129, 135, 0.75)' }}>
                {budgetStartDate}&nbsp;-&nbsp;{budgetEndDate}
            </span>
        </Stack>
    )

    return (
        <DetailWrapper
            leftContent={<DetailTag text="Budget Cap" />}
            rightContent={<DetailText text={text} />}
        />
    )
}
