import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import HelpDocsIconSvg from './HelpDocsIconSvg'

const HelpDocsIcon = (props: IconProps): ReactElement => (
    <Icon component={HelpDocsIconSvg} {...props} />
)

export default HelpDocsIcon
