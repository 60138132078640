import { ReactElement } from 'react'

import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { type ItemType } from 'antd/es/menu/interface'
import classNames from 'classnames'
import type { MenuInfo } from 'rc-menu/lib/interface'

import { ToolTip } from 'components/ToolTip'
import { DashboardAction } from 'types'

import * as styles from './styles.scss'

interface Props {
    actions: DashboardAction[]
    onSelectAction(e: MenuInfo): void
    btn?: ReactElement
}

const ActionsDropdown = ({
    actions,
    onSelectAction,
    btn,
}: Props): ReactElement => {
    const renderAction = ({
        label,
        type,
        isSelected = false,
        subActions,
        enabled = true,
        tooltipTitle,
    }: DashboardAction): ItemType => {
        if (subActions) {
            return {
                key: type,
                label,
                disabled: !enabled,
                children: subActions.map((subAction) =>
                    renderAction(subAction)
                ),
            }
        }

        return {
            key: type,
            label: <ToolTip title={tooltipTitle}>{label}</ToolTip>,
            disabled: !enabled,
            onClick: onSelectAction,
            className: classNames({
                [styles['action-selected']]: isSelected,
            }),
        }
    }

    const items = actions.map((action) => renderAction(action))

    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            {btn || (
                <Button>
                    Actions <DownOutlined />
                </Button>
            )}
        </Dropdown>
    )
}

ActionsDropdown.displayName = 'ActionsDropdown'
export default ActionsDropdown
