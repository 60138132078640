import { ReactElement, useRef, useEffect } from 'react'

import classNames from 'classnames'

import Stack from 'components/Layout/Stack'

import CreateKeywordFormAllRow from './CreateKeywordFormAllRow'
import CreateKeywordFormHeader from './CreateKeywordFormHeader'
import CreateKeywordFormRow from './CreateKeywordFormRow'
import { ColumnConfig, BaseKeyword } from './localTypes'
import * as styles from './styles.scss'

interface Props<KeywordField> {
    keywords: KeywordField[]
    remove: (index: number) => void
    columnsConfig: ColumnConfig[]
}

function CreateKeywordForm<KeywordField extends BaseKeyword>({
    keywords = [],
    remove,
    columnsConfig,
}: Props<KeywordField>): ReactElement {
    const scrollToBottomRef = useRef<null | HTMLDivElement>(null)

    // Scroll to bottom of list when adding new keywords
    useEffect(() => {
        scrollToBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [keywords])

    return (
        <Stack direction="column" style={{ flexShrink: 0, marginTop: 8 }}>
            <div
                className="fg-font-size-medium fg-text-primary fg-font-weight-500"
                style={{ marginBottom: 8 }}
            >
                Added Keywords ({keywords.length})
            </div>
            {keywords.length > 0 ? (
                <div className={styles['keyword-table-container']}>
                    <div className={styles.sticky}>
                        <CreateKeywordFormHeader
                            columnsConfig={columnsConfig}
                        />

                        <CreateKeywordFormAllRow<KeywordField>
                            keywords={keywords}
                            columnsConfig={columnsConfig}
                        />
                    </div>

                    {keywords.map((keyword, idx) => (
                        <CreateKeywordFormRow<KeywordField>
                            key={idx}
                            keyword={keyword}
                            keywordIndex={idx}
                            remove={remove}
                            columnsConfig={columnsConfig}
                        />
                    ))}
                    <div ref={scrollToBottomRef} />
                </div>
            ) : (
                <div className={classNames('p-3', styles['empty-table-text'])}>
                    You haven’t added any Keywords yet.
                </div>
            )}
        </Stack>
    )
}

export default CreateKeywordForm
