import { useCallback, useEffect, useMemo, useRef } from 'react'

import moment from 'moment/moment'
import { useSelector } from 'react-redux'

import { updateChartColorsMap } from 'actions/ui/app'
import { AVAILABLE_CHART_COLORS, Color } from 'const/colors'
import { useAction } from 'hooks/redux'
import { selectDomainValue as selectUIDomainValue } from 'selectors/ui'
import { ChartColorsMap, RootReduxState } from 'types'

const THRESHOLD_TO_UPDATE = 1000 * 60 * 60 * 24 // 24 hours

interface UseMappedChartColorsReturn {
    mapElementToColor: (element: string) => Color
}

const useMappedChartColors = (
    widgetId?: string
): UseMappedChartColorsReturn => {
    const colorsMap = useSelector<RootReduxState, ChartColorsMap>((state) =>
        selectUIDomainValue(state, ['app', 'chartColorsMap'])
    )
    const updateColorsMap = useAction(updateChartColorsMap)
    const pendingUpdates = useRef<ChartColorsMap>({})

    const sessionTime = useMemo(() => moment().valueOf(), [widgetId])

    useEffect(() => {
        // Batch apply updates after render
        if (Object.keys(pendingUpdates.current).length > 0) {
            updateColorsMap(pendingUpdates.current)
            pendingUpdates.current = {}
        }
    })

    const mapElementToColor = useCallback(
        (element: string): Color => {
            const elementLower = element.toLowerCase()

            if (pendingUpdates.current[elementLower]) {
                return pendingUpdates.current[elementLower].color
            }

            if (colorsMap[elementLower]) {
                const existingColor = colorsMap[elementLower].color

                if (
                    sessionTime - colorsMap[elementLower].updatedAt >
                    THRESHOLD_TO_UPDATE
                ) {
                    pendingUpdates.current[elementLower] = {
                        color: existingColor,
                        updatedAt: sessionTime,
                    }
                }

                return existingColor
            }

            const currentCount =
                Object.keys(colorsMap).length +
                Object.keys(pendingUpdates.current).length

            const newColor =
                AVAILABLE_CHART_COLORS[
                    currentCount % AVAILABLE_CHART_COLORS.length
                ]

            pendingUpdates.current[elementLower] = {
                color: newColor,
                updatedAt: sessionTime,
            }

            return newColor
        },
        [colorsMap, updateColorsMap, sessionTime]
    )

    return { mapElementToColor }
}

export default useMappedChartColors
