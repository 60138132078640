import { ReactElement, ReactNode } from 'react'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'

import BreadcrumbTreeCell from 'components/FieldRenderers/BreadcrumbTreeCell/BreadcrumbTreeCell'
import RankingCategoriesCell from 'components/FieldRenderers/RankingCategoriesCell/RankingCategoriesCell'
import Stack from 'components/Layout/Stack'
import { UNDEFINED_VALUE } from 'const/formatting'
import { formatNumber } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import {
    BreadcrumbCategory,
    CellRenderProps,
    CountryCode,
    ProductMetadata,
    RankingCategory,
    RootBreadcrumbCategory,
} from 'types'

import { SegmentProductTitleCell } from '../SegmentProductTitleCell'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
}

export interface SegmentProductInfo {
    product_id?: string
    asin: string
    name: string | null
    small_image_url?: string
    title: string | null
    hero_asin_image_url?: string
    hero_asin_title?: string
    marketplace?: CountryCode
    variant_count__max?: number
    category?: string
    sub_categories?: RankingCategory[]
    root_breadcrumb?: RootBreadcrumbCategory
    breadcrumb_tree?: BreadcrumbCategory[]
    product?: ProductMetadata
    rank?: number
}

const extractMarketplaceFromRecord = (
    record: SegmentProductInfo
): CountryCode | undefined => {
    let { marketplace } = record
    if (!marketplace && record.product_id) {
        marketplace = record.product_id.split('_')[0] as CountryCode
    }
    return marketplace
}

export function SegmentProductNameRenderer<
    RecordType extends SegmentProductInfo,
>(props: RenderProps<RecordType>, pathToProduct: string[] = []): ReactElement {
    const { t } = useTranslation(['segments'])
    const {
        cellRenderProps: { record },
    } = props
    const product =
        !!record && pathToProduct.length ? get(record, pathToProduct) : record

    const ASIN = record?.asin ?? record?.product_id?.split('_')[1]

    if (isUnset(product)) {
        if ((record?.variant_count__max ?? 0) > 0) {
            return (
                <span>
                    {t(
                        'segments:fields.product.parentWithAsin',
                        'Parent ASIN ({{count}} variant): {{ASIN}}',
                        {
                            count: record.variant_count__max,
                            ASIN,
                        }
                    )}
                </span>
            )
        }
        return <span>{ASIN}</span>
    }

    return (
        <Stack direction="row" alignItems="center">
            {product.title}
        </Stack>
    )
}

interface TitleRenderCellProps<RecordType> extends CellRenderProps<RecordType> {
    variant?: 'default' | 'dashboard'
}

export function TitleRenderer<RecordType extends SegmentProductInfo>({
    record,
    showImage,
    variant = 'default',
}: TitleRenderCellProps<RecordType>): ReactNode {
    const { product } = record
    return (
        <SegmentProductTitleCell
            value={
                product?.hero_asin_title ??
                record.hero_asin_title ??
                product?.title ??
                record.title ??
                record.name
            }
            productImg={
                product?.hero_asin_image_url ??
                record.hero_asin_image_url ??
                product?.small_image_url ??
                record.small_image_url
            }
            productAsin={record.asin}
            productMarketplace={extractMarketplaceFromRecord(record)}
            showImage={showImage}
            variant={variant}
            numChildren={record.variant_count__max ?? 0}
        />
    )
}

export function BreadcrumbTreeRenderer<RecordType extends SegmentProductInfo>({
    record,
    undefinedValue = <span>{UNDEFINED_VALUE}</span>,
}: CellRenderProps<RecordType>): ReactNode {
    const { product } = record
    const value = product?.breadcrumb_tree ?? record.breadcrumb_tree

    if (isEmpty(value)) {
        return undefinedValue
    }

    return <BreadcrumbTreeCell breadcrumbTree={value!} />
}

export function RankingCategoriesRenderer<
    RecordType extends SegmentProductInfo,
>({
    record,
    undefinedValue = <span>{UNDEFINED_VALUE}</span>,
}: CellRenderProps<RecordType>): ReactNode {
    const { product } = record
    const bestRankingCategory = product?.category ?? record.category
    const otherCategories = product?.sub_categories ?? record.sub_categories
    const bestRankingCategoryRank = product?.rank ?? record.rank

    if (isEmpty(bestRankingCategory) || isEmpty(otherCategories)) {
        return undefinedValue
    }
    return (
        <RankingCategoriesCell
            bestRankingCategory={bestRankingCategory!}
            bestRankingCategoryRank={bestRankingCategoryRank}
            otherRankings={otherCategories!}
        />
    )
}

export function WeightRenderer<RecordType extends SegmentProductInfo>({
    record,
}: CellRenderProps<RecordType>): ReactElement {
    const { product } = record

    if (isUnset(product?.weight) || isUnset(product?.weight_unit)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    return (
        <span>{`${formatNumber(product?.weight, '0.00')} ${product?.weight_unit}`}</span>
    )
}

export function DimensionRenderer<RecordType extends SegmentProductInfo>({
    record,
}: CellRenderProps<RecordType>): ReactElement {
    const { product } = record

    if (
        isUnset(product?.length) ||
        isUnset(product?.width) ||
        isUnset(product?.height) ||
        isUnset(product?.dimension_unit)
    ) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const length = formatNumber(product?.length, '0.00')
    const width = formatNumber(product?.width, '0.00')
    const height = formatNumber(product?.height, '0.00')

    return (
        <span>{`${length} x ${width} x ${height} ${product?.dimension_unit}`}</span>
    )
}
