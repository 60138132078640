import {
    ad_contribution_14_day,
    ad_contribution_14_day_same_sku,
    ad_contribution_1_day,
    ad_contribution_1_day_same_sku,
    attributed_sales_14_day__sum,
    attributed_sales_14_day_same_sku__sum,
    browser_page_views__sum,
    browser_sessions__sum,
    buy_box_win_percentage,
    buy_box_wins__sum,
    conversion_rate_sessions,
    cost__sum,
    gift_wrap_price__sum,
    gift_wrap_tax__sum,
    item_price__sum,
    item_promotion_discount__sum,
    item_tax__sum,
    mobile_app_page_views__sum,
    mobile_app_sessions__sum,
    order_count__sum,
    ordered_product_sales__sum,
    ordered_product_sales_b2b__sum,
    organic_sales_14_day__sum,
    organic_sales_14_day_same_sku__sum,
    organic_sales_1_day__sum,
    organic_sales_1_day_same_sku__sum,
    page_views__sum,
    quantity__sum,
    sessions__sum,
    ship_promotion_discount__sum,
    shipping_price__sum,
    shipping_tax__sum,
    total_advertising_cost_of_sales,
    total_aov,
    total_aov_b2b_order_items,
    total_aov_order_items,
    total_average_ordered_unit_value,
    total_average_ordered_unit_value_b2b,
    total_cpa,
    total_cpa_order_items,
    total_cpa_units,
    total_cvr_order_items,
    total_cvr_units,
    total_order_items__sum,
    total_order_items_b2b__sum,
    total_roas,
    total_sales__sum,
    units_ordered__sum,
    units_ordered_b2b__sum,
    vat_exclusive_giftwrap_price__sum,
    vat_exclusive_item_price__sum,
    vat_exclusive_shipping_price__sum,
} from 'configuration/fieldCreators/metrics/sellerCentral'

import { AMS_WITH_SD_METRICS_COLUMNS_NO_PACING } from './ams'
import { makeMapping } from './helpers'

const SC_COMMON_METRICS = [
    cost__sum({ isVisible: true }),
    attributed_sales_14_day__sum({ isVisible: true }),
    attributed_sales_14_day_same_sku__sum({ isVisible: true }),
    ...AMS_WITH_SD_METRICS_COLUMNS_NO_PACING,
]

const SC_METRIC_AND_METADATA_COLUMNS = [
    item_price__sum({ isVisible: true }),
    item_promotion_discount__sum({ isVisible: true }),
    item_tax__sum({ isVisible: true }),
    ship_promotion_discount__sum({ isVisible: true }),
    shipping_price__sum({ isVisible: true }),
    shipping_tax__sum({ isVisible: true }),
    gift_wrap_price__sum({ isVisible: true }),
    gift_wrap_tax__sum({ isVisible: true }),
    vat_exclusive_item_price__sum({ isVisible: true }),
    vat_exclusive_giftwrap_price__sum({ isVisible: true }),
    vat_exclusive_shipping_price__sum({ isVisible: true }),
    quantity__sum({ isVisible: true }),
    ...SC_COMMON_METRICS,
]

export const SC_SALES_AND_TRAFFIC_COLUMNS = [
    units_ordered__sum({ isVisible: true }),
    units_ordered_b2b__sum({ isVisible: true }),
    total_order_items__sum({ isVisible: true }),
    total_order_items_b2b__sum({ isVisible: true }),
    browser_sessions__sum({ isVisible: true }),
    mobile_app_sessions__sum({ isVisible: true }),
    sessions__sum({ isVisible: true }),
    browser_page_views__sum({ isVisible: true }),
    mobile_app_page_views__sum({ isVisible: true }),
    page_views__sum({ isVisible: true }),
    buy_box_wins__sum({ isVisible: true }),
    buy_box_win_percentage({ isVisible: true }),
    ordered_product_sales__sum({ isVisible: true }),
    ordered_product_sales_b2b__sum({ isVisible: true }),
    ad_contribution_1_day({ isVisible: true, releaseDate: '2023-11-27' }),
    ad_contribution_1_day_same_sku({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    ad_contribution_14_day({ isVisible: true, releaseDate: '2023-11-27' }),
    ad_contribution_14_day_same_sku({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    organic_sales_1_day__sum({ isVisible: true, releaseDate: '2023-11-27' }),
    organic_sales_1_day_same_sku__sum({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    organic_sales_14_day__sum({ isVisible: true, releaseDate: '2023-11-27' }),
    organic_sales_14_day_same_sku__sum({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_advertising_cost_of_sales({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_roas({ isVisible: true, releaseDate: '2023-11-27' }),
    total_cvr_order_items({ isVisible: true, releaseDate: '2023-11-27' }),
    total_cvr_units({ isVisible: true, releaseDate: '2023-11-27' }),
    total_aov_order_items({ isVisible: true, releaseDate: '2023-11-27' }),
    total_aov_b2b_order_items({ isVisible: true, releaseDate: '2023-11-27' }),
    total_average_ordered_unit_value({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_average_ordered_unit_value_b2b({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_cpa_order_items({ isVisible: true, releaseDate: '2023-11-27' }),
    total_cpa_units({ isVisible: true, releaseDate: '2023-11-27' }),
    ...AMS_WITH_SD_METRICS_COLUMNS_NO_PACING,
    order_count__sum({
        isVisible: true,
        isDeprecated: true,
        endOfLifeDate: '2024-03-01',
    }),
    total_sales__sum({
        isVisible: true,
        isDeprecated: true,
        endOfLifeDate: '2024-03-01',
    }),
    total_cpa({
        isVisible: true,
        isDeprecated: true,
        endOfLifeDate: '2024-03-01',
    }),
    total_aov({
        isVisible: true,
        isDeprecated: true,
        endOfLifeDate: '2024-03-01',
    }),
    conversion_rate_sessions({
        isVisible: true,
        isDeprecated: true,
        endOfLifeDate: '2024-03-01',
    }),
]

export const SC_METRICS_AND_METADATA_CONFIG = makeMapping(
    SC_METRIC_AND_METADATA_COLUMNS
)

export const SC_SALES_AND_TRAFFIC_CONFIG = makeMapping(
    SC_SALES_AND_TRAFFIC_COLUMNS
)

export const SPECIAL_SC_GROUP_BY_COLUMNS = [
    // no SC_GROUP_BY_COLUMNS do special rendering
]
