import { ProductFactRecord } from 'types'

import {
    makeCurrencyMetricFieldFunc,
    makePercentageMetricFieldFunc,
} from './localUtils'

export const product_cpu = makeCurrencyMetricFieldFunc<ProductFactRecord>({
    name: 'CPU (Attributed Units Ordered 14 Day Same SKU)',
    shortName: 'CPU (14 Day Same SKU)',
    deltas: [],
    id: 'cost_per_attributed_units_ordered_14_day_same_sku',
    isTotalSupported: false,
})

export const product_cpu_goal = makeCurrencyMetricFieldFunc<ProductFactRecord>({
    name: 'CPU Goal',
    shortName: 'CPU Goal',
    deltas: [],
    id: 'product_cpu_goal',
    isTotalSupported: false,
})

export const product_cpu_goal_delta =
    makeCurrencyMetricFieldFunc<ProductFactRecord>({
        name: 'CPU Goal Delta',
        shortName: 'CPU Goal Delta',
        deltas: [],
        id: 'product_cpu_goal_delta',
        isTotalSupported: false,
    })

export const product_cpu_goal_performanceIndex =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'CPU Goal Performance Index',
        shortName: 'CPU Goal Performance Index',
        deltas: [],
        id: 'product_cpu_goal_performance_index',
        isTotalSupported: false,
    })

export const product_acos_goal =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'ACoS Goal',
        shortName: 'ACoS Goal',
        deltas: [],
        id: 'product_acos_goal',
        isTotalSupported: false,
    })

export const product_acos_goal_delta =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'ACoS Goal Delta',
        shortName: 'ACoS Goal Delta',
        deltas: [],
        id: 'product_acos_goal_delta',
        isTotalSupported: false,
    })

export const product_acos_goal_performanceIndex =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'ACoS Goal Performance Index',
        shortName: 'ACoS Goal Performance Index',
        deltas: [],
        id: 'product_acos_goal_performance_index',
        isTotalSupported: false,
    })

export const product_total_cpu_goal =
    makeCurrencyMetricFieldFunc<ProductFactRecord>({
        name: 'Total CPU Goal',
        shortName: 'Total CPU Goal',
        deltas: [],
        id: 'product_total_cpu_goal',
        isTotalSupported: false,
    })

export const product_total_cpu_goal_ordered_delta =
    makeCurrencyMetricFieldFunc<ProductFactRecord>({
        name: 'Total CPU Goal Delta (Ordered)',
        shortName: 'Total CPU Goal Delta (Ordered)',
        deltas: [],
        id: 'product_total_cpu_goal_ordered_delta',
        isTotalSupported: false,
    })

export const product_total_cpu_goal_ordered_performance_index =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'Total CPU Goal Performance Index (Ordered)',
        shortName: 'Total CPU Goal Performance Index (Ordered)',
        deltas: [],
        id: 'product_total_cpu_goal_ordered_performance_index',
        isTotalSupported: false,
    })

export const product_total_cpu_goal_shipped_delta =
    makeCurrencyMetricFieldFunc<ProductFactRecord>({
        name: 'Total CPU Goal Delta (Shipped)',
        shortName: 'Total CPU Goal Delta (Shipped)',
        deltas: [],
        id: 'product_total_cpu_goal_shipped_delta',
        isTotalSupported: false,
    })

export const product_total_cpu_goal_shipped_performance_index =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'Total CPU Goal Performance Index (Shipped)',
        shortName: 'Total CPU Goal Performance Index (Shipped)',
        deltas: [],
        id: 'product_total_cpu_goal_shipped_performance_index',
        isTotalSupported: false,
    })

export const product_tacos_goal =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'TACoS Goal',
        shortName: 'TACoS Goal',
        deltas: [],
        id: 'product_tacos_goal',
        isTotalSupported: false,
    })

export const product_tacos_goal_delta =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'TACoS Goal Delta',
        shortName: 'TACoS Goal Delta',
        deltas: [],
        id: 'product_tacos_goal_delta',
        isTotalSupported: false,
    })

export const product_tacos_goal_performanceIndex =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'TACoS Goal Performance Index',
        shortName: 'TACoS Goal Performance Index',
        deltas: [],
        id: 'product_tacos_goal_performance_index',
        isTotalSupported: false,
    })

export const product_tacos_goal_shipped_delta =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'TACoS Goal Delta (Shipped)',
        shortName: 'TACoS Goal Delta (Shipped)',
        deltas: [],
        id: 'product_tacos_goal_delta',
        isTotalSupported: false,
    })

export const product_tacos_goal_shipped_performanceIndex =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'TACoS Goal Performance Index (Shipped)',
        shortName: 'TACoS Goal Performance Index (Shipped)',
        deltas: [],
        id: 'product_tacos_goal_shipped_performance_index',
        isTotalSupported: false,
    })

export const product_tacos_goal_ordered_delta =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'TACoS Goal Delta (Ordered)',
        shortName: 'TACoS Goal Delta (Ordered)',
        deltas: [],
        id: 'product_tacos_goal_ordered_delta',
        isTotalSupported: false,
    })

export const product_tacos_goal_ordered_performanceIndex =
    makePercentageMetricFieldFunc<ProductFactRecord>({
        name: 'TACoS Goal Performance Index (Ordered)',
        shortName: 'TACoS Goal Performance Index (Ordered)',
        deltas: [],
        id: 'product_tacos_goal_ordered_performance_index',
        isTotalSupported: false,
    })
