import { ReactElement } from 'react'

import noop from 'lodash/noop'

import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { OrganizationIcon } from 'components/Icons'
import { ToolTip } from 'components/ToolTip'

import * as styles from './styles.scss'

interface Props {
    isSidebarCollapsed: boolean
    toggleModal: () => void
    title: string
}

const OrganizationButton = ({
    isSidebarCollapsed,
    toggleModal,
    title,
}: Props): ReactElement => (
    // intentionally not using components/TextButton to get ToolTip to work
    <div onClick={toggleModal} role="button" tabIndex={-1} onKeyPress={noop}>
        <ConditionalWrapper
            condition={!isSidebarCollapsed}
            wrapper={(children) => (
                <ToolTip title={title} placement="right">
                    {children}
                </ToolTip>
            )}
        >
            <div className={styles['organization-title']}>
                <OrganizationIcon />
                {!isSidebarCollapsed && <span>{title}</span>}
            </div>
        </ConditionalWrapper>
    </div>
)

export default OrganizationButton
