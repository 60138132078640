import { useMemo } from 'react'

import {
    UseQueryOptions,
    useQuery,
    useQueryClient,
    UseQueryResult,
    useMutation,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useSelector } from 'react-redux'

import { DATES } from 'const/filters'
import { useOrgContext } from 'context/OrgContext'
import { useUserOverrideContext } from 'context/UserOverrideContext'
import { generateReportNotification } from 'helpers/notifications'
import {
    ColumnsFormatted,
    formatColumns as defaultFormatColumns,
    formatCurrency,
    formatMetrics,
    formatPagination,
    formatPeriodDeltaDateRange,
    formatSorter,
} from 'helpers/params'
import { useCerebroApiRequest } from 'hooks'
import {
    selectTableSettings,
    selectVisibleColumnsOfTable,
    selectVisibleMetricsOfTable,
} from 'selectors/ui'
import {
    CerebroPaginatedResponse,
    CerebroResourceResponse,
    CurrencyCode,
    Field,
    FiltersState,
    PageFilters,
    RootReduxState,
    Report,
} from 'types'

interface CerebroPaginatedDataQueryProps<T> {
    queryKeyPrefix: string
    pagePath: string[]
    tablePath: string[]
    filters: PageFilters
    currencyCode?: CurrencyCode
    options?: Exclude<
        UseQueryOptions<CerebroPaginatedResponse<T> | null, AxiosError>,
        'queryKey' | 'queryFn'
    >
    formatFilters: (filters: PageFilters | FiltersState) => any // we should make the types for filter functions better
    groupBy?: string
    additionalParams?: Record<string, unknown>
    apiCall: (
        params: Record<string, unknown>,
        options?: Record<string, unknown>
    ) => Promise<CerebroPaginatedResponse<T>>
}

interface CerebroPaginatedDataQueryResult<T> {
    result: UseQueryResult<CerebroPaginatedResponse<T> | null, AxiosError>
    updateRecord: (data: { rowIndex: number; record: T }) => void
    invalidateAllRelatedQueries: () => void
}

export function useCerebroPaginatedDataQuery<T>({
    queryKeyPrefix,
    tablePath,
    filters,
    currencyCode,
    options,
    groupBy,
    additionalParams = {},
    formatFilters,
    apiCall,
}: CerebroPaginatedDataQueryProps<T>): CerebroPaginatedDataQueryResult<T> {
    const queryClient = useQueryClient()

    const makeCerebroApiRequest = useCerebroApiRequest({ throwOnError: true })
    const { userOverride } = useUserOverrideContext()
    const { orgId } = useOrgContext()

    const { pagination, sorter } = useSelector((state: RootReduxState) =>
        selectTableSettings(state, tablePath)
    )

    const params: Record<string, unknown> = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatCurrency(currencyCode),
        ...additionalParams,
    }
    if (groupBy) {
        params.group_by = groupBy
    }

    const apiOptions = {
        headers: {
            userId: userOverride?.userId,
            orgId: userOverride?.organizationId,
            orgGroupId: userOverride?.organizationGroupId,
        },
    }

    const queryKey = [
        queryKeyPrefix,
        'summary',
        orgId,
        tablePath,
        {
            ...params,
        },
    ]

    return {
        invalidateAllRelatedQueries: () => {
            queryClient.invalidateQueries({
                queryKey: [queryKeyPrefix],
            })
        },
        result: useQuery({
            queryKey,
            ...options,
            queryFn: () =>
                makeCerebroApiRequest({
                    request: apiCall(params, apiOptions),
                }),
        }),
        updateRecord: (data: { rowIndex: number; record: any }) => {
            queryClient.setQueryData<CerebroPaginatedResponse<any> | null>(
                queryKey,
                (prev) => {
                    if (!prev) {
                        return prev
                    }

                    const newRecords = [...prev.data.results]
                    newRecords[data.rowIndex] = data.record

                    return {
                        ...prev,
                        data: {
                            ...prev.data,
                            results: newRecords,
                        },
                    }
                }
            )
        },
    }
}

export type CerebroPaginatedMetricDataQueryProps<T> =
    CerebroPaginatedDataQueryProps<T> & {
        config: Field<T>[]
        periodDateFilterFallback?: string
        periodDateFilterPrefix?: string
    }

export type CerebroPaginatedMetricDataQueryResult<T> =
    CerebroPaginatedDataQueryResult<T>

export function useCerebroPaginatedMetricDataQuery<T>({
    queryKeyPrefix,
    pagePath,
    tablePath,
    config,
    filters,
    currencyCode,
    options,
    groupBy,
    additionalParams = {},
    apiCall,
    formatFilters,
    periodDateFilterFallback,
    periodDateFilterPrefix,
}: CerebroPaginatedMetricDataQueryProps<T>): CerebroPaginatedMetricDataQueryResult<T> {
    const metrics = useSelector((state: RootReduxState) =>
        selectVisibleMetricsOfTable(state, tablePath, config)
    )
    const {
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
        showTotalRow,
    } = useSelector((state: RootReduxState) =>
        selectTableSettings(state, tablePath)
    )
    const additionalMetricParams = {
        ...formatMetrics(metrics, showPeriodDeltas, showTotalRow),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES] ?? periodDateFilterFallback,
            periodDateFilterPrefix
        ),
        ...additionalParams,
    }
    return useCerebroPaginatedDataQuery({
        queryKeyPrefix,
        pagePath,
        tablePath,
        filters,
        currencyCode,
        options,
        groupBy,
        additionalParams: additionalMetricParams,
        apiCall,
        formatFilters,
    })
}

export type CerebroPaginatedNonMetricDataQueryProps<T> = Omit<
    CerebroPaginatedMetricDataQueryProps<T>,
    'config'
>

export type CerebroPaginatedNonMetricDataQueryResult<T> =
    CerebroPaginatedMetricDataQueryResult<T>

export function useCerebroPaginatedNonMetricDataQuery<T>({
    queryKeyPrefix,
    tablePath,
    filters,
    options,
    groupBy,
    currencyCode,
    additionalParams = {},
    apiCall,
    formatFilters,
}: CerebroPaginatedNonMetricDataQueryProps<T>): CerebroPaginatedNonMetricDataQueryResult<T> {
    const queryClient = useQueryClient()

    const makeCerebroApiRequest = useCerebroApiRequest({ throwOnError: true })
    const { userOverride } = useUserOverrideContext()
    const { orgId } = useOrgContext()

    const { pagination, sorter } = useSelector((state: RootReduxState) =>
        selectTableSettings(state, tablePath)
    )

    const params: Record<string, unknown> = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatCurrency(currencyCode),
        group_by: groupBy,
        ...additionalParams,
    }

    const apiOptions = {
        headers: {
            userId: userOverride?.userId,
            orgId: userOverride?.organizationId,
            orgGroupId: userOverride?.organizationGroupId,
        },
    }

    const queryKey = [
        queryKeyPrefix,
        'summary',
        orgId,
        tablePath,
        {
            ...params,
        },
    ]

    return {
        invalidateAllRelatedQueries: () => {
            queryClient.invalidateQueries({
                queryKey: [queryKeyPrefix],
            })
        },
        result: useQuery({
            queryKey,
            ...options,
            queryFn: () =>
                makeCerebroApiRequest({
                    request: apiCall(params, apiOptions),
                }),
        }),
        updateRecord: (data: { rowIndex: number; record: any }) => {
            queryClient.setQueryData<CerebroPaginatedResponse<any> | null>(
                queryKey,
                (prev) => {
                    if (!prev) {
                        return prev
                    }

                    const newRecords = [...prev.data.results]
                    newRecords[data.rowIndex] = data.record

                    return {
                        ...prev,
                        data: {
                            ...prev.data,
                            results: newRecords,
                        },
                    }
                }
            )
        },
    }
}

export type CerebroDownloadQueryProps<T> = Omit<
    CerebroPaginatedMetricDataQueryProps<T>,
    'options' | 'apiCall' | 'pagePath'
> & {
    reportName: string
    apiCall: (
        params: Record<string, unknown>
    ) => Promise<CerebroResourceResponse<Report>>
    formatColumns?: (
        columns: Field<T>[],
        showPeriodDeltas: boolean
    ) => ColumnsFormatted
}

export interface CerebroDownloadQueryResult {
    triggerReport: () => void
    isPending: boolean
}

export function useCerebroDownload<T>({
    queryKeyPrefix,
    tablePath,
    config,
    filters,
    currencyCode,
    additionalParams,
    groupBy,
    reportName,
    apiCall,
    formatFilters,
    formatColumns,
}: CerebroDownloadQueryProps<T>): CerebroDownloadQueryResult {
    const makeCerebroApiRequest = useCerebroApiRequest({ throwOnError: true })
    const { orgId } = useOrgContext()

    const currency = currencyCode

    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        useSelector((state: RootReduxState) =>
            selectTableSettings(state, tablePath)
        )

    const columns = useSelector((state: RootReduxState) =>
        selectVisibleColumnsOfTable(state, tablePath, config)
    )

    const params: Record<string, unknown> = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...(formatColumns
            ? formatColumns(columns, showPeriodDeltas)
            : defaultFormatColumns(columns, showPeriodDeltas)),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        ...additionalParams,
        group_by: groupBy,
        async_download_name: reportName,
    }

    const mutationKey = [
        queryKeyPrefix,
        'download',
        orgId,
        tablePath,
        {
            ...params,
        },
    ]

    const { mutate, isPending } = useMutation({
        mutationKey,
        mutationFn: async () => {
            const response = await makeCerebroApiRequest({
                request: apiCall(params),
            })
            generateReportNotification(params.async_download_name as string)
            return response
        },
    })

    return useMemo(
        () => ({
            triggerReport: mutate,
            isPending,
        }),
        [mutate, isPending]
    )
}
