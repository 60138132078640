import {
    ReactElement,
    ReactNode,
    useState,
    useEffect,
    useCallback,
} from 'react'

import { Row, Col, Button, Alert, Flex } from 'antd'
import isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'

import { Collapse } from 'components/Collapse'
import { formatNumber } from 'helpers/formatting'

import { BulkEditError } from '../BulkUpdateAction/BulkEditError'

import { Props } from './localTypes'
import * as styles from './styles.scss'

function BulkUpdateTitle<RecordType>({
    title = null,
    recordName,
    reloadData,
    unselectAllRows,
    isAllRowsSelected,
    selectedRows,
    pagination,
    bulkActions,
    updatePastPage,
    showBulkActions = false,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation('table')
    const [selectedActionIndex, setSelectedActionIndex] = useState(-1)
    const [isOpened, setIsOpened] = useState(selectedRows.length > 0)
    const [isPastPageSelectionDisable, setPastPageSelectionDisable] =
        useState(false)

    const nextIsOpened = selectedRows.length > 0

    const resetState = useCallback(() => {
        setIsOpened(nextIsOpened)
        setSelectedActionIndex(-1)
        if (updatePastPage) {
            updatePastPage.onChange(false)
        }
    }, [nextIsOpened, updatePastPage])

    // reset bulk edit title state on collapse
    useEffect(() => {
        if (nextIsOpened !== isOpened) {
            resetState()
        }
    }, [nextIsOpened, isOpened, resetState])

    const selectionSize = updatePastPage?.selected
        ? pagination.total
        : selectedRows.length

    const selectedText = t(
        'table:BulkActions.BulkUpdateTitle.selectedText',
        '{{total}} {{recordName}}(s) Selected: ',
        { total: formatNumber(selectionSize), recordName }
    )

    const { showDryRunPrompt = true } = bulkActions[selectedActionIndex] ?? {}

    const renderDryRunPrompt = (): ReactNode =>
        !isUndefined(updatePastPage) &&
        isAllRowsSelected &&
        updatePastPage.selected &&
        Boolean(bulkActions[selectedActionIndex]) &&
        showDryRunPrompt && (
            <Alert
                type="info"
                message={t(
                    'table:BulkActions.BulkUpdateTitle.dryRunPrompt',
                    'For bulk updates we recommend you first complete a "Dry Run", preview your changes, then apply changes'
                )}
            />
        )

    const renderSelectPastPagePrompt = (): ReactNode =>
        !isUndefined(updatePastPage) &&
        isAllRowsSelected && (
            <Row justify="center" align="middle" className={styles.alert}>
                {updatePastPage.selected ? (
                    <Col>
                        {t(
                            'table:BulkActions.BulkUpdateTitle.pastPageSelectedText',
                            'All {{total}} {{recordName}}(s) are selected.',
                            {
                                total: formatNumber(pagination.total),
                                recordName,
                            }
                        )}{' '}
                        <Button
                            type="link"
                            onClick={() => updatePastPage.onChange(false)}
                        >
                            {t(
                                'table:BulkActions.BulkUpdateTitle.pastPageSelectedBtnText',
                                'Clear Selection'
                            )}
                        </Button>
                    </Col>
                ) : (
                    <Col>
                        {t(
                            'table:BulkActions.BulkUpdateTitle.pageSelectedText',
                            'All {{total}} {{recordName}}(s) on this page are selected.',
                            { total: pagination.pageSize, recordName }
                        )}{' '}
                        <Button
                            type="link"
                            onClick={() => updatePastPage.onChange(true)}
                            disabled={isPastPageSelectionDisable}
                        >
                            {t(
                                'table:BulkActions.BulkUpdateTitle.pageSelectedBtnText',
                                'Select all {{total}} {{recordName}}(s) that match this criteria.',
                                {
                                    total: formatNumber(pagination.total),
                                    recordName,
                                }
                            )}
                        </Button>
                    </Col>
                )}
            </Row>
        )

    const renderSelectedBulkAction = (): ReactNode => (
        <div>
            {bulkActions[selectedActionIndex].render({
                recordName,
                reloadData,
                unselectAllRows,
                selectedRows,
                pagination,
                updatePastPage,
                onCancel: () => setSelectedActionIndex(-1),
                setPastPageSelectionDisable,
            })}
        </div>
    )

    const renderBulkActions = (): ReactNode => (
        <Flex vertical>
            <Flex align="center">
                {bulkActions.map((action, index) => (
                    <Button
                        style={{ marginRight: 8 }}
                        key={index}
                        onClick={() => setSelectedActionIndex(index)}
                        type="primary"
                        ghost
                    >
                        {action.title}
                    </Button>
                ))}
            </Flex>
            {/* Render empty BulkEditError for proper spacing */}
            <BulkEditError />
        </Flex>
    )

    const selectedBulkAction = bulkActions[selectedActionIndex]

    return (
        <>
            {title}
            <Collapse isOpened={isOpened}>
                <Flex align="start" style={{ paddingTop: 8 }}>
                    <p className={`${styles['selected-count']} flex-shrink-0`}>
                        {selectedText}
                    </p>
                    {selectedBulkAction && renderSelectedBulkAction()}
                    {(!selectedBulkAction || showBulkActions) &&
                        renderBulkActions()}
                </Flex>
                {renderDryRunPrompt()}
                {renderSelectPastPagePrompt()}
            </Collapse>
        </>
    )
}

export default BulkUpdateTitle
