import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import TimezoneIconSvg from './TimezoneIconSvg'

const TimezoneIcon = (props: IconProps): ReactElement => (
    <Icon component={TimezoneIconSvg} {...props} />
)

export default TimezoneIcon
