import { ReactElement, useEffect } from 'react'

import { ErrorMessage, Form, Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import { verifyEmailRequest } from 'actions/auth'
import CodeInput from 'components/CodeInput/CodeInput'
import { useAction, useAuthDomainValue } from 'hooks'
import { useAnalytics } from 'services/analytics'

import { AuthError, SubmitButton } from '../Shared'
import * as styles from '../styles.scss'

const VerifyEmailForm = (): ReactElement => {
    const verifyEmail = useAction(verifyEmailRequest)
    const email = useAuthDomainValue('email')
    const { t } = useTranslation('account')

    const { trackEvent, trackCtaClick } = useAnalytics()

    useEffect(() => {
        trackEvent('Sign Up - Verify Email Started', {
            text: 'Sign Up - Verify Email Started',
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className={styles.title}>
                <h1>
                    {t(
                        'account:VerifyEmailForm.title',
                        'Verify your email address'
                    )}
                </h1>
                <div className={styles.subtitle}>
                    <Trans i18nKey="account:VerifyEmailForm.subTitle">
                        We sent an email to {{ email }} <br /> with your 6-digit
                        code.
                    </Trans>
                </div>
            </div>

            <Formik
                initialValues={{ code: '' }}
                onSubmit={(values) => {
                    trackCtaClick({
                        destination: 'Sign in Page',
                        text: 'Verify Email',
                        type: 'button',
                        location: 'Verify Email Page',
                    })
                    verifyEmail(values)
                }}
                validationSchema={object().shape({
                    code: string()
                        .required()
                        .label(t('account:code', '6-Digit Code')),
                })}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <div>
                            <CodeInput
                                length={6}
                                onChange={(code) => setFieldValue('code', code)}
                            />
                            <ErrorMessage
                                name="code"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <SubmitButton>
                            {t(
                                'account:VerifyEmailForm.submitBtnText',
                                'Verify'
                            )}
                        </SubmitButton>
                    </Form>
                )}
            </Formik>

            <AuthError />
        </>
    )
}

export default VerifyEmailForm
