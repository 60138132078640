import { ReactElement, ReactNode } from 'react'

import { Tag, type TagProps } from 'antd'
import classNames from 'classnames'

import { IconButton } from 'components/Buttons'

import * as styles from './styles.scss'

export interface PillTagProps extends TagProps {
    label: ReactNode
    iconTooltipTitle?: string
    hasError?: boolean
    className?: string
}

const PillTag = ({
    className,
    onClose,
    onClick,
    icon,
    iconTooltipTitle,
    label,
    hasError = false,
    ...rest
}: PillTagProps): ReactElement => {
    return (
        <Tag
            className={classNames(className, styles['pasted-tag'], {
                [styles['pasted-tag-error']]: hasError,
                [styles['on-click-hover']]: Boolean(onClick),
            })}
            onClose={onClose}
            onClick={onClick}
            closeIcon={
                <IconButton
                    className={styles['tag-close-btn']}
                    size="small"
                    icon={icon}
                    tooltipTitle={iconTooltipTitle}
                />
            }
            {...rest}
        >
            {label}
        </Tag>
    )
}

export default PillTag
