/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all, takeEvery } from 'redux-saga/effects'

import { fetchChildrenRequest } from 'actions/ui/productsSummaryPage'

import { fetchChildrenWorker } from './workers'

export default function* parentProductsTabWatcher() {
    yield all([takeEvery(fetchChildrenRequest.toString(), fetchChildrenWorker)])
}
