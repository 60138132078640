import { ReactElement, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button, message, Modal, Select } from 'antd'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { acceptInvitationRequest } from 'actions/auth'
import { CreateOrganizationModal } from 'components/CreateResourceModal'
import { FormikSelect } from 'components/formik'
import { OrganizationIcon } from 'components/Icons'
import Stack from 'components/Layout/Stack'
import {
    userHasCustomerServicePermissions,
    userHasDashboardsOnlyPermissions,
    userHasOrgAdminPermissions,
} from 'helpers/featurePermissions'
import {
    useAction,
    useAuthDomainValue,
    useModal,
    useOrganizations,
    useUserHasPermissions,
} from 'hooks'
import { Organization, OrganizationInvitation } from 'types'
import { JoinOrganizationForm } from 'views/Auth/Forms'

import * as authStyles from '../../../../Auth/styles.scss'

import { ImpersonationButtonToggle } from './ImpersonationButtonToggle'
import * as organizationMenuStyles from './styles.scss'

interface FormValues {
    organizationId: string
}

interface Props {
    modalVisible: boolean
    closeModal: () => void
    currentOrganization: Organization
    handleChangeOrganization: (organizationId: string) => void
}

const OrganizationModal = ({
    modalVisible,
    closeModal,
    currentOrganization,
    handleChangeOrganization,
}: Props): ReactElement => {
    const { t } = useTranslation(['common', 'navigation'])
    const organizations = useOrganizations()

    const pendingInvitations =
        useAuthDomainValue<OrganizationInvitation[]>('pendingInvitations')

    const acceptInvite = useAction(acceptInvitationRequest)

    const [activeInvitation, setActiveInvitation] =
        useState<OrganizationInvitation | null>(null)

    const {
        isModalVisible: isAcceptInviteModalVisible,
        toggleModalVisible: toggleAcceptInviteModal,
    } = useModal()

    const {
        isModalVisible: isCreateOrgModalVisible,
        toggleModalVisible: toggleCreateOrgModal,
    } = useModal()

    const isImpersonationMode = useUserHasPermissions(
        userHasCustomerServicePermissions
    )

    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    const hasOrgAdminPermissions = useUserHasPermissions(
        userHasOrgAdminPermissions
    )

    const pendingOrgs = [...pendingInvitations].sort((a, b) =>
        a.organization.name.localeCompare(b.organization.name)
    )

    return (
        <>
            <Formik<FormValues>
                initialValues={{
                    organizationId: currentOrganization.id,
                }}
                onSubmit={({ organizationId }) => {
                    closeModal()
                    handleChangeOrganization(organizationId)
                }}
            >
                {({ handleSubmit }) => (
                    <Modal
                        title={t(
                            'navigation:SideMenu.OrganizationModal.title',
                            'Select Organization'
                        )}
                        open={modalVisible}
                        onCancel={closeModal}
                        footer={
                            <Stack direction="row" justifyContent="between">
                                <div>
                                    {!dashboardOnlyExperience &&
                                        currentOrganization.enabled &&
                                        !currentOrganization.locked &&
                                        currentOrganization.is_agency === 1 &&
                                        hasOrgAdminPermissions && (
                                            <Button
                                                key="create"
                                                onClick={() =>
                                                    toggleCreateOrgModal()
                                                }
                                                icon={<PlusOutlined />}
                                            >
                                                {t(
                                                    'navigation:SideMenu.OrganizationModal.createNewOrgBtnText',
                                                    'Create New Organization'
                                                )}
                                            </Button>
                                        )}
                                </div>
                                <div>
                                    <Button key="cancel" onClick={closeModal}>
                                        {t('common:cancel', 'Cancel')}
                                    </Button>
                                    <Button
                                        key="ok"
                                        type="primary"
                                        onClick={() => handleSubmit()}
                                    >
                                        {t('common:ok', 'Ok')}
                                    </Button>
                                </div>
                            </Stack>
                        }
                    >
                        <Field
                            autoFocus
                            name="organizationId"
                            component={FormikSelect}
                            optionFilterProp="children"
                            showSearch
                            style={{ width: '100%' }}
                            getPopupContainer={(trigger: any) =>
                                trigger.parentElement
                            }
                        >
                            {organizations
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((org) => {
                                    return (
                                        <Select.Option
                                            key={org.id}
                                            value={org.id}
                                        >
                                            {org.name}
                                        </Select.Option>
                                    )
                                })}
                        </Field>
                        {pendingOrgs.length > 0 && (
                            <fieldset
                                className={
                                    organizationMenuStyles[
                                        'pending-invites-fieldset'
                                    ]
                                }
                            >
                                <legend className="fg-form-legend">
                                    {t(
                                        'navigation:SideMenu.OrganizationModal.pendingInvites',
                                        'Pending Invites:'
                                    )}
                                </legend>
                                {pendingOrgs.map((invite) => (
                                    <div
                                        key={invite.id}
                                        className={
                                            organizationMenuStyles[
                                                'pending-invites-item'
                                            ]
                                        }
                                    >
                                        <OrganizationIcon />{' '}
                                        <span
                                            className={
                                                organizationMenuStyles[
                                                    'pending-invites-item-label'
                                                ]
                                            }
                                        >
                                            {invite.organization.name}
                                        </span>
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                setActiveInvitation(invite)
                                                toggleAcceptInviteModal()
                                            }}
                                            style={{ paddingLeft: 4 }}
                                        >
                                            (
                                            {t(
                                                'navigation:SideMenu.OrganizationModal.acceptInviteBtnText',
                                                'Accept Pending Invitation'
                                            )}
                                            )
                                        </Button>
                                    </div>
                                ))}
                            </fieldset>
                        )}
                        {isImpersonationMode && (
                            <div style={{ marginTop: 12 }}>
                                <ImpersonationButtonToggle />
                            </div>
                        )}
                    </Modal>
                )}
            </Formik>
            <Modal
                open={isAcceptInviteModalVisible}
                onCancel={toggleAcceptInviteModal}
                footer={null}
            >
                {activeInvitation && (
                    <div className={authStyles.form}>
                        <JoinOrganizationForm
                            invitation={activeInvitation}
                            onSubmit={(values) =>
                                acceptInvite({
                                    ...values,
                                    callback: () => {
                                        toggleAcceptInviteModal()
                                        message.success(
                                            t(
                                                'navigation:SideMenu.OrganizationModal.acceptInviteSuccessMsg',
                                                'Successfully accepted invitation.'
                                            )
                                        )
                                    },
                                })
                            }
                        />
                    </div>
                )}
            </Modal>
            <CreateOrganizationModal
                visible={isCreateOrgModalVisible}
                closeModal={toggleCreateOrgModal}
            />
        </>
    )
}

export default OrganizationModal
