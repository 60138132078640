import { ReactElement } from 'react'

import { Button, Tabs } from 'antd'
import { FieldArray, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { Schema } from 'yup'

import Stack from 'components/Layout/Stack'

import CreateResourceModal from '../CreateResourceModal/CreateResourceModal'
import { BulkAddTextArea } from '../Shared'

import CreateAdItemForm from './CreateAdItemForm'
import { BaseAdItem, ColumnConfig, FormValues } from './localTypes'
import { SearchAdItem } from './SearchAdItem'
import * as styles from './styles.scss'

interface Props<AdItemField> {
    isModalVisible: boolean
    onModalCancel: () => void
    toggleModalVisible: () => void
    columnsConfig: ColumnConfig[]
    submitForm: (
        values: FormValues<AdItemField>,
        formikHelpers: FormikHelpers<FormValues<AdItemField>>
    ) => void | Promise<any>
    serializeAdItemField: (itemId: string) => AdItemField
    subtitle?: ReactElement | undefined
    initialValues: FormValues<AdItemField>
    validationSchema: Schema
}

export default function CreateAdItemModal<AdItemField extends BaseAdItem>({
    isModalVisible,
    onModalCancel,
    toggleModalVisible,
    columnsConfig,
    initialValues,
    submitForm,
    validationSchema,
    serializeAdItemField,
    subtitle,
}: Props<AdItemField>): ReactElement {
    const { t } = useTranslation(['common', 'table'])

    const handleFieldArrayPush = (
        itemId: string,
        push: (obj: any) => void,
        itemName: null | string = null
    ): void => {
        const adItem = { ...serializeAdItemField(itemId), name: itemName }
        push(adItem)
    }

    const renderModalTitle = (): ReactElement => {
        return (
            <>
                <div>{t('common:addItem', 'Add Items')}</div>
                {subtitle && subtitle}
            </>
        )
    }

    return (
        <Formik
            validateOnMount
            initialValues={initialValues}
            enableReinitialize
            onSubmit={submitForm}
            validationSchema={validationSchema}
        >
            {({
                isSubmitting,
                resetForm,
                handleSubmit,
                values,
                isValid,
                dirty,
                setFieldValue,
            }) => {
                const tabItems = [
                    {
                        key: '1',
                        label: 'Search',
                        children: (
                            <div style={{ padding: 0, paddingTop: 4 }}>
                                <FieldArray
                                    name="adItems"
                                    render={(arrayHelpers) => (
                                        <SearchAdItem
                                            addAdItem={(itemId, name) =>
                                                handleFieldArrayPush(
                                                    itemId as string,
                                                    arrayHelpers.push,
                                                    name
                                                )
                                            }
                                            addedAdItemIds={values.adItems.map(
                                                (item) => item.itemId
                                            )}
                                        />
                                    )}
                                />
                            </div>
                        ),
                    },
                    {
                        key: '2',
                        label: 'Paste List',
                        children: (
                            <div style={{ padding: 0, paddingTop: 4 }}>
                                <FieldArray
                                    name="adItems"
                                    render={(arrayHelpers) => (
                                        <Stack direction="column">
                                            <BulkAddTextArea
                                                label="Items by Item ID"
                                                type="adItem"
                                                loading={false}
                                                push={(item) => {
                                                    handleFieldArrayPush(
                                                        item.adItem as string,
                                                        arrayHelpers.push
                                                    )
                                                }}
                                            />
                                        </Stack>
                                    )}
                                />
                            </div>
                        ),
                    },
                ]

                return (
                    <CreateResourceModal
                        className={styles['ad-item-modal']}
                        open={isModalVisible}
                        title={renderModalTitle()}
                        onCancel={() => {
                            onModalCancel()
                            resetForm()
                        }}
                        width={900}
                        maskClosable={false}
                        destroyOnClose
                        footer={[
                            <Button
                                key={1}
                                style={{ marginRight: 8 }}
                                onClick={() => {
                                    resetForm()
                                    toggleModalVisible()
                                }}
                                disabled={isSubmitting}
                            >
                                {t('common:cancel', 'Cancel')}
                            </Button>,
                            <Button
                                key={2}
                                type="primary"
                                onClick={() => handleSubmit()}
                                loading={isSubmitting}
                                disabled={!dirty || !isValid || isSubmitting}
                            >
                                {t('common:addItem', 'Add Items')}
                            </Button>,
                        ]}
                    >
                        <Tabs
                            className={styles['add-items-tabs']}
                            items={tabItems}
                        />
                        <CreateAdItemForm<AdItemField>
                            adItems={values.adItems}
                            remove={(index) => {
                                const { adItems } = values
                                adItems.splice(index, 1)

                                setFieldValue('adItems', adItems)
                            }}
                            columnsConfig={columnsConfig}
                        />
                    </CreateResourceModal>
                )
            }}
        </Formik>
    )
}
