import produce from 'immer'
import cloneDeep from 'lodash/cloneDeep'
import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import update from 'lodash/fp/update'
import isEmpty from 'lodash/isEmpty'
import mapValues from 'lodash/mapValues'
import without from 'lodash/without'
import { normalize } from 'normalizr'
import { handleActions } from 'redux-actions'
import { v4 as uuid } from 'uuid'

import {
    // Tab
    updateTabs,

    // widget
    addWidget,
    toggleWidgetModal,
    deleteWidget,
    updateWidget,
    cloneWidget,

    // Save Dashboard
    saveDashboardSuccess,
    saveDashboardFailure,
    saveDashboardRequest,
    handleFieldsChange,

    // dashboard actions
    changeLayout,
    fetchScheduledExportsSuccess,
    changeDashboardCurrencyCode,

    // Share
    shareDashboardRequest,
    shareDashboardSuccess,
    shareDashboardFailure,
} from 'actions/ui/dashboardPage'
import {
    updateWidgetPagination,
    updateWidgetSorter,
    updateWidgetTableColumns,
} from 'actions/ui/shared/dashboard'
import { Y_BOTTOM_OF_PAGE } from 'const/dashboards'
import { DATES } from 'const/filters'
import { DASHBOARD_PAGE } from 'const/pages'
import { getCurrentTab } from 'helpers/pages'
import { tabListSchema } from 'schemas'

import {
    defaultWidgetState,
    defaultPaginationAndSorter,
    defaultWidgetLayout,
    defaultDashboardPage,
    defaultDatesFilter,
} from '../defaults'

const defaultState = {
    ...defaultDashboardPage,
    widgetModalOpen: false,
    saving: false,
    isDirty: false,
    scheduledExport: null,
}

export default handleActions(
    {
        // add widget
        [addWidget](state, action) {
            const widget = action.payload
            const tabId = getCurrentTab()
            if (!tabId) {
                return state
            }
            return flow(
                update(['tabs', tabId, 'widgets'], (widgets) => [
                    ...widgets,
                    widget.id,
                ]),
                set(['widgets', widget.id], {
                    ...widget,
                    ...defaultWidgetState,
                    ...defaultPaginationAndSorter(widget),
                    layout: widget.layout?.h
                        ? {
                              ...widget.layout, // width and height of source widget
                              i: widget.id,
                              x: 0, // left aligned
                              y: Y_BOTTOM_OF_PAGE,
                          }
                        : defaultWidgetLayout(widget, Y_BOTTOM_OF_PAGE).layout,
                    new: true,
                }),
                set('widgetModalOpen', false),
                set('isDirty', true)
            )(state)
        },

        [cloneWidget](state, action) {
            const { tabId, widgetId } = action.payload
            const sourceWidget = state.widgets[widgetId]
            const newWidgetId = uuid()

            return flow(
                update(['tabs', tabId, 'widgets'], (widgets) => [
                    ...widgets,
                    newWidgetId,
                ]),
                set(['widgets', newWidgetId], {
                    ...sourceWidget,
                    id: newWidgetId,
                    name: sourceWidget.name
                        ? `${sourceWidget.name} (copy)`
                        : '',
                    new: true,
                    layout: {
                        ...sourceWidget.layout, // width and height of source widget
                        i: newWidgetId,
                        x: 0, // left aligned
                        y: Y_BOTTOM_OF_PAGE,
                    },
                }),
                set('isDirty', true)
            )(state)
        },

        [toggleWidgetModal](state) {
            return set('widgetModalOpen', !state.widgetModalOpen, state)
        },

        // delete widget
        [deleteWidget](state, action) {
            const { tabId, widgetId } = action.payload
            return flow(
                set(
                    ['tabs', tabId, 'widgets'],
                    without(state.tabs[tabId].widgets, widgetId)
                ),
                set(
                    ['widgets'],
                    produce(state.widgets, (draft) => {
                        delete draft[widgetId]
                    })
                ),
                set('isDirty', true)
            )(state)
        },

        // update widget
        [updateWidget](state, action) {
            const widget = action.payload
            return flow(
                set(['widgets', widget.id], {
                    ...widget,
                    ...defaultPaginationAndSorter(widget),
                }),
                set('widgetModalOpen', false),
                set('isDirty', true)
            )(state)
        },

        [updateTabs](state, action) {
            const {
                entities: { tabs, widgets },
            } = normalize(action.payload, tabListSchema)

            return flow(
                set(
                    'tabs',
                    mapValues(tabs, (tab) =>
                        produce(tab, (draft) => {
                            draft.localFilters = {
                                ...draft.localFilters,
                                [DATES]: !isEmpty(draft?.localFilters?.[DATES])
                                    ? draft.localFilters[DATES]
                                    : defaultDatesFilter,
                            }
                        })
                    )
                ),
                set('widgets', widgets),
                set('isDirty', true)
            )(state)
        },

        // save dashboard
        [saveDashboardRequest](state) {
            return set('saving', true, state)
        },
        [saveDashboardSuccess](state, action) {
            const { payload: dashboard } = action

            return flow(
                set('saving', false),
                set('isDirty', false),
                set('dashboard', {
                    ...state.dashboard,
                    ...dashboard,
                })
            )(state)
        },
        [saveDashboardFailure](state, action) {
            const { message } = action.payload
            return flow(set('saving', false), set('error', message))(state)
        },

        // save dashboard
        [shareDashboardRequest](state) {
            return set('saving', true, state)
        },
        [shareDashboardSuccess](state) {
            return set('saving', false, state)
        },
        [shareDashboardFailure](state, action) {
            const { message } = action.payload
            return flow(set('saving', false), set('error', message))(state)
        },

        // handle fields change
        [handleFieldsChange](state, action) {
            const { name, description, markAsDirty } = action.payload
            return flow(
                set(['dashboard', 'name'], name),
                set(['dashboard', 'description'], description),
                set('isDirty', markAsDirty || state.isDirty)
            )(state)
        },

        [changeLayout](state, action) {
            const layouts = action.payload
            return flow(
                update(['widgets'], (widgets) => {
                    const widgetsToUpdate = cloneDeep(widgets)

                    layouts.forEach((layout) => {
                        const widget = widgetsToUpdate[layout.i]

                        if (widget) {
                            widget.layout = layout
                        }
                    })

                    return widgetsToUpdate
                }),
                set(['isDirty'], true)
            )(state)
        },

        [fetchScheduledExportsSuccess](state, action) {
            const scheduledExports = action.payload.results
            return set(
                ['scheduledExport'],
                (scheduledExports?.length ?? 0) > 0 ? scheduledExports[0] : null
            )(state)
        },

        [updateWidgetPagination](state, action) {
            const {
                pageName,
                data: { widgetId, pagination },
            } = action.payload

            if (pageName === DASHBOARD_PAGE) {
                return produce(state, (draft) => {
                    const widget = draft.widgets[widgetId]
                    if (widget.limit !== pagination.pageSize) {
                        widget.limit = pagination.pageSize
                        draft.isDirty = true
                    }
                })
            }

            return state
        },

        [updateWidgetSorter](state, action) {
            const {
                pageName,
                data: { widgetId, sorter },
            } = action.payload

            if (pageName === DASHBOARD_PAGE) {
                return produce(state, (draft) => {
                    const widget = draft.widgets[widgetId]
                    if (widget.order_by !== sorter.field) {
                        widget.order_by = sorter.field
                        draft.isDirty = true
                    }
                    if (widget.order_by_direction !== sorter.order) {
                        widget.order_by_direction = sorter.order
                        draft.isDirty = true
                    }
                })
            }

            return state
        },

        [updateWidgetTableColumns](state, action) {
            const { pageName } = action.payload

            if (pageName === DASHBOARD_PAGE) {
                return set(['isDirty'], true)(state)
            }

            return state
        },

        [changeDashboardCurrencyCode](state, action) {
            const currencyCode = action.payload

            return flow(
                set('currencyCode', currencyCode),
                set('isDirty', true),
                update('tabs', (tabs) =>
                    mapValues(tabs, (tab) => ({
                        ...tab,
                        loaded: false,
                    }))
                )
            )(state)
        },
    },
    cloneDeep(defaultState) // create clone, so the defaultState is not mutated
)
