import { ReactElement } from 'react'

import { Link } from 'react-router-dom'

import Stack from 'components/Layout/Stack'
import { AUTO } from 'const/targetingTypes'
import { formatMatchType } from 'helpers/formatting'
import { keywordUrl } from 'helpers/urls'

import { SelectedSearchTerm } from '../localTypes'
import * as styles from '../styles.scss'

interface Props {
    selectedSearchTerm: SelectedSearchTerm
}

const isKeywordSearchTerm = (searchTerm: SelectedSearchTerm): boolean =>
    !!searchTerm.record.keyword

const isAutoCampaign = (searchTerm: SelectedSearchTerm): boolean =>
    searchTerm.record.campaign.targeting_type === AUTO

const KeywordLabel = ({ selectedSearchTerm }: Props): ReactElement | null => {
    if (
        isKeywordSearchTerm(selectedSearchTerm) &&
        !isAutoCampaign(selectedSearchTerm)
    ) {
        const {
            record: {
                keyword: {
                    id: keywordId,
                    text: keywordText,
                    match_type: keywordMatchType,
                },
            },
        } = selectedSearchTerm
        return (
            <Stack direction="column">
                <div className={styles.label}>Source Keyword</div>
                <div className={styles.value}>
                    <Link to={keywordUrl(keywordId)}>{keywordText}</Link> (
                    {formatMatchType(keywordMatchType)})
                </div>
            </Stack>
        )
    }
    return null
}

export default KeywordLabel
