/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import {
    acceptInvitationRequest,
    acceptSignUpFormInvitationRequest,
    changeOrganizationGroupRequest,
    changeOrganizationRequest,
    createAuthPageOrganizationRequest,
    fetchProfileFeaturePermissionsRequest,
    fetchUserOrganizationFeaturePermissionsRequest,
    loadAuthRequest,
    resendCodeRequest,
    resetPasswordRequest,
    sendResetEmailRequest,
    setUserIsImpersonating,
    signInMfaRequest,
    signInRequest,
    signOutRequest,
    signUpRequest,
    verifyEmailRequest,
} from 'actions/auth'

import {
    acceptInvitationWorker,
    acceptSignUpFormInvitationWorker,
    changeOrganizationGroupWorker,
    changeOrganizationWorker,
    createAuthPageOrganizationWorker,
    fetchProfileFeaturePermissionsWorker,
    fetchUserOrganizationFeaturePermissionsWorker,
    impersonationModeWorker,
    loadAuthWorker,
    resendCodeWorker,
    resetPasswordWorker,
    sendResetEmailWorker,
    signInMfaWorker,
    signInWorker,
    signOutWorker,
    signUpWorker,
    verifyEmailWorker,
} from './workers'

export default function* authWatcher() {
    yield all([
        takeLatest(loadAuthRequest.toString(), loadAuthWorker),

        takeLatest(signUpRequest.toString(), signUpWorker),
        takeLatest(signInRequest.toString(), signInWorker),
        takeLatest(signInMfaRequest.toString(), signInMfaWorker),
        takeLatest(resendCodeRequest.toString(), resendCodeWorker),
        takeLatest(signOutRequest.toString(), signOutWorker),

        takeLatest(sendResetEmailRequest.toString(), sendResetEmailWorker),
        takeLatest(resetPasswordRequest.toString(), resetPasswordWorker),

        takeLatest(
            changeOrganizationRequest.toString(),
            changeOrganizationWorker
        ),
        takeLatest(
            changeOrganizationGroupRequest.toString(),
            changeOrganizationGroupWorker
        ),
        takeLatest(
            createAuthPageOrganizationRequest.toString(),
            createAuthPageOrganizationWorker
        ),
        takeLatest(verifyEmailRequest.toString(), verifyEmailWorker),
        takeLatest(
            acceptSignUpFormInvitationRequest.toString(),
            acceptSignUpFormInvitationWorker
        ),
        takeEvery(
            fetchProfileFeaturePermissionsRequest.toString(),
            fetchProfileFeaturePermissionsWorker
        ),
        takeLatest(
            fetchUserOrganizationFeaturePermissionsRequest.toString(),
            fetchUserOrganizationFeaturePermissionsWorker
        ),
        takeLatest(acceptInvitationRequest.toString(), acceptInvitationWorker),
        takeLatest(setUserIsImpersonating.toString(), impersonationModeWorker),
    ])
}
