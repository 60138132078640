import { call, select } from 'redux-saga/effects'

import { makeFetchResourceSuccess } from 'actions/ui/shared'
import { WALMART_AD_GROUP_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import { getWalmartAdGroup } from 'services/cerebroApi/orgScope/walmartApi'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* fetchWalmartAdGroupSaga() {
    const { wmAdGroupId } = yield select(
        selectResourceParamsOfPage,
        WALMART_AD_GROUP_PAGE
    )

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(WALMART_AD_GROUP_PAGE),
        getWalmartAdGroup,
        wmAdGroupId
    )
}

/**
 * Mounts the Walmart Ad Group Page
 */

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* mountPageSaga() {
    yield call(fetchWalmartAdGroupSaga)
}

uiSagaRegistry.registerSagas([WALMART_AD_GROUP_PAGE], {
    mountPageSaga,
})
