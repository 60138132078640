import { ReactElement } from 'react'

import { NumberOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps } from 'antd'
import { useTranslation } from 'react-i18next'

import {
    ContactSupportIcon,
    HelpDocsIcon,
    PlayButtonIcon,
} from 'components/Icons'
import TrackedLink from 'components/Links/TrackedLink'
import { CONTACT_US } from 'configuration/urls'
import { METRICS_GLOSSARY_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { helpCenterExternalUrl, learningHubExternalUrl } from 'helpers/urls'

import * as styles from './styles.scss'

const GetHelpMenu = (): ReactElement => {
    const { t } = useTranslation('common')

    const baseItems: MenuProps['items'] = [
        {
            key: 'helpDocs',
            label: (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={helpCenterExternalUrl()}
                >
                    {t('common:helpDocs', 'Help Docs')}
                </a>
            ),
            icon: <HelpDocsIcon />,
        },
        {
            key: 'learningHub',
            label: (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={learningHubExternalUrl()}
                >
                    {t('common:learningHub', 'Learning Hub')}
                </a>
            ),
            icon: <PlayButtonIcon />,
        },
        {
            key: 'contactSupport',
            label: (
                <a target="_blank" rel="noopener noreferrer" href={CONTACT_US}>
                    {t('common:contactSupport', 'Contact Support')}
                </a>
            ),
            icon: <ContactSupportIcon />,
        },
    ]

    const items = [...baseItems]

    items.push({
        key: 'metricsGlossary',
        label: (
            <TrackedLink to={getPath(METRICS_GLOSSARY_PAGE)} from="GetHelpMenu">
                {t('common:metricsGlossary', 'Metric Glossary')}
            </TrackedLink>
        ),
        icon: <NumberOutlined />,
    })

    return (
        <Dropdown
            overlayClassName={styles['get-help-menu']}
            trigger={['click']}
            placement="bottomLeft"
            menu={{ items }}
        >
            <Button
                color="default"
                variant="link"
                className="fg-text-muted ant-btn ant-btn-link"
            >
                <QuestionCircleOutlined />
                {t('common:getHelp', 'Get Help')}
            </Button>
        </Dropdown>
    )
}

export default GetHelpMenu
