import { useFlags } from 'launchdarkly-react-client-sdk'

// Flags can be any JSON serializable type, not just boolean
// Declare all flags here to keep them in one place
// Note: flag names in the LaunchDarkly UI are
// transformed to camelCase in the React SDK
export interface FeatureFlagsSet {
    compactFilterUx: boolean
    preReleaseMetrics: boolean
    segmentTopKeywords: boolean
    segmentCsvExportEnabled: boolean
    segmentCsvExportHidden: boolean
    keywordLists: boolean
    metricGoals: boolean
    timeSeriesReferenceLines: boolean
    productCatalogPage: boolean
}

// Return a typed set of flags for better typing
// and to centralize dependency on the feature flag library
function useFeatureFlags(): FeatureFlagsSet {
    return useFlags<FeatureFlagsSet>()
}

export default useFeatureFlags
