import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

import CheckIconSvg from './CheckIconSvg'

const CheckIcon = (props: IconProps): ReactElement => (
    <Icon component={CheckIconSvg} {...props} />
)

export default CheckIcon
