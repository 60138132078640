import { ReactElement } from 'react'

import { ArrowRightOutlined } from '@ant-design/icons'
import { Popover, List } from 'antd'
import compact from 'lodash/compact'
import pluralize from 'pluralize'

import Stack from 'components/Layout/Stack'
import { ResourceLink } from 'components/Links/ResourceLink'
import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { truncateLabel } from 'helpers/labels'
import { labelUrl } from 'helpers/urls'
import { useUserHasPermissions } from 'hooks'
import { Label } from 'types'

import LabelColor from '../LabelColor'

import * as styles from './styles.scss'

interface Props {
    labels: Label[]
}

const GroupedLabels = ({ labels }: Props): ReactElement => {
    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    const renderGroupedColors = (): ReactElement => {
        const groupedLabelsPreview = compact(
            labels.map((label, idx) => idx < 3 && label)
        )

        return (
            <Stack
                direction="row"
                alignItems="center"
                className={styles['label-group--wrapper']}
            >
                <div
                    style={{
                        width: groupedLabelsPreview.length * 9,
                        marginRight: 8,
                        position: 'relative',
                    }}
                    className={styles['label-group--color--wrapper']}
                >
                    {groupedLabelsPreview.map((label) => (
                        <div
                            key={label.id}
                            className={styles['label-group--color--border']}
                        >
                            <LabelColor color={label.color} />
                        </div>
                    ))}
                </div>
                <span>{pluralize('Label', labels.length, true)}</span>
            </Stack>
        )
    }

    return (
        <Popover
            content={
                <List
                    size="small"
                    split={false}
                    dataSource={labels}
                    renderItem={(label) => (
                        <List.Item>
                            <ResourceLink
                                to={labelUrl(label.id)}
                                style={{ width: '100%' }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Stack
                                        component="span"
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <LabelColor
                                            color={label.color}
                                            style={{ marginRight: 8 }}
                                        />
                                        {truncateLabel(label.name)}
                                    </Stack>
                                    {!dashboardOnlyExperience && (
                                        <ArrowRightOutlined
                                            style={{ marginLeft: 16 }}
                                        />
                                    )}
                                </Stack>
                            </ResourceLink>
                        </List.Item>
                    )}
                />
            }
        >
            {renderGroupedColors()}
        </Popover>
    )
}

export default GroupedLabels
