import { ReactElement } from 'react'

import Stack from 'components/Layout/Stack'
import { ResourceLink } from 'components/Links/ResourceLink'
import { adGroupUrl } from 'helpers/urls'

import { SelectedSearchTerm } from '../localTypes'
import * as styles from '../styles.scss'

interface Props {
    selectedSearchTerm: SelectedSearchTerm
}

const AdGroupLabel = ({
    selectedSearchTerm: {
        record: { ad_group, campaign },
    },
}: Props): ReactElement => (
    <Stack direction="column">
        <div className={styles.label}>Source Ad Group</div>
        <div className={styles.value}>
            <ResourceLink to={adGroupUrl(campaign.id, ad_group.id)}>
                {ad_group.name}
            </ResourceLink>
        </div>
    </Stack>
)

export default AdGroupLabel
